import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'
import { getPercentage } from '../pages/panel/admin/StatisticsAdminPanel'

ChartJS.register(ArcElement, Tooltip, Legend)

const PlansGraphic = ({ plansPopularity }) => {
  const [plansList, setPlansList] = useState([])

  useEffect(() => {
    const plansSorted = plansPopularity
      .sort((a, b) => a.plan.id - b.plan.id)

    let list = []
    for (const info of plansSorted) {
      list = [
        ...list,
        {
          id: info.plan.id,
          label: `${info.plan.name} - ${info.plan.plan_type.split(' ')[0]}`,
          color: info.plan.color || '#ffffff',
          count: info.count,
          porcent: info.porcent,
        },
      ]
    }
    if(list.length !== 0 )
      setPlansList(list)
    else setPlansList([{
      label: 'Sin información',
      color: '#ECECEC',
      count: 1,
    }])
  }, [plansPopularity])

  return (
    <div className="StatisticsAdminPanel__Graphic-Container">
      <h2 className="text-1">Plan más vendido</h2>

      <div className="StatisticsAdminPanel__Main-Graphic-Card mx-auto">
        <div className="StatisticsAdminPanel__Main-Graphic-Card-Container">
          <Pie
            style={{
              position: 'relative',
              width: '100%',
              maxWidth: '100%',
              height: 'max-content',
              aspectRatio:
              window.innerWidth < 768 ? 3 / 5 : 4 / 3,
            }}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              aspectRatio: window.innerWidth < 768 ? 3 / 5 : 4 / 3,
              plugins: {
                legend: {
                  display: true,
                  position: 'bottom',
                  labels: {
                    boxWidth: 25,
                    boxHeight: 25,
                    padding: 25,
                    usePointStyle: true,
                    pieceLabel: {
                      mode: 'percentage',
                      render: 'value',
                    },
                  },
                },
                tooltip: {
                  usePointStyle: true,
                  boxPadding: 3,
                  callbacks: {
                    title: (context) => {
                      const hiddenIndices = []

                      for (const index in context[0].chart._hiddenIndices) {
                        if (context[0].chart._hiddenIndices[index])
                          hiddenIndices.push(index)
                      }

                      hiddenIndices.reverse()

                      const data = [...context[0].dataset.data]
                      hiddenIndices.forEach((index) => {
                        data.splice(index, 1)
                      })

                      let totalCount = 0
                      data.forEach((number) => (totalCount += number))

                      const title = `${context[0].formattedValue} plan${
                        context[0].formattedValue !== '1' ? 'es' : ''
                      }, ${getPercentage(context[0].parsed, totalCount)}%`
                      return title
                    },
                    label: (context) => context.label,
                  },
                },
              },
            }}
            data={{
              labels: plansList.map(
                (plan) => plan.label
              ),
              datasets: [
                {
                  label: plansList.map(
                    (plan) => plan.label
                  ),
                  data: plansList.map((plan) => plan.count),
                  backgroundColor: plansList.map((plan) => plan.color),
                  hoverOffset: 4,
                },
              ],
            }}
          />
        </div>
      </div>
    </div>
  )
}

PlansGraphic.propTypes = {
  plansPopularity: PropTypes.array.isRequired,
}

export default PlansGraphic
