import propTypes from 'prop-types'
import FaqItem from '../components/FaqItem'


const Faqs = ({ faqsGroups }) => {
  return (
    <div className="Faqs container-custom text-start">
      <div className="Faqs__Container">
        {faqsGroups?.map(group => (
          <FaqItem key={group.id} name={group.name} faqs={group.faqs} />
        ))}
      </div>
    </div>
  )
}

Faqs.propTypes = {
  faqsGroups: propTypes.array
}

export default Faqs