import { useContext, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import Table from './Table'
import TableSearcher from '../components/TableSearcher'
import DateSelector from '../components/DateSelector'
import { AppContext } from '../context'
import useApi from '../hooks/useApi'
import { getMeetingRoomsBooks } from '../api/meet-rooms'
import moment from 'moment'
import { getSystemPlans } from '../api/plans'
import Dropdown from '../components/Dropdown'


const RoomsGeneralAdmin = ({ showEmailModal }) => {
  const { token, rooms } = useContext(AppContext)
  const [firstTime, setFirstTime] = useState(true)
  const [page, setPage] = useState(1)
  const per_page = 25
  const [search, setSearch] = useState('')
  const [dateRange, setDateRange] = useState(
    {
      date1: moment().format('YYYY-MM-DD'),
      date2: moment().add(6, 'd').format('YYYY-MM-DD'),
    }
  )
  const [dateRangeSelected, setDateRangeSelected] = useState({
    id: 1,
    label: 'Semana',
    value: '1 week',
  })
  const [filtersSelected, setFiltersSelected] = useState([
    {
      label: 'Por sala',
      defaultOption: {
        label: 'Todas las salas',
        value: '',
      },
      option: {
        label: 'Todas las salas',
        value: '',
      },
    },
    {
      label: 'Por plan',
      defaultOption: {
        label: 'Todos los planes',
        value: '',
      },
      option: {
        label: 'Todos los planes',
        value: '',
      },
    },
    {
      label: 'Por pago',
      defaultOption: {
        label: 'Todos los pagos',
        value: '',
      },
      option: {
        label: 'Todos los pagos',
        value: '',
      },
    },
    {
      label: 'Por estado',
      defaultOption: {
        label: 'Todos los estados',
        value: '',
      },
      option: {
        label: 'Todos los estados',
        value: '',
      },
    },
  ])
  const [order_by, setOrderBy] = useState('')
  const body = {
    page,
    per_page,
    search,
    date1: dateRange.date1,
    date2: dateRange.date2,
    meeting_room: filtersSelected[0].option.value,
    plan: filtersSelected[1].option.value,
    is_paid: filtersSelected[2].option.value,
    is_debtor: filtersSelected[3].option.value,
    order_by
  }
  if (order_by === '-start_date,end_date') {
    body.order_by = '-start_date,-end_date'
  }
  const [mettingRoomsBooks] = useApi(
    () => getMeetingRoomsBooks(token, body),
    {
      page: 1,
      total_pages: 1,
      total_items: 0,
      data: [],
    },
    [token, page, search, filtersSelected, dateRange, order_by]
  )
  const [plans] = useApi(getSystemPlans, {
    data: {},
    is_selling_allowed: true,
  })
  const plansNames = Object.keys(plans.data)

  useEffect(() => {
    setPage(1)
  }, [search, filtersSelected])

  const handleSearch = (e) => {
    setSearch(e.target.value)
  }

  useEffect(() => {
    if(firstTime) return setFirstTime(false)

    const currentDate = moment().format('YYYY-MM-DD')

    switch (dateRangeSelected.value) {
    case '1 day': {
      return setDateRange({
        date1: currentDate,
        date2: currentDate // tomorrow
      })
    }
    case 'tomorrow': {
      const tomorrow = moment().add(1, 'd').format('YYYY-MM-DD')
      return setDateRange({
        date1: tomorrow,
        date2: tomorrow // dayAfterTomorrow
      })
    }
    case '1 week': {
      const date1Week = moment().add(6, 'd').format('YYYY-MM-DD')
      return setDateRange({
        date1: currentDate,
        date2: date1Week,
      })
    }
    case '1 mount': {
      const date1Mount = moment().add(1, 'M').subtract(1, 'd').format('YYYY-MM-DD')
      return setDateRange({
        date1: currentDate,
        date2: date1Mount,
      })
    }

    default:
      break
    }
  }, [dateRangeSelected])

  const options = [
    {
      id: 0,
      label: 'Hoy',
      value: '1 day'
    },
    {
      id: 1,
      label: 'Mañana',
      value: 'tomorrow'
    },
    {
      id: 2,
      label: 'Semana',
      value: '1 week',
    },
    {
      id: 3,
      label: 'Mes',
      value: '1 mount',
    },
  ]

  const handleDateRangeSelected = (dateIndex) => {
    setDateRangeSelected(options[dateIndex])
  }

  const handleDateRangeCustomSelected1 = (value) => {
    if(value === '') return
    let date1 = moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD')

    if(date1 > dateRange.date2)
      date1 = moment(dateRange.date2).format('YYYY-MM-DD')

    setDateRange(prev => ({
      date1,
      date2: prev.date2
    }))

    setDateRangeSelected({
      id: 3,
      label: 'Período personalizado',
      value: 'custom',
    })
  }

  const handleDateRangeCustomSelected2 = (value) => {
    if(value === '') return
    let date2 = moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD')

    if(date2 < dateRange.date1)
      date2 = moment(dateRange.date1).format('YYYY-MM-DD')

    setDateRange(prev => ({
      date1: prev.date1,
      date2,
    }))
    setDateRangeSelected({
      id: 3,
      label: 'Período personalizado',
      value: 'custom',
    })
  }

  const filtersForCard = [
    {
      label: 'Por sala',
      options: rooms.map((mettingRoom) => ({
        label: `Sala ${mettingRoom.name.toLowerCase()}`,
        value: mettingRoom.id,
      })),
    },
    {
      label: 'Por plan',
      options: plansNames.map((planName) => ({
        label: `Plan ${planName.toLowerCase()}`,
        value: planName,
      })),
    },
    {
      label: 'Por pago',
      options: [
        {
          label: 'Si',
          value: 'true',
        },
        {
          label: 'No',
          value: 'false',
        },
      ],
    },
    {
      label: 'Por estado',
      options: [
        {
          label: 'Activo',
          value: 'false',
        },
        {
          label: 'Semibloqueado',
          value: 'true',
        },
      ],
    },
  ]

  const tableHeaders = [
    {
      id: 0,
      label: 'Fecha',
      value: 'start_date,end_date',
    },
    {
      id: 1,
      label: 'Nombre y Apellido',
      value: 'full_name',
    },
    {
      id: 2,
      label: 'Empresa',
      value: 'company',
    },
    {
      id: 3,
      label: 'Horas',
      value: 'hours',
    },
    {
      id: 4,
      label: 'Sala',
      value: 'meeting_room',
    },
    'Horario',
    'Plan',
    {
      id: 7,
      label: 'Pago',
      value: 'is_paid',
    },
    {
      id: 8,
      label: 'Estado',
      value: 'status',
    },
  ]
  const tableHeadersMobile = [
    {
      id: 0,
      label: 'Fecha',
      value: 'start_date,end_date',
    },
    {
      id: 1,
      label: 'Nombre y Apellido',
      value: 'full_name',
    },
    'Hora',
    {
      id: 3,
      label: 'Sala',
      value: 'meeting_room',
    },
  ]

  return (
    <section className="RoomsGeneralAdmin">
      <header className="RoomsGeneralAdmin__Header">
        <Dropdown
          options={options}
          optionSelected={dateRangeSelected}
          onChange={handleDateRangeSelected}
          type={'Rounded'}
          maxItems={4}
        />

        <div className='d-flex flex-wrap align-items-center gap-3'>
          <div className='d-flex align-items-center gap-3'>
            <span>Desde:</span>
            <DateSelector
              dateSelected={moment(dateRange.date1, 'YYYY-MM-DD').format('DD/MM/YYYY')}
              handleDateSelected={handleDateRangeCustomSelected1}
            />
          </div>

          <div className='d-flex align-items-center gap-3'>
            <span>Hasta:</span>
            <DateSelector
              dateSelected={moment(dateRange.date2, 'YYYY-MM-DD').format('DD/MM/YYYY')}
              handleDateSelected={handleDateRangeCustomSelected2}
            />
          </div>
        </div>

        <div className="SpacesGeneralAdmin__Buttons">
          <button className="button-green-panel" onClick={showEmailModal}>
            Enviar mail
          </button>
        </div>

        <TableSearcher
          search={search}
          handleSearch={handleSearch}
          filtersSelected={filtersSelected}
          setFiltersSelected={setFiltersSelected}
          filtersForCard={filtersForCard}
        />
      </header>

      <Table
        type="Rooms"
        headers={tableHeaders}
        headersMobile={tableHeadersMobile}
        data={mettingRoomsBooks.data}
        page={mettingRoomsBooks.page}
        setPage={setPage}
        perPage={per_page}
        totalPages={mettingRoomsBooks.total_pages}
        totalItems={mettingRoomsBooks.total_items}
        setOrderBy={setOrderBy}
        showInfoMobile
        unselectable
      />
    </section>
  )
}

RoomsGeneralAdmin.propTypes = {
  showEmailModal: propTypes.func.isRequired,
}

export default RoomsGeneralAdmin
