import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faChevronDown,
  faPhone,
  faUser,
  faBriefcase,
  faCircleExclamation,
} from '@fortawesome/free-solid-svg-icons'
import PlanCards from '../../containers/PlanCards'
import EventsSlider from '../../containers/EventsSlider'
import Benefits from '../../containers/Benefits'
import Reviews from '../../containers/Reviews'
import Partners from '../../containers/Partners'
import Faqs from '../../containers/Faqs'
import Banner from '../../components/Banner'
import MiniBanner from '../../components/MiniBanner'
import EventBackground from '../../assets/events/events-background.webp'
import PlantImage1 from '../../assets/details/plant-1.webp'
import PlantImage2 from '../../assets/details/plant-2.webp'
import MapIcon from '../../assets/brand/map-icon-white.svg'
import BusIcon from '../../assets/map/bus.png'
import BarIcon from '../../assets/map/bar.png'
import BankIcon from '../../assets/map/bank.png'
import CenterIcon from '../../assets/map/center.png'
import PlaneIcon from '../../assets/map/plane.png'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { postIndexForm } from '../../api/community'
import FinishModal from '../../components/modals/FinishModal'
import InputAnimated from '../../components/InputAnimated'
import { getFaqs } from '../../api/admin-settings'
import useApi from '../../hooks/useApi'


const Home = () => {
  const [isMobile, setIsMobile] = useState(false)
  const [showSelector, setShowSelector] = useState(false)
  const formTypes = [
    'Espacios compartidos',
    'Salas de reuniones',
    'Oficinas privadas',
    'Eventos sociales',
    'Jornadas laborales',
    'Alianzas estratégicas',
  ]
  const [showModal, setShowModal] = useState(false)
  const formRef = useRef(null)
  const [faqsGroups] = useApi(getFaqs, [])


  useEffect(() => {
    const checkIsMobile = () => {
      if (window.matchMedia('(max-width: 992px)').matches) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    }
    checkIsMobile()

    window.addEventListener('resize', () => {
      checkIsMobile()
    })
  }, [])


  const handleFormType = (type) => {
    formik.setFieldValue('interest', type)
  }

  const handleMenssageChange = (e) => {
    formik.setFieldValue('message', e.target.value)
  }

  const handleShowSelector = () => {
    setShowSelector(!showSelector)
  }

  const toggleShowmodal = () => {
    setShowModal((prev) => !prev)
  }

  const closeShowmodal = () => {
    setShowModal(false)
  }


  const formik = useFormik({
    initialValues: {
      name: '',
      last_name: '',
      company_name: '',
      email: '',
      phone: '',
      interest: '',
      message: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Tu nombre es requerido')
        .max(50, 'Máximo de caracteres: 50'),
      last_name: Yup.string()
        .required('Tu apellido es requerido')
        .max(50, 'Máximo de caracteres: 50'),
      company_name: Yup.string().max(50, 'Máximo de caracteres: 50'),
      email: Yup.string()
        .required('Tu email es requerido')
        .email('Ingrese un email valido')
        .max(60, 'Máximo de caracteres: 60'),
      phone: Yup.number().nullable().typeError('Ingrese solo números'),
      interest: Yup.string().required('Tu interés es requerido'),
      message: Yup.string()
        .required('La consulta es requerida')
        .max(300, 'Máximo de caracteres: 300'),
    }),
    onSubmit: async (data) => {
      if (data.email.includes(' ')) {
        data.email = data.email.replace(/ /g, '')
      }
      const formFiltered = Object.entries(data).reduce(
        (a, [k, v]) => (v ? ((a[k] = v), a) : a),
        {}
      )
      const response = await postIndexForm(formFiltered)
      if (response) {
        toggleShowmodal()

        window.dataLayer.push({
          'event': 'submit_form'
        })
      }
      formik.resetForm()
    },
  })


  const handleAlliance = () => {
    formik.setFieldValue('interest', 'Alianzas estratégicas')
    formRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
  }


  return (
    <>
      <main className="Home landing-content">
        <Banner home videoType="INICIO" textType="inicio" />

        {/* Plans */}
        <section className="Home-Plans py-5 px-3 px-xl-0 text-center position-relative">
          <img
            className="Home-Plans__Plant-1 d-none d-lg-block"
            alt='Plans Plant 1'
            src={PlantImage1}
            loading='lazy'
          />
          <img
            className="Home-Plans__Plant-2"
            alt='Plans Plant 2'
            src={PlantImage2}
            loading='lazy'
          />

          <h2 className="caption mt-0 mt-lg-5 mx-2 mx-sm-0">
            ¡Elegí tu plan en nuestro espacio compartido de trabajo!
          </h2>
          <h2 className="caption mt-2 mx-2 mx-sm-0">
            Coworking en Córdoba
          </h2>
          <p className="mt-5">
            Ideales para startups, empresas, freelancers y emprendedores que buscan crecer en el ámbito profesional y personal.
          </p>
          <PlanCards />
        </section>

        {/* MiniBanners */}
        <MiniBanner type="INICIO BANNER 1">
          <h2 className="caption">Oficinas privadas</h2>
          <p className='text-4'>Listas para elevar tu proyecto al siguiente nivel.</p>
          <Link to="/oficinas" className="button-white-orange fw-bold">
            ¡Conocelas!
          </Link>
        </MiniBanner>

        <MiniBanner type="INICIO BANNER 2" right>
          <h2 className="caption"> Salas de reuniones</h2>
          <p className='text-4'>Fusionar ideas y conocimientos siempre es una buena opción.</p>
          <Link to="/salas" className="button-white-gray fw-bold">
            ¡Descubrilas!
          </Link>
        </MiniBanner>

        {/* Events */}
        <section className="Home-Events constainer-fluid mt-5 mt-md-0 mt-lg-0 py-5 px-3 px-xl-0">
          <img
            className="Home-Events__Background"
            src={EventBackground}
            alt='Events'
            loading='lazy'
          />

          <h2 className="caption mt-0 mt-md-5 mb-4">Eventos</h2>
          <p className="text-3 mb-0">Diseñamos experiencias.</p>
          <p className="text-3 mt-4 mt-md-0">
            Te acompañamos y asesoramos en la creación de tu evento corporativo o social.
          </p>
          <EventsSlider />
        </section>

        {/* Partners & Reviews */}
        <section className="Home-Reviews background-white position-relative">
          <img
            className="Home-Reviews__Plant"
            alt='Reviews Plant'
            src={PlantImage2}
            loading='lazy'
          />

          <div className="Home-Reviews__Content py-5 pb-4 px-3 px-xl-0 text-center">
            <div className="mt-0 mt-lg-5 mb-5">
              <h2 className="caption mb-4">Nos eligen</h2>
              <p className="Home-Reviews__Description text-3 mb-5">
                Confían y apoyan nuestro proyecto.
              </p>
              <Partners />
            </div>

            <div className="pt-5 pb-4">
              <h2 className="caption mb-4">Testimonios</h2>
              <p className="Home-Reviews__Description text-3">
                Te contamos cómo nuestra comunidad siente Vida.
              </p>
            </div>
            <Reviews />
          </div>
        </section>

        {/* Community */}
        <section className="background-white py-5 px-3 px-xl-0 text-center position-relative">
          <h2 className="caption mt-0 mt-lg-5 mb-4">Beneficios de Vida Cowork</h2>
          <p className="text-3 m-0">
            ¡Un plus! Siendo parte podés acceder a estos servicios.
          </p>
          <Benefits />
          <button className="button-orange mx-auto" onClick={handleAlliance}>
            ¡Quiero sumarme!
          </button>
        </section>

        {/* Map */}
        <section className="Home-Map container-fluid py-5 position-relative">
          <div className="container-custom d-flex flex-column align-items-center px-0 px-lg-3 px-xl-0 text-center">
            <div className="Home-Map__Title-Container mt-0 mt-lg-5 mb-4">
              <img
                className="Home-Map__Title-Image d-none d-lg-block"
                src={MapIcon}
                width={41}
                height={60}
                alt="Vida, en un lugar estratégico"
                title="Vida, en un lugar estratégico"
                loading='lazy'
              />
              <h2 className="Home-Map__Title caption">Ubicaciones estratégicas de los coworkings</h2>
              <div className="Home-Map__Title-Line"></div>
              <div className="Home-Map__Address">
                <img
                  className="Home-Map__Title-Image d-block d-lg-none"
                  src={MapIcon}
                  width={34}
                  height={50}
                  alt="Vida, en un lugar estratégico"
                  title="Vida, en un lugar estratégico"
                  loading='lazy'
                />
                <div>
                  <h3 className="text-2">Fernando Fader 3971 | Hugo Wast 4557</h3>
                  <span className="text-4 fst-italic fw-light">
                    Cerro de Las Rosas | Córdoba
                  </span>
                </div>
              </div>
            </div>
            <div className="row w-100 pb-0 pb-lg-5 py-5">
              <div className="col-12 col-xl-7 px-0">
                <div className="Home-Map__Map-Container">
                  <iframe
                    className="Home-Map__Map"
                    src="https://www.google.com/maps/d/u/0/embed?mid=1PyGhgOe8qP5nyG7iND21CMavpL6AQ1c&ehbc=2E312F"
                    loading="lazy"
                    title='Ubicaciones de Vida Cowork'
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
              <div className="col-12 col-xl-5 d-flex justify-content-center align-items-center px-0 px-xl-5 pt-5 pt-xl-0">
                <div className="Home-Map__Items">
                  <p className="caption mb-3 mb-lg-5 d-block d-xl-none">
                    Información Adicional
                  </p>
                  <div className="Home-Map__Items-Container">
                    <div className="Home-Map__Item">
                      <img
                        className="Home-Map__Item-Image"
                        src={BankIcon}
                        alt="300 metros de la zona bancaria"
                        title="300 metros de la zona bancaria"
                        loading='lazy'
                      />
                      <p className="Home-Map__Item_Text text-4">
                        300 mts. de bancos y ATMs
                      </p>
                    </div>

                    <div className="Home-Map__Item">
                      <img
                        className="Home-Map__Item-Image"
                        src={BarIcon}
                        alt="Cercanía a bares y restaurantes"
                        title="Cercanía a bares y restaurantes"
                        loading='lazy'
                      />
                      <p className="Home-Map__Item_Text text-4">
                        200 mts. de cafeterías, restaurantes y bares
                      </p>
                    </div>

                    <div className="Home-Map__Item">
                      <img
                        className="Home-Map__Item-Image"
                        src={PlaneIcon}
                        alt="12” del aeropuerto"
                        title="12” del aeropuerto"
                        loading='lazy'
                      />
                      <p className="Home-Map__Item_Text text-4">
                        12’ del Aeropuerto Internacional de Córdoba
                      </p>
                    </div>

                    <div className="Home-Map__Item">
                      <img
                        className="Home-Map__Item-Image"
                        src={CenterIcon}
                        alt="15” del centro"
                        title="15” del centro"
                        loading='lazy'
                      />
                      <p className="Home-Map__Item_Text text-4">
                        15’ del centro de la ciudad
                      </p>
                    </div>

                    <div className="Home-Map__Item">
                      <img
                        className="Home-Map__Item-Image"
                        src={BusIcon}
                        alt="Colectivos 13, 18, 19, 80, 83"
                        title="Colectivos 13, 18, 19, 80, 83"
                        loading='lazy'
                      />
                      <p className="Home-Map__Item_Text text-4">
                        Colectivos{' '}
                        <span className="d-none d-md-inline">
                          desde Zona Centro y Nueva Córdoba:
                        </span>{' '}
                        <span className="fw-light">13, 18, 19, 80, 83, B1</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <img
            className="Home-Map__Plant"
            alt='Map Plant'
            src={PlantImage1}
            loading='lazy'
          />
        </section>

        {/* FAQs */}
        {faqsGroups?.length > 0 && (
          <section className="text-center py-4 py-md-5 px-3">
            <div className="py-4 py-md-5">
              <h2 className='caption mb-4'>Preguntas frecuentes</h2>
              <p className="text-3 mb-5">
              ¿Tenés dudas? Quizás podemos resolverlas
              </p>
              <Faqs faqsGroups={faqsGroups}/>
            </div>
          </section>
        )}

        {/* Form */}
        <section
          id='envia-tu-consulta'
          className="Home-Contact container-fluid d-flex justify-content-center position-relative"
          ref={formRef}
        >
          <img
            className="Home-Contact__Plant-1"
            alt='Contact Plant 1'
            src={PlantImage2}
            loading='lazy'
          />
          <img
            className="Home-Contact__Plant-2"
            alt='Contact Plant 2'
            src={PlantImage2}
            loading='lazy'
          />
          <div className="Home-Contact__Content container-custom text-center py-0 py-lg-5">
            {!isMobile && (
              <div className="Home-Contact__Title-Container d-none d-lg-block">
                <p className="Home-Contact__Title mb-1 fw-bold">Envía tu consulta</p>
              </div>
            )}
            <form className="Home-Contact__Form py-0 py-lg-5" action="">
              {isMobile && (
                <p className="Home-Contact__Title mb-1 mx-auto d-block d-lg-none fw-bold">
                  Envía tu consulta
                </p>
              )}

              <div className="Home-Contact__Inputs-Container">
                <InputAnimated
                  label="Nombre*"
                  name="name"
                  type="text"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.errors.name}
                  icon={faUser}
                />

                <InputAnimated
                  label="Apellido*"
                  name="last_name"
                  type="text"
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                  error={formik.errors.last_name}
                  icon={faUser}
                />

                <InputAnimated
                  label="Nombre de tu empresa"
                  name="company_name"
                  type="text"
                  value={formik.values.company_name}
                  onChange={formik.handleChange}
                  error={formik.errors.company_name}
                  icon={faBriefcase}
                />

                <InputAnimated
                  label="Email/correo electrónico*"
                  name="email"
                  type="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.errors.email}
                  icon={faEnvelope}
                />

                <InputAnimated
                  label="Número telefónico"
                  name="phone"
                  type="tel"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  error={formik.errors.phone}
                  icon={faPhone}
                />
              </div>

              <div
                className="Home-Contact__Select text-3 fw-normal d-flex pb-1 position-relative"
                onClick={handleShowSelector}
              >
                <span className="fw-bold me-2">Quiero saber sobre*:</span>{' '}
                {formik.values.interest}
                <div
                  className={
                    showSelector
                      ? 'Home-Contact__Select-Button--Active'
                      : 'Home-Contact__Select-Button'
                  }
                >
                  <FontAwesomeIcon icon={faChevronDown} />
                </div>
                {formik.errors.interest && (
                  <span className="Input__Error">
                    <FontAwesomeIcon
                      icon={faCircleExclamation}
                      className="Input__Error-Icon"
                    />
                    {formik.errors.interest}
                  </span>
                )}
                {showSelector && (
                  <div className="Home-Contact__Select-Content text-2">
                    {formTypes.map((item, index) => (
                      <button
                        className={
                          item === formik.values.interest
                            ? 'Home-Contact__Select-Item--Active'
                            : 'Home-Contact__Select-Item'
                        }
                        key={`Home-Contact__Select-Item-${index}`}
                        onClick={() => handleFormType(item)}
                      >
                        {item}
                      </button>
                    ))}
                  </div>
                )}
              </div>

              <div className="d-flex flex-column align-items-start position-relative">
                <label htmlFor="" className="text-3 fw-bold mb-2">
                  Consulta*
                </label>
                <textarea
                  className="Home-Contact__Textarea"
                  name=""
                  id=""
                  cols="30"
                  rows="5"
                  placeholder="Escribe tu consulta aquí"
                  value={formik.values.message}
                  onChange={handleMenssageChange}
                />
                {formik.errors.message && (
                  <span className="Input__Error">
                    <FontAwesomeIcon
                      icon={faCircleExclamation}
                      className="Input__Error-Icon"
                    />
                    {formik.errors.message}
                  </span>
                )}
              </div>

              <button
                className="Home-Contact__Submit button-orange mt-3"
                type="submit"
                onClick={formik.handleSubmit}
              >
                  Enviar
              </button>
            </form>
          </div>
        </section>

        {showModal && (
          <FinishModal
            type="--Big"
            title="Formulario consulta"
            message="Formulario enviado"
            description="Tus datos fueron correctamente enviados. En breve nos comunicaremos con vos."
            buttonText="¡Entendido!"
            closeModal={closeShowmodal}
          />
        )}
      </main>
    </>
  )
}

export default Home
