import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import propTypes from 'prop-types'
import PlaceCard from '../components/PlaceCard'


const PlacesCardContainer = ({ data, type, openAddModal, openDeleteModal }) => {
  return (
    <section className="PlacesCardContainer">
      {type === 'rooms' && (
        <header className="PlacesCardContainer__Header">
          <h2 className="text-2">Salas de reunión</h2>
        </header>
      )}

      {type === 'offices' && (
        <header className="PlacesCardContainer__Header">
          <h2 className="text-2">Oficinas</h2>
          <button className="button-green-panel" onClick={openAddModal}>
            <FontAwesomeIcon icon={faPlus}/>
            Agregar oficina
          </button>
        </header>
      )}

      <div className="PlacesCardContainer__Cards">
        {data.map((item) => (
          <PlaceCard
            key={item.id}
            data={item}
            type={type}
            openDeleteModal={openDeleteModal}
          />
        ))}
      </div>
    </section>
  )
}

PlacesCardContainer.propTypes = {
  data: propTypes.array.isRequired,
  type: propTypes.string.isRequired,
  openAddModal: propTypes.func,
  openDeleteModal: propTypes.func,
}

export default PlacesCardContainer