import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useParams } from 'react-router-dom'
import { confirmEmail } from '../../api/auth'


const RegisterToken = () => {
  const [menssage, setMenssage] = useState('¡Tu email ha sido verificado exitosamente!')
  const { token } = useParams()

  useEffect(() => {
    const sendData = async () => {
      const response = await confirmEmail(token)

      if(response.error)
        setMenssage('Opss, Algo salio mal.')
      else if(response.detail === 'Not found.')
        setMenssage('Este email ya fue verificado.')
    }

    sendData()
  }, [token])

  return (
    <>
      <Helmet>
        <title>Vida Cowork: Verificación de email</title>
      </Helmet>

      <main className='Register landing-content'>
        {/* Content */}
        <div className="Register__Row row mx-0 bg-white">
          <div className="col-12 col-lg-6 py-5 d-flex justify-content-center">
            <div className="Register__Content">
              <h1 className="Register__Title text-center text-1">
                {menssage}
              </h1>
              <div className="Register__Form pt-5 pt-md-4 pt-xxl-5">
                <div className="Login__Button-Container">
                  <Link
                    to="/iniciar-sesion"
                    className="Login__Button--Submit"
                  >
                    Iniciar sesión
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="Register__Image col-6"></div>
        </div>
      </main>
    </>
  )
}

export default RegisterToken