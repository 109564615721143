import { useContext } from 'react'
import propTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmarkCircle} from '@fortawesome/free-solid-svg-icons'
import { AppContext } from '../../context'
import ModalHeader from '../ModalHeader'


const ErrorModal = ({ title, message, closeModal, data, type }) => {
  const { isAdmin } = useContext(AppContext)

  const date = new Date(data.date)

  if(type == 'spaces')return (
    <div className="ErrorModal text-center px-3" onClick={closeModal}>
      <div className={'ErrorModal__Card--Medium'} onClick={(e) => e.stopPropagation()}>
        <ModalHeader title={title} closeModal={closeModal} />

        <div className="ErrorModal__Check caption text-center">
          <FontAwesomeIcon icon={faXmarkCircle}/>
          <span className="text-2 mt-2">{ message }</span>
        </div>

        <div className={isAdmin ? 'ErrorModal__Data--Admin' : 'ErrorModal__Data'}>
          {isAdmin && (
            <div className="d-flex flex-column align-items-center">
              <span className="text-3">Usuario</span>
              <span className="text-2 fw-bold">{`${data.first_name} ${data.last_name}` || data.reference}</span>
            </div>
          )}
          <div className="d-flex flex-column align-items-center">
            <span className='text-3'>Fecha</span>
            <span className='text-2 fw-bold'>{date.toLocaleDateString('es-AR', { day: '2-digit', month: '2-digit', year: 'numeric'})}</span>
          </div>
          <div className="d-flex flex-column align-items-center">
            <span className='text-3'>Puesto N°</span>
            <span className='text-2 fw-bold'>{data.chair?.number || data.chair_list[0]?.number}</span>
          </div>
          <div className="d-flex flex-column align-items-center">
            <span className='text-3'>Horario</span>
            <span className='text-2 fw-bold'>{data.booking_type}</span>
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <button className='button-green-panel' onClick={closeModal}>Entendido</button>
        </div>
      </div>
    </div>
  )

  if(type == 'rooms')return (
    <div className="ErrorModal text-center px-3" onClick={closeModal}>
      <div className={'ErrorModal__Card--Medium'} onClick={(e) => e.stopPropagation()}>
        <ModalHeader title={title} closeModal={closeModal} />

        <div className="ErrorModal__Check caption text-center">
          <FontAwesomeIcon icon={faXmarkCircle}/>
          <span className="text-2 mt-2">{ message }</span>
        </div>

        <div className={isAdmin ? 'ErrorModal__Data--Admin' : 'ErrorModal__Data'}>
          {isAdmin && (
            <div className="d-flex flex-column align-items-center">
              <span className="text-3">Usuario</span>
              <span className="text-2 fw-bold">{`${data.first_name} ${data.last_name}` || data.reference}</span>
            </div>
          )}
          <div className="d-flex flex-column align-items-center">
            <span className='text-3'>Fecha</span>
            <span className='text-2 fw-bold'>{new Date(data.start_date).getDate()}/{new Date(data.start_date).getMonth() + 1}</span>
          </div>
          <div className="d-flex flex-column align-items-center">
            <span className='text-3'>Sala</span>
            <span className='text-2 fw-bold'>{data.room_name}</span>
          </div>
          <div className="d-flex flex-column align-items-center">
            <span className='text-3'>Horario</span>
            <span className='text-2 fw-bold'>{data.start_hour} - {data.end_hour}</span>
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <button className='button-green-panel' onClick={closeModal}>Entendido</button>
        </div>
      </div>
    </div>
  )
}

ErrorModal.propTypes = {
  title: propTypes.string.isRequired,
  message: propTypes.string.isRequired,
  closeModal: propTypes.func.isRequired,
  data: propTypes.object.isRequired,
  type: propTypes.oneOf(['spaces', 'rooms']).isRequired,
}

export default ErrorModal