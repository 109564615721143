import { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Table from '../../../containers/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import ModalHeader from '../../ModalHeader'
import useApi from '../../../hooks/useApi'
import { getCompanyDetail } from '../../../api/companies'
import { AppContext } from '../../../context'
import Steps from '../../Steps'


const AssignPlanUserModalStep1 = ({
  closeModal,
  setStep,
  employeesSelected,
  setEmployeesSelected,
  discountCommonUse,
  setDiscountCommonUse,
  company_id,
  notAssingedPlans,
  total_items,
}) => {
  const { token } = useContext(AppContext)
  const [order_by, setOrderBy] = useState('')
  const [companyInfo] = useApi(
    () => getCompanyDetail(token, company_id, { per_page: total_items, order_by }),
    {
      total_hours_to_assign: 0,
      total_credits_to_assign: 0,
      personal: {
        page: 1,
        per_page: 0,
        total_amount: 0,
        data: [],
      },
    },
    [token, company_id, order_by]
  )
  const [companyPersonal, setCompanyPersonal] = useState({
    page: 1,
    per_page: 0,
    total_amount: 0,
    data: [],
  })
  const [plansQuantity, setPlansQuantity] = useState([])
  const [companyEmployeesplans, setCompanyEmployeesplans] = useState([])
  const [employeesSelectedIds, setEmployeesSelectedIds] = useState([])
  const [employeeIndex, setEmployeeIndex] = useState(0)
  const [showSelectPlan, setShowSelectPlan] = useState(false)
  const [showInfo, setShowInfo] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    setCompanyPersonal({
      page: Number(companyInfo.personal.page),
      per_page: Number(companyInfo.personal.per_page),
      total_amount: companyInfo.personal.total_amount,
      data: companyInfo.personal.data.map(employee => ({
        ...employee,
        ...employee.user
      })),
    })
  }, [companyInfo])

  useEffect(() => {
    setEmployeesSelected(
      employeesSelectedIds.map((id) =>
        companyEmployeesplans.find((employee) => employee.id === id)
      )
    )
  }, [employeesSelectedIds, companyEmployeesplans])

  const plansNames = () => {
    let plansList = []
    notAssingedPlans.forEach((plan) => {
      const planIndex = plansList.findIndex(
        (item) => item.id === plan.plan__id
      )
      if (planIndex >= 0) {
        plansList = [
          ...plansList.slice(0, planIndex),
          {
            ...plansList[planIndex],
            quantity: plansList[planIndex].quantity + 1,
          },
          ...plansList.slice(planIndex + 1),
        ]
      } else {
        plansList = [
          ...plansList,
          {
            id: plan.plan__id,
            plan:
              plan.plan__name + ' ' + plan.plan__plan_type.split(' ')[0],
            quantity: 1,
          },
        ]
      }
    })
    plansList = plansList.sort((a,b) => a.id - b.id)

    return plansList
  }

  useEffect(() => {
    setPlansQuantity(plansNames())
  }, [notAssingedPlans])

  useEffect(() => {
    const firstPLan = plansNames()[0]
    setCompanyEmployeesplans(
      companyPersonal.data.map((employee) => ({ ...employee, plan: firstPLan?.plan, plan__id: firstPLan?.id }))
    )
  }, [companyPersonal.data])

  const getPlansSelected = () => {
    let plansSelected = plansQuantity.map((plan) => ({ ...plan, quantity: 0 }))
    const addPlanOfEmployee = (employee) => {
      const planIndex = plansSelected.findIndex(
        (item) => item.id === employee.plan__id
      )
      if (planIndex >= 0)
        plansSelected = [
          ...plansSelected.slice(0, planIndex),
          {
            ...plansSelected[planIndex],
            quantity: plansSelected[planIndex].quantity + 1,
          },
          ...plansSelected.slice(planIndex + 1),
        ]
    }

    employeesSelected.forEach((employee) => addPlanOfEmployee(employee))

    return plansSelected
  }

  useEffect(() => {
    let plansSelected = getPlansSelected()

    const availablePlans = plansQuantity.map((plan) =>
      plansSelected.some(
        (planSelected) =>
          planSelected.plan === plan.plan &&
          planSelected.quantity <= plan.quantity
      )
    )

    const havePlans = availablePlans.every((bool) => bool)

    if (!havePlans)
      setError(
        `no hay suficientes planes de ${
          plansQuantity[availablePlans.findIndex((bool) => !bool)].plan
        } para asignar`
      )
    else setError('')
  }, [companyEmployeesplans, employeesSelected])

  const getAvailablePlans = () => {
    let availablePlans = []
    plansQuantity.forEach((plan) => {
      const planIndex = getPlansSelected().findIndex(
        (planSelected) =>
          planSelected.plan === plan.plan &&
          planSelected.quantity < plan.quantity
      )
      if (planIndex === -1) return

      availablePlans = [
        ...availablePlans,
        { plan__id: plan.id, plan: plan.plan },
      ]
    })
    return availablePlans
  }

  const openShowSelectPlan = (employee) => {
    setEmployeeIndex(
      companyEmployeesplans.findIndex((item) => item.id === employee.id)
    )
    setShowSelectPlan(true)
  }

  const closeShowSelectPlan = () => {
    setShowSelectPlan(false)
  }

  const handleSelectPlan = (plan) => {
    setCompanyEmployeesplans((prev) => {
      return [
        ...prev.slice(0, employeeIndex),
        {
          ...prev[employeeIndex],
          plan: plan.plan,
          plan__id: plan.plan__id
        },
        ...prev.slice(employeeIndex + 1),
      ]
    })
    setShowSelectPlan(false)
  }

  const handleSelectEmployee = (employeeSelected) => {
    if (employeesSelectedIds.includes(employeeSelected.id)) {
      setEmployeesSelectedIds((prev) =>
        prev.filter((val) => val !== employeeSelected.id)
      )
    } else {
      setEmployeesSelectedIds((prev) => [...prev, employeeSelected.id])
    }
  }

  const toggleDiscountCommonUse = () => {
    setDiscountCommonUse((prev) => !prev)
  }

  const toggleShowInfo = () => {
    setShowInfo((prev) => !prev)
  }

  const dataHeaders = [
    {
      id: 0,
      label: 'Nombre y Apellido',
      value: 'full_name'
    },
    {
      id: 1,
      label: 'Tipo',
      value: 'type'
    },
    'Plan'
  ]

  const handleAssingPlans = async () => {
    if (error.length !== 0 || employeesSelectedIds.length === 0) return

    setStep((prev) => prev + 1)
  }

  return (
    <>
      <div className="PanelModal px-3" onClick={closeModal}>
        <div
          className="PanelModal__Card--Big"
          onClick={(e) => e.stopPropagation()}
        >
          <ModalHeader title={'Asignar plan'} closeModal={closeModal} />

          <div className="PanelModal__Plan-Info">
            {plansQuantity.map((plan) => {
              return (
                <div
                  key={plan.id}
                  className={`PanelModal__Plan-Info-Item--${plan.plan} text-5`}
                >
                  <span className="text-4 fw-semibold me-2">
                    {plan.quantity}
                  </span>{' '}
                  {plan.plan}{' '}
                  {plan.quantity === 1 ? 'disponible' : 'disponibles'}
                </div>
              )
            })}
          </div>

          <Table
            type={'Assign-Plan'}
            headers={dataHeaders}
            data={companyEmployeesplans}
            selectedParentItems={employeesSelected}
            handleParentItems={handleSelectEmployee}
            openInfoModal={openShowSelectPlan}
            setOrderBy={setOrderBy}
            rounded
          />

          <div className="PanelModal__Special-Option">
            <div
              className="PanelModal__Special-Option-1"
              onClick={toggleDiscountCommonUse}
            >
              <div
                className={`PanelModal__Checkbox ${
                  discountCommonUse && 'PanelModal__Checkbox--Selected'
                }`}
              ></div>
              <p className="text-4 fw-semibold">
                ¿Descontar créditos y horas para uso común?
              </p>
            </div>
            <FontAwesomeIcon
              icon={faCircleExclamation}
              onClick={toggleShowInfo}
            />
          </div>

          {error.length !== 0 && (
            <div className="d-flex justify-content-center">
              <span className="PanelModal__Error text-5 text-center mt-3">
                {error}
              </span>
            </div>
          )}

          <div className="d-flex justify-content-center">
            <button
              className={`button-green-panel ${
                error.length !== 0 || employeesSelectedIds.length === 0
                  ? 'button-disabled'
                  : ''
              } my-3 `}
              onClick={handleAssingPlans}
            >
              Siguiente
            </button>
          </div>
          <Steps totalCount={2} currentCount={1} />
        </div>
      </div>

      {showInfo && (
        <div className="PanelModal">
          <div className="PanelModal__Card">
            <ModalHeader title={'Info'} closeModal={toggleShowInfo} />

            <p className="my-3">
              Si marca esta opción, un porcentaje de créditos y horas se
              descontará del plan elegido y podrán ser asignados libremente
              por el administrador.
              <br />
              <br />
              <span className="fw-bold">
                Utilice esta opción cuando desee tener créditos y horas para
                uso común
              </span>
              <br />
              <br />
              -Créditos y horas pueden ser asignados a otras personas.
              <br />
              <br />
              -Los integrantes sólo podrán ver sus créditos y horas
              disponibles.
            </p>
          </div>
        </div>
      )}

      {showSelectPlan && (
        <div className="PanelModal">
          <div className="PanelModal__Card">
            <ModalHeader
              title={'Seleccionar plan'}
              closeModal={closeShowSelectPlan}
            />
            <div className="PanelModal__Options-Container mt-3">
              {getAvailablePlans().map((plan) => {
                return (
                  <span
                    key={plan.plan__id}
                    className="PanelModal__Option text-4 fw-bold justify-content-center"
                    onClick={() => handleSelectPlan(plan)}
                  >
                    {plan.plan}
                  </span>
                )
              })}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

AssignPlanUserModalStep1.propTypes = {
  closeModal: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  notAssingedPlans: PropTypes.array.isRequired,
  employeesSelected: PropTypes.array.isRequired,
  setEmployeesSelected: PropTypes.func.isRequired,
  discountCommonUse: PropTypes.bool.isRequired,
  setDiscountCommonUse: PropTypes.func.isRequired,
  company_id: PropTypes.number.isRequired,
  total_items: PropTypes.number.isRequired
}

export default AssignPlanUserModalStep1