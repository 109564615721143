import { faRectangleList } from '@fortawesome/free-regular-svg-icons'
import {
  faDollarSign,
  faPause,
  faPlay,
  faPlus,
  faUserCheck,
  faUserXmark,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import { patchDebtorCompany } from '../api/companies'
import { postCancelPlan, postMarkPlanAsPaid } from '../api/plans'
import { getUserData, getUserDetail, patchBlockUser, patchDebtorUser } from '../api/users'


export const userTableActions = [
  {
    type: 'assign plan',
    label: 'Cargar plan',
    icon: faRectangleList,
    enableClick: (selectedItems) =>
      !selectedItems.every(
        (user) => user.total_debt === 0 && !user.is_debtor && user.is_active && !Array.isArray(user.companies)
      ),
  },
  // Seleccionar de un user a la vez
  // {
  //   type: 'mark as paid',
  //   label: 'Marcar como pago',
  //   icon: faDollarSign,
  //   enableClick: (selectedItems) => {
  //     return !selectedItems.every((plan) => plan.is_debtor)
  //   },
  //   doQuery: async (selectedItems, token, updateData, closeModal, setButtonDisable) => {
  //     console.log(selectedItems)
  //     const dataToPaid = await Promise.all(selectedItems.map(async (item) => {
  //       const data = await getUserDetail(token, item.id)
  //       return data
  //     }))

  //     console.log(dataToPaid)

  //     // let plan_ids = ''
  //     // selectedItems.forEach((plan) => {
  //     //   plan_ids += plan.id + ','
  //     // })
  //     // plan_ids = plan_ids.slice(0, -1)

  //     // const response = await postMarkPlanAsPaid(token, { plan_ids })

  //     // if (response.error) {
  //     //   // console.error(response.error)
  //     //   return
  //     // }
  //     // else if (response) {
  //     //   updateData()
  //     //   closeModal()
  //     // }
  //     setButtonDisable(false)
  //   },
  // },
  {
    type: 'pause service',
    label: 'Marcar como Semibloqueado',
    icon: faPause,
    enableClick: (selectedItems) =>
      !selectedItems.every((user) => user.is_active && !user.is_debtor),
    doQuery: async (selectedItems, token, updateData, closeModal, setButtonDisable) => {
      let user_ids = ''
      selectedItems.forEach((user) => {
        user_ids += user.id + ','
      })
      user_ids = user_ids.slice(0, -1)

      const body = { is_debtor: 'true', user_ids }

      const response = await patchDebtorUser(token, body)

      if (response.error) {
        // console.error(response.error)
        return
      }
      else if (response) {
        updateData()
        closeModal()
      }
      setButtonDisable(false)
    },
  }, {
    type: 'resume service',
    label: 'Reanudar servicio',
    icon: faPlay,
    enableClick: (selectedItems) =>
      !selectedItems.every((user) => user.is_active && user.is_debtor),
    doQuery: async (selectedItems, token, updateData, closeModal, setButtonDisable) => {
      let user_ids = ''
      selectedItems.forEach((user) => {
        user_ids += user.id + ','
      })
      user_ids = user_ids.slice(0, -1)

      const body = { is_debtor: 'false', user_ids }

      const response = await patchDebtorUser(token, body)

      if (response.error) {
        // console.error(response.error)
        return
      }
      else if (response) {
        updateData()
        closeModal()
      }
      setButtonDisable(false)
    },
  }, {
    type: 'block user',
    label: 'Bloquear usuario',
    icon: faUserXmark,
    enableClick: (selectedItems) =>
      !selectedItems.every((user) => user.is_active),
    doQuery: async (selectedItems, token, updateData, closeModal, setButtonDisable) => {
      let user_ids = ''
      selectedItems.forEach((user) => {
        user_ids += user.id + ','
      })
      user_ids = user_ids.slice(0, -1)

      const body = { is_block: 'true', user_ids }

      const response = await patchBlockUser(token, body)

      if (response.error) {
        // console.error(response.error)
        return
      }
      else if (response) {
        updateData()
        closeModal()
      }
      setButtonDisable(false)
    },
  }, {
    type: 'unblock user',
    label: 'Desbloquear usuario',
    icon: faUserCheck,
    enableClick: (selectedItems) =>
      !selectedItems.every((user) => !user.is_active),
    doQuery: async (selectedItems, token, updateData, closeModal, setButtonDisable) => {
      let user_ids = ''
      selectedItems.forEach((user) => {
        user_ids += user.id + ','
      })
      user_ids = user_ids.slice(0, -1)

      const body = { is_block: 'false', user_ids }

      const response = await patchBlockUser(token, body)

      if (response.error) {
        // console.error(response.error)
        return
      }
      else if (response) {
        updateData()
        closeModal()
      }
      setButtonDisable(false)
    }
  }
]

export const plansTableActions = [
  {
    type: 'mark as paid',
    label: 'Marcar como pago',
    icon: faDollarSign,
    enableClick: (selectedItems) => !selectedItems.every((plan) => !plan.paid),
    doQuery: async (selectedItems, token, updateData, closeModal, setButtonDisable) => {
      let plan_ids = ''
      selectedItems.forEach((plan) => {
        plan_ids += plan.id + ','
      })
      plan_ids = plan_ids.slice(0, -1)

      const response = await postMarkPlanAsPaid(token, { plan_ids })

      if (response.error) {
        // console.error(response.error)
        return
      }
      else if (response) {
        updateData()
        closeModal()
      }
      setButtonDisable(false)
    },
  },
  {
    type: 'cancel plan',
    label: 'Cancelar plan',
    icon: faXmark,
    enableClick: (selectedItems) => selectedItems.length !== 1 || !selectedItems.every((plan) => {
      let totalCredits = 0
      let totalHours = 0


      if (!plan.user_is_first_time) {
        totalCredits = plan.plan_credits
        totalHours = plan.plan_hours
      } else {
        if (plan.plan_credits >= 2) {
          totalCredits = 2
          totalHours = 0
        } else {
          totalCredits = plan.plan_credits
          totalHours = 0
        }
      }

      let availableCredits = Number(plan.available_credits.split('/')[0])
      let availableHours = Number(plan.available_hours.split('/')[0])

      return totalCredits === availableCredits && totalHours === availableHours
    }),
    doQuery: async (selectedItems, token, updateData, closeModal, setButtonDisable) => {
      const body = {
        user_plan_id: selectedItems[0].id
      }

      const response = await postCancelPlan(token, body)

      if (response.error) {
        // console.error(response.error)
        return
      }
      else if (response) {
        updateData()
        closeModal()
      }
      setButtonDisable(false)
    },
  },
]

export const extraTableActions = [
  {
    type: 'assign hours and credits',
    label: 'Horas o creditos',
    icon: faPlus,
    enableClick: (selectedItems) =>
      selectedItems.length !== 1 ||
      selectedItems[0].is_debtor
  },
  {
    type: 'mark as paid modal',
    label: 'Marcar como pago',
    icon: faDollarSign,
    enableClick: (selectedItems) => selectedItems.length !== 1 || !selectedItems.every((plan) => !plan.paid),
    // doQuery: async (selectedItems, token, updateData, closeModal, setButtonDisable) => {
    //   let plan_ids = ''
    //   selectedItems.forEach((plan) => {
    //     plan_ids += plan.id + ','
    //   })
    //   plan_ids = plan_ids.slice(0, -1)

    //   const response = await postMarkPlanAsPaid(token, { plan_ids })

    //   if (response.error) {
    //     // console.error(response.error)
    //     return
    //   }
    //   else if (response) {
    //     updateData()
    //     closeModal()
    //   }
    //   setButtonDisable(false)
    // },
  },
]

export const companiesTableActions = [
  {
    type: 'assign plan company',
    label: 'Cargar plan',
    icon: faRectangleList,
    enableClick: (selectedItems) =>
      !selectedItems.every(
        (user) => user.total_debt === 0 && !user.is_debtor
      ),
  }, {
    type: 'mark as paid',
    label: 'Marcar como pago',
    icon: faDollarSign,
    enableClick: (selectedItems) =>
      !selectedItems.every((company) => company.total_debt),
    doQuery: async (selectedItems, token, updateData, closeModal, setButtonDisable) => {
      let plan_ids = ''
      selectedItems.forEach((company) => {
        let companyPlansIds = ''
        company.plans_to_pay.forEach(
          (planId) => (companyPlansIds += planId + ',')
        )
        plan_ids += companyPlansIds
      })
      plan_ids = plan_ids.slice(0, -1)

      const response = await postMarkPlanAsPaid(token, { plan_ids })

      if (response.error) {
        // console.error(response.error)
        return
      }
      else if (response) {
        updateData()
        closeModal()
      }
      setButtonDisable(false)
    },
  },
  {
    type: 'pause service',
    label: 'Marcar como Semibloqueado',
    icon: faPause,
    enableClick: (selectedItems) =>
      !selectedItems.every((user) => !user.is_debtor),
    doQuery: async (selectedItems, token, updateData, closeModal, setButtonDisable) => {
      let company_ids = ''
      selectedItems.forEach((company) => {
        company_ids += company.id + ','
      })
      company_ids = company_ids.slice(0, -1)

      const body = { is_debtor: 'true', company_ids }

      const response = await patchDebtorCompany(token, body)

      if (response.error) {
        // console.error(response.error)
        return
      }
      else if (response) {
        updateData()
        closeModal()
      }
      setButtonDisable(false)
    },
  },
  {
    type: 'resume service',
    label: 'Reanudar servicio',
    icon: faPlay,
    enableClick: (selectedItems) =>
      !selectedItems.every((user) => user.is_debtor),
    doQuery: async (selectedItems, token, updateData, closeModal, setButtonDisable) => {
      let company_ids = ''
      selectedItems.forEach((company) => {
        company_ids += company.id + ','
      })
      company_ids = company_ids.slice(0, -1)

      const body = { is_debtor: 'false', company_ids }

      const response = await patchDebtorCompany(token, body)

      if (response.error) {
        // console.error(response.error)
        return
      }
      else if (response) {
        updateData()
        closeModal()
      }
      setButtonDisable(false)
    },
  },
]

// export const dataTableActions = [
//   {
//     type: 'mark as paid',
//     label: 'Marcar como pago',
//     icon: faDollarSign,
//     enableClick: () => {},
//     doQuery: async () => {},
//   },
//   {
//     type: 'pause service',
//     label: 'Pausar servicio',
//     icon: faPause,
//     enableClick: () => {},
//     doQuery: async () => {},
//   },
//   {
//     type: 'resume service',
//     label: 'Reanudar servicio',
//     icon: faPlay,
//     enableClick: () => {},
//     doQuery: async () => {},
//   },
// ]
