import Services from '../../containers/Services'
import ImagesSlider from '../../containers/ImagesSlider'
import Banner from '../../components/Banner'
import comuIcon from '../../assets/icons/Comu-04.png'
import librestressIcon from '../../assets/icons/librestress-09.png'
import flexIcon from '../../assets/icons/flexi-08.png'
import Plant1 from '../../assets/details/plant-1.webp'
import Plant2 from '../../assets/details/plant-2.webp'
import { Helmet } from 'react-helmet'
import Media from '../../components/Media'
import useApi from '../../hooks/useApi'
import { getImages } from '../../api/admin-settings'


const Offices = () => {
  const [images] = useApi(() => getImages('OFICINAS BANNER 1'), [])
  const [officesImages] = useApi(() => getImages('OFICINAS BANNER 2'), [])


  return (
    <>
      <Helmet>
        <title>Vida Cowork: Oficinas</title>
      </Helmet>
      <main className='Offices landing-content'>
        <Banner videoType='OFICINAS' textType="oficina" />

        {/* Info */}
        <section className='background-white container-fluid position-relative py-5 px-3 px-xl-0' id="info">
          <div className="container-custom pt-0 pt-lg-5 pb-4 pb-lg-5">
            <div className="row">
              <div className='Offices-Info col-12 col-xl-6'>
                <h2 className='text-1 mb-3'>¿Por qué contratar una oficina privada en Vida?</h2>
                <p className='text-4 m-0'>
                Para enfocarte al 100% en tu proyecto o empresa, administrar el tiempo y aumentar la motivación de tu equipo trabajando en un entorno natural con propuestas y experiencias enriquecedoras para su bienestar.
                </p>
                <div className='Offices-Info__List m-0'>
                  <img
                    className='Plans-Info__List-Icon'
                    src={flexIcon}
                    alt='Icono de flexibilidad'
                    title='Icono de flexibilidad'
                    width={60}
                    height={60}
                  />
                  <p className='Offices-Info__List-Item'>Tecnología: <span className='text-4 fw-light'>Wifi de alta velocidad + Back up de emergencia. Sistema de acceso independiente al cowork.</span></p>
                  <img
                    className='Plans-Info__List-Icon'
                    src={comuIcon}
                    alt='Icono de comunidad'
                    title='Icono de comunidad'
                    width={60}
                    height={60}
                  />
                  <p className='Offices-Info__List-Item'>Equipamiento: <span className='text-4 fw-light'>Sillas Ergonomicas, Climatizacion central, Uso de Cocinas y quincho con parrilla e infusiones incluidas.</span></p>
                  <img
                    className='Plans-Info__List-Icon'
                    src={librestressIcon}
                    alt='Icono de estrés cero'
                    title='Icono de estrés cero'
                    width={60}
                    height={60}
                  />
                  <p className='Offices-Info__List-Item'>Estrés cero: <span className='text-4 fw-light'>Despreocupate. Todos los servicios están incluidos</span></p>
                </div>
                <p className='text-4'>
                Salir de la rutina de trabajo, es posible. Diseñamos experiencias para potenciar el trabajo en equipo.
                </p>
              </div>
              <div className="col-12 col-xl-6 d-none d-xl-flex flex-column justify-content-start">
                <Media type='image' className='Offices__Main-Image' src={images[0]?.image ?? ''} alt='' />
              </div>
            </div>
          </div>
        </section>

        {/* Images */}
        <section className='background-white container-fluid pb-4'>
          <ImagesSlider
            title='Mirá tu nueva oficina.'
            images={officesImages}
          />
        </section>

        {/* Services */}
        <section className='background-white container-fluid pb-0 pb-lg-5'>
          <div className="container-custom pt-4 pt-lg-5">
            <h2 className="text-1">Servicios que incluye el espacio</h2>
            <Services category="oficinas" />
          </div>
        </section>

        <img className='Offices__Plant-1' src={Plant1} alt='Plant 1' />
        <img className='Offices__Plant-2' src={Plant2} alt='Plant 2' />
      </main>
    </>
  )
}

export default Offices