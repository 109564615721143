import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation, Pagination } from 'swiper'
import ReviewCard from '../components/ReviewCard'
import { getReviews } from '../api/community'
import useApi from '../hooks/useApi'


const Reviews = () => {
  const [reviews] = useApi(getReviews, [])

  return (
    <div className="Reviews container-custom pb-5">
      <Swiper
        className='Reviews__Swiper'
        modules={[Navigation, Pagination, Autoplay]}
        slidesPerView={1}
        spaceBetween={20}
        breakpoints={{
          576: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          1300: {
            slidesPerView: 3,
            spaceBetween: 20
          },
          1600: {
            slidesPerView: 4,
            spaceBetween: 20
          }
        }}
        navigation
        pagination={{ clickable: true }}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        grabCursor={true}
        loop={true}
        speed={1500}
      >
        {reviews.map((item, index) => (
          <SwiperSlide key={`ReviewCard-${index}`}>
            <ReviewCard {...item}/>
            <div className="swiper-lazy-preloader"></div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default Reviews