import propTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  companiesTableActions,
  extraTableActions,
  plansTableActions,
  userTableActions,
} from '../utils/TableActions'
import { useEffect, useState } from 'react'
import TableActionsModal from './modals/TableActionsModal'


const TableActions = ({ selectedItems, updateData, type }) => {
  const [showTableActionsModal, setShowTableActionsModal] = useState(false)
  const [selectedActionType, setSelectedActionType] = useState({})
  const [actions, setActions] = useState([])

  useEffect(() => {
    const seekActions = () => {
      switch (type) {
      case 'Users':
        return userTableActions
      case 'Plans':
        return plansTableActions
      case 'Hours-And-Credits':
        return extraTableActions
      case 'Companies':
        return companiesTableActions
      default:
        return []
      }
    }

    setActions(seekActions())
  }, [])

  const openShowTableActionsModal = (index) => {
    setShowTableActionsModal(true)
    setSelectedActionType(actions[index])
  }

  const closeShowTableActionsModal = () => {
    setShowTableActionsModal(false)
  }

  if (actions.length !== 0)
    return (
      <>
        <div className="TableActions">
          <div className="h-100 text-3 text-center text-black">
            <div className="TableActions__Select">
              <span className="text-2 fw-bold me-2">
                {selectedItems.length}
              </span>
              {selectedItems.length === 1 ? 'Seleccionado' : 'Seleccionados'}
            </div>
          </div>
          <div className="TableActions__Buttons">
            {actions.map((action, index) => (
              <button
                key={index}
                className={`TableActions__Button${
                  selectedItems.length === 0 || action?.enableClick(selectedItems) ? '--Disabled' : ''
                }`}
                title={(type == 'Users' && action.label.includes('Cargar') && action?.enableClick(selectedItems)) ? 'No le puedes cargar un plan a un usuario empresa' : undefined}
                onClick={() => openShowTableActionsModal(index)}
                disabled={selectedItems.length === 0 || action?.enableClick(selectedItems)}
              >
                <FontAwesomeIcon className="text-4" icon={action.icon} />
                <span className="ms-2 text-5 d-none d-lg-inline">
                  {action.label}
                </span>
              </button>
            ))}
          </div>
        </div>

        {showTableActionsModal && (
          <TableActionsModal
            selectedItems={selectedItems}
            updateData={updateData}
            type={type}
            selectedActionType={selectedActionType}
            closeModal={closeShowTableActionsModal}
          />
        )}
      </>
    )
}

TableActions.propTypes = {
  selectedItems: propTypes.array.isRequired,
  updateData: propTypes.func.isRequired,
  type: propTypes.string.isRequired,
}

export default TableActions
