import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>Vida Cowork</title>
      </Helmet>
      <main className="landing-content d-flex flex-column justify-content-center align-items-center gap-4">
        <h1>Error 404</h1>
        <Link to="/" className="button-orange">
          Volver al inicio
        </Link>
      </main>
    </>
  )
}

export default NotFound