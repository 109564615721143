import { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { AppContext } from '../context'

const PanelNotFound = () => {
  const { firstCharge, user  } = useContext(AppContext)

  return (
    <>
      <Helmet>
        <title>Vida Cowork</title>
      </Helmet>
      <main className="d-flex flex-column justify-content-center align-items-center gap-4 w-100 h-100">
        {(firstCharge || !user) ? (
          <h1 className="text-gray-dark">Cargando...</h1>
        ) : (
          <>
            <h1 className="text-gray-dark">Error 404</h1>
            <Link to="/panel" className="button-orange">
              Volver al general
            </Link>
          </>
        )}
      </main>
    </>
  )
}

export default PanelNotFound