import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import PlanCard from '../components/PlanCard'
import { getSystemPlans } from '../api/plans'
import useApi from '../hooks/useApi'


const PlanCards = () => {
  const [plans] = useApi(getSystemPlans, {
    data: {},
    is_selling_allowed: true,
  })

  return (
    <div className="PlanCards container-custom">
      <div className="PlanCards__Content mb-0 mb-lg-5">
        {Object.keys(plans.data).map((plan, index) => (
          <PlanCard key={`PlanCard-${plan}-${index}`} name={plan} info={plans.data[plan]} />
        ))}
      </div>
      <Swiper
        className='PlanCards__Swiper'
        modules={[Pagination]}
        slidesPerView={1}
        spaceBetween={50}
        speed={1000}
        loop={true}
        pagination={{ clickable: true }}
      >
        {Object.keys(plans.data).map((plan, index) => (
          <SwiperSlide key={`PlanCard-${plan}-${index}`}>
            <PlanCard name={plan} info={plans.data[plan]} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default PlanCards