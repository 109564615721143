import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { getContactInformation, getMeetRoomsTime, getSharedAreasTime } from '../api/admin-settings'
import { getUserData } from '../api/users'
import useApi from '../hooks/useApi'
import { getMeetingRooms } from '../api/meet-rooms'
import { getPlansDescriptions } from '../api/plans'
// import { isAdmin } from '../constants'

export const AppContext = React.createContext()

const AppContextProvider = ({ children }) => {
  // State to avoid deleting token from local storage on page reload
  const [firstCharge, setFirstCharge] = useState(true)
  const [token, setToken] = useState(null)
  const [user, setUser] = useState(null)
  const [isAdmin, setIsAdmin] = useState(null)
  const [rooms, setRooms] = useState([])
  const [sharedAreasTime, setSharedAreasTime] = useState({
    morningStartTime: '',
    morningEndTime: '',
    afternoonStartTime: '',
    afternoonEndTime: '',
  })
  const [meetRoomsTime, setMeetRoomsTime] = useState({
    startTime: '',
    endTime: ''
  })
  const [networks] = useApi(getContactInformation, [])
  const [plansDescriptions] = useApi(getPlansDescriptions, [])

  // console.log(user)

  // Save and remove token in local storage
  useEffect(() => {
    if (!firstCharge) {
      if (token) {
        localStorage.setItem('token', token)
        checkToken()
      } else {
        localStorage.removeItem('token')
      }
    }
  }, [token])

  // Check token and get user data
  useEffect(() => {
    checkToken()

    setFirstCharge(false)
  }, [])

  // Catch local storage changes
  useEffect(() => {
    function storageEventHandler(event) {
      if(!event.isTrusted) {
        if(event.currentTarget.localStorage.token) {
          setToken(event.currentTarget.localStorage.token)
        } else {
          setToken('')
        }
      } else {
        if (event.key === 'token') {
          setToken(event.newValue)
        }
      }
    }

    window.addEventListener('storage', storageEventHandler)
  }, [])

  const checkToken = async () => {
    const savedToken = localStorage.getItem('token')

    if (savedToken) {
      setToken(savedToken)
      updateUser(savedToken)
    }

    // Get meeting rooms
    const roomsData = await getMeetingRooms()
    setRooms(roomsData)

    // Time data
    const sharedAreasTimeData = await getSharedAreasTime()
    const newSharedTimeData = {
      morningStartTime: sharedAreasTimeData[0]?.time_start.slice(0, -3),
      morningEndTime: sharedAreasTimeData[0]?.time_end.slice(0, -3),
      afternoonStartTime: sharedAreasTimeData[1]?.time_start.slice(0, -3),
      afternoonEndTime: sharedAreasTimeData[1]?.time_end.slice(0, -3),
    }

    setSharedAreasTime(newSharedTimeData)

    const meetRoomsTimeData = await getMeetRoomsTime()
    const newMeetRoomsTimeData = {
      startTime: meetRoomsTimeData[0]?.time_start.slice(0, -3),
      endTime: meetRoomsTimeData[0]?.time_end.slice(0, -3),
    }

    setMeetRoomsTime(newMeetRoomsTimeData)
  }

  const updateUser = async (savedToken) => {
    const data = await getUserData(savedToken || token)
    setUser({
      data: data.user,
      profile: data.user_profile,
      admin_in: data.admin_in,
      employee_in: data.employee_in,
      plans: data.user_plans,
      total_credits: data.total_credits,
      total_hours: data.total_hours,
      debt: data.user_debt,
      extra_plans: data.extra_plans,
      total_extra_credit: data.total_extra_credit,
      total_extra_hours: data.total_extra_hours,
    })
    setIsAdmin(
      data.user?.is_admin
    )
  }

  const values = {
    token,
    setToken,
    user,
    setUser,
    updateUser,
    isAdmin,
    setIsAdmin,
    rooms,
    sharedAreasTime,
    meetRoomsTime,
    networks,
    plansDescriptions,
    firstCharge
  }

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>
}

AppContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AppContextProvider
