import propTypes from 'prop-types'
import { useState } from 'react'
import FinishModal from '../FinishModal'
import SolicitPlanModalStep1 from './SolicitPlanModalStep1'
import AssignPlanAdminModalStep2 from './SolicitPlanModalStep2'


const SolicitPlanModal = ({ closeModal, company_id, updateCompanyInfo }) => {
  const [step, setStep] = useState(1)
  const [userSelected, setUserSelected] = useState('Mi empresa')
  const [plansSelected, setPlansSelected] = useState([])

  if (step === 1)
    return (
      <SolicitPlanModalStep1
        closeModal={closeModal}
        setStep={setStep}
        userSelected={userSelected}
        setUserSelected={setUserSelected}
        plansSelected={plansSelected}
        setPlansSelected={setPlansSelected}
      />
    )

  if (step === 2)
    return (
      <AssignPlanAdminModalStep2
        closeModal={closeModal}
        company_id={company_id}
        updateCompanyInfo={updateCompanyInfo}
        setStep={setStep}
        userSelected={userSelected}
        plansSelected={plansSelected}
      />
    )

  if (step === 3)
    return (
      <FinishModal
        type="--Big"
        title="Solicitá un plan"
        message="¡Plan solicitado con éxito!"
        tips={[
          'Recordá que para ingresar al espacio, tiene que estar <b>abonado el plan.</b>',
        ]}
        buttonText="Entendido"
        closeModal={closeModal}
      />
    )
}

SolicitPlanModal.propTypes = {
  closeModal: propTypes.func.isRequired,
  company_id: propTypes.number.isRequired,
  updateCompanyInfo: propTypes.func.isRequired,
}

export default SolicitPlanModal
