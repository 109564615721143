import PlansSlider from '../../containers/PlansSlider'
import ImagesSlider from '../../containers/ImagesSlider'
import Services from '../../containers/Services'
import Banner from '../../components/Banner'
import comuIcon from '../../assets/icons/Comu-04.png'
import librestressIcon from '../../assets/icons/librestress-09.png'
import flexIcon from '../../assets/icons/flexi-08.png'
import Plant1 from '../../assets/details/plant-1.webp'
import Plant2 from '../../assets/details/plant-2.webp'
import { Helmet } from 'react-helmet'
import useApi from '../../hooks/useApi'
import { getImages } from '../../api/admin-settings'
import Media from '../../components/Media'


const Plans = () => {
  const [images] = useApi(() => getImages('PLANES BANNER 1'), [])
  const [plansImages] = useApi(() => getImages('PLANES BANNER 2'), [])


  return (
    <>
      <Helmet>
        <title>Vida Cowork: Planes</title>
      </Helmet>
      <main className='Plans landing-content'>
        <Banner videoType='PLANES' textType="plan" />

        {/* Info */}
        <section className='background-white container-fluid position-relative py-5 px-3 px-xl-0' id="info">
          <div className="container-custom mx-auto pt-0 pt-lg-5 pb-4 pb-lg-5">
            <div className="row">
              <div className='Plans-Info col-12 col-xl-7'>
                <h2 className='text-1 mb-3'>¿Por qué contratar un plan en espacio compartido en Vida?</h2>
                <p className='text-4 m-0'>
                Porque es una opción súper flexible que se adapta a la modalidad de trabajo que necesitás.
                </p>
                <p className="text-4 m-0">
                Nuestros espacios son amplios, luminosos y están equipados con todo lo necesario para tu jornada laboral.
                </p>
                <div className='Plans-Info__List m-0'>
                  <img
                    className='Plans-Info__List-Icon'
                    src={flexIcon}
                    alt='Icono de flexibilidad'
                    title='Icono de flexibilidad'
                    width={60}
                    height={60}
                  />
                  <p className='Plans-Info__List-Item'>Flexibilidad: <span className='text-4 fw-light'>Vos elegís cuando venís a trabajar.</span></p>
                  <img
                    className='Plans-Info__List-Icon'
                    src={comuIcon}
                    alt='Icono de comunidad'
                    title='Icono de comunidad'
                    width={60}
                    height={60}
                  />
                  <p className='Plans-Info__List-Item'>Comunidad: <span className='text-4 fw-light'>Brindamos experiencias y propuestas mensuales. Contamos con una red de beneficios de la mano de marcas y emprendedores de la zona.</span></p>
                  <img
                    className='Plans-Info__List-Icon'
                    src={librestressIcon}
                    alt='Icono de estrés cero:'
                    title='Icono de estrés cero:'
                    width={60}
                    height={60}
                  />
                  <p className='Plans-Info__List-Item'>Estrés cero: <span className='text-4 fw-light'>Despreocupate. Todos los servicios están incluidos.</span></p>
                </div>
                <p className='text-4'>
                  La sinergia que se genera en nuestro espacio nutre, motiva, aporta ideas y potencia a cada coworker de la comunidad. 
                </p>
                <PlansSlider/>
                <div className="d-flex flex-column align-items-center gap-1">
                  <p className='text-6 fw-light'>(*) los valores no incluyen IVA</p>
                  <button className="Plans-Info__Button button-green mx-auto">¡Lo quiero!</button>
                </div>
              </div>
              <div className="col-12 col-xl-5 d-none d-xl-flex flex-column justify-content-start">
                <Media type='image' className='Plans__Main-Image' src={images[0]?.image ?? ''} alt='' />
              </div>
            </div>
          </div>
        </section>

        {/* Images */}
        <section className='background-white container-fluid pb-4'>
          <ImagesSlider
            title='Visualízate en tu nuevo espacio de trabajo.'
            images={plansImages}
          />
        </section>

        {/* Services */}
        <section className='background-white container-fluid pb-0 pb-lg-5'>
          <div className="container-custom pt-4 pt-lg-5">
            <h2 className="text-1">SERVICIOS</h2>
            <Services category="planes" />
          </div>
        </section>

        <img className='Plans__Plant-1' src={Plant1} alt='Plant 1' />
        <img className='Plans__Plant-2' src={Plant2} alt='Plant 2' />
      </main>
    </>
  )
}

export default Plans