import { deleteData, getData, patchData, postData, putData } from './apiVariables'

export const getCompanyDetail = async (token, companyId, params) => {
  if (!companyId) return
  params = Object.entries(params).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {})
  return await getData({
    path: `/companies/detail/${companyId}`,
    token,
    params,
  })
}

export const putEditCompany = async (token, companyId, body) => {
  if (!companyId) return
  return await putData({
    path: `/companies/detail/${companyId}/`,
    token,
    body
  })
}

export const getCompanyEmployees = async (token, params) => {
  return await getData({
    path: '/companies/company-employees',
    token,
    params,
    callback: (data) => data.data,
  })
}

export const getCompanyHoursAndCreditsAtDate = async (token, params) => {
  return await getData({
    path: '/companies/get-company-hours-credits-by-date/',
    token,
    params
  })
}

export const postAddEmployee = async (token, body) => {
  return await postData({
    path: '/companies/company-employees/',
    token,
    body,
  })
}

export const delDeleteEmployee = async (token, body) => {
  return await deleteData({
    path: '/companies/company-employees/',
    token,
    body,
  })
}

export const delDeleteAdmin = async (token, body) => {
  return await deleteData({
    path: '/companies/admins/',
    token,
    body,
  })
}

export const postAssignPlanToEmployee = async (token, body) => {
  return await postData({
    path: '/companies/assign-plan-to-employee/',
    token,
    body,
  })
}

export const postAssignHoursCreditsToEmployee = async (token, body) => {
  return await postData({
    path: '/companies/assign-hours-credits-to-employee/',
    token,
    body,
    callback: (data) => data.data,
  })
}

export const getCompanyBooks = async (token, params) => {
  if (!params.company_id) return
  params = Object.entries(params).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {})
  return await getData({
    path: '/companies/get-company-books/',
    token,
    params,
  })
}

export const patchDebtorCompany = async (token, body) => {
  return await patchData({
    path: '/companies/debtor-company/',
    token,
    body
  })
}

export const getDebtorCompany = async (token, params) => {
  if (!params.company_id) return
  return await getData({
    path: '/companies/debtor-company/',
    token,
    params,
  })
}

export const postCreateCompany = async (token, body) => {
  return await postData({
    path: '/companies/create-company/',
    token,
    body,
  })
}

export const postAssingHoursAndCreditsCompany = async (token, body) => {
  return await postData({
    path: '/companies/add-hours-credits/',
    token,
    body
  })
}

export const postRemoveHoursAndCreditsCompany = async (token, body) => {
  return await postData({
    path: '/companies/remove-hours-credits/',
    token,
    body
  })
}