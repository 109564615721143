import propTypes from 'prop-types'
import PanelModalSelector from './PanelModalSelector'


const PlanOptions = ({
  planIndex,
  plansName,
  planNameSelected,
  handlePlanName,
  isQuantity,
  quantity,
  handlePlanQuantity,
  planPrice,
  handleRemove,
  planOptions,
  planOptionSelected,
  handlePlanOption,
  availablePlans,
}) => {
  return (
    <div className="PanelModal__SolicitPlanModal-Plans-Container-item w-100 pb-3">
      <div className="d-flex justify-content-center pb-3">
        <PanelModalSelector
          planIndex={planIndex}
          options={plansName}
          optionSelected={planNameSelected}
          onChange={handlePlanName}
          isQuantity={isQuantity}
          quantity={quantity}
          handlePlanQuantity={handlePlanQuantity}
          planPrice={planPrice}
          handleRemove={handleRemove}
        />
      </div>
      <div className="PanelModal__Options-Container">
        {planOptions.length > 0 &&
          planOptions.map((option) => {
            const planType = option.plan_type.split(' ')[0]

            return (
              <button
                className={`PanelModal__Option${
                  option.id === planOptionSelected ? '--Active' : ''
                } text-4`}
                key={option.id}
                onClick={() => handlePlanOption(option, planIndex)}
                disabled={
                  option.id !== planOptionSelected &&
                  !availablePlans.some((plan) => plan.id === option.id)
                }
              >
                <div
                  className={`PanelModal__Option-Circle${
                    option.id === planOptionSelected ? '--Active' : ''
                  }`}
                ></div>
                <span>{planType}</span>
                <span>{option.credits} créditos</span>
                <span>{option.hours} hs en salas</span>
                <span className="PanelModal__Option-Price">
                  ${option.price}
                </span>
              </button>
            )
          })}
      </div>
    </div>
  )
}

PlanOptions.propTypes = {
  planIndex: propTypes.number.isRequired,
  plansName: propTypes.array.isRequired,
  planNameSelected: propTypes.string,
  handlePlanName: propTypes.func,
  isQuantity: propTypes.bool,
  quantity: propTypes.number,
  handlePlanQuantity: propTypes.func,
  planPrice: propTypes.number,
  handleRemove: propTypes.func,
  planOptions: propTypes.array,
  planOptionSelected: propTypes.number,
  handlePlanOption: propTypes.func,
  availablePlans: propTypes.array,
}

export default PlanOptions
