import { useState, useEffect, useContext } from 'react'
import propTypes from 'prop-types'
import { AppContext } from '../context'
import { getAvailableChairsByGroup } from '../api/shared-areas'
import Loader from '../components/Loader'


const ChairsSelector = ({ chairsSelected, setChairsSelected, date, bookingType, limit = 1000000 }) => {
  const { token, isAdmin } = useContext(AppContext)
  const [ chairs, setChairs ] = useState([])
  const [ loading, setLoading ] = useState(true)

  const isArray = Array.isArray(chairsSelected)

  // Set chairs
  useEffect(() => {
    const getChairsData = async () => {
      const chairsAvailables = await getAvailableChairsByGroup(token, {
        date: date,
        shift: bookingType
      })

      const normalizedChairs = chairsAvailables.sort((a, b) => a.number - b.number)

      setChairs(normalizedChairs)
      setLoading(false)
    }

    getChairsData()
  }, [])

  const handleSelectChair = ( chair ) => {
    const chair_id = chair.id

    if(isArray) {
      const chairExists = chairsSelected?.find(chair => chair.id == chair_id)

      if(chairExists) {
        const newChairsSelected = chairsSelected.filter(chair => chair.id != chair_id)
        setChairsSelected(newChairsSelected)
      } else {
        if(chairsSelected?.length < limit) {
          const newChairsSelected = [
            ...chairsSelected,
            chair
          ]
          const sortChairs = newChairsSelected.sort((a, b) => a.number - b.number)
          setChairsSelected(sortChairs)
        }
      }
    } else {
      if(chairsSelected && chairsSelected == chair_id) {
        setChairsSelected('')
      } else {
        const newChairSelected = chairs.find(chair => chair.id === chair_id)
        setChairsSelected(newChairSelected)
      }
    }
  }

  const handleSelectAllChairs = () => {
    const availableChairs = chairs.filter(chair => chair.is_available === 1)

    if(chairsSelected?.length === availableChairs.length) return setChairsSelected([])
    else return setChairsSelected(availableChairs)
  }

  if(loading) return (
    <div className="d-flex justify-content-center align-items-center h-100 py-5">
      <Loader />
    </div>
  )

  return (
    <>
      <div className="ChairsSelector">
        {chairs.map((chair) => {
          const isArray = Array.isArray(chairsSelected)
          const isSelected = isArray ? chairsSelected?.find(chairSelected => chairSelected.id === chair.id) : chairsSelected?.id === chair.id

          if (isSelected) return (
            <button
              className="ChairsSelector__Item--Active text-4"
              key={`Chair-Item-Active-${chair.id}`}
              onClick={() => handleSelectChair(chair)}
            >
              { chair.number < 10 ? `0${chair.number}` : chair.number }
            </button>
          )
          else if (chair.is_available !== 1) return (
            <button
              className="ChairsSelector__Item--Disabled text-4"
              key={`Chair-Item-Disabled-${chair.id}`}
              disabled
            >
              { chair.number < 10 ? `0${chair.number}` : chair.number }
            </button>
          )
          else return (
            <button
              className="ChairsSelector__Item text-4"
              key={`Chair-Item-${chair.id}`}
              onClick={() => handleSelectChair(chair)}
            >
              { chair.number < 10 ? `0${chair.number}` : chair.number }
            </button>
          )
        })}
      </div>

      {isAdmin && isArray && (
        <button className="ChairsSelector__Select-All d-flex gap-2 align-items-center mt-3" onClick={handleSelectAllChairs}>
          {chairsSelected?.length === chairs.length
            ? <div className="ChairsSelector__Checkbox--Active"></div>
            : <div className="ChairsSelector__Checkbox"></div>
          }
          <span className="text-5">Seleccionar todos los puestos disponibles</span>
        </button>
      )}
    </>
  )
}

ChairsSelector.propTypes = {
  chairsSelected: propTypes.oneOfType([propTypes.object, propTypes.array, propTypes.string]),
  setChairsSelected: propTypes.func.isRequired,
  date: propTypes.string.isRequired,
  bookingType: propTypes.string.isRequired,
  limit: propTypes.number,
}

export default ChairsSelector