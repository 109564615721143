import { useContext, useEffect, useRef, memo } from 'react'
import propTypes from 'prop-types'
import gsap from 'gsap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faBriefcase, faChampagneGlasses, faUser, faUserSlash } from '@fortawesome/free-solid-svg-icons'
import chroma from 'chroma-js'
import { AppContext } from '../context'


const RoomDayReservation = ({ room, reservation, hours, isPastDay, openOverlay }) => {
  const { user, isAdmin } = useContext(AppContext)
  const reservationRef = useRef(null)

  // Animation & Position
  useEffect(() => {
    if(reservationRef.current) {
      const cellWidth = document.querySelector('.RoomDay__Item-Cell')?.offsetWidth

      // Get index of start and end time
      const start = hours.indexOf(reservation.start_hour)
      const end = hours.indexOf(reservation.end_hour)

      // Position reservation
      gsap.to(reservationRef.current, {
        left: start * cellWidth,
        duration: 0,
      })
      gsap.to(reservationRef.current, {
        width: (end - start) * cellWidth,
        opacity: 1,
        delay: .25
      })
    }
  }, [])

  if(reservation.is_owner || reservation.company_owner || isAdmin) return (
    <button className="RoomDayReservation" ref={reservationRef} onClick={openOverlay}>
      <div
        className='RoomDayReservation__Item'
        style={{
          backgroundColor: room.color,
          borderLeftColor: chroma(room.color).darken(1).hex(),
        }}
      >
        {isAdmin
          ? (
            <>
              {reservation.user
                ? <FontAwesomeIcon icon={faUser} size='xs'/>
                : reservation.reference
                  ? <FontAwesomeIcon icon={faChampagneGlasses} size='xs'/>
                  : <FontAwesomeIcon icon={faUserSlash} size='xs'/>
              }
            </>
          ) : user.admin_in.length > 0 && (
            <>
              {reservation.company_owner
                ? <FontAwesomeIcon icon={faBriefcase} size='xs'/>
                : <FontAwesomeIcon icon={faUser} size='xs'/>
              }
            </>
          )}
        <span className="RoomDayReservation__Text text-6">{reservation.start_hour} a {reservation.end_hour}</span>
      </div>
    </button>
  )
  else if(!isPastDay) return (
    <div className="RoomDayReservation" ref={reservationRef}>
      <div className={'RoomDayReservation__Item--Not-Allowed'}>
        <span className="RoomDayReservation__Text text-6">
          <FontAwesomeIcon icon={faBan}/>
          <span className="ms-2">Ocupado</span>
        </span>
      </div>
    </div>
  )
}

RoomDayReservation.propTypes = {
  room: propTypes.object.isRequired,
  reservation: propTypes.object.isRequired,
  hours: propTypes.array.isRequired,
  isPastDay: propTypes.bool.isRequired,
  openOverlay: propTypes.func.isRequired,
}

export default memo(RoomDayReservation)