import { faTags } from '@fortawesome/free-solid-svg-icons'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import Input from '../../Input'
import ModalHeader from '../../ModalHeader'
import { getCommunityDiscount } from '../../../api/community'
import { AppContext } from '../../../context'
import { postBuyPlanByCompany, postBuyPlanByUser } from '../../../api/plans'
import Steps from '../../Steps'


const AssignPlanAdminModalStep2 = ({
  closeModal,
  company_id,
  updateCompanyInfo,
  setStep,
  userSelected,
  plansSelected,
}) => {
  const { updateUser, token } = useContext(AppContext)
  const [firstShow, setFirstShow] = useState(true)
  const [error, setError] = useState('')
  const [buttonDisable, setButtonDisable] = useState(false)

  const [paySelected, setPaySelected] = useState('')
  const [discount, setDiscount] = useState({ name: '', porcent: 0 })

  const payOptions = ['Coordinar pago con Vida Cowork']

  const prevStep = () => {
    setStep((prev) => prev - 1)
  }

  const joinPlanCompPrices = (isDiscount) => {
    let finalPrice = 0
    plansSelected.forEach((plan) => (finalPrice += plan.price * plan.quantity))
    if (isDiscount) return finalPrice - finalPrice * (discount.porcent / 100)

    return finalPrice
  }

  const joinPlanUserPrices = (isDiscount) => {
    let finalPrice = 0
    plansSelected.forEach((plan) => (finalPrice += plan.price))
    if (isDiscount) return finalPrice - finalPrice * (discount.porcent / 100)

    return finalPrice
  }

  const formik = useFormik({
    initialValues: {
      code: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      code: Yup.string()
        .min(1, 'El codigo de descuento esta vacio')
        .max(40, 'Máximo de caracteres: 40'),
    }),
    onSubmit: async ({ code }) => {
      if (code === '') return
      const params = { code }

      const response = await getCommunityDiscount(token, params)

      if (response.error) {
        formik.setFieldError('code', 'El codigo de descuento no es  valido')
        setDiscount({
          name: '',
          porcent: 0,
        })
      } else if (response) {
        setDiscount({
          name: response.code,
          porcent: response.porcent_discount,
        })
      }
    },
  })

  const handlePayOption = (value) => {
    if (value === paySelected) {
      setPaySelected('')
      setError('Es necesario un metodo de pago')
    } else {
      setPaySelected(value)
    }
  }

  useEffect(() => {
    if (firstShow) return setFirstShow(false)
    const checkPay = paySelected.length === 0
    setError(checkPay ? 'Es necesario un metodo de pago' : '')
  }, [paySelected])

  const handleEndStep = async () => {
    if(buttonDisable) return
    setButtonDisable(true)
    if (paySelected.length === 0) {
      setError('Es necesario un metodo de pago')
      setButtonDisable(false)
      return null
    }

    if (userSelected === 'Mi empresa') {
      let plansIds = ''
      plansSelected.forEach((plan) => {
        let plansQuantityIds = ''
        for (let i = 0; i < plan.quantity; i++) {
          plansQuantityIds += plan.id + ','
        }
        plansIds += plansQuantityIds
      })

      if (plansIds.endsWith(',')) {
        plansIds = plansIds.slice(0, -1)
      }

      const body = {
        plan_ids: plansIds,
        company_id,
      }
      if (discount.porcent !== 0) {
        body.code = discount.name
      }

      const response = await postBuyPlanByCompany(token, body)

      if (response.error === 'Selling is not allowed for the moment')
        setError('La compra de planes esta deshabilitada por el momento')
      else if (response.error === 'You have an unpaid plan')
        setError('El plan no puede ser solicitado si hay planes impagos')
      else if (response) {
        updateCompanyInfo()
        setStep((step) => step + 1)
      }
    } else {
      const body = {
        plan: plansSelected[0].id,
      }
      if (discount.porcent !== 0) {
        body.code = discount.name
      }

      const response = await postBuyPlanByUser(token, body)

      if (response.error === 'Selling is not allowed for the moment'){
        setError('La compra de planes esta deshabilitada por el momento')
      } else if (response.error === 'You have an unpaid plan')
        setError('El plan no puede ser solicitado si hay planes impagos')
      else if (response) {
        updateUser()
        setStep((step) => step + 1)
      }
    }
    setButtonDisable(false)
  }

  return (
    <div className="PanelModal px-3" onClick={closeModal}>
      <div
        className="PanelModal__Card--Big"
        onClick={(e) => e.stopPropagation()}
      >
        <ModalHeader
          title={'Solicitá un plan'}
          closeModal={closeModal}
          goBack={prevStep}
        />

        <div className="py-3">
          <h3 className="text-4 text-center">Detalle de compra</h3>
        </div>

        <div className='PanelModal__Plan-Detail-Container'>
          {plansSelected.map((plan, index) => {
            if (plan.quantity === 0 && userSelected === 'Mi empresa')
              return <></>
            return (
              <div className="PanelModal__Plan-Detail text-uppercase" key={index}>
                <span className="text-3 fw-bold">{plan.name}</span>
                <span className="text-5">{plan.plan_type.split(' ')[0]}</span>
                {userSelected === 'Mi empresa' ? (
                  <>
                    <div className="d-flex align-items-center gap-2">
                      <span className="text-5">cantidad</span>
                      <span className="text-3 fw-bold">{plan.quantity}</span>
                    </div>
                    <span className="text-3 fw-bold">
                      ${plan.price * plan.quantity}
                    </span>
                  </>
                ) : (
                  <span className="text-3 fw-bold">${plan.price}</span>
                )}
              </div>
            )
          })}
        </div>

        <div className="PanelModal__Discount-Container py-3">
          <div className="w-100 h-100 d-flex flex-column justify-content-between align-items-start">
            <form className="d-flex align-items-end gap-2 w-100">
              <Input
                label="Código de descuento"
                name="code"
                value={formik.values.code}
                onChange={formik.handleChange}
                error={formik.errors.code}
                icon={faTags}
                tip="Ingresa aquí tu código de descuento"
              />
              <button
                className="button-green-panel text-4"
                type="submit"
                onClick={formik.handleSubmit}
              >
              Validar
              </button>
            </form>

            <div className="mt-30px">
              <h5 className="text-4 fw-normal">Subtotal</h5>
              <h4 className="text-3 mt-1">
              $
                {userSelected === 'Mi empresa'
                  ? joinPlanCompPrices()
                  : joinPlanUserPrices()}
              </h4>
            </div>
          </div>

          <span className="PanelModal__Discount-Divisor"></span>

          <div className="w-100 d-flex flex-column justify-content-start align-items-start">
            <h4 className="text-3">Pago:</h4>
            <div className="PanelModal__Options-Container my-4">
              {payOptions &&
              payOptions.map((option, index) => {
                return (
                  <button
                    className={`PanelModal__Option${
                      option === paySelected ? '--Active' : ''
                    } text-4 justify-content-start`}
                    key={`Spaces-Reservation-Modal-Option-${index}`}
                    onClick={() => handlePayOption(option)}
                  >
                    <div
                      className={`PanelModal__Option-Circle${
                        option === paySelected ? '--Active' : ''
                      }`}
                    />
                    <span>{option}</span>
                  </button>
                )
              })}
            </div>

            <h5 className="text-3 fw-normal">Total</h5>
            <h4 className="text-2 mt-2">
            $
              {userSelected === 'Mi empresa'
                ? joinPlanCompPrices(true)
                : joinPlanUserPrices(true)}
            </h4>
            {discount.porcent !== 0 && (
              <span className="text-5 mt-2">
                {discount.porcent}% de descuento
              </span>
            )}
          </div>
        </div>

        {error.length !== 0 && (
          <div className="d-flex justify-content-center">
            <span className="PanelModal__Error text-5 text-center mt-3">
              {error}
            </span>
          </div>
        )}

        <div className="d-flex justify-content-center">
          <button
            className={`button-green-panel ${
              error.length !== 0 ? 'button-disabled' : ''
            } my-3 `}
            onClick={handleEndStep}
          >
          Finalizar compra
          </button>
        </div>

        <Steps totalCount={2} currentCount={2} />
      </div>
    </div>
  )
}

AssignPlanAdminModalStep2.propTypes = {
  closeModal: PropTypes.func.isRequired,
  company_id: PropTypes.number.isRequired,
  updateCompanyInfo: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  userSelected: PropTypes.string.isRequired,
  plansSelected: PropTypes.array.isRequired,
}

export default AssignPlanAdminModalStep2
