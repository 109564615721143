import { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { getCompanyBooks, getCompanyDetail } from '../api/companies'
import DebtAlert from '../components/DebtAlert'
import InfoCard from '../components/InfoCard'
import AddEmployeeModal from '../components/modals/AddEmployeeModal'
import AssignCreditsUserModal from '../components/modals/AssignCreditsUserModal'
import AssignPlanUserModal from '../components/modals/AssignPlanUserModal/AssignPlanUserModal'
import ViewEmployeeModal from '../components/modals/ViewEmployeeModal'
import DeleteEmployeeModal from '../components/modals/DeleteEmployeeModal'
import HelpModal from '../components/modals/HelpModal'
import SolicitPlanModal from '../components/modals/solicitPlanModal/SolicitPlanModal'
import PanelCompanyCard from '../components/PanelCompanyCard'
import Table from './Table'
import { AppContext } from '../context'
import useApi from '../hooks/useApi'
import CancelBookModal from '../components/modals/CancelBookModal'
import TableSearcher from '../components/TableSearcher'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { Link, useSearchParams } from 'react-router-dom'
import CompanyInfoModal from '../components/modals/CompanyInfoModal'
import ExtraCreditsAndHours from '../components/ExtraCreditsAndHours'

const per_page = 5
const PER_PAGE_2 = 12
const COMPANY_EMPLOYEES_HEADERS = [
  {
    id: 0,
    label: 'Nombre y Apellido',
    value: 'full_name'
  },
  'Plan',
  'VTO',
  'Créditos del plan',
  'Horas del plan',
  {
    id: 5,
    label: 'Créditos asignados',
    value: 'assigned_credits',
  },
  {
    id: 6,
    label: 'Horas asignadas',
    value: 'assigned_hours',
  },
  {
    id: 7,
    label: 'Tipo de usuario',
    value: 'type',
  },
  '',
  '',
]
const COMPANY_EMPLOYEES_HEADER_MOBILE = [
  {
    id: 0,
    label: 'Nombre y Apellido',
    value: 'full_name'
  },
  'Plan',
  'VTO'
]

const COMPANY_BOOKS_HEADERS = [
  {
    id: 0,
    label: 'Fecha',
    value: 'date',
  },
  {
    id: 1,
    label: 'Nombre y Apellido',
    value: 'full_name',
  },
  {
    id: 2,
    label: 'Tipo de reserva',
    value: 'type',
  },
  'Jornada',
  'Horas / créditos utilizados',
  '',
]
const COMPANY_BOOKS_HEADERS_MOBILE = [
  {
    id: 0,
    label: 'Fecha',
    value: 'date',
  },
  {
    id: 1,
    label: 'Nombre y Apellido',
    value: 'full_name',
  },
  {
    id: 2,
    label: 'Reserva',
    value: 'type',
  },
  'Horario'
]


const CompanyAdminPanel = ({ company_id, companyData }) => {
  const { user, token, isAdmin } = useContext(AppContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const [search, setSearch] = useState(searchParams.get('search'))
  const [page, setPage] = useState(1)
  const [order_by, setOrderBy] = useState('')
  const [companyInfo, updateCompanyInfo] = useApi(
    () => getCompanyDetail(token, company_id, { page, per_page, search, order_by}),
    {
      data: {},
      total_hours_to_assign: 0,
      total_credits_to_assign: 0,
      personal: {
        page: 1,
        per_page,
        total_amount: 0,
        total_pages: 1,
        data: [],
      },
      assigned_plans: []
    },
    [token, user, company_id, page, search, order_by]
  )
  const [companyPersonal, setCompanyPersonal] = useState({
    page: 1,
    per_page,
    total_amount: 0,
    data: [],
  })
  const [page2, setPage2] = useState(1)
  const [order_by2, setOrderBy2] = useState('')
  const body2 = {
    company_id,
    page: page2,
    per_page: PER_PAGE_2,
    search,
    order_by: order_by2
  }
  if (order_by === '-date') {
    body2.order_by = 'date'
    body2.reverse = 'true'
  } else if (order_by === '-type') {
    body2.order_by = 'type'
    body2.reverse = 'true'
  } else if (order_by === '-full_name') {
    body2.order_by = 'full_name'
    body2.reverse = 'true'
  }
  const [companyBooks, updateCompanyBooks] = useApi(
    () => getCompanyBooks(token, body2),
    {
      page: 1,
      total_pages: 1,
      total_items: 0,
      data: [],
    },
    [token, user, company_id, page2, search, order_by2]
  )


  const [showSolicitPlan, setShowSolicitPlan] = useState(false)
  const [showAssignPlanModal, setShowAssignPlanModal] = useState(false)
  const [showHelpModal, setShowHelpModal] = useState(false)
  const [showAssignCreditsModal, setShowAssignCreditsModal] = useState(false)
  const [showAddEmployeeModal, setShowAddEmployeeModal] = useState(false)
  const [showViewEmployeeModal, setShowViewEmployeeModal] = useState(false)
  const [showDeleteEmployeeModal, setShowDeleteEmployeeModal] = useState(false)
  const [showInfoModal, setShowInfoModal] = useState(false)
  const [userInfo, setUserInfo] = useState({})
  const [showCancelBookModal, setShowCancelBookModal] = useState(false)
  const [book, setBook] = useState({})

  console.log(companyInfo)


  useEffect(() => {
    setCompanyPersonal({
      page: Number(companyInfo.personal.page),
      per_page: Number(companyInfo.personal.per_page),
      total_pages: Number(companyInfo.personal.total_pages),
      total_amount: companyInfo.personal.total_amount,
      data: companyInfo.personal.data.map(employee => ({
        ...employee,
        ...employee.user
      })),
    })
  }, [companyInfo])


  const handleSearch = (e) => {
    setSearch(e.target.value)
  }

  const toggleShowSolicitPlan = () => {
    setShowSolicitPlan((prev) => !prev)
  }

  const toggleShowAssignPlanModal = () => {
    setShowAssignPlanModal((prev) => !prev)
  }

  const toggleShowAssignCreditsModal = () => {
    setShowAssignCreditsModal((prev) => !prev)
  }

  const toggleShowAddEmployeeModal = () => {
    setShowAddEmployeeModal((prev) => !prev)
  }

  const toggleShowInfoModal = () => setShowInfoModal((prev) => !prev)

  const handleCompanyUsersActions = (data) => {
    if (data?.action === 'view user') {
      setUserInfo(data.user)
      setShowViewEmployeeModal(true)
    } else if (data?.action === 'remove user') {
      setUserInfo(data.user)
      setShowDeleteEmployeeModal(true)
    }
  }

  const closeShowViewEmployeeModal = () => {
    setShowViewEmployeeModal(false)
  }
  const closeShowDeleteEmployeeModal = () => {
    setShowDeleteEmployeeModal(false)
  }

  const openShowCancelBookModal = (book) => {
    setBook(book)
    setShowCancelBookModal(true)
  }
  const closeShowCancelBookModal = () => {
    setShowCancelBookModal(false)
  }


  const DEFAULT_INFO = [
    {
      title: 'Créditos Vida',
      name: 'créditos',
      description: 'Disponibles para reservar un puesto en el espacio compartido.',
      value: companyInfo.total_credits_to_assign || 0,
      buttonText: 'Asignar créditos',
    },
    {
      title: 'Horas en Salas',
      name: 'horas',
      description: 'Disponibles para reservar las salas de reuniones.',
      value: companyInfo.total_hours_to_assign || 0,
      buttonText: 'Asignar horas',
    },
  ]
  console.log(company_id)
  console.log(companyInfo)
  console.log(user)


  return (
    <main className="CompanyAdminPanel panel-main pb-4">
      {isAdmin && (
        <div className="CompanyAdminPanel__Header">
          <div className="d-flex gap-3 align-items-center">
            <span className='text-5'>Datos</span>

            <FontAwesomeIcon icon={faChevronRight} size='sm'/>

            <Link to='/panel/datos/empresas' className='text-5'>
              Empresas
            </Link>

            <FontAwesomeIcon icon={faChevronRight} size='sm'/>

            <span className='text-3 fw-bold'>{companyInfo.data.name}</span>
          </div>

          <button className="d-flex align-items-center gap-2 text-white" onClick={toggleShowInfoModal}>
            <FontAwesomeIcon icon={faCircleExclamation} />

            <span className='text-5'>Ver información</span>
          </button>
        </div>
      )}

      <div className="px-3 px-md-5 pt-4">
        <DebtAlert company_id={company_id} companyInfo={companyInfo} />
      </div>

      <section className="CompanyAdminPanel__Three-Card px-3 px-md-5">
        <PanelCompanyCard
          notAssingedPlans={companyInfo?.not_assinged_plans ?? []}
          showSolicitPlan={toggleShowSolicitPlan}
          showAssignPlan={toggleShowAssignPlanModal}
        />
        {DEFAULT_INFO.map((info, index) => (
          <InfoCard
            key={`Info-Company-Card-${index}`}
            {...info}
            showHelp={() => setShowHelpModal(true)}
            company
            assignCredits={toggleShowAssignCreditsModal}
          />
        ))}
      </section>

      <div className="px-3 px-md-5">
        <ExtraCreditsAndHours/>
      </div>

      <section className="CompanyAdminPanel__CompanieUsers">
        <div className="CompanyAdminPanel__CompanieUsers-title px-3 px-md-5">
          <h3 className="text-1 flex-shrink-0">
            Integrantes ({companyPersonal.data.length})
          </h3>
          <button
            className="button-green-panel-rounded CompanyAdminPanel__Button text-5"
            onClick={toggleShowAddEmployeeModal}
          >
            + Agregar integrante
          </button>
          <TableSearcher
            search={search}
            handleSearch={handleSearch}
            filtersSelected={[]}
            // setFiltersSelected={setFiltersSelected}
            filtersForCard={[]}
          />
        </div>
        <Table
          type={'Company-Users'}
          headers={COMPANY_EMPLOYEES_HEADERS}
          headersMobile={COMPANY_EMPLOYEES_HEADER_MOBILE}
          data={companyPersonal.data}
          page={companyPersonal.page}
          setPage={setPage}
          perPage={per_page}
          totalPages={companyPersonal.total_pages}
          totalItems={companyPersonal.total_amount}
          isEmptyMenssage="No hay integrantes"
          openInfoModal={handleCompanyUsersActions}
          setOrderBy={setOrderBy}
          unselectable
          showInfoMobile
        />
      </section>
      <section className="CompanyAdminPanel__Reservations">
        <div className="CompanyAdminPanel__CompanieUsers-title px-3 px-md-5">
          <h3 className="text-2">
            {companyBooks.data.length !== 0
              ? `Reservas realizadas (${companyBooks.data.length})`
              : 'Reservas'}
          </h3>
        </div>
        <Table
          type={'Reservation-Admin'}
          headers={COMPANY_BOOKS_HEADERS}
          headersMobile={COMPANY_BOOKS_HEADERS_MOBILE}
          data={companyBooks.data}
          page={companyBooks.page}
          setPage={setPage2}
          perPage={PER_PAGE_2}
          totalPages={companyBooks.total_pages}
          totalItems={companyBooks.total_items}
          setOrderBy={setOrderBy2}
          isEmptyMenssage="No hay reservas realizadas"
          openInfoModal={openShowCancelBookModal}
          unselectable
          showInfoMobile
        />
      </section>

      {showSolicitPlan && (
        <SolicitPlanModal
          company_id={company_id}
          updateCompanyInfo={updateCompanyInfo}
          closeModal={toggleShowSolicitPlan}
        />
      )}

      {showAssignPlanModal && (
        <AssignPlanUserModal
          notAssingedPlans={companyInfo?.not_assinged_plans ?? []}
          company_id={company_id}
          updateCompanyInfo={updateCompanyInfo}
          total_items={companyPersonal.total_amount}
          closeModal={toggleShowAssignPlanModal}
        />
      )}

      {showHelpModal && (
        <HelpModal closeModal={() => setShowHelpModal(false)} />
      )}

      {showAssignCreditsModal && (
        <AssignCreditsUserModal
          company_id={company_id}
          updateCompanyInfo={updateCompanyInfo}
          total_items={companyPersonal.total_amount}
          closeModal={toggleShowAssignCreditsModal}
        />
      )}

      {showAddEmployeeModal && (
        <AddEmployeeModal
          closeModal={toggleShowAddEmployeeModal}
          company_id={company_id}
          updateCompanyInfo={updateCompanyInfo}
        />
      )}

      {showViewEmployeeModal && (
        <ViewEmployeeModal
          userInfo={userInfo}
          company_id={company_id}
          updateCompanyInfo={updateCompanyInfo}
          closeModal={closeShowViewEmployeeModal}
        />
      )}

      {showDeleteEmployeeModal && (
        <DeleteEmployeeModal
          userInfo={userInfo}
          company_id={company_id}
          updateCompanyInfo={updateCompanyInfo}
          closeModal={closeShowDeleteEmployeeModal}
        />
      )}

      {showCancelBookModal && (
        <CancelBookModal
          book={book}
          updateCompanyBooks={updateCompanyBooks}
          closeModal={closeShowCancelBookModal}
        />
      )}

      {showInfoModal && (
        <CompanyInfoModal
          companyInfo={companyData}
          closeModal={toggleShowInfoModal}
        />
      )}
    </main>
  )
}

CompanyAdminPanel.propTypes = {
  company_id: PropTypes.number.isRequired,
  companyData: PropTypes.object.isRequired,
}

export default CompanyAdminPanel
