import { useContext, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBuilding,
  faPhone,
  faSearch,
} from '@fortawesome/free-solid-svg-icons'
import FinishModal from './FinishModal'
import ModalHeader from '../ModalHeader'
import Table from '../../containers/Table'
import { AppContext } from '../../context'
import useApi from '../../hooks/useApi'
import { getUsersData } from '../../api/admin-data'
import Input from '../Input'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { postCreateCompany } from '../../api/companies'
import { phoneRegExp } from '../../utils/regExp'
import Steps from '../Steps'


const AddCompanyModal = ({ closeModal, updateCompaniesData }) => {
  const {token} = useContext(AppContext)
  const [firstShow, setFirstShow] = useState(true)
  const [step, setStep] = useState(1)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const per_page = 5
  const [usersData, updateUsersData] = useApi(
    () => getUsersData(token, { search, page, per_page }),
    {
      page: 1,
      total_pages: 1,
      total_items: 0,
      data: [],
    },
    [token, search, page]
  )
  const [selectedItemsId, setSelectedItemsId] = useState([])
  const [selectedItems, setSelectedItems] = useState([])
  const [error, setError] = useState('')

  useEffect(() => {
    if (firstShow) return setFirstShow(false)
    if (selectedItemsId.length === 0)
      return setError('No hay usuarios seleccionados')
    else setError('')
  }, [selectedItemsId])

  useEffect(() => {
    setError('')
  }, [step])

  useEffect(() => {
    setSelectedItems(
      selectedItemsId.map((id) => usersData.data.find((item) => item.id === id))
    )
  }, [selectedItemsId, usersData.data])

  useEffect(() => {
    setSelectedItems([])
    setSelectedItemsId([])
  }, [usersData.data])

  const handleSelectItem = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItemsId((prev) => prev.filter((id) => id !== item.id))
    } else {
      setSelectedItemsId([item.id])
    }
  }

  const handleSearch = (e) => {
    setSearch(e.target.value)
  }

  const dataHeaders = ['Nombre y Apellido', 'Email']

  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      name: Yup.string()
        .required('El nombre de la empresa es requerido')
        .max(60, 'Máximo de caracteres: 60'),
      phone: Yup.string()
        .required('Su celular es requerido')
        .matches(phoneRegExp, 'Ingrese un numero de telefono valido')
        .max(40, 'Máximo de números: 40'),
    }),
    onSubmit: async () => {
      setStep((prev) => prev + 1)
    },
  })

  const handleCreateCompany = async () => {
    const body = {
      name: formik.values.name,
      phone: formik.values.phone,
      admin_id: `${selectedItemsId[0]}`,
    }

    const response = await postCreateCompany(token, body)

    if (response.error?.name[0] === 'Company with this name already exists.') 
      setError('El nombre de la empresa de la ya existe')
    else if (response) {
      updateCompaniesData()

      setStep((prev) => prev + 1)
    }
  }

  if (step === 1)
    return (
      <div className="PanelModal px-3" onClick={closeModal}>
        <div
          className="PanelModal__Card--Big"
          onClick={(e) => e.stopPropagation()}
        >
          <ModalHeader title={'Agregar empresa'} closeModal={closeModal} />

          <div className="PanelModal__Inputs-Container pt-3 pb-4">
            <Input
              label={'Nombre de la empresa'}
              name="name"
              type="text"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.errors.name}
              icon={faBuilding}
            />
            <Input
              label={'Teléfono de contacto'}
              name="phone"
              type="tel"
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.errors.phone}
              icon={faPhone}
            />
          </div>

          <div className="pb-3">
            <div className="text-3 fw-normal pb-2">Asignar administrador</div>

            <div className="d-flex align-items-center">
              <FontAwesomeIcon icon={faSearch} />
              <input
                className="PanelModal__Input text-5 ms-2"
                name="search"
                type="text"
                value={search}
                onChange={handleSearch}
              />
            </div>
          </div>

          <Table
            type={'Add-Company'}
            headers={dataHeaders}
            data={usersData.data}
            updateData={updateUsersData}
            page={usersData.page}
            setPage={setPage}
            perPage={per_page}
            totalPages={usersData.total_pages}
            totalItems={usersData.total_items}
            selectedParentItems={selectedItems}
            handleParentItems={handleSelectItem}
            rounded
          />

          {error.length !== 0 && (
            <div className="d-flex justify-content-center">
              <span className="PanelModal__Error text-5 text-center my-3">
                {error}
              </span>
            </div>
          )}

          <div className="d-flex justify-content-center">
            <button
              className={`button-green-panel ${
                error.length !== 0 ||
                (formik.errors.name && formik.values.name.length === 0) ||
                (formik.errors.phone && formik.values.phone.length === 0)
                  ? 'button-disabled'
                  : ''
              } my-3 `}
              type="submit"
              onClick={formik.handleSubmit}
            >
              Siguiente
            </button>
          </div>

          <Steps totalCount={2} currentCount={1} />
        </div>
      </div>
    )

  if (step === 2)
    return (
      <div className="PanelModal px-3" onClick={closeModal}>
        <div
          className="PanelModal__Card--Medium"
          onClick={(e) => e.stopPropagation()}
        >
          <ModalHeader
            title={'Agregar empresa'}
            closeModal={closeModal}
            goBack={() => setStep((prev) => prev - 1)}
          />

          <h3 className="PanelModal__Confirm text-2 py-4 text-center">
            {formik.values.name}
          </h3>

          <div className="PanelModal__Confirm py-4 text-center">
            <div
              className="mx-auto gap-3"
              style={{
                width: 'max-content',
                display: 'grid',
                gridTemplateColumns: 'max-content max-content',
                justifyItems: 'start',
              }}
            >
              <h3 className="text-3">Administrador:</h3>
              <span className="text-4 ">{`${selectedItems[0].first_name} ${selectedItems[0].last_name}`}</span>
              <h3 className="text-3">Teléfono de contacto:</h3>
              <span className="text-4">{formik.values.phone}</span>
            </div>
          </div>

          {error.length !== 0 && (
            <div className="d-flex justify-content-center">
              <span className="PanelModal__Error text-5 text-center mt-3">
                {error}
              </span>
            </div>
          )}

          <div className="d-flex justify-content-center pt-3">
            <button
              className={`button-green-panel ${
                error.length !== 0
                  ? 'button-disabled'
                  : ''
              } my-3 `}
              onClick={handleCreateCompany}
            >
              Agregar empresa
            </button>
          </div>

          <Steps totalCount={2} currentCount={2} />
        </div>
      </div>
    )

  if (step === 3)
    return (
      <FinishModal
        title="Agregar empresa"
        message="¡Empresa agregada con éxito!"
        closeModal={closeModal}
      />
    )
}

AddCompanyModal.propTypes = {
  closeModal: propTypes.func.isRequired,
  updateCompaniesData: propTypes.func.isRequired,
}

export default AddCompanyModal
