import { memo, useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'


const ReviewCard = ({ name, description, calification }) => {
  const [showMore, setShowMore] = useState(false)

  const maxValue = [1, 2, 3, 4, 5]
  const hasOverflow = description.length >= 300

  const toggleShowMore = () => setShowMore(p => !p)

  return (
    <div className="ReviewCard swiper-lazy">
      <div className="ReviewCard__Title-Container">
        <div>
          <p className="text-4 mb-1">{ name }</p>
        </div>
        <div className='flex-shrink-0'>
          {calification > 0
            ? maxValue.map(item => {
              if (item <= calification) return (
                <FontAwesomeIcon key={`ReviewCard-${item}`} icon={faStar} className="ReviewCard__Star--Active"/>
              )
              else return (
                <FontAwesomeIcon key={`ReviewCard-${item}`} icon={faStar} className="ReviewCard__Star"/>
              )
            })
            : <>
              <FontAwesomeIcon icon={faStar} className="ReviewCard__Star"/>
              <FontAwesomeIcon icon={faStar} className="ReviewCard__Star"/>
              <FontAwesomeIcon icon={faStar} className="ReviewCard__Star"/>
              <FontAwesomeIcon icon={faStar} className="ReviewCard__Star"/>
              <FontAwesomeIcon icon={faStar} className="ReviewCard__Star"/>
            </>
          }
        </div>
      </div>

      <div className={`ReviewCard__Description text-5 ${hasOverflow ? 'hasOverflow' : ''} ${showMore ? 'showMore' : ''}`} dangerouslySetInnerHTML={{ __html: description }} />

      {hasOverflow ? <button className='text-5 ms-auto d-block' onClick={toggleShowMore}>{!showMore ? 'Mostrar más': 'Mostrar menos'}</button> : <></>}
    </div>
  )
}

ReviewCard.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  calification: PropTypes.number.isRequired
}

export default memo(ReviewCard)