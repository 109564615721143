import { useContext, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import {
  faMinus,
  faPlus,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FinishModal from './FinishModal'
import ModalHeader from '../ModalHeader'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Dropdown from '../Dropdown'
import moment from 'moment'
import { AppContext } from '../../context'
import { postChargeHoursToOfficePlan } from '../../api/offices'
import Steps from '../Steps'


const ChargeHoursModal = ({
  closeModal,
  offices,
  updateOffices,
  updateOfficesData,
}) => {
  const { token } = useContext(AppContext)
  const [step, setStep] = useState(1)
  const [officesFill, setOfficesFill] = useState([])
  const [officeSelected, setOfficeSelected] = useState({ label: '', value: 0 })
  const [hoursSelected, setHoursSelected] = useState(0)
  const [buttonDisable, setButtonDisable] = useState(false)

  useEffect(() => {
    const list = []
    offices.forEach((office) => {
      if (!office.books) return

      const today = moment(new Date()).format('YYYY-MM-DD')
      const date = office.books[0].start_date

      if (date <= today) {
        list.push({
          label: `Oficina ${office.name} - ${office.books[0].company_name}`,
          value: office.id,
        })
      }
    })

    setOfficesFill(list)
    if (list.length === 0) return

    setOfficeSelected(list[0])

  }, [offices])

  const changeOfficeSelected = (filterIndex) => {
    setOfficeSelected(officesFill[filterIndex])
  }

  const formik = useFormik({
    initialValues: {
      hours: 0,
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      hours: Yup.number()
        .required('La horas son requerida')
        .test(
          'test .5',
          'Las horas deben terminar en .0 o .5',
          (num) => num % 0.5 === 0
        )
        .min(0.5, 'Horas minimas es de 0.5')
        .max(100, 'Horas máximas es de 100'),
    }),
    onSubmit: () => {
      setStep((prev) => prev + 1)
    },
  })

  const handleAdd = (to) => {
    formik.setFieldValue(to, Number(formik.values[to]) + 1)
  }

  const handleLess = (to) => {
    formik.setFieldValue(to, Number(formik.values[to]) - 1)
  }

  const handleChange = (e, to) => {
    if(isNaN(Number(e.target.value))) return
    return formik.setFieldValue(to, Number(e.target.value))
  }

  const handleSubmit = () => {
    setHoursSelected(formik.values.hours)
    formik.handleSubmit()
  }

  const handleAddHours = async () => {
    if(buttonDisable) return
    setButtonDisable(true)

    const body = {
      office_id: officeSelected.value ,
      hours: formik.values.hours
    }

    const response = await postChargeHoursToOfficePlan(token, body)

    if (response.error) {
      return
    }
    else if (response) {
      updateOfficesData()
      updateOffices()

      setStep((prev) => prev + 1)
    }
    setButtonDisable(false)
  }

  if (officesFill.length === 0)
    return (
      <div className="PanelModal px-3" onClick={closeModal}>
        <div className="PanelModal__Card" onClick={(e) => e.stopPropagation()}>
          <ModalHeader title={'Cargar horas'} closeModal={closeModal} />

          <h3 className="PanelModal__Confirm text-2 py-4 text-center">
            No hay reservas en curso para asignarle horas
          </h3>

          <div className="d-flex justify-content-center pt-3">
            <button
              className="button-green-panel"
              onClick={closeModal}
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    )

  if (step === 1)
    return (
      <div className="PanelModal px-3" onClick={closeModal}>
        <div className="PanelModal__Card" onClick={(e) => e.stopPropagation()}>
          <ModalHeader title={'Cargar horas'} closeModal={closeModal} />

          <div className="pt-3 pb-4">
            <Dropdown
              options={officesFill}
              optionSelected={officeSelected}
              onChange={changeOfficeSelected}
            />
          </div>

          <div className="d-flex justify-content-evenly">
            <div className="PanelModal__Amount-Container">
              <h3 className="text-3">Horas</h3>

              <div className="PanelModal__Amount-Buttons">
                <button
                  className="PanelModal__Amount-Button"
                  onClick={() => handleLess('hours')}
                >
                  <FontAwesomeIcon icon={faMinus} />
                </button>

                <input
                  className="PanelModal__Amount-Input text-5"
                  type="text"
                  name="hours"
                  id="hours"
                  value={formik.values.hours}
                  onChange={(e) => handleChange(e, 'hours')}
                />

                <button
                  className="PanelModal__Amount-Button"
                  onClick={() => handleAdd('hours')}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              </div>
            </div>
          </div>

          {formik.errors.hours?.length !== 0 && (
            <div className="d-flex flex-column aling-items-center my-3">
              <span className="PanelModal__Error text-5 text-center">
                {formik.errors.hours}
              </span>
            </div>
          )}

          <div className="d-flex justify-content-center mt-3">
            <button
              className={`button-green-panel ${
                formik.errors.hours &&
                formik.values.hours !== 0 &&
                formik.values.hours === hoursSelected
                  ? 'button-disabled'
                  : ''
              } my-3 `}
              type="submit"
              onClick={handleSubmit}
            >
              Siguiente
            </button>
          </div>

          <Steps totalCount={2} currentCount={1} />
        </div>
      </div>
    )

  if (step === 2)
    return (
      <div className="PanelModal px-3" onClick={closeModal}>
        <div className="PanelModal__Card" onClick={(e) => e.stopPropagation()}>
          <ModalHeader title={'Cargar horas'} closeModal={closeModal} goBack={() => setStep(prev => prev - 1)} />

          <h3 className="PanelModal__Confirm text-2 py-4 text-center">
            {officeSelected.label}
          </h3>

          <div className="PanelModal__Confirm py-4 text-center">
            <h5>{formik.values.hours} horas</h5>
          </div>

          <div className="d-flex justify-content-center">
            <button
              className="button-green-panel my-3"
              onClick={handleAddHours}
            >
              Cargar horas
            </button>
          </div>

          <Steps totalCount={2} currentCount={2} />
        </div>
      </div>
    )

  if (step === 3)
    return (
      <FinishModal
        title="Cargar horas"
        message="¡Horas cargadas con éxito!"
        closeModal={closeModal}
      />
    )
}

ChargeHoursModal.propTypes = {
  closeModal: propTypes.func.isRequired,
  offices: propTypes.array.isRequired,
  updateOffices: propTypes.func.isRequired,
  updateOfficesData: propTypes.func.isRequired,
}

export default ChargeHoursModal
