import { useState, useContext, useEffect } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import propTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRightFromBracket,
  faBars,
  faBell,
  faPercent,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import Logo from '../assets/brand/logo.svg'
import { logout } from '../api/auth'
import { AppContext } from '../context'
import NotificationsModal from '../components/modals/NotificationsModal'
import { getNotifications, postCleanNotifications } from '../api/notifications'
import useApi from '../hooks/useApi'
import CreditsInfo from '../components/CreditsInfo'
import { URL } from '../constants'


const PanelHeader = ({
  showMenu,
  toggleMenu,
  showLogoutModal,
  setShowLogoutModal,
}) => {
  const { token, setToken, setUser, setIsAdmin, isAdmin } =
    useContext(AppContext)
  const [notifications, updateNotifications] = useApi(
    () => getNotifications(token),
    {},
    [token]
  )
  const [showNotificationsModal, setShowNotificationsModal] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  const handleLogout = async () => {
    try {
      const res = await logout(token)
      if (res) {
        navigate('/', { replace: true })
        setToken(null)
        setUser(null)
        setIsAdmin(null)
      }
    } catch (error) {
      return
    }
  }

  useEffect(() => {
    setInterval(() => {
      updateNotifications()
    }, 60000)
  }, [])

  useEffect(() => {
    updateNotifications()
  }, [location])

  const toggleNotificationsModal = () => {
    updateNotifications()
    setShowNotificationsModal((prev) => !prev)
  }

  const closeNotificationsModal = async () => {
    setShowNotificationsModal(false)

    const response = await postCleanNotifications(token)

    if (response) updateNotifications()
  }

  const hasNewNotificatios = notifications?.data?.some(
    (noti) => noti.is_read === false
  )

  const openMenu = () => {
    if (showNotificationsModal) closeNotificationsModal()
    toggleMenu()
  }

  return (
    <>
      <header className="PanelHeader">
        <NavLink to="/" title='Inicio'>
          <img src={Logo} className="PanelHeader__Logo" />
        </NavLink>

        {location.pathname.endsWith('reservas') ? (
          <CreditsInfo className="d-none d-xxl-grid" />
        ) : (
          <></>
        )}

        <div className="PanelHeader__Buttons">
          {isAdmin ? (
            <a
              className="PanelHeader__Button"
              href={`${URL}/admin/community/discounts/`}
              target="_blank"
              rel="noopener noreferrer"
              title='Descuentos'
            >
              <FontAwesomeIcon icon={faPercent} />
            </a>
          ) : (
            <></>
          )}

          <button
            className="PanelHeader__Button--Notification text-1"
            onClick={toggleNotificationsModal}
            title='Notificaciones'
          >
            <FontAwesomeIcon icon={faBell} />
            {hasNewNotificatios && <div className="PanelHeader__New-Noti" />}
          </button>

          {showMenu !== undefined && (
            <button
              className="PanelHeader__Button--Logout d-none d-md-flex text-1"
              onClick={() => setShowLogoutModal(true)}
              title='Cerrar sesión'
            >
              <FontAwesomeIcon icon={faArrowRightFromBracket} />
            </button>
          )}

          {showMenu !== undefined &&
            (showMenu ? (
              <button
                className="PanelHeader__Button--Menu d-flex d-md-none text-1"
                onClick={toggleMenu}
              >
                <FontAwesomeIcon icon={faXmark} />
              </button>
            ) : (
              <button
                className="PanelHeader__Button--Menu d-flex d-md-none text-1"
                onClick={openMenu}
              >
                <FontAwesomeIcon icon={faBars} />
              </button>
            ))}
        </div>
      </header>

      {/* Modal */}
      {showLogoutModal && (
        <div className="Modal--Small">
          <div className="Modal__Content">
            <h2 className="Modal__Title">¿Deseas cerrar sesión?</h2>

            <div className="w-100 d-flex align-items-center gap-3">
              <button
                className="Modal__Button button-white-gray"
                onClick={() => setShowLogoutModal(false)}
              >
                Cancelar
              </button>
              <button className="button-orange" onClick={handleLogout}>
                Cerrar sesión
              </button>
            </div>
          </div>
        </div>
      )}

      {showNotificationsModal && (
        <NotificationsModal
          notifications={notifications?.data ?? []}
          updateNotifications={updateNotifications}
          closeModal={closeNotificationsModal}
        />
      )}
    </>
  )
}

PanelHeader.propTypes = {
  showMenu: propTypes.bool,
  toggleMenu: propTypes.func,
  showLogoutModal: propTypes.bool,
  setShowLogoutModal: propTypes.func,
}

export default PanelHeader
