import { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortDown } from '@fortawesome/free-solid-svg-icons'


const Filter = ({ data, optionSelected, defaultOption, onChange }) => {
  const [ showOptions, setShowOptions ] = useState(false)


  useEffect(() => {
    setShowOptions(false)
  }, [data])


  const toggleShowOptions = () => {
    setShowOptions(!showOptions)
  }

  const selectOption = (e, option) => {
    e.stopPropagation()
    e.preventDefault()
    if(onChange) {
      const index = data.indexOf(option)
      onChange(index)
    }

    setShowOptions(false)
  }


  return (
    <div
      className={ showOptions ? 'Filter--Active text-5' : 'Filter text-5'}
      onMouseDown={toggleShowOptions}
      onBlur={() => setShowOptions(false)}
    >
      Mostrar ({ optionSelected === '' ? defaultOption : optionSelected || data[0] })
      <FontAwesomeIcon icon={faSortDown} className="Filter__Icon"/>
      { showOptions && (
        <div className='Filter__Items-Container'>
          { data.map((item, index) => {
            if(item == optionSelected) return (
              <div
                key={`Filter-Item-${item}-${index}`}
                className="Filter__Item--Active text-5"
                onMouseDown={(e) => selectOption(e, item)}
                disabled
              >
                {item}
              </div>
            )
            else return(
              <div
                key={`Filter-Item-${item}-${index}`}
                className="Filter__Item text-5"
                onMouseDown={(e) => selectOption(e, item)}
              >
                {item}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

Filter.propTypes = {
  data: propTypes.array.isRequired,
  optionSelected: propTypes.string,
  defaultOption: propTypes.string,
  onChange: propTypes.func
}

export default Filter