import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { CSSTransition } from 'react-transition-group'


const FaqItem = ({ name, faqs }) => {
  const [showFaqs, setShowFaqs] = useState(false)


  const toggleShowFaqs = () => {
    setShowFaqs(prev => !prev)
  }


  return (
    <button className={`Faqs__Group${showFaqs ? '--Active' : ''}`} onClick={toggleShowFaqs} >
      <h2 className="Faqs__Group-Name text-2">
        {name}
        <FontAwesomeIcon className='Faqs__Group-Icon' icon={faCaretDown}/>
      </h2>
      <CSSTransition
        in={showFaqs}
        timeout={400}
        className='Faqs__Group-Expanded'
        unmountOnExit={true}
      >
        <div>
          {faqs.map(faq => (
            <div key={faq.id} className="Faqs__Faq-Item">
              <span className="Faqs__Faq-Question text-4 fw-bold">
                {faq.question}
              </span>
              <span className="Faqs__Faq-Answer text-4">
                {faq.answer}
              </span>
            </div>
          ))}
        </div>
      </CSSTransition>
    </button>
  )
}

FaqItem.propTypes = {
  name: PropTypes.string.isRequired,
  faqs: PropTypes.array.isRequired
}

export default FaqItem