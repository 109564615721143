import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import propTypes from 'prop-types'
import { useContext, useState } from 'react'
import { delDeleteOffice } from '../../api/offices'
import { AppContext } from '../../context'
import ModalHeader from '../ModalHeader'
import FinishModal from './FinishModal'


const DeleteOfficeModal = ({
  closeModal,
  officeSelected,
  updateOffices,
  updateOfficesData = () => {},
}) => {
  const { token } = useContext(AppContext)
  const [step, setStep] = useState(1)
  const [buttonDisable, setButtonDisable] = useState(false)

  const handleDelete = async () => {
    if(buttonDisable) return
    setButtonDisable(true)

    const response = await delDeleteOffice(token, officeSelected.id)

    if (response.error) {
      return
    }
    else if (response) {
      updateOffices()
      updateOfficesData()

      setStep(2)
    }
    setButtonDisable(false)
  }

  if (step === 1)
    return (
      <div className="PanelModal" onClick={closeModal}>
        <div className="PanelModal__Card--Medium" onClick={(e) => e.stopPropagation()}>
          <ModalHeader title={'Eliminar oficina'} closeModal={closeModal} />

          <div className="d-flex flex-column align-items-center gap-2 my-4 text-2 text-center">
            <FontAwesomeIcon icon={faExclamationCircle} />
						¿Estás seguro que deseas eliminar Oficina {officeSelected.name}?
          </div>

          <div className="text-4 text-center mb-4">
            Al eliminar una oficina se elimina toda la información de la misma
          </div>

          <div className="d-flex justify-content-center mb-2">
            <button className="button-green-panel" onClick={handleDelete}>
							Sí, eliminar
            </button>
          </div>

          <div className="d-flex justify-content-center">
            <button
              className="button-white-gray-dark px-4 text-decoration-underline"
              onClick={closeModal}
            >
							Cambié de opinión, no quiero eliminar
            </button>
          </div>
        </div>
      </div>
    )

  if (step === 2)
    return (
      <FinishModal
        type="--Medium"
        title="Eliminar oficina"
        message="La officina ha sido eliminada"
        buttonText="¡Entendido!"
        closeModal={closeModal}
      />
    )
}

DeleteOfficeModal.propTypes = {
  closeModal: propTypes.func.isRequired,
  officeSelected: propTypes.object.isRequired,
  updateOffices: propTypes.func.isRequired,
  updateOfficesData: propTypes.func,
}

export default DeleteOfficeModal