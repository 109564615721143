import { useContext, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FinishModal from './FinishModal'
import ModalHeader from '../ModalHeader'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Input from '../Input'
import { AppContext } from '../../context'
import { postAddOffice } from '../../api/offices'
import Steps from '../Steps'


const AddOfficeModal = ({ closeModal, updateOffices }) => {
  const {token} = useContext(AppContext)
  const [step, setStep] = useState(1)
  const [error, setError] = useState('')
  const [buttonDisable, setButtonDisable] = useState(false)

  useEffect(() => {
    setError('')
  }, [step])

  const formik = useFormik({
    initialValues: {
      name: '',
      capacity: 0,
      hours_by_month: 0,
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      name: Yup.string()
        .required('El nombre de la oficina es requerido')
        .max(60, 'Máximo de caracteres: 60'),
      capacity: Yup.number()
        .required('La capacidad es requerida')
        .min(1, 'Capacidad minima es de 1')
        .max(100, 'Capacidad máxima es de 100'),
      hours_by_month: Yup.number()
        .required('Las horas es requerida')
        .min(1, 'Horas minima es de 1')
        .max(100, 'Horas máxima es de 100'),
    }),
    onSubmit: () => {
      setStep((prev) => prev + 1)
    },
  })

  const handleAdd = (to) => {
    formik.setFieldValue(to, parseInt(formik.values[to], 10) + 1)
  }

  const handleLess = (to) => {
    formik.setFieldValue(to, parseInt(formik.values[to], 10) - 1)
  }

  const handleChange = (e, to) => {
    if(isNaN(Number(e.target.value))) return
    return formik.setFieldValue(to, Number(e.target.value))
  }

  const handleAddOffice = async () => {
    if(buttonDisable) return
    setButtonDisable(true)
    const body = {
      name: formik.values.name,
      capacity: formik.values.capacity,
      hours_by_month: formik.values.hours_by_month,
    }

    const response = await postAddOffice(token, body)

    if (response.error?.name[0] === 'Oficina with this name already exists.')
      setError('El nombre de la oficina de la ya existe')
    else if (response) {
      updateOffices()

      setStep((prev) => prev + 1)
    }
    setButtonDisable(false)
  }

  if(step === 1) return (
    <div className="PanelModal px-3" onClick={closeModal}>
      <div className="PanelModal__Card" onClick={(e) => e.stopPropagation()}>
        <ModalHeader title={'Agregar oficina'} closeModal={closeModal} />

        <div className="mt-3 mb-4">
          <Input
            label={'Nombre de la oficina'}
            name="name"
            type="text"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.errors.name}
          />
        </div>

        <div className="d-flex justify-content-evenly">
          <div className="PanelModal__Amount-Container">
            <h3 className='text-3'>Capacidad</h3>

            <div className="PanelModal__Amount-Buttons">
              <button className='PanelModal__Amount-Button' onClick={() => handleLess('capacity')}>
                <FontAwesomeIcon icon={faMinus}/>
              </button>

              <input
                className='PanelModal__Amount-Input text-5'
                type="text"
                name="capacity"
                id="capacity"
                value={formik.values.capacity}
                onChange={(e) => handleChange(e, 'capacity')}
                placeholder={0}
              />

              <button className='PanelModal__Amount-Button' onClick={() => handleAdd('capacity')}>
                <FontAwesomeIcon icon={faPlus}/>
              </button>
            </div>
          </div>

          <div className="PanelModal__Amount-Container">
            <h3 className='text-3'>Horas mensuales</h3>

            <div className="PanelModal__Amount-Buttons">
              <button className='PanelModal__Amount-Button' onClick={() => handleLess('hours_by_month')}>
                <FontAwesomeIcon icon={faMinus}/>
              </button>

              <input
                className='PanelModal__Amount-Input text-5'
                type="text"
                name="hours_by_month"
                id="hours_by_month"
                value={formik.values.hours_by_month}
                onChange={(e) => handleChange(e, 'hours_by_month')}
                placeholder={0}
              />

              <button className='PanelModal__Amount-Button' onClick={() => handleAdd('hours_by_month')}>
                <FontAwesomeIcon icon={faPlus}/>
              </button>
            </div>
          </div>
        </div>

        {(formik.errors.capacity?.length !== 0 || formik.errors.hours_by_month?.length !== 0) && (
          <div className="d-flex flex-column aling-items-center my-3">
            {formik.errors.capacity?.length !== 0 && (
              <span className="PanelModal__Error text-5 text-center">
                {formik.errors.capacity}
              </span>
            )}
            {formik.errors.hours_by_month?.length !== 0 && (
              <span className="PanelModal__Error text-5 text-center">
                {formik.errors.hours_by_month}
              </span>
            )}
          </div>
        )}

        <div className="d-flex justify-content-center mt-3">
          <button
            className={`button-green-panel ${
              (formik.errors.capacity && formik.values.capacity.length === 0) ||
                (formik.errors.hours_by_month && formik.values.hours_by_month.length === 0)
                ? 'button-disabled'
                : ''
            } my-3 `}
            type="submit"
            onClick={formik.handleSubmit}
          >
              Siguiente
          </button>
        </div>

        <Steps totalCount={2} currentCount={1} />
      </div>
    </div>
  )

  if(step === 2) return (
    <div className="PanelModal px-3" onClick={closeModal}>
      <div className="PanelModal__Card" onClick={(e) => e.stopPropagation()}>
        <ModalHeader title={'Agregar oficina'} closeModal={closeModal} goBack={() => setStep(prev => prev - 1)} />

        <h3 className="PanelModal__Confirm text-2 py-4 text-center">{formik.values.name}</h3>

        <div className="PanelModal__Confirm py-4 text-center">
          <h4 className='text-3'>{formik.values.hours_by_month} horas mensuales</h4>
          <h5 className='text-4 my-3'>Capacidad: {formik.values.capacity}</h5>
        </div>

        {error.length !== 0 && (
          <div className="d-flex justify-content-center">
            <span className="PanelModal__Error text-5 text-center mt-3">
              {error}
            </span>
          </div>
        )}

        <div className="d-flex justify-content-center pt-3">
          <button
            className={`button-green-panel ${
              error.length !== 0
                ? 'button-disabled'
                : ''
            } my-3 `}
            onClick={handleAddOffice}
          >
              Agregar oficina
          </button>
        </div>

        <Steps totalCount={2} currentCount={2} />
      </div>
    </div>
  )

  if(step === 3) return (
    <FinishModal
      title='Agregar oficina'
      message='¡Oficina agregada con éxito!'
      closeModal={closeModal}
    />
  )
}

AddOfficeModal.propTypes = {
  closeModal: propTypes.func.isRequired,
  updateOffices: propTypes.func.isRequired,
}

export default AddOfficeModal