import { getData, postData } from './apiVariables'

export const getNotifications = async (token) => {
  return await getData({
    path: '/notifications/get-notifications/',
    token,
  })
}

export const postCleanNotifications = async (token) => {
  return await postData({
    path: '/notifications/clean-notifications/',
    token,
  })
}
