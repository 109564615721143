import { memo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Media from './Media'


const EventCard = ({ event }) => {
  return (
    <Link to="/eventos" className="EventCard">
      <Media type='image' className="EventCard__Image" src={event.main_image} alt={event.title} />
      <div className="EventCard__Title-Container">
        <h3 className='EventCard__Title text-2 fw-bold'>{ event.title }</h3>
      </div>
      <div className="EventCard__Content">
        <p className="EventCard__Description m-0">{ event.description }</p>
      </div>
    </Link>
  )
}

EventCard.propTypes = {
  event: PropTypes.object.isRequired,
}

export default memo(EventCard)