import { useContext } from 'react'
import propTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons'
import { AppContext } from '../context'
import moment from 'moment'


const PanelPlanCard = ({
  direction = 'horizontal',
  plan,
  marginBotton,
  canBeCanceled,
  openModal,
}) => {
  const { user } = useContext(AppContext)
  const startDate =
    plan && moment(plan.start_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
  const endDate =
    plan && moment(plan.end_date, 'YYYY-MM-DD').format('DD/MM/YYYY')


  if (!plan)
    return (
      <section className="PanelPlanCard--Horizontal py-4 px-3 text-center">
        <h2 className="text-1">No estás suscrito a ningún plan</h2>
      </section>
    )

  if (plan) {
    if (direction == 'horizontal')
      return (
        <>
          <section
            className={`PanelPlanCard--Horizontal${
              user?.profile.is_first_time && !plan.paid ? '-Firts-Plan' : ''
            } ${marginBotton ? 'PanelPlanCard__Margin-Bottom' : ''}`}
          >
            <CancelPlanButton
              canBeCanceled={canBeCanceled}
              openModal={openModal}
              user_plan_id={plan.id}
              planName={`plan ${plan.plan.name} ${
                plan.plan.plan_type.split(' ')[0]
              }`}
            />
            <div className="PanelPlanCard-container">
              <div className="d-flex flex-column align-items-start gap-2">
                <span className="text-5">Suscripción</span>
                <h2 className="text-1 text-uppercase">
                  {plan.plan.name} - {plan.plan.plan_type.split(' ')[0]}
                </h2>
              </div>

              <div className="PanelPlanCard__Division"/>

              <div className="d-flex flex-column align-items-start gap-2">
                <span className="text-5">Vigencia</span>
                <h3 className="text-4 text-start">Desde el {startDate} hasta el {endDate}</h3>
              </div>
            </div>
            {user?.profile.is_first_time && !plan.paid && (
              <div className="PanelPlanCard-Plan-Debt">
                <FontAwesomeIcon icon={faExclamationCircle} />
                <span className="text-5">
                  La totalidad de tus créditos y horas se verán reflejados
                  cuando el plan se encuentre abonado.
                </span>
              </div>
            )}
          </section>
        </>
      )
    else
      return (
        <>
          <div
            className={`PanelPlanCard--Vertical py-3 py-md-4 py-lg-5 px-3 ${
              marginBotton ? 'PanelPlanCard__Margin-Bottom' : ''
            }${marginBotton && canBeCanceled ? '--Cancel' : ''}`}
          >
            <CancelPlanButton
              canBeCanceled={canBeCanceled}
              openModal={openModal}
              user_plan_id={plan.id}
              planName={`plan ${plan.plan.name} ${
                plan.plan.plan_type.split(' ')[0]
              }`}
            />
            <div className="PanelPlanCard-container">
              <div className="d-flex flex-column align-items-start gap-2">
                <span className="text-5">Suscripción</span>
                <h2 className="text-1 text-uppercase">
                  {plan.plan.name} - {plan.plan.plan_type.split(' ')[0]}
                </h2>
              </div>

              <div className="PanelPlanCard__Division" />

              <div className="d-flex flex-column align-items-start gap-2">
                <span className="text-5">Vigencia</span>
                <h3 className="text-4 text-start">Desde el {startDate} hasta el {endDate}</h3>
              </div>
            </div>
          </div>
        </>
      )
  }
}

PanelPlanCard.propTypes = {
  direction: propTypes.oneOf(['horizontal', 'vertical']),
  plan: propTypes.object,
  marginBotton: propTypes.bool,
  canBeCanceled: propTypes.bool,
  openModal: propTypes.func,
}

const CancelPlanButton = ({
  canBeCanceled,
  openModal,
  user_plan_id,
  planName,
}) => {
  if (canBeCanceled)
    return (
      <div
        className="PanelPlanCard__Cancel-Button"
        onClick={() => openModal(user_plan_id, planName)}
      >
        Cancelar plan
      </div>
    )

  return <></>
}

CancelPlanButton.propTypes = {
  canBeCanceled: propTypes.bool,
  openModal: propTypes.func,
  user_plan_id: propTypes.number,
  planName: propTypes.string,
}

export default PanelPlanCard
