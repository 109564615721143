import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'
import { getPercentage } from '../pages/panel/admin/StatisticsAdminPanel'

ChartJS.register(ArcElement, Tooltip, Legend)

const MettingRoomsGraphic = ({ shiftAndHoursMostRequested }) => {
  const [mettingRoomsList, setMettingRoomsList] = useState([])

  useEffect(() => {
    let list = []
    for (const MettingRoom of shiftAndHoursMostRequested.most_solicited_mt) {
      list = [
        ...list,
        {
          label: MettingRoom.meeting_room__name,
          count: MettingRoom.count,
          color: MettingRoom.meeting_room__color,
        },
      ]
    }
    if(list.length !== 0 )
      setMettingRoomsList(list)
    else setMettingRoomsList([{
      label: 'Sin información',
      color: '#ECECEC',
      count: 1,
    }])
  }, [shiftAndHoursMostRequested])

  return (
    <div className="StatisticsAdminPanel__Graphic-Container">
      <h2 className="text-1">Salas más solicitadas</h2>

      <div className="StatisticsAdminPanel__Main-Graphic-Card mx-auto">
        <div className="StatisticsAdminPanel__Main-Graphic-Card-Container">
          <Pie
            style={{
              position: 'relative',
              width: '100%',
              maxWidth: '100%',
              aspectRatio: 
              window.innerWidth < 768 ? 3 / 5 : 16 / 11,
            }}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              aspectRatio: window.innerWidth < 768 ? 3 / 5 : 16 / 11,
              plugins: {
                legend: {
                  display: true,
                  position: 'bottom',
                  labels: {
                    boxWidth: 25,
                    boxHeight: 25,
                    padding: 25,
                    usePointStyle: true,
                    pieceLabel: {
                      mode: 'percentage',
                      render: 'value',
                    },
                  },
                },
                tooltip: {
                  usePointStyle: true,
                  boxPadding: 3,
                  callbacks: {
                    title: (context) => {
                      const hiddenIndices = []

                      for (const index in context[0].chart._hiddenIndices) {
                        if (context[0].chart._hiddenIndices[index])
                          hiddenIndices.push(index)
                      }

                      hiddenIndices.reverse()

                      const data = [...context[0].dataset.data]
                      hiddenIndices.forEach((index) => {
                        data.splice(index, 1)
                      })

                      let totalCount = 0
                      data.forEach((number) => (totalCount += number))

                      const title = `${context[0].formattedValue} sala${
                        context[0].formattedValue !== '1' ? 's' : ''
                      }, ${getPercentage(context[0].parsed, totalCount)}%`
                      return title
                    },
                    label: (context) => context.label,
                  },
                },
              },
            }}
            data={{
              labels: mettingRoomsList.map(
                (mettingRoom) => mettingRoom.label
              ),
              datasets: [
                {
                  label: mettingRoomsList.map(
                    (mettingRoom) => mettingRoom.label
                  ),
                  data: mettingRoomsList.map((mettingRoom) => mettingRoom.count),
                  backgroundColor: mettingRoomsList.map((mettingRoom) => mettingRoom.color),
                  hoverOffset: 4,
                },
              ],
            }}
          />
        </div>
      </div>
    </div>
  )
}

MettingRoomsGraphic.propTypes = {
  shiftAndHoursMostRequested: PropTypes.object.isRequired,
}

export default MettingRoomsGraphic
