import { useFormik } from 'formik'
import * as Yup from 'yup'
import propTypes from 'prop-types'
import ModalHeader from '../ModalHeader'
import { useContext, useEffect, useState } from 'react'
import FinishModal from './FinishModal'
import Input from '../Input'
import Table from '../../containers/Table'
import { getCompanyDetail, postAssignHoursCreditsToEmployee } from '../../api/companies'
import { AppContext } from '../../context'
import useApi from '../../hooks/useApi'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import Steps from '../Steps'


const AssignCreditsUserModal = ({
  closeModal,
  company_id,
  updateCompanyInfo,
  total_items = 100,
}) => {
  const { token } = useContext(AppContext)
  const [step, setStep] = useState(1)
  const [order_by, setOrderBy] = useState('')
  const [companyInfo] = useApi(
    () => getCompanyDetail(token, company_id, { per_page: total_items, order_by }),
    {
      total_hours_to_assign: 0,
      total_credits_to_assign: 0,
      personal: {
        page: 1,
        per_page: 0,
        total_amount: 0,
        data: [],
      },
    },
    [token, company_id, order_by]
  )
  const [companyPersonal, setCompanyPersonal] = useState({
    page: 1,
    per_page: 0,
    total_amount: 0,
    data: [],
  })
  const [employeesSelected, setEmployeesSelected] = useState([])
  const [employeesError, setEmployeesError] = useState(false)
  const [buttonDisable, setButtonDisable] = useState(false)

  useEffect(() => {
    setCompanyPersonal({
      page: Number(companyInfo.personal.page),
      per_page: Number(companyInfo.personal.per_page),
      total_amount: companyInfo.personal.total_amount,
      data: companyInfo.personal.data.map(employee => ({
        ...employee,
        ...employee.user
      })),
    })
  }, [companyInfo])


  const handleSelectEmployee = (employee) => {
    if (employeesSelected.includes(employee)) {
      setEmployeesSelected((prev) => prev.filter((val) => val !== employee))
    } else {
      setEmployeesSelected((prev) => [...prev, employee])
    }
  }

  useEffect(() => {
    if (employeesSelected.length !== 0) setEmployeesError(false)
  }, [employeesSelected])

  const formik = useFormik({
    initialValues: {
      credits: 0,
      hours: 0,
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      credits: Yup.number()
        .integer('Los creditos no pueden tener decimales')
        .positive('Los creditos no pueden ser negativos')
        .min(0, 'Es necesario asignar creditos')
        .max(
          employeesSelected.length === 0
            ? companyInfo.total_credits_to_assign
            : Math.floor(companyInfo.total_credits_to_assign / employeesSelected.length),
          `El maximo de creditos es ${
            employeesSelected.length === 0
              ? companyInfo.total_credits_to_assign
              : Math.floor(companyInfo.total_credits_to_assign / employeesSelected.length)
          }`
        ),
      hours: Yup.number()
        .positive('Las horas no pueden ser negativas')
        .test(
          'test .5',
          'Las horas deben terminar en .0 o .5',
          (num) => num % 0.5 === 0
        )
        .min(0, 'Es necesario asignar horas')
        .max(
          employeesSelected.length === 0
            ? companyInfo.total_hours_to_assign
            : Math.floor((companyInfo.total_hours_to_assign / employeesSelected.length) * 2) / 2,
          `El maximo de horas es ${
            employeesSelected.length === 0
              ? companyInfo.total_hours_to_assign
              : Math.floor((companyInfo.total_hours_to_assign / employeesSelected.length) * 2) / 2
          }`
        ),
    }),
    onSubmit: async ({ credits, hours }) => {
      if (credits === 0 && hours === 0) {
        formik.setFieldError('credits', 'Es necesario al menos un campo')
        formik.setFieldError('hours', 'Es necesario al menos un campo')
        return
      }
      if (employeesSelected.length === 0) return setEmployeesError(true)

      setStep(prev => prev + 1)
    },
  })

  const handleChange = (e, to) => {
    if(isNaN(Number(e.target.value))) return
    return formik.setFieldValue(to, e.target.value)
  }

  const dataHeaders = [
    {
      id: 0,
      label: 'Nombre y Apellido',
      value: 'full_name'
    },
    {
      id: 1,
      label: 'Tipo',
      value: 'type'
    },
  ]

  if (step === 1)
    return (
      <div className="PanelModal px-3" onClick={closeModal}>
        <div
          className="PanelModal__Card--Medium"
          onClick={(e) => e.stopPropagation()}
        >
          <ModalHeader
            title={'Asignar horas y créditos'}
            closeModal={closeModal}
          />

          <div className="my-4">
            <div className="PanelModal__Inputs-Container mb-4">
              <div className="PanelModal__Input-Info text-4">
                {companyInfo.total_credits_to_assign} créditos disponibles
              </div>
              <div className="PanelModal__Input-Info text-4">
                {companyInfo.total_hours_to_assign} horas disponibles
              </div>
            </div>
            <div className="PanelModal__Inputs-Container mb-4">
              <Input
                label={'Cantidad de créditos'}
                name="credits"
                type="text"
                min={0}
                max={companyInfo.total_credits_to_assign}
                value={formik.values.credits}
                onChange={(e) => handleChange(e, 'credits')}
                error={formik.errors.credits}
              />
              <Input
                label={'Cantidad de horas'}
                name="hours"
                type="text"
                min={0}
                max={companyInfo.total_hours_to_assign}
                value={formik.values.hours}
                onChange={(e) => handleChange(e, 'hours')}
                error={formik.errors.hours}
              />
            </div>

            <Table
              type={'Assign-Credits'}
              headers={dataHeaders}
              data={companyPersonal.data}
              rounded
              selectedParentItems={employeesSelected}
              handleParentItems={handleSelectEmployee}
              setOrderBy={setOrderBy}
            />
            {employeesError && (
              <span className="d-flex justify-content-center text-5 text-error mb-4">
                No hay empleados seleccionados
              </span>
            )}
          </div>

          <div className="d-flex justify-content-center mb-4">
            total: {formik.values.credits * employeesSelected.length} créditos |{' '}
            {formik.values.hours * employeesSelected.length} horas
          </div>

          <div className="d-flex justify-content-center">
            <button
              className="button-green-panel my-3"
              type="submit"
              onClick={formik.handleSubmit}
            >
              Asignar
            </button>
          </div>

          <Steps totalCount={2} currentCount={1} />
        </div>
      </div>
    )

  const handleEndStep = async () => {
    if(buttonDisable) return
    setButtonDisable(true)

    let users_list = ''
    employeesSelected.forEach(
      (employee) => (users_list += employee.user.id + ',')
    )
    users_list = users_list.slice(0, -1)


    let credits_list = ''
    employeesSelected.forEach(() => (credits_list += formik.values.credits + ','))
    if (credits_list.endsWith(',')) {
      credits_list = credits_list.slice(0, -1)
    }

    let hours_list = ''
    employeesSelected.forEach(() => (hours_list += formik.values.hours + ','))
    if (hours_list.endsWith(',')) {
      hours_list = hours_list.slice(0, -1)
    }

    const body = {
      company_id,
      users_list,
      credits_list,
      hours_list,
    }

    const response = await postAssignHoursCreditsToEmployee(token, body)

    if (response) {
      updateCompanyInfo()
      setStep(step + 1)
    }

    setButtonDisable(false)
  }

  if (step === 2)
    return (
      <div className="PanelModal px-3" onClick={closeModal}>
        <div
          className="PanelModal__Card--Big"
          onClick={(e) => e.stopPropagation()}
        >
          <ModalHeader
            title={'Asignar plan'}
            closeModal={closeModal}
            goBack={() => setStep((prev) => prev - 1)}
          />

          <div className="PanelModal__Confirm PanelModal__User-Info">
            <div className="PanelModal__User-Info-Item-2">
              <h4 className="text-4">Usuario</h4> <h4 className="text-4">Creditos</h4> <h4 className="text-4">Horas</h4>
            </div>
            {employeesSelected.map((employee) => (
              <div key={employee.id} className="PanelModal__User-Info-Item-2">
                <span>
                  {employee.first_name} {employee.last_name}
                </span>{' '}
                <span>{formik.values.credits}</span>
                <span>{formik.values.hours}</span>
              </div>
            ))}
          </div>

          <div className="PanelModal__Confirm FinishModal__Info">
            <FontAwesomeIcon icon={faTriangleExclamation} />
            <span>
              Una vez completo el proceso, los créditos y horas no podrán ser reasignados
            </span>
          </div>

          <div className="d-flex justify-content-center">
            <button className="button-green-panel my-3" onClick={handleEndStep}>
            Asignar
            </button>
          </div>

          <Steps totalCount={2} currentCount={2} />
        </div>
      </div>
    )

  if (step === 3)
    return (
      <FinishModal
        type="--Medium"
        title="Asignar horas y créditos"
        message="¡Asignación exitosa!"
        buttonText="¡Entendido!"
        closeModal={closeModal}
      />
    )
}

AssignCreditsUserModal.propTypes = {
  closeModal: propTypes.func.isRequired,
  company_id: propTypes.number.isRequired,
  updateCompanyInfo: propTypes.func.isRequired,
  total_items: propTypes.number
}

export default AssignCreditsUserModal
