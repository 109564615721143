import { useContext } from 'react'
import { Link } from 'react-router-dom'
import propTypes from 'prop-types'
import { faGreaterThan, faLessThan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { monthNames } from '../utils/CalendarDataTime'
import CalendarExplanation from './CalendarExplanation'
import MonthSelector from './MonthSelector'
import { AppContext } from '../context'

const Calendar = ({ type, days, month, year, changeMonth, setMonth, openModal, openOverlay, closeOverlay }) => {
  const {  isAdmin } = useContext(AppContext)

  const handleOpenModal = (day) => {
    closeOverlay()
    openModal(day)
  }
  const handleOpenOverlay = (day) => {
    openOverlay(day)
  }


  return (
    <div className="px-3 d-block d-lg-none">
      <section className="Calendar">
        <header className="Calendar__Header">
          <MonthSelector data={monthNames} optionSelected={monthNames[month]} onChange={setMonth} isMini={true}/>

          <h2 className="Calendar__Header-Year text-4">{ year.toString() }</h2>

          <nav className="Calendar__Nav">
            <button className="Calendar__Nav-Button pe-1" onClick={() => changeMonth('prev')}>
              <FontAwesomeIcon icon={faLessThan}/>
            </button>
            <button className="Calendar__Nav-Button ps-1" onClick={() => changeMonth('next')}>
              <FontAwesomeIcon icon={faGreaterThan}/>
            </button>
          </nav>
        </header>

        <div className="Calendar__Week text-6 fw-semibold">
          <div>
            lun
          </div>
          <div>
            mar
          </div>
          <div>
            mie
          </div>
          <div>
            jue
          </div>
          <div>
            vie
          </div>
          <div>
            sab
          </div>
          <div>
            dom
          </div>
        </div>

        <div className="Calendar__Days-Container">
          {days.map((day, index) => {
            if(day.isFill) return (
              <button
                className="Calendar__Day--Fill text-6"
                key={`Calendar-Fill-Day-${day}-${index}`}
              >
                { day.dayNumber }
              </button>
            )

            if(day.isDisabled || day.isHoliday || day.isWeekendDay) return (
              <button
                className="Calendar__Day--Disabled text-6"
                key={`Calendar-Disabled-Day-${day}-${index}`}
                disabled
              >
                { day.dayNumber }
              </button>
            )

            if(day.isPastDay) return (
              <button
                className="Calendar__Day--PastDay text-6"
                key={`Calendar-Past-Day-${day}-${index}`}
                onClick={day.data?.length == 0 ? () => handleOpenModal(day) : () => handleOpenOverlay(day)}
              >
                {day.data?.length > 0 && <span className="Calendar__Day-Detail"></span>}
                { day.dayNumber }
              </button>
            )

            if(day.isLimitDay && !isAdmin) return (
              <button
                className="Calendar__Day--Disabled text-6"
                key={`Calendar-Limit-Day-${day}-${index}`}
                disabled
              >
                { day.dayNumber }
              </button>
            )

            if(!day.isAfternoonAvailable && !day.isMorningAvailable && !isAdmin && type == 'spaces') return (
              <button
                className="Calendar__Day--Disabled text-6"
                key={`Calendar-No-Available-Day-${day}-${index}`}
                disabled
              >
                {day.dayNumber}
              </button>
            )

            if(day.isCurrentDay) return (
              <button
                className="Calendar__Day--Current text-6"
                key={`Calendar-Current-Day-${day}-${index}`}
                onClick={day.data?.length == 0 ? () => handleOpenModal(day) : () => handleOpenOverlay(day)}
              >
                {day.data?.length > 0 && <span className="Calendar__Day-Detail"></span>}
                { day.dayNumber }
              </button>
            )

            return (
              <button
                className="Calendar__Day text-6"
                key={`Calendar-Day-${day}-${index}`}
                onClick={day.data?.length == 0 ? () => handleOpenModal(day) : () => handleOpenOverlay(day)}
              >
                {day.data?.length > 0 && <span className="Calendar__Day-Detail"></span>}
                { day.dayNumber }
              </button>
            )
          })}
        </div>
      </section>

      <CalendarExplanation/>

      {isAdmin && (
        <div className="d-flex justify-content-center pb-3">
          <Link className="button-inverted-panel" to='deshabilitar'>
            Deshabilitar día
          </Link>
        </div>
      )}
    </div>
  )
}

Calendar.propTypes = {
  type: propTypes.string.isRequired,
  days: propTypes.array.isRequired,
  month: propTypes.number.isRequired,
  year: propTypes.number.isRequired,
  changeMonth: propTypes.func.isRequired,
  setMonth: propTypes.func.isRequired,
  openModal: propTypes.func.isRequired,
  openOverlay: propTypes.func.isRequired,
  closeOverlay: propTypes.func.isRequired,
}

export default Calendar