import { useEffect, useRef } from 'react'
import gsap from 'gsap'
import propTypes from 'prop-types'


const PanelSwitch = ({ tab, changeTab }) => {
  const sliderRef = useRef()

  useEffect(() => {
    if(tab === 'spaces') {
      gsap.to(sliderRef.current, {
        duration: 0.2,
        left: '0%'
      })
    } else {
      gsap.to(sliderRef.current, {
        duration: 0.2,
        left: '50%'
      })
    }
  }, [tab])

  return (
    <div className="PanelSwitch__Container px-3">
      <div className="PanelSwitch">
        <div ref={sliderRef} className="PanelSwitch__Slider"/>
        <button
          className={ tab === 'spaces' ? 'PanelSwitch__Button--Active text-4' : 'PanelSwitch__Button text-4'}
          onClick={() => changeTab('spaces')}
        >
          Espacio compartido
        </button>
        <button
          className={ tab === 'rooms' ? 'PanelSwitch__Button--Active text-4' : 'PanelSwitch__Button text-4'}
          onClick={() => changeTab('rooms')}
        >
          Salas de reuniones
        </button>
      </div>
    </div>
  )
}

PanelSwitch.propTypes = {
  tab: propTypes.string.isRequired,
  changeTab: propTypes.func.isRequired
}

export default PanelSwitch