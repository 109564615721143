import propTypes from 'prop-types'
import { useContext, useMemo } from 'react'
import { getLandingTexts, getVideos } from '../api/admin-settings'
import Plant from '../assets/details/plant-2.webp'
import { AppContext } from '../context'
import useApi from '../hooks/useApi'
import { findContact } from '../utils/findContact'
import Media from './Media'

// const Banner = ({ home, images, video = DefaultVideo, setSwiper, children }) => {
const Banner = ({ home, videoType, textType, children }) => {
  const { networks } = useContext(AppContext)
  const [video] = useApi(() => getVideos(videoType), '')
  const [landingTexts] = useApi(() => getLandingTexts(textType), [])
  const Title = useMemo(
    () => landingTexts.find((item) => item.text_type.endsWith('titulo')),
    [landingTexts]
  )
  const descripcion = useMemo(
    () => landingTexts.find((item) => item.text_type.endsWith('descripcion')),
    [landingTexts]
  )
  const buttonText = useMemo(
    () => landingTexts.find((item) => item.text_type.endsWith('boton')),
    [landingTexts]
  )

  return (
    <div
      className={
        home ? 'Banner container-fluid' : 'Banner-Page container-fluid'
      }
    >
      {/* Images & Videos */}
      <Media
        type="video"
        className="Banner__Video"
        src={video}
        alt=""
        autoPlay
        loop
        muted
      />
      <div className="Banner__Title-Container ps-3 ps-lg-4">
        {Title ? (
          <h1 className="title" dangerouslySetInnerHTML={{ __html: Title.text }} />
        ) : (
          <></>
        )}
        {descripcion ? (
          <p
            className="text-2 mb-0 me-0 me-lg-4"
            dangerouslySetInnerHTML={{ __html: descripcion.text }}
          />
        ) : (
          <></>
        )}

        {buttonText ? (
          <a
            className="Banner__Button button-orange gap-1"
            target="_blank"
            rel="noopener noreferrer"
            href={`https://api.whatsapp.com/send?phone=${findContact(
              networks,
              'whatsapp'
            )}&text=${encodeURIComponent(
              'Hola! Quisiera realizar un día de prueba en Vida'
            )}`}
          >
            <p
              className="mb-0 text-5"
              dangerouslySetInnerHTML={{ __html: buttonText.text }}
            />
          </a>
        ) : (
          <></>
        )}
        {children}
      </div>
      {!home && (
        <img
          className="Banner__Plant d-block d-lg-none"
          src={Plant}
          alt="Banner Plant"
        />
      )}
    </div>
  )
}

Banner.propTypes = {
  home: propTypes.bool,
  images: propTypes.array,
  videoType: propTypes.string,
  textType: propTypes.string,
  setSwiper: propTypes.func,
  children: propTypes.node,
}

export default Banner
