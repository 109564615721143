import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const useApi = (callback, initialState, dependencyList = []) => {
  const [data, setData] = useState(initialState)

  const getData = async () => {
    const response = await callback()

    if(!response?.error) setData(response)
  }

  const updateData = () => {
    getData()
  }

  useEffect(() => {
    getData()
  }, dependencyList)

  return [data, updateData]
}

useApi.propTypes = {
  callback: PropTypes.func.isRequired,
  initialState: PropTypes.any.isRequired,
}

export default useApi