import { useState, useContext, useEffect, useMemo, Fragment } from 'react'
import propTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBriefcase, faCaretLeft, faCaretRight, faChevronLeft, faCircleExclamation, faMinus, faPlus, faTimes, faUser, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import ChairsSelector from '../../containers/ChairsSelector'
import FinishModal from './FinishModal'
import ErrorModal from './ErrorModal'
import ModalHeader from '../ModalHeader'
import { AppContext } from '../../context/index'
import { createSharedAreaBook } from '../../api/shared-areas'
import { getUserHoursAndCreditsAtDate } from '../../api/users'
import { dayNames } from '../../utils/CalendarDataTime'
import DefaultImage from '../../assets/chairs-map.webp'
import Loader from '../Loader'
import Steps from '../Steps'
import { getCompanyDetail, getCompanyEmployees, getCompanyHoursAndCreditsAtDate } from '../../api/companies'
import Input from '../Input'

const SpacesUserReservationModal = ({ days, initialData, refreshData, changeMonth, closeModal }) => {
  const { token, user, updateUser, sharedAreasTime } = useContext(AppContext)
  const [ step, setStep ] = useState(1)
  const [ loading, setLoading ] = useState(true)
  const [ reservation, setReservation ] = useState({
    booking_type: '',
    user_type: 'user',
    company_id: '',
    users_list: [],
    chair: '',
    chair_list: [],
    date: null,
    first_name: user.data.first_name,
    last_name: user.data.last_name,
    is_test_day: false,
  })
  const [ dateState, setDateState ] = useState({
    day: initialData.dayWeekName,
    dayNumber: initialData.dayNumber,
    month: initialData.month,
    year: initialData.year
  })
  const [ quantity, setQuantity ] = useState(1)
  const [ employees, setEmployees ] = useState([])
  const [ resetEmployeeSelector, setResetEmployeeSelector ] = useState(false)
  const [ creditsAtDate, setCreditsAtDate ] = useState('')
  const [ creditsAvailable, setCreditsAvailable ] = useState({
    companies: {},
    user: 0
  })
  const [ currentDay, setCurrentDay ] = useState([])
  const [ availability, setAvailability ] = useState({
    isMorningAvailable: true,
    isAfternoonAvailable: true
  })


  const modalDate = `${dateState.day} ${dateState.dayNumber < 10 ? `0${dateState.dayNumber}` : dateState.dayNumber}/${dateState.month + 1 < 10 ? `0${dateState.month + 1}` : dateState.month + 1}`

  const bookingTypes = [
    {
      value: 'Mañana',
      text: `Por la mañana (${sharedAreasTime.morningStartTime} - ${sharedAreasTime.morningEndTime}hs)`,
      schedule: `${sharedAreasTime.morningStartTime} - ${sharedAreasTime.morningEndTime}hs`,
      credits: '1 crédito'
    }, {
      value: 'Tarde',
      text: `Por la tarde (${sharedAreasTime.afternoonStartTime} - ${sharedAreasTime.afternoonEndTime}hs)`,
      schedule: `${sharedAreasTime.afternoonStartTime} - ${sharedAreasTime.afternoonEndTime}hs`,
      credits: '1 crédito'
    }, {
      value: 'Completo',
      text: `Jornada completa (${sharedAreasTime.morningStartTime} - ${sharedAreasTime.afternoonEndTime}hs)`,
      schedule: `${sharedAreasTime.morningStartTime} - ${sharedAreasTime.afternoonEndTime}hs`,
      credits: '2 créditos'
    }
  ]


  // Get current day data
  useEffect(() => {
    const getData = async () => {
      // Find user reservations for the current day
      const currentDayData = days.find(day => {
        if(day.dayWeekName == dateState.day && day.dayNumber == dateState.dayNumber) {
          // const existUserReservation = day.data.find(reservation => reservation.is_owner)
          return day
        }
      })

      setCurrentDay(currentDayData == undefined ? currentDay : currentDayData)

      // Find availability for the current day
      const availabilityDayData = days.find(day => {
        if(day.dayWeekName == dateState.day && day.dayNumber == dateState.dayNumber) return day
      })

      setAvailability({
        isMorningAvailable: availabilityDayData?.isMorningAvailable === undefined ? true : availabilityDayData?.isMorningAvailable,
        isAfternoonAvailable: availabilityDayData?.isAfternoonAvailable === undefined ? true : availabilityDayData?.isAfternoonAvailable
      })
    }

    getData()
  }, [days, dateState])

  // Get user and company credits for the current day
  useEffect(() => {
    const getData = async () => {
      const credits = {}
      const employees = {}
      const promises = []
      const requestDate = `${dateState.year}-${dateState.month + 1 < 10 ? `0${dateState.month + 1}` : dateState.month + 1}-${dateState.dayNumber < 10 ? `0${dateState.dayNumber}` : dateState.dayNumber}`
      let available_credits = 0

      // Admin Company: Get company employees and credits for the current day
      if(user.admin_in?.length !== 0) {
        user.admin_in?.forEach(company => {
          const newPromise = async () => {
            const response = await getCompanyEmployees(token, { company_id: company.id })
            const companyEmployees = response.map(item => item.user)
            companyEmployees.unshift({
              id: user.data.id,
              first_name: user.data.first_name,
              last_name: user.data.last_name,
              email: 'Tu',
            })

            const { total_credits_to_assign } = await getCompanyDetail(token, company.id, { date: requestDate })

            credits[company.id] = total_credits_to_assign
            employees[company.id] = companyEmployees
          }

          promises.push(newPromise())
        })
      }

      // Employee Company: Get company credits for the current day
      if(user.admin_in?.length !== 0 || user.employee_in?.length !== 0) {
        user.employee_in?.forEach(company => {
          const newPromise = async () => {
            const { available_credits } = await getCompanyHoursAndCreditsAtDate(token, {
              company_id: company.id,
              date: requestDate
            })
            credits[`user-${company.id}`] = available_credits
          }

          promises.push(newPromise())
        })
        user.admin_in?.forEach(company => {
          const newPromise = async () => {
            const { available_credits } = await getCompanyHoursAndCreditsAtDate(token, {
              company_id: company.id,
              date: requestDate
            })
            credits[`user-${company.id}`] = available_credits
          }

          promises.push(newPromise())
        })
      }
      // Get user credits for the current day
      else {
        const response = await getUserHoursAndCreditsAtDate(token, {
          date: requestDate
        })
        available_credits = response.available_credits
      }

      await Promise.all(promises)

      const firstAdminCompanyWithCredits = user.admin_in.find(company => credits[company.id] > 0)
      const firstAdminInvididualCompanyWithCredits = user.admin_in.find(company => credits[`user-${company.id}`] > 0)
      const firstEmployeeCompanyWithCredits = user.employee_in.find(company => credits[`user-${company.id}`] > 0)

      if(!available_credits) {
        setReservation({
          ...reservation,
          user_type: firstAdminCompanyWithCredits ? 'company' : 'user-company',
          company_id: firstAdminCompanyWithCredits?.id || (firstAdminInvididualCompanyWithCredits?.id || firstEmployeeCompanyWithCredits?.id)
        })
      } else {
        setReservation({
          ...reservation,
          user_type: 'user',
          company_id: null,
        })
      }

      setCreditsAvailable({
        ...credits,
        user: available_credits
      })
      setEmployees({
        ...employees,
      })

      setLoading(false)
    }

    token && getData()
  }, [token, dateState])

  // Set credits available for the current day
  useEffect(() => {
    if(reservation.company_id) {
      if(reservation.user_type == 'company') {
        setCreditsAtDate(creditsAvailable[reservation.company_id])
      } else {
        setCreditsAtDate(creditsAvailable[`user-${reservation.company_id}`])
      }
    } else {
      setCreditsAtDate(creditsAvailable.user)
    }
  }, [reservation.company_id, reservation.user_type, creditsAvailable])


  const changeDate = (direction) => {
    const daysInMonth = new Date(dateState.year, dateState.month + 1, 0).getDate()

    if(direction == 'next') {
      let step = 1
      if(dateState.day == 'Viernes') {
        step = 3
      }

      let day = dateState.dayNumber + step
      let month = dateState.month
      let year = dateState.year

      if(day > daysInMonth) {
        day = day - daysInMonth
        month = month + 1
        changeMonth(direction)
      }

      if(month > 11) {
        month = 0
        year = year + 1
      }

      const date = new Date(year, month, day)
      const dayName = date.toString().slice(0, 3)


      setDateState({
        day: dayNames[dayName],
        dayNumber: day,
        month: month,
        year: year
      })
    } else {
      let step = 1
      if(dateState.day == 'Lunes') {
        step = 3
      }

      let day = dateState.dayNumber - step
      let month = dateState.month
      let year = dateState.year

      if(day < 1 && dateState.day == 'Lunes') {
        day = new Date(year, month, day).getDate()
        month = month - 1
        changeMonth(direction)
      } else if(day < 1) {
        day = new Date(year, month, 0).getDate()
        month = month - 1
        changeMonth(direction)
      }

      if(month < 0) {
        month = 11
        year = year - 1
      }

      const date = new Date(year, month, day)
      const dayName = date.toString().slice(0, 3)

      // Reset reservation hours when change date
      setDateState({
        day: dayNames[dayName],
        dayNumber: day,
        month: month,
        year: year
      })
    }

    setReservation({
      ...reservation,
      start_date: '',
      start_hour: '',
      end_date: '',
      end_hour: ''
    })
  }

  const handleSelectCompany = (type, id) => {
    setReservation({
      ...reservation,
      user_type: type,
      company_id: id,
    })
  }

  const handleUserType = () => {
    setReservation({
      ...reservation,
      user_type: 'user',
      first_name: user.data.first_name,
      last_name: user.data.last_name,
      company_id: '',
      users_list: []
    })
    setQuantity(1)
  }

  const handleBookingType = (value) => {
    setReservation({
      ...reservation,
      booking_type: value
    })
  }

  const handleQuantity = (value) => {
    if(value >= 1 || value === '') {
      setQuantity(value)
    }
  }

  const handleEmployeeSelector = (id) => {
    const userList = reservation.users_list
    const exist = userList.find(item => item == id)

    if(id && exist === undefined) {
      userList.push(id)
      setReservation({
        ...reservation,
        users_list: userList,
        first_name: '',
        last_name: '',
      })
      setResetEmployeeSelector(true)

      setTimeout(() => {
        setResetEmployeeSelector(false)
      }, 100)
    }
  }

  const handleRemoveEmployee = (id) => {
    const userList = reservation.users_list
    const newUserList = userList.filter(item => item != id)

    setReservation({
      ...reservation,
      users_list: newUserList
    })
  }

  const handleDate = () => {
    setStep(step + 1)
    setReservation({
      ...reservation,
      date: `${dateState.year}-${dateState.month + 1}-${dateState.dayNumber}`,
      chair: null,
      chair_list: [],
    })
  }

  const handleReservationChair = (chair) => {
    if(reservation.user_type == 'company') {
      setReservation({
        ...reservation,
        chair_list: chair
      })
    } else {
      setReservation({
        ...reservation,
        chair: chair
      })
    }
  }

  const createReservation = async (e) => {
    e.target.disabled = true
    let data

    if(reservation.user_type == 'company') {
      const chairsId = reservation.chair_list.map(chair => chair.id).join(',')
      const usersId = reservation.users_list.join(',')

      data = await createSharedAreaBook(token, {
        ...reservation,
        chair_list: chairsId,
        users_list: usersId,

      })
    } else {
      data = await createSharedAreaBook(token, {
        ...reservation,
        chair: reservation.chair.id,
      })
    }

    if(!data.error) {
      setStep(step + 1)
      updateUser()
      refreshData()
    } else {
      setStep(5)
    }
  }


  const renderAdminCompanyOptions = (option, index) => {
    const hasGeneralCompanyCredits = creditsAvailable[option.id] > 0
    const hasInvidivualCompanyCredits = creditsAvailable[`user-${option.id}`] > 0

    if(option.id === reservation.company_id) return (
      <Fragment key={`SpacesReservationModal__Admin-Option-${index}`}>
        {reservation.user_type == 'company' && (
          <>
            {hasGeneralCompanyCredits && (
              <button
                className="SpacesReservationModal__Option--Active"
                key={`Spaces-Reservation-Modal-Option-${option.id}-${index}`}
                disabled
              >
                <div className="SpacesReservationModal__Option-Circle--Active"></div>
                <span className='text-4'>
                  <FontAwesomeIcon icon={faBriefcase} className='me-2'/>
                  {option.name}
                </span>
              </button>
            )}
            {hasInvidivualCompanyCredits && (
              <button
                className="SpacesReservationModal__Option"
                key={`Spaces-Reservation-Modal-Option-User-${option.id}-${index}`}
                onClick={() => handleSelectCompany('user-company', option.id)}
              >
                <div className="SpacesReservationModal__Option-Circle"></div>
                <span className='text-4'>
                  <FontAwesomeIcon icon={faBriefcase} className='me-2'/>
                  {option.name} (Tu plan asignado)
                </span>
              </button>
            )}
          </>
        )}
        {reservation.user_type == 'user-company' && (
          <>
            {hasGeneralCompanyCredits && (
              <button
                className="SpacesReservationModal__Option"
                key={`Spaces-Reservation-Modal-Option-${option.id}-${index}`}
                onClick={() => handleSelectCompany('company', option.id)}
              >
                <div className="SpacesReservationModal__Option-Circle"></div>
                <span className='text-4'>
                  <FontAwesomeIcon icon={faBriefcase} className='me-2'/>
                  {option.name}
                </span>
              </button>
            )}
            {hasInvidivualCompanyCredits && (
              <button
                className="SpacesReservationModal__Option--Active"
                key={`Spaces-Reservation-Modal-Option-User-${option.id}-${index}`}
                disabled
              >
                <div className="SpacesReservationModal__Option-Circle--Active"></div>
                <span className='text-4'>
                  <FontAwesomeIcon icon={faBriefcase} className='me-2'/>
                  {option.name} (Tu plan asignado)
                </span>
              </button>
            )}
          </>
        )}
      </Fragment>
    )
    else return (
      <Fragment key={`SpacesReservationModal__Admin-Option-${index}`}>
        {hasGeneralCompanyCredits && (
          <button
            className="SpacesReservationModal__Option"
            key={`Spaces-Reservation-Modal-Option-${option.id}-${index}`}
            onClick={() => handleSelectCompany('company', option.id)}
          >
            <div className="SpacesReservationModal__Option-Circle"></div>
            <span className='text-4'>
              <FontAwesomeIcon icon={faBriefcase} className='me-2'/>
              {option.name}
            </span>
          </button>
        )}
        {hasInvidivualCompanyCredits && (
          <button
            className="SpacesReservationModal__Option"
            key={`Spaces-Reservation-Modal-Option-User-${option.id}-${index}`}
            onClick={() => handleSelectCompany('user-company', option.id)}
          >
            <div className="SpacesReservationModal__Option-Circle"></div>
            <span className='text-4'>
              <FontAwesomeIcon icon={faBriefcase} className='me-2'/>
              {option.name} (Individual)
            </span>
          </button>
        )}
      </Fragment>
    )
  }

  const renderEmployeeCompanyOptions = (option, index) => {
    if(option.id === reservation.company_id) return (
      <button
        className="SpacesReservationModal__Option--Active"
        key={`Spaces-Reservation-Modal-Option-${option.id}-${index}`}
        disabled
      >
        <div className="SpacesReservationModal__Option-Circle--Active"></div>
        <span className='text-4'>
          <FontAwesomeIcon icon={faBriefcase} className='me-2'/>
          {option.name}
        </span>
      </button>
    )
    else return (
      <button
        className="SpacesReservationModal__Option"
        key={`Spaces-Reservation-Modal-Option-${option.id}-${index}`}
        onClick={() => handleSelectCompany(option.id)}
      >
        <div className="SpacesReservationModal__Option-Circle"></div>
        <span className='text-4'>
          <FontAwesomeIcon icon={faBriefcase} className='me-2'/>
          {option.name}
        </span>
      </button>
    )
  }

  const renderBookingOptions = (option, index) => {
    if(option.value === 'Completo' && (!availability.isMorningAvailable || !availability.isAfternoonAvailable || creditsAtDate == 1)) return (
      <button
        className="SpacesReservationModal__Option--Disabled"
        key={`Spaces-Reservation-Modal-Option-${option.value}-${index}`}
        disabled
      >
        <div className="SpacesReservationModal__Option-Circle"></div>
        <span className='text-4'>
          {option.text}<br/>
          {option.credits}
        </span>
      </button>
    )
    else if(option.value === 'Mañana' && !availability.isMorningAvailable) return (
      <button
        className="SpacesReservationModal__Option--Disabled"
        key={`Spaces-Reservation-Modal-Option-${option.value}-${index}`}
        disabled
      >
        <div className="SpacesReservationModal__Option-Circle"></div>
        <span className='text-4'>
          {option.text}<br/>
          {option.credits}
        </span>
      </button>
    )
    else if(option.value === 'Tarde' && !availability.isAfternoonAvailable) return (
      <button
        className="SpacesReservationModal__Option--Disabled"
        key={`Spaces-Reservation-Modal-Option-${option.value}-${index}`}
        disabled
      >
        <div className="SpacesReservationModal__Option-Circle"></div>
        <span className='text-4'>
          {option.text}<br/>
          {option.credits}
        </span>
      </button>
    )
    else if(option.value === reservation.booking_type) return (
      <button
        className="SpacesReservationModal__Option--Active"
        key={`Spaces-Reservation-Modal-Option-${option.value}-${index}`}
        disabled
      >
        <div className="SpacesReservationModal__Option-Circle--Active"></div>
        <span className='text-4'>
          {option.text}<br/>
          {option.credits}
        </span>
      </button>
    )
    else return (
      <button
        className="SpacesReservationModal__Option"
        key={`Spaces-Reservation-Modal-Option-${option.value}-${index}`}
        onClick={() => handleBookingType(option.value)}
      >
        <div className="SpacesReservationModal__Option-Circle"></div>
        <span className='text-4'>
          {option.text}<br/>
          {option.credits}
        </span>
      </button>
    )
  }


  const hasCredits = useMemo(() => {
    return Object.values(creditsAvailable).some(credits => credits > 0)
  }, [creditsAvailable])

  const hasCompaniesWithCredits = useMemo(() => {
    const companiesCredits = {
      ...creditsAvailable
    }
    delete companiesCredits.user

    return Object.values(companiesCredits).some(company => company > 0)
  }, [creditsAvailable])


  if(loading) return (
    <div className="PanelModal px-3">
      <div className="PanelModal__Card">
        <header>
          <button className='PanelModal__Button-Close' onClick={closeModal}>
            <FontAwesomeIcon icon={faXmark}/>
          </button>
          <h2 className="PanelModal__Title text-1 text-center">Reserva tu lugar</h2>
        </header>

        <div className='PanelModal__Date-Selector PanelModal__Confirm'>
          <button className='PanelModal__Button-Arrow text-1' onClick={() => changeDate('prev')}>
            <FontAwesomeIcon icon={faCaretLeft}/>
          </button>
          <h3 className="mx-2 text-1">{ modalDate }</h3>
          <button className='PanelModal__Button-Arrow text-1' onClick={() => changeDate('next')}>
            <FontAwesomeIcon icon={faCaretRight}/>
          </button>
        </div>

        <div className="title text-center py-5">
          <Loader/>
        </div>
      </div>
    </div>
  )

  if(step === 1) return (
    <div className="PanelModal px-3" onClick={closeModal}>
      <div className="PanelModal__Card" onClick={(e) => e.stopPropagation()}>
        <header>
          <button className='PanelModal__Button-Close' onClick={closeModal}>
            <FontAwesomeIcon icon={faXmark}/>
          </button>
          <h2 className="PanelModal__Title text-1 text-center">Reserva tu lugar</h2>
        </header>

        <div className='PanelModal__Date-Selector PanelModal__Confirm'>
          <button className='PanelModal__Button-Arrow text-1' onClick={() => changeDate('prev')}>
            <FontAwesomeIcon icon={faCaretLeft}/>
          </button>
          <h3 className="mx-2 text-1">{ modalDate }</h3>
          <button className='PanelModal__Button-Arrow text-1' onClick={() => changeDate('next')}>
            <FontAwesomeIcon icon={faCaretRight}/>
          </button>
        </div>

        {currentDay?.isPastDay ? (
          <div className="PanelModal__Alert title text-center">
            <FontAwesomeIcon icon={faXmarkCircle}/>
            <span className="text-2 mt-2">No podés reservar un espacio compartido para una fecha en el pasado</span>
          </div>
        ) : currentDay?.isHoliday ? (
          <div className="PanelModal__Alert title text-center">
            <FontAwesomeIcon icon={faXmarkCircle}/>
            <span className="text-2 mt-2">Esta fecha está inhabilitada para realizar reservas</span>
          </div>
        ) : currentDay?.isLimitDay ? (
          <div className="PanelModal__Alert title text-center">
            <FontAwesomeIcon icon={faXmarkCircle}/>
            <span className="text-2 mt-2">No podés reservar un espacio compartido 30 días después de la fecha actual</span>
          </div>
        ) : !currentDay.isAfternoonAvailable && !currentDay.isMorningAvailable ? (
          <div className="PanelModal__Alert title text-center">
            <FontAwesomeIcon icon={faXmarkCircle}/>
            <span className="text-2 mt-2">No hay espacios disponibles para esta fecha</span>
          </div>
        ) : (
          <>
            <div className="PanelModal__Confirm py-2 mb-3">
              {creditsAtDate > 0 && creditsAtDate == 1
                ? <div className="PanelModal__Credits-Detail text-5 fw-bold">
                  {creditsAtDate} crédito disponible
                </div>
                : <div className="PanelModal__Credits-Detail text-5 fw-bold">
                  {creditsAtDate || 0} créditos disponibles
                </div>
              }
            </div>

            {hasCredits
              ? (
                <>
                  {hasCompaniesWithCredits
                    ? (
                      <>
                        <div className="PanelModal__Scroll-Content">
                          <h4 className='text-3'>¿Con los créditos de que empresa reservas?</h4>

                          {/* User Types */}
                          <div className="PanelModal__Options-Container PanelModal__Confirm">
                            {user?.admin_in?.length > 0 && user?.admin_in?.map((option, index) => renderAdminCompanyOptions(option, index))}
                            {user?.employee_in?.length > 0 && user?.employee_in?.map((option, index) => renderEmployeeCompanyOptions(option, index))}

                            {creditsAvailable.user > 0 && (
                              <button
                                className={
                                  reservation.user_type == 'user'
                                    ? 'SpacesReservationModal__Option--Active'
                                    : 'SpacesReservationModal__Option'
                                }
                                onClick={reservation.user_type != 'user' ? handleUserType : undefined}
                                disabled={reservation.user_type == 'user'}
                              >
                                <div className={
                                  reservation.user_type == 'user'
                                    ? 'SpacesReservationModal__Option-Circle--Active'
                                    : 'SpacesReservationModal__Option-Circle'
                                }
                                ></div>
                                <span className='text-4'>
                                  <FontAwesomeIcon icon={faUser} className='me-2'/>
                                  Para mi
                                </span>
                              </button>
                            )}
                          </div>

                          {/* Booking Types */}
                          <h4 className='text-3 mt-3'>¿En qué horario te gustaría venir?</h4>

                          <div className="PanelModal__Options-Container PanelModal__Confirm">
                            {bookingTypes.map((option, index) => renderBookingOptions(option, index))}
                          </div>

                          {reservation.user_type == 'company' && reservation.booking_type && (
                            <>
                              <div className="PanelModal__Confirm">
                                <h4 className='text-3 text-start'>Cantidad de reservas</h4>
                                <div className="PanelModalSelector__Amount-Buttons justify-content-center mt-3 mb-2 position-relative">
                                  <button className='PanelModal__Amount-Button' onClick={() => handleQuantity(Number(quantity) - 1)}>
                                    <FontAwesomeIcon icon={faMinus}/>
                                  </button>

                                  <input
                                    className='PanelModal__Amount-Input text-5'
                                    type="text"
                                    value={quantity}
                                    min={1}
                                    max={50}
                                    onChange={(e) => handleQuantity(e.target.value)}
                                  />

                                  <button className='PanelModal__Amount-Button' onClick={() => handleQuantity(Number(quantity) + 1)}>
                                    <FontAwesomeIcon icon={faPlus}/>
                                  </button>

                                  {(quantity * (reservation.booking_type == 'Completo' ? 2 : 1)) > creditsAtDate && (
                                    <span className="Input__Error">
                                      <FontAwesomeIcon icon={ faCircleExclamation } className="Input__Error-Icon"/>
                                      No tenes créditos suficientes para realizar esta reserva
                                    </span>
                                  )}
                                </div>
                              </div>

                              {employees[reservation.company_id]?.length > 0
                                ? <div className="py-3 mb-5">
                                  <Input
                                    label='Nombre del usuario'
                                    name='user'
                                    search={employees[reservation.company_id]}
                                    value={reservation.date || ''}
                                    onChange={handleEmployeeSelector}
                                    reset={resetEmployeeSelector}
                                    icon={faUser}
                                  />
                                  {employees[reservation.company_id].length > 0 && reservation.users_list.length > 0 && (
                                    <>
                                      <div className="d-flex gap-2 mt-2">
                                        {employees[reservation.company_id].map((item, index) => {
                                          if(reservation.users_list.includes(item.id)) return (
                                            <div className="PanelModal__Pill" key={`Employee-Pill-${item.id}-${index}`}>
                                              <span className="text-5">{item.first_name}</span>

                                              <button className="PanelModal__Pill-Button" onClick={() => handleRemoveEmployee(item.id)}>
                                                <FontAwesomeIcon icon={faTimes}/>
                                              </button>
                                            </div>
                                          )
                                        })}
                                      </div>

                                      {reservation.users_list.length !== quantity && (
                                        <div className="Input__Error position-static text-start pt-2">
                                          <FontAwesomeIcon icon={ faCircleExclamation } className="Input__Error-Icon"/>
                                        El número de empleados seleccionados debe ser igual al número de reservas
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                                : <div className="pt-3">
                                  <Input
                                    label='Nombre del usuario'
                                    name='user'
                                    value='Empresa sin empleados'
                                    icon={faUser}
                                    disabled={true}
                                  />
                                </div>
                              }
                            </>
                          )}
                        </div>

                        <div className="d-flex justify-content-center">
                          {reservation.user_type == 'user' || reservation.user_type == 'user-company'
                            ? reservation.booking_type
                              ? <button className='button-green-panel my-3' onClick={handleDate}>Siguiente</button>
                              : <button className='button-disabled-panel my-3' disabled>Siguiente</button>
                            : reservation.booking_type && quantity > 0 && (quantity * (reservation.booking_type == 'Completo' ? 2 : 1)) <= creditsAtDate && reservation.users_list.length > 0 && reservation.users_list.length === quantity
                              ? <button className='button-green-panel my-3' onClick={handleDate}>Siguiente</button>
                              : <button className='button-disabled-panel my-3' disabled>Siguiente</button>
                          }
                        </div>
                      </>
                    )
                    : (
                      <>
                        <div className="PanelModal__Scroll-Content">
                          <h4 className='text-3 mb-3'>¿En qué horario te gustaría venir?</h4>

                          {bookingTypes.length > 0 && bookingTypes.map((option, index) => renderBookingOptions(option, index))}
                        </div>

                        <div className="d-flex justify-content-center">
                          {reservation.booking_type
                            ? <button className='button-green-panel my-3' onClick={handleDate}>Siguiente</button>
                            : <button className='button-disabled-panel my-3' disabled>Siguiente</button>
                          }
                        </div>
                      </>
                    )}

                  <Steps totalCount={3} currentCount={1} />
                </>
              )
              : (
                <div className="PanelModal__Alert title text-center">
                  <FontAwesomeIcon icon={faXmarkCircle}/>
                  <span className="text-2 mt-2">No tenés créditos disponibles para esta fecha</span>
                </div>
              )
            }
          </>
        )
        }
      </div>
    </div>
  )

  if(step === 2) return (
    <div className="PanelModal px-3" onClick={closeModal}>
      <div className="PanelModal__Card SpacesReservationModal__Card-Step-2" onClick={(e) => e.stopPropagation()}>
        <ModalHeader title={'Reservar espacio compartido'} closeModal={closeModal} goBack={() => { setStep(step - 1); setReservation({ ...reservation, chair_list: [] }) }} />

        <div className='SpacesReservationModal__Content-Step-2'>
          <div>
            <h3 className='PanelModal__Subtitle text-3 my-3 pb-1'>Seleccioná tu puesto {reservation.user_type == 'company' && `- Reservas: ${quantity}`}</h3>

            <ChairsSelector
              chairsSelected={reservation.user_type == 'company' ? reservation.chair_list : reservation.chair}
              setChairsSelected={handleReservationChair}
              date={reservation.date}
              bookingType={reservation.booking_type}
              limit={reservation.user_type == 'company' ? quantity : null}
            />
          </div>

          <div className="SpacesReservationModal__Image-Container pt-3">
            <img className="SpacesReservationModal__Image" src={DefaultImage} alt="Puestos"/>
          </div>
        </div>

        <div>
          <div className="d-flex justify-content-center">
            {(reservation.chair_list.length == quantity) || reservation.chair
              ? <button className='button-green-panel my-3' onClick={() => setStep(step + 1)}>Siguiente</button>
              : <button className='button-disabled-panel button-disabled my-3' disabled>Siguiente</button>
            }
          </div>

          <Steps totalCount={3} currentCount={2} />
        </div>
      </div>
    </div>
  )

  if(step === 3) return (
    <div className="PanelModal px-3" onClick={closeModal}>
      <div className="PanelModal__Card" onClick={(e) => e.stopPropagation()}>
        <header>
          <button className='PanelModal__Button-GoBack' onClick={() => setStep(step - 1)}>
            <FontAwesomeIcon icon={faChevronLeft}/>
          </button>
          <button className='PanelModal__Button-Close' onClick={closeModal}>
            <FontAwesomeIcon icon={faXmark}/>
          </button>
          <h2 className="PanelModal__Title text-1 text-center">Confirmación</h2>
        </header>

        <div className='PanelModal__Confirm'>
          <h3 className="mx-2 text-1">{ modalDate }</h3>
        </div>

        <div className='PanelModal__Confirm'>
          {reservation.chair_list.length > 0
            ? <span>Puesto/s: <span className="fw-bold">
              N° { reservation.chair_list.map((item, index) => {
                if(index !== reservation.chair_list.length - 1) return `${item.number}, `
                else return `${item.number}`
              })}
            </span></span>
            : <span>Puesto: <span className="fw-bold">N° { reservation.chair.number}</span></span>
          }
        </div>

        <div className='PanelModal__Confirm'>
          <span>Horario: <span className="fw-bold">{ reservation.booking_type }</span></span><br />
          <span>{ bookingTypes.find(item => item.value == reservation.booking_type).schedule}</span>
        </div>

        {reservation.user_type == 'company'
          ? <div className="PanelModal__Confirm">
            <span>Total: <span className="fw-bold">{ (quantity * (reservation.booking_type == 'Completo' ? 2 : 1)) > 1 ? `${(quantity * (reservation.booking_type == 'Completo' ? 2 : 1))} créditos` : '1 crédito'}</span></span>
          </div>
          : <div className="PanelModal__Confirm">
            <span>Total: <span className="fw-bold">{ reservation.booking_type == 'Completo' ? '2 créditos' : '1 crédito'}</span></span>
          </div>
        }

        <div className="d-flex justify-content-center">
          <button className='button-green-panel my-3' onClick={createReservation}>Reservar</button>
        </div>

        <Steps totalCount={3} currentCount={3} />
      </div>
    </div>
  )

  if(step === 4) return (
    <FinishModal
      title='Reserva tu lugar'
      message='¡Reserva exitosa!'
      closeModal={closeModal}
    />
  )

  if(step === 5) return (
    <ErrorModal
      title='Algo salió mal'
      message='Por favor anota los datos de tu reserva y contactanos por whatsapp para que podamos ayudarte'
      closeModal={closeModal}
      data={reservation}
      type='spaces'
    />
  )
}


SpacesUserReservationModal.propTypes = {
  days: propTypes.array,
  initialData: propTypes.object,
  refreshData: propTypes.func,
  changeMonth: propTypes.func,
  closeModal: propTypes.func.isRequired
}


export default SpacesUserReservationModal