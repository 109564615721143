import { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import FinishModal from '../FinishModal'
import AssignOfficeModalStep1 from './CreateOfficeBookModalStep1'
import AssignOfficeModalStep2 from './CreateOfficeBookModalStep2'
import { AppContext } from '../../../context'
import { getCompaniesData } from '../../../api/admin-data'
import useApi from '../../../hooks/useApi'


const CreateOfficeBookModal = ({
  closeModal,
  offices,
  officeSelected,
  updateOfficesData,
  updateOffices,
}) => {
  const { token } = useContext(AppContext)
  const [step, setStep] = useState(1)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const per_page = 5
  const [companiesData, updateCompaniesData] = useApi(
    () => getCompaniesData(token, { search, page, per_page }),
    {
      page: 1,
      total_pages: 1,
      total_items: 0,
      data: [],
    },
    [token, page, search]
  )
  const [body, setBody] = useState({
    office_id: '',
    company_id: '',
    start_date: '',
    end_date: '',
  })

  if (step === 1 && !officeSelected.book)
    return (
      <AssignOfficeModalStep1
        offices={offices}
        officeSelected={officeSelected}
        companiesData={companiesData}
        updateCompaniesData={updateCompaniesData}
        search={search}
        setSearch={setSearch}
        setPage={setPage}
        per_page={per_page}
        setBody={setBody}
        setStep={setStep}
        closeModal={closeModal}
      />
    )

  if (step === 2)
    return (
      <AssignOfficeModalStep2
        offices={offices}
        companiesData={companiesData}
        body={body}
        updateOfficesData={updateOfficesData}
        updateOffices={updateOffices}
        setStep={setStep}
        closeModal={closeModal}
      />
    )

  if (step === 3)
    return (
      <FinishModal
        title="Asignar oficina"
        message="¡Oficina asignada con éxito!"
        closeModal={closeModal}
      />
    )
}

CreateOfficeBookModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  offices: PropTypes.array.isRequired,
  officeSelected: PropTypes.object.isRequired,
  updateOfficesData: PropTypes.func.isRequired,
  updateOffices: PropTypes.func.isRequired,
}

export default CreateOfficeBookModal
