import propTypes from 'prop-types'


const FiltersCard = ({
  showFilters,
  filtersSelected,
  setFiltersSelected,
  filtersForCard,
  toggleShowCalendar,
  filtersLength
}) => {
  const findFilterSelected = (filterGroup) =>
    filtersSelected.find(
      (filterSelected) => filterSelected.label === filterGroup.label
    )

  const optionCheck = (filterGroup, option) =>
    findFilterSelected(filterGroup).option.value === option.value

  const handleFilter = (filterGroup, option) => {
    const findIndexFilterSelected = filtersSelected.findIndex(
      (filterSelected) => filterSelected.label === filterGroup.label
    )

    if (findFilterSelected(filterGroup).option.value === option.value) {
      setFiltersSelected((prev) => [
        ...prev.slice(0, findIndexFilterSelected),
        {
          ...prev[findIndexFilterSelected],
          option: prev[findIndexFilterSelected].defaultOption,
        },
        ...prev.slice(findIndexFilterSelected + 1),
      ])
    } else {
      setFiltersSelected((prev) => [
        ...prev.slice(0, findIndexFilterSelected),
        {
          ...prev[findIndexFilterSelected],
          option: option,
        },
        ...prev.slice(findIndexFilterSelected + 1),
      ])
    }
  }

  const clearFilters = () => { 
    setFiltersSelected(prev => prev.map(filterGroup => {
      if(filterGroup.label === 'Por fecha')
        return ({
          ...filterGroup,
          value: ''
        })
      return ({
        ...filterGroup,
        option: filterGroup.defaultOption
      })
    }))
  }

  if (showFilters)
    return (
      <div className="FiltersCard">
        {filtersLength !== 0 && (
          <div 
            className='FiltersCard__Clear'
            onClick={clearFilters}
          >
            Limpiar filtros
          </div>
        )}
        {filtersForCard.map((filterGroup, index) => (
          <div key={index} className="FiltersCard__Section">
            <h5 className="FiltersCard__Title">{filterGroup.label}</h5>

            {filterGroup?.options?.length > 0 && (
              <div className="FiltersCard__Options">
                {filterGroup.options.map((option, index) => {
                  return (
                    <div
                      key={index}
                      className={`FiltersCard__Option${
                        optionCheck(filterGroup, option) ? '--Active' : ''
                      }`}
                      onClick={() => handleFilter(filterGroup, option)}
                    >
                      {option.label}
                      <div className="FiltersCard__Option-Checkbox"></div>
                    </div>
                  )
                })}
              </div>
            )}

            {filterGroup.label === 'Por fecha' && (
              <div
                className="FiltersCard__Button"
                onClick={toggleShowCalendar}
              >
                {filtersSelected.find(filterSelected => filterSelected.label === 'Por fecha').value || 'Elegir fecha'}
              </div>
            )}
          </div>
        ))}
      </div>
    )
}

FiltersCard.propTypes = {
  showFilters: propTypes.bool.isRequired,
  filtersSelected: propTypes.array.isRequired,
  setFiltersSelected: propTypes.func.isRequired,
  filtersForCard: propTypes.array.isRequired,
  toggleShowCalendar: propTypes.func,
  filtersLength: propTypes.number.isRequired,
}

export default FiltersCard
