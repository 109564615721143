import propTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import oneCreditIcon from '../../assets/icons/1-CREDITO.svg'
import twoCreditIcon from '../../assets/icons/2-CREDITOS.svg'


const HelpModal = ({ closeModal }) => {
  return (
    <div className="PanelModal px-3" onClick={closeModal}>
      <div className="PanelModal__Card--Medium" onClick={(e) => e.stopPropagation()}>
        <header>
          <button className='PanelModal__Button-Close' onClick={closeModal}>
            <FontAwesomeIcon icon={faXmark}/>
          </button>
          <h2 className="PanelModal__Title text-1 text-center">¿Cómo funcionan los Créditos Vida?</h2>
        </header>

        <div className="d-flex flex-column justify-content-between align-items-center gap-3 p-4">
          <div className='d-flex gap-2'>
            <img
              src={oneCreditIcon}
              width={40}
              height={40}
              alt="icono de un credito"
            />
            <img
              src={twoCreditIcon}
              width={40}
              height={40}
              alt="icono de dos creditos"
            />
          </div>
          <div className="d-flex align-items-center gap-2 text-5 text-gray-dark">
            <img
              src={oneCreditIcon}
              width={20}
              height={20}
              alt="icono de un credito"
            />
            <p className="flex-shrink-0 mb-0 fw-bold">1 Crédito</p>
            <p className="mb-0">=</p>
            <p className="flex-shrink-0 mb-0">Medio día</p>
          </div>
          <div className="d-flex align-items-center gap-2 text-5 text-gray-dark">
            <img
              src={twoCreditIcon}
              width={20}
              height={20}
              alt="icono de dos creditos"
            />
            <p className="flex-shrink-0 mb-0 fw-bold">2 Créditos</p>
            <p className="mb-0">=</p>
            <p className="flex-shrink-0 mb-0">Día completo</p>
          </div>
        </div>
      </div>
    </div>
  )
}

HelpModal.propTypes = {
  closeModal: propTypes.func.isRequired
}

export default HelpModal