import { useState, useEffect, useRef, useContext } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import Logo from '../components/Logo.jsx'
import { AppContext } from '../context'
import { findContact } from '../utils/findContact.js'

const Header = () => {
  const { token, networks } = useContext(AppContext)
  const [ showMenu, setShowMenu ] = useState(false)
  const progressBar = useRef(null)
  const progressMobileBar = useRef(null)
  const { pathname } = useLocation()

  useEffect(() => {
    setShowMenu(false)
  }, [pathname])

  const handleToggleMenu = () => {
    setShowMenu(!showMenu)
  }

  window.addEventListener('resize', () => {
    if (window.innerWidth > 992) {
      setShowMenu(false)
    }
  })

  window.addEventListener('scroll', () => {
    if(progressBar.current) {
      const bodyHeight = document.body.clientHeight - window.innerHeight
      const windowScroll = window.scrollY
      const progress = Math.round((windowScroll / bodyHeight) * 100)

      progressBar.current.style.width = `${progress}%`
    }
    if(progressMobileBar.current) {
      const bodyHeight = document.body.clientHeight - window.innerHeight
      const windowScroll = window.scrollY
      const progress = Math.round((windowScroll / bodyHeight) * 100)

      progressMobileBar.current.style.width = `${progress}%`
    }
  })

  return (
    <>
      <header className="Header container-fluid px-3 px-lg-4">
        <div className="Header__Progress-Container">
          <div className="Header__Progress-Bar" ref={progressBar}></div>
        </div>
        <NavLink to='/' title='Inicio'>
          <Logo className='Header__Logo'/>
        </NavLink>

        <nav className='Nav'>
          <div className='Nav__Links'>
            <NavLink to='/planes' className={({ isActive }) => isActive ? 'Nav__Link--Active' : 'Nav__Link'} title='Planes'>
              Planes
            </NavLink>
            <NavLink to='/oficinas' className={({ isActive }) => isActive ? 'Nav__Link--Active' : 'Nav__Link'} title='Oficinas'>
              Oficinas
            </NavLink>
            <NavLink to='/salas' className={({ isActive }) => isActive ? 'Nav__Link--Active' : 'Nav__Link'} title='Salas de Reuniones'>
              Salas de Reuniones
            </NavLink>
            <div className="Nav__Divider"></div>
            <NavLink to='/eventos' className={({ isActive }) => isActive ? 'Nav__Link--Active' : 'Nav__Link'} title='Eventos'>
              Eventos
            </NavLink>
          </div>
        </nav>

        <div className='Nav__Links--Special'>
          <NavLink to='/#envia-tu-consulta' className={'Nav__Link--Contact d-none d-lg-flex'} title='Contacto'>
            Contacto
          </NavLink>
          {/* <a href={`https://api.whatsapp.com/send?phone=${findContact(networks, 'whatsapp')}`} target='_blank' rel='noreferrer' className={'Nav__Link--Contact d-none d-lg-flex'}>
            Contacto
          </a> */}
          {token
            ? <NavLink to='/panel/' className={'Nav__Link--Panel'} title='Panel'>
              Panel
            </NavLink>
            : <NavLink to='/iniciar-sesion' className={'Nav__Link--Login'} title='Iniciar Sesión'>
              Iniciar Sesión
            </NavLink>
          }
        </div>
      </header>

      <header className="Header-Mobile px-3">
        <div className="Header__Progress-Container">
          <div className="Header__Progress-Bar" ref={progressMobileBar}></div>
        </div>
        <div className='Header-Mobile__Bar'>
          <NavLink to='/' title='Inicio'>
            <Logo className='Header__Logo'/>
          </NavLink>

          <div className="Header__Button-Container">
            <a
              className='Header__Button'
              href={`https://api.whatsapp.com/send?phone=${findContact(networks, 'whatsapp')}&text=${encodeURIComponent('Hola Vida Cowork 🍃 Visité su página web y quisiera más info de:')}`}
              aria-label='Whatsapp'
              target='_blank'
              rel='noreferrer'
            >
              <FontAwesomeIcon icon={faWhatsapp}/>
            </a>
            <button
              className='Header__Button'
              onClick={handleToggleMenu}
              aria-label='Menu'
              role='button'
            >
              {showMenu
                ? <FontAwesomeIcon icon={faXmark}/>
                : <FontAwesomeIcon icon={faBars}/>
              }
            </button>
          </div>
        </div>

        {showMenu && (
          <div className='Header-Mobile__Content'>
            <NavLink to='/planes' className={({ isActive }) => isActive ? 'Nav__Link--Active' : 'Nav__Link'} title='Planes'>
              Planes
            </NavLink>
            <NavLink to='/oficinas' className={({ isActive }) => isActive ? 'Nav__Link--Active' : 'Nav__Link'} title='Oficinas'>
              Oficinas
            </NavLink>
            <NavLink to='/salas' className={({ isActive }) => isActive ? 'Nav__Link--Active' : 'Nav__Link'} title='Salas de Reuniones'>
              Salas de Reuniones
            </NavLink>
            <NavLink to='/eventos' className={({ isActive }) => isActive ? 'Nav__Link--Active' : 'Nav__Link'} title='Eventos'>
              Eventos
            </NavLink>

            <div className='Nav__Links--Special'>
              {token
                ? <NavLink to='/panel/' className={'Nav__Link--Panel'} title='Panel'>
                  Panel
                </NavLink>
                : <NavLink to='/iniciar-sesion' className={'Nav__Link--Login'} title='Iniciar Sesión'>
                  Iniciar Sesión
                </NavLink>
              }
            </div>
          </div>
        )}
      </header>
    </>
  )
}

export default Header