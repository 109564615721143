import { useContext, useEffect, useRef, useState } from 'react'
import propTypes from 'prop-types'
import gsap from 'gsap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBriefcase,
  faPlus,
  faUser,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import Filter from '../components/Filter'
import { AppContext } from '../context'


const SpacesReservationOverlay = ({
  data,
  dayWeekName,
  dayNumber,
  isCurrentDay,
  isPastDay,
  showOverlay,
  closeOverlay,
  openModal,
  openCancelModal,
}) => {
  const { user, isAdmin, sharedAreasTime } = useContext(AppContext)
  const overlayRef = useRef()
  const [filter, setFilter] = useState('')

  useEffect(() => {
    if (overlayRef.current) {
      if (showOverlay) {
        gsap.to(overlayRef.current, { translateX: '0%', duration: 0.2 })
        setTimeout(() => {
          overlayRef.current.focus()
        }, 200)
      } else {
        gsap.to(overlayRef.current, { translateX: '100%', duration: 0.2 })
      }
    }
    setFilter(null)
  }, [showOverlay])

  const filters = [
    'Todas las reservas',
    'Jornada Completa',
    'Turno Mañana',
    'Turno Tarde',
  ]
  const filterData =
    data?.length > 0 &&
    data.filter((item) => {
      if (filter == 1) {
        return item.booking_type === 'Completo'
      }
      if (filter == 2) {
        return item.booking_type === 'Mañana'
      }
      if (filter == 3) {
        return item.booking_type === 'Tarde'
      }
      return item
    })
  const bookingTypes = {
    Completo: {
      title: 'Jornada Completa',
      className: 'Complete',
      schedule: `${sharedAreasTime.morningStartTime} - ${sharedAreasTime.afternoonEndTime} hs`,
    },
    Mañana: {
      title: 'Turno Mañana',
      className: 'Morning',
      schedule: `${sharedAreasTime.morningStartTime} - ${sharedAreasTime.morningEndTime} hs`,
    },
    Tarde: {
      title: 'Turno Tarde',
      className: 'Afternoon',
      schedule: `${sharedAreasTime.afternoonStartTime} - ${sharedAreasTime.afternoonEndTime} hs`,
    },
  }

  const time = new Date().getTime()

  const changeFilter = (filter) => {
    if (filter === 0) {
      setFilter(null)
    } else {
      setFilter(filter)
    }
  }

  const handleOpenModal = () => {
    openModal({ data, dayWeekName, dayNumber, isPastDay })
    closeOverlay()
  }

  const handleOpenCancelReservationModal = async (reservation) => {
    closeOverlay()
    openCancelModal(reservation, isCurrentDay)
  }

  return (
    <aside className="SpacesReservationOverlay" ref={overlayRef} tabIndex="0">
      <div className="SpacesReservationOverlay__Content">
        <header className="SpacesReservationOverlay__Header pb-3">
          <h2 className="text-1">Reservas del día:</h2>
          <button
            className="SpacesReservationOverlay__Button-Close"
            onClick={closeOverlay}
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </header>

        <div className="SpacesReservationOverlay__Date">
          <h3 className="text-2 fw-bold">{dayWeekName}</h3>
          <h3 className="text-2 fw-bold">{dayNumber}</h3>
        </div>

        <Filter
          data={filters}
          optionSelected={filters[filter || 0]}
          type="reservas"
          onChange={changeFilter}
        />

        <div className="SpacesReservationOverlay__Items-Container">
          {filterData?.length > 0 ? (
            filterData.map((item, index) => {
              const reservationDate = new Date(`${item.date}T11:00:00Z`)
              const startHour = bookingTypes[item.booking_type].schedule
                .split(' - ')[0]
                .split(':')[0]

              reservationDate.setHours(startHour, 0, 0, 0)
              const reservationStartTime = reservationDate.getTime()

              const reservationIsStarted = reservationStartTime < time

              return (
                <div
                  className={`SpacesReservationOverlay__Item--${
                    bookingTypes[item.booking_type].className
                  }`}
                  key={`SpaceDay-Data-Item-${index}-${item.date}`}
                >
                  <h4 className="SpacesReservationOverlay__Item-Title fw-bold text-4">
                    {bookingTypes[item.booking_type].title}
                  </h4>

                  {isAdmin ? (
                    <div className="d-flex flex-column justify-content-between align-items-start gap-1">
                      <h5 className="text-4">
                        {item.user ||
                          item.name_reference ||
                          `${item.first_name} ${item.last_name}`}
                      </h5>
                      {item.shift === 'Test day' ? (
                        <h6 className="text-5 fw-medium">Día de prueba</h6>
                      ) : (
                        <></>
                      )}
                      <span className="text-5">
                        Puesto N°{item.chair__number}
                      </span>
                      <span className="text-5">
                        {bookingTypes[item.booking_type].schedule}
                      </span>
                    </div>
                  ) : user.admin_in.length > 0 ? (
                    <div className="d-flex flex-column justify-content-between align-items-start gap-1">
                      <h5 className="text-4">
                        {item.company_id ? (
                          <>
                            <FontAwesomeIcon
                              icon={faBriefcase}
                              className="me-2"
                              size="1x"
                            />
                            {item.user__first_name} {item.user__last_name}
                          </>
                        ) : (
                          <>
                            <FontAwesomeIcon
                              icon={faUser}
                              className="me-2"
                              size="1x"
                            />
                            Personal
                          </>
                        )}
                      </h5>
                      <span className="text-5">
                        Puesto N°{item.chair__number}
                      </span>
                    </div>
                  ) : (
                    <div className="d-flex flex-column justify-content-between align-items-start gap-1">
                      <h5 className="text-4">Puesto N°{item.chair__number}</h5>
                      <span className="text-5">
                        {bookingTypes[item.booking_type].schedule}
                      </span>
                    </div>
                  )}

                  {!isPastDay &&
                    (reservationIsStarted && !isAdmin ? (
                      <button
                        className="SpacesReservationOverlay__Cancel-Button--Disabled"
                        title="No se puede cancelar una reserva que ya ha comenzado o terminado"
                        disabled
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    ) : (
                      <button
                        className="SpacesReservationOverlay__Cancel-Button"
                        onMouseDown={() =>
                          handleOpenCancelReservationModal(item)
                        }
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    ))}
                </div>
              )
            })
          ) : (
            <div className="SpacesReservationOverlay__Item--No-Reservations">
              <h4 className="SpacesReservationOverlay__Item-Title fw-bold text-4">
                {isPastDay
                  ? 'No tuviste reservas para este horario'
                  : 'No tenes reservas para este horario'}
              </h4>
            </div>
          )}
        </div>
      </div>

      {!isPastDay && (
        <footer className="d-flex justify-content-center">
          <button
            className="SpacesReservationOverlay__Button"
            onMouseDown={handleOpenModal}
          >
            <span className="text-4">
              <FontAwesomeIcon icon={faPlus} />
            </span>
            <span className="ms-2">Crear reserva</span>
          </button>
        </footer>
      )}
    </aside>
  )
}

SpacesReservationOverlay.propTypes = {
  data: propTypes.array,
  dayWeekName: propTypes.string,
  dayNumber: propTypes.number,
  isCurrentDay: propTypes.bool,
  isPastDay: propTypes.bool,
  showOverlay: propTypes.bool.isRequired,
  closeOverlay: propTypes.func.isRequired,
  openModal: propTypes.func.isRequired,
  openCancelModal: propTypes.func.isRequired,
}

export default SpacesReservationOverlay
