import ReactDOM from 'react-dom/client'
import App from './router/Routes'
import AppContextProvider from './context/index.jsx'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/thumbs'
import 'swiper/css/effect-fade'
import 'swiper/css/free-mode'
import './styles/index.sass'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <AppContextProvider>
    <App />
  </AppContextProvider>
)
