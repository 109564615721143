import { faTags } from '@fortawesome/free-solid-svg-icons'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import Input from '../../Input'
import ModalHeader from '../../ModalHeader'
import { getCommunityDiscount } from '../../../api/community'
import { AppContext } from '../../../context'
import { postBuyPlanByAdmin, postBuyPlanByCompany } from '../../../api/plans'
import Steps from '../../Steps'


const AssignPlanAdminModalStep2 = ({
  type = 'user',
  closeModal,
  setStep,
  planSelected,
  selectedItems,
  updateData,
}) => {
  const { token } = useContext(AppContext)
  const [firstShow, setFirstShow] = useState(true)
  const [error, setError] = useState('')

  const [paySelected, setPaySelected] = useState('')
  const [discount, setDiscount] = useState({ name: '', porcent: 0 })

  const payOptions = ['Abonado', 'Coordinar pago posteriormente']

  const prevStep = () => {
    setStep((prev) => prev - 1)
  }

  const formik = useFormik({
    initialValues: {
      code: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      code: Yup.string()
        .min(1, 'El codigo de descuento esta vacio')
        .max(40, 'Máximo de caracteres: 40'),
    }),
    onSubmit: async ({ code }) => {
      if (code === '') return
      const params = { code }

      const response = await getCommunityDiscount(token, params)

      if (response.error) {
        formik.setFieldError('code', 'El codigo de descuento no es  valido')
        setDiscount({
          name: '',
          porcent: 0,
        })
      } else if (response) {
        setDiscount({
          name: response.code,
          porcent: response.porcent_discount,
        })
      }
    },
  })

  const handlePayOption = (value) => {
    if (value === paySelected) {
      setPaySelected('')
      setError('Es necesario un metodo de pago')
    } else {
      setPaySelected(value)
    }
  }

  useEffect(() => {
    if (firstShow) return setFirstShow(false)
    const checkPay = paySelected.length === 0
    setError(checkPay ? 'Es necesario un metodo de pago' : '')
  }, [paySelected])

  const handleEndStep = async () => {
    if (paySelected.length === 0) {
      setError('Es necesario un metodo de pago')
      return null
    }

    let user_list = ''
    selectedItems.forEach((user) => {
      user_list += user.id + ','
    })
    user_list = user_list.slice(0, -1)

    let body
    if(type === 'user') {
      body = {
        plan: planSelected.id,
        user_list,
        is_paid: paySelected === 'Abonado' ? 'true' : 'false'
      }
    } else {
      body = {
        plan_ids: planSelected.id,
        company_id: selectedItems[0].id,
        user_list,
        is_paid: paySelected === 'Abonado' ? 'true' : 'false'
      }
    }

    if (discount.porcent !== 0) {
      body.code = discount.name
    }

    let response
    if(type === 'user') {
      response = await postBuyPlanByAdmin(token, body)
    } else {
      response = await postBuyPlanByCompany(token, body)
    }

    if (response.error === 'Selling is not allowed for the moment') {
      setError('La compra de planes esta deshabilitada por el momento')
    } else {
      updateData()
      setStep((step) => step + 1)
    }
  }

  return (
    <div className="PanelModal px-3" onClick={closeModal}>
      <div
        className="PanelModal__Card--Big"
        onClick={(e) => e.stopPropagation()}
      >
        <ModalHeader
          title={'Cargar plan'}
          closeModal={closeModal}
          goBack={prevStep}
        />

        <div className="py-3">
          <h3 className="text-4 text-center">Detalle de compra</h3>
        </div>

        <div className="PanelModal__Plan-Detail text-uppercase mb-4">
          <span className="text-2 fw-bold">{planSelected.name}</span>
          <span className="text-3">{planSelected.type.split(' ')[0]}</span>
          <span className="text-2 fw-bold">${planSelected.price}</span>
        </div>

        <div className="PanelModal__Discount-Container py-3">
          <div className="w-100 h-100 d-flex flex-column justify-content-between align-items-start">
            <form className="d-flex align-items-end gap-2 w-100">
              <Input
                label="Código de descuento"
                name="code"
                value={formik.values.code}
                onChange={formik.handleChange}
                error={formik.errors.code}
                icon={faTags}
                tip="Ingresa aquí tu código de descuento"
              />
              <button
                className="button-green-panel text-4"
                type="submit"
                onClick={formik.handleSubmit}
              >
                Validar
              </button>
            </form>

            <div className="mt-30px">
              <h5 className="text-4 fw-normal">Subtotal</h5>
              <h4 className="text-3 mt-2">${planSelected.price}</h4>
            </div>
          </div>

          <span className="PanelModal__Discount-Divisor"></span>

          <div className="w-100 d-flex flex-column justify-content-start align-items-start">
            <h4 className="text-3">Pago:</h4>
            <div className="PanelModal__Options-Container my-4">
              {payOptions.map((option, index) => {
                return (
                  <button
                    className={`PanelModal__Option${
                      option === paySelected ? '--Active' : ''
                    } text-4 justify-content-start`}
                    key={`Spaces-Reservation-Modal-Option-${index}`}
                    onClick={() => handlePayOption(option)}
                  >
                    <div
                      className={`PanelModal__Option-Circle${
                        option === paySelected ? '--Active' : ''
                      }`}
                    />
                    <span>{option}</span>
                  </button>
                )
              })}
            </div>

            <h5 className="text-3 fw-normal">Total</h5>
            <h4 className="text-2 mt-2">
              $
              {discount.porcent !== 0
                ? planSelected.price -
                  planSelected.price * (discount.porcent / 100)
                : planSelected.price}
            </h4>
            {discount.porcent !== 0 && (
              <span className="text-5 mt-2">
                {discount.porcent}% de descuento
              </span>
            )}
          </div>
        </div>

        {error.length !== 0 && (
          <div className="d-flex justify-content-center">
            <span className="PanelModal__Error text-5 text-center mt-3">
              {error}
            </span>
          </div>
        )}

        <div className="d-flex justify-content-center">
          <button
            className={`button-green-panel ${
              error.length !== 0 ? 'button-disabled' : ''
            } my-3 `}
            onClick={handleEndStep}
          >
            Finalizar compra
          </button>
        </div>

        <Steps totalCount={2} currentCount={2} />
      </div>
    </div>
  )
}

AssignPlanAdminModalStep2.propTypes = {
  type: PropTypes.oneOf(['user', 'company']),
  closeModal: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  planSelected: PropTypes.object.isRequired,
  selectedItems: PropTypes.array.isRequired,
  updateData: PropTypes.func.isRequired,
}

export default AssignPlanAdminModalStep2
