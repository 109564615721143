import { useState } from 'react'
import propTypes from 'prop-types'
import FinishModal from '../FinishModal'
import AssignPlanUserModalStep2 from './AssignPlanUserModalStep2'
import AssignPlanUserModalStep1 from './AssignPlanUserModalStep1'


const AssignPlanUserModal = ({
  closeModal,
  notAssingedPlans,
  company_id,
  updateCompanyInfo,
  total_items = 100,
}) => {
  const [step, setStep] = useState(1)
  const [employeesSelected, setEmployeesSelected] = useState([])
  const [discountCommonUse, setDiscountCommonUse] = useState(false)

  if (step === 1)
    return (
      <AssignPlanUserModalStep1
        closeModal={closeModal}
        setStep={setStep}
        employeesSelected={employeesSelected}
        setEmployeesSelected={setEmployeesSelected}
        discountCommonUse={discountCommonUse}
        setDiscountCommonUse={setDiscountCommonUse}
        company_id={company_id}
        notAssingedPlans={notAssingedPlans}
        total_items={total_items}
      />
    )

  if (step === 2)
    return (
      <AssignPlanUserModalStep2
        closeModal={closeModal}
        setStep={setStep}
        employeesSelected={employeesSelected}
        discountCommonUse={discountCommonUse}
        notAssingedPlans={notAssingedPlans}
        company_id={company_id}
        updateCompanyInfo={updateCompanyInfo}
      />
    )

  if (step === 3)
    return (
      <FinishModal
        type="--Big"
        title="Asignar plan"
        message="¡Asignación exitosa!"
        buttonText="¡Entendido!"
        closeModal={closeModal}
      />
    )
}

AssignPlanUserModal.propTypes = {
  closeModal: propTypes.func.isRequired,
  notAssingedPlans: propTypes.array.isRequired,
  company_id: propTypes.number.isRequired,
  updateCompanyInfo: propTypes.func.isRequired,
  total_items: propTypes.number,
}

export default AssignPlanUserModal
