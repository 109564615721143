import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { getSystemPlans } from '../../../api/plans'
import useApi from '../../../hooks/useApi'
import ModalHeader from '../../ModalHeader'
import PanelModalSelector from '../../PanelModalSelector'
import Steps from '../../Steps'


const AssignPlanAdminModalStep1 = ({ closeModal, setStep, setPlanSelected }) => {
  const [error, setError] = useState('')

  const [plans] = useApi(getSystemPlans, {
    data: {},
    is_selling_allowed: true,
  })
  const [planNameSelected, setPlanNameSelected] = useState('')
  const [planOptionSelected, setPlanOptionSelected] = useState({})

  const plansNames = Object.keys(plans.data)

  useEffect(() => {
    setPlanNameSelected(plansNames[0] ?? '')
  }, [plans.data])

  const handlePlanOption = (value) => {
    if (value.id === planOptionSelected?.id) {
      setPlanOptionSelected({})
    } else {
      setPlanOptionSelected(value)
    }
  }

  const changePlanName = (planNameIndex) => {
    setPlanNameSelected(plansNames[planNameIndex])
    setPlanOptionSelected({})
  }

  const planOptions = (planName) => {
    if (plansNames?.length !== 0 && planName) {
      const optionsKey = Object.keys(plans.data[planName])
      return optionsKey.map((option) => {
        return { id: option, name: planName, ...plans.data[planName][option] }
      })
    }
  }

  useEffect(() => {
    if (planOptionSelected?.id) {
      return setError('')
    }
  }, [planOptionSelected])

  const handleNextStep = () => {
    if (!planOptionSelected?.id) {
      return setError('No hay plan seleccionado')
    }

    setPlanSelected(planOptionSelected)

    setStep((step) => step + 1)
  }

  return (
    <div className="PanelModal px-3" onClick={closeModal}>
      <div
        className="PanelModal__Card--Big"
        onClick={(e) => e.stopPropagation()}
      >
        <ModalHeader title={'Cargar plan'} closeModal={closeModal} />

        <div className="PanelModal__SolicitPlanModal-Plans-Container">
          <div className="PanelModal__SolicitPlanModal-Plans-Container-item w-100">
            <div className="d-flex justify-content-center py-4">
              <PanelModalSelector
                options={plansNames}
                optionSelected={planNameSelected}
                onChange={changePlanName}
              />
            </div>

            <div className="PanelModal__Options-Container mb-4">
              {planOptions(planNameSelected) &&
                  planOptions(planNameSelected).map((option) => {
                    const planType = option.type.split(' ')[0]

                    return (
                      <button
                        className={`PanelModal__Option${
                          option.id === planOptionSelected?.id ? '--Active' : ''
                        } text-4`}
                        key={option.id}
                        onClick={() => handlePlanOption(option)}
                      >
                        <div
                          className={`PanelModal__Option-Circle${
                            option.id === planOptionSelected?.id
                              ? '--Active'
                              : ''
                          }`}
                        ></div>
                        <span>{planType}</span>
                        <span>{option.credits} créditos</span>
                        <span>{option.hours} hs en salas</span>
                        <span className="PanelModal__Option-Price">
                          ${option.price}
                        </span>
                      </button>
                    )
                  })}
            </div>
          </div>
        </div>

        {error.length !== 0 && (
          <div className="d-flex justify-content-center">
            <span className="PanelModal__Error text-5 text-center mt-3">
              {error}
            </span>
          </div>
        )}

        <div className="d-flex justify-content-center">
          <button
            className={`button-green-panel ${
              error.length !== 0 ? 'button-disabled' : ''
            } my-3 `}
            onClick={handleNextStep}
          >
              Siguiente
          </button>
        </div>

        <Steps totalCount={2} currentCount={1} />
      </div>
    </div>
  )
}

AssignPlanAdminModalStep1.propTypes = {
  closeModal: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  setPlanSelected: PropTypes.func.isRequired,
}

export default AssignPlanAdminModalStep1