import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import ModalHeader from '../ModalHeader'
import FinishModal from './FinishModal'
import Input from '../Input'
import {
  faCakeCandles,
  faEnvelope,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import { AppContext } from '../../context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { postAddEmployee } from '../../api/companies'
import moment from 'moment'


const ViewEmployeeModal = ({
  userInfo,
  company_id,
  updateCompanyInfo,
  closeModal,
}) => {
  const { token } = useContext(AppContext)
  const [step, setStep] = useState(1)
  const userTypeList = ['Usuario', 'Administrador']
  const [saveChanges, setSaveChanges] = useState(false)

  const userType = userInfo.type === 'Company_admin' ? 'Administrador' : 'Usuario'

  const formik = useFormik({
    initialValues: {
      user_type: userType,
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      user_type: Yup.string()
        .required('El tipo de usuario es requerido')
        .max(60, 'Máximo de caracteres: 60'),
    }),
    onSubmit: async ({ user_type }) => {
      if (!saveChanges) return

      const body = {
        company_id,
        user_email: userInfo.user.email,
        is_company_admin: `${user_type === 'Administrador'}`,
      }

      const response = await postAddEmployee(token, body)

      if (response.error) {
        return
      }
      else if (response) {
        updateCompanyInfo()
        setStep(step + 1)
      }
    },
  })

  useEffect(() => {
    if (!userInfo.user.email) return

    if (userType !== formik.values.user_type) setSaveChanges(true)
    else setSaveChanges(false)
  }, [formik.values.user_type])

  if (step === 1)
    return (
      <div className="PanelModal px-3" onClick={closeModal}>
        <form
          className="PanelModal__Card--Medium"
          onClick={(e) => e.stopPropagation()}
        >
          <ModalHeader title={'Editar información'} closeModal={closeModal} />

          <div className="d-flex flex-column gap-3 mt-3">
            <div className="PanelModal__Inputs-Container">
              <div className="w-100 d-flex flex-column align-items-start position-relative">
                <label htmlFor='password' className="text-3 fw-normal">Nombre</label>
                <div className="w-100 position-relative">
                  <input
                    className={'Input--Disabled text-3'}
                    type='text'
                    value={userInfo.user.first_name || 'Sin Nombre'}
                    disabled
                  />
                  <FontAwesomeIcon icon={ faUser } className="Input__Icon-Disabled" style={{ position: 'absolute', top: 'calc(50% - 10px)', left: '10px' }}/>
                </div>
              </div>
              <div className="w-100 d-flex flex-column align-items-start position-relative">
                <label htmlFor='password' className="text-3 fw-normal">Apellido</label>
                <div className="w-100 position-relative">
                  <input
                    className={'Input--Disabled text-3'}
                    type='text'
                    value={userInfo.user.last_name || 'Sin Apellido'}
                    disabled
                  />
                  <FontAwesomeIcon icon={ faUser } className="Input__Icon-Disabled" style={{ position: 'absolute', top: 'calc(50% - 10px)', left: '10px' }}/>
                </div>
              </div>
            </div>
            <div className="w-100 d-flex flex-column align-items-start position-relative">
              <label htmlFor='password' className="text-3 fw-normal">Email</label>
              <div className="w-100 position-relative">
                <input
                  className={'Input--Disabled text-3'}
                  type='text'
                  value={userInfo.user.email || 'Sin Mail, Informar a Vida'}
                  disabled
                />
                <FontAwesomeIcon icon={ faEnvelope } className="Input__Icon-Disabled" style={{ position: 'absolute', top: 'calc(50% - 10px)', left: '10px' }}/>
              </div>
            </div>
            <div className="w-100 d-flex flex-column align-items-start position-relative">
              <label htmlFor='password' className="text-3 fw-normal">Cumpleaños</label>
              <div className="w-100 position-relative">
                <input
                  className={'Input--Disabled text-3'}
                  type='text'
                  value={moment(userInfo.birthdate, 'YYYY-MM-DD').format('DD/MM/YYYY') || '00/00/0000'}
                  disabled
                />
                <FontAwesomeIcon icon={ faCakeCandles } className="Input__Icon-Disabled" style={{ position: 'absolute', top: 'calc(50% - 10px)', left: '10px' }}/>
              </div>
            </div>
            <Input
              label="Tipo de usuario"
              name="user_type"
              type="select"
              value={formik.values.user_type}
              list={userTypeList}
              onChange={formik.setFieldValue}
              error={formik.errors.profession}
              icon={faUser}
            />
          </div>

          <div className="d-flex justify-content-center mt-4">
            <button
              className={`button-green-panel ${
                !saveChanges ? 'button-disabled' : ''
              }`}
              type="submit"
              onClick={formik.handleSubmit}
            >
							Guardar cambios
            </button>
          </div>
        </form>
      </div>
    )

  if (step === 2)
    return (
      <FinishModal
        title="Editar información"
        message="Cambios guardados"
        buttonText="¡Entendido!"
        closeModal={closeModal}
      />
    )
}

ViewEmployeeModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  userInfo: PropTypes.object.isRequired,
  company_id: PropTypes.number.isRequired,
  updateCompanyInfo: PropTypes.func.isRequired,
}

export default ViewEmployeeModal
