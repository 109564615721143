import moment from 'moment'
import { useContext, useState } from 'react'
import propTypes from 'prop-types'
import { CSVLink } from 'react-csv'
import { AppContext } from '../context'
import { getCompaniesData, getOfficesData, getPlansData, getUsersData } from '../api/admin-data'
import ExportIcon from '../assets/icons/export-icon.svg'
import { getLogs } from '../api/admin-settings'
import { getAction } from './TableItem'


const ExportButton = ({
  search,
  filtersSelected,
  totalItems,
  type,
}) => {
  const { token } = useContext(AppContext)
  const [exportState, setExportState] = useState({
    label: 'Exportar',
    value: false,
  })
  const [dataToExport, setDataToExport] = useState([])

  const sections = {
    users: 'Usuarios',
    plans: 'Planes',
    companies: 'Empresas',
    offices: 'Oficinas',
    Historic: 'Historial',
  }

  const handleGetData = async () => {
    switch (type) {
    case 'users':
      return await getUsersData(token, {
        page: 1,
        per_page: totalItems,
        search,
        is_debtor:
            filtersSelected[0].option.value === 'Activo' ||
            filtersSelected[0].option.value === 'Semibloqueado'
              ? filtersSelected[0].option.value === 'Activo'
                ? 'false'
                : 'true'
              : '',
        is_active:
            filtersSelected[0].option.value === 'Bloqueado' ? 'false' : '',
      })
    case 'plans':
      return await getPlansData(token, {
        page: 1,
        per_page: totalItems,
        search,
        plan: filtersSelected[0].option.value,
        is_paid: filtersSelected[1].option.value,
      })
    case 'companies':
      return await getCompaniesData(token, {
        page: 1,
        per_page: totalItems,
        search,
        is_debtor: filtersSelected[0].option.value,
      })
    case 'offices':
      return await getOfficesData(token, {
        page: 1,
        per_page: totalItems,
        search,
        historical: filtersSelected[0].option.value,
        office: filtersSelected[1].option.value,
      })
    case 'Historic':
      return await getLogs(token, {
        page: 1,
        per_page: totalItems,
        search,
        date: filtersSelected[0].value
          ? moment(filtersSelected[0].value, 'DD/MM/YYYY').format('YYYY-MM-DD')
          : '',
        action: filtersSelected[1].option.value,
      })
    default:
      return { data: [] }
    }
  }

  const handleResponseData = (response) => {
    switch (type) {
    case 'users':
      return response.data.map((item) => ({
        'Nombre y Apellido': `${item.first_name} ${item.last_name}`,
        Email: item.email || '-',
        'Fecha de Nacimiento': item.birthdate
          ? moment(item.birthdate, 'YYYY-MM-DD').format('DD/MM/YY')
          : '-',
        'Ultimo Plan': item.last_plan || '-',
        'Fecha de Ultimo Plan': item.plan_buy_time
          ? moment(item.plan_buy_time, 'YYYY-MM-DD').format('DD/MM/YY')
          : '-',
        'Fecha de Registro': item.date_joined
          ? moment(item.date_joined, 'YYYY-MM-DD').format('DD/MM/YY')
          : '-',
        Estado: item.is_active
          ? !item.is_debtor
            ? 'Activo'
            : 'Semibloqueado'
          : 'Bloqueado',
        Deuda:
            typeof item.total_debt === 'number' ? `$${item.total_debt}` : '-',
      }))
    case 'plans':
      return response.data.map((item) => ({
        'Nombre y Apellido': `${item.first_name} ${item.last_name}`,
        Plan: item.last_plan || '-',
        Créditos: item.available_credits || '-',
        Horas: item.available_hours || '-',
        Inicio:
            moment(item.plan_start_date, 'YYYY-MM-DD').format('DD/MM/YY') ||
            '-',
        Fin:
            moment(item.plan_end_date, 'YYYY-MM-DD').format('DD/MM/YY') || '-',
        Pago: item.paid ? 'Si' : 'No',
        Precio: item.payment_amount ? `$${item.payment_amount}` : '-',
      }))
    case 'companies':
      return response.data.map((item) => ({
        Empresa: item.name || '-',
        Miembros: item.total_people || '-',
        'Administrador/es': `(${item.admines.length})  ${item.admines[0].user__first_name} ${item.admines[0].user__last_name}`,
        'Fecha de Registro': item.date_created
          ? moment(item.date_created, 'YYYY-MM-DD').format('DD/MM/YY')
          : '-',
        Teléfono: item.phone || '-',
        Estado: !item.is_debtor ? 'Activo' : 'Semibloqueado',
        Debe:
            typeof item.total_debt === 'number' ? `$${item.total_debt}` : '-',
      }))
    case 'offices':
      return response.data.map((item) => ({
        'Nombre y Apellido': `${item.first_name} ${item.last_name}`,
        Empresa: item.company || '-',
        Oficina: (item.office && `Oficina ${item.office}`) || '-',
        'Tipo de Usuario': item.type || '-',
      }))
    case 'Historic':
      return response.data.map((item) => ({
        Fecha: item.log_date
          ? moment(item.log_date, 'YYYY-MM-DD').format('DD-MM-YY')
          : '-',
        Tipo: item.user_type === 'admin_empresa' ? 'admin de empresa' : item.user_type,
        Usuario: item.user?.first_name ? `${item.user?.first_name} ${item.user?.last_name}` : 'Servidor',
        Acción: getAction(item)
      }))
    default:
      return response.data
    }
  }

  const handleExport = async (event, done) => {
    if (exportState.value) {
      setExportState({ label: 'Exportar', value: false })
      return done(true)
    }

    done(false)
    if (exportState.label === 'Descargando') return

    setExportState({ label: 'Descargando', value: false })

    const response = await handleGetData()

    if (response.error) {
      setExportState({ label: 'Exportar', value: false })
      setDataToExport([])
    } else if (response) {
      setDataToExport(handleResponseData(response))
      setExportState({ label: 'Exportar', value: true })
      setTimeout(() => event.target.click())
    }
  }

  return (
    <CSVLink
      className="TableSearcher__Button-Export text-3 fw-light d-none d-md-flex"
      data={dataToExport}
      filename={`${sections[type]}_${moment().format('YYYY-MM-DD')}.csv`}
      asyncOnClick={true}
      onClick={handleExport}
      target="_blank"
    >
      <img
        className="TableSearcher__Button-Export-Icon"
        src={ExportIcon}
        alt="Export Icon"
      />
      {exportState.label}
    </CSVLink>
  )
}

ExportButton.propTypes = {
  search: propTypes.string,
  filtersSelected: propTypes.array,
  totalItems: propTypes.number,
  type: propTypes.string,
}

export default ExportButton