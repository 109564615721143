import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import propTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../context'


const PanelCompanyCard = ({
  notAssingedPlans,
  showSolicitPlan,
  showAssignPlan,
}) => {
  const { isAdmin } = useContext(AppContext)
  const [showData, setShowData] = useState(true)
  const [sortedPlans, setSortedPlans] = useState([])

  const toggleShowData = () => {
    setShowData((prev) => !prev)
  }

  useEffect(() => {
    let list = []
    notAssingedPlans.forEach((plan) => {
      const planIndex = list.findIndex(
        (item) =>
          item.plan__id === plan.plan__id && item.end_date === plan.end_date
      )
      if (planIndex >= 0) {
        list = [
          ...list.slice(0, planIndex),
          {
            ...list[planIndex],
            quantity: list[planIndex].quantity + 1,
          },
          ...list.slice(planIndex + 1),
        ]
      } else {
        list = [...list, { ...plan, quantity: 1 }]
      }
    })
    const sortedList = list.sort(
      (a, b) =>
        moment(a.end_date, 'YYYY-MM-DD').toDate().getTime() -
				moment(b.end_date, 'YYYY-MM-DD').toDate().getTime()
    )
    setSortedPlans(sortedList)
  }, [notAssingedPlans])

  const hasPlansToAssing = notAssingedPlans.length !== 0

  return (
    <div className="PanelCompanyCard">
      <div className="PanelCompanyCard__Title d-flex d-xxl-none">
        <span className="text-1 fw-bold">
          {hasPlansToAssing ? notAssingedPlans.length : ''} Planes para asignar
        </span>
      </div>
      <span className="text-1 fw-bold d-none d-xxl-block">
        {hasPlansToAssing ? notAssingedPlans.length : ''} Planes para asignar
      </span>
      {hasPlansToAssing ? (
        <div className="PanelCompanyCard__Table d-none d-xxl-flex">
          {sortedPlans.map((plan) => {
            return (
              <div key={plan.id} className="PanelCompanyCard__TableItem">
                <span className="text-1 fw-bold">{plan.quantity}</span>
                <span className="text-2 fw-bold">{plan.plan__name}</span>
                <span className="text-4 text-uppercase">
                  {plan.plan__plan_type.split(' ')[0]}
                </span>
                <span className="text-4">
									VTO: {moment(plan.end_date, 'YYYY-MM-DD').format('DD-MM')}
                </span>
              </div>
            )
          })}
        </div>
      ) : (
        <div className="text-4 d-none d-xxl-flex">No hay planes disponibles para asignar</div>
      )}

      {!isAdmin && (
        <div className="PanelCompanyCard__Buttons d-none d-xxl-flex">
          <button
            className="button-green-panel-rounded-border text-4"
            onClick={showSolicitPlan}
          >
					Comprar planes
          </button>
          {hasPlansToAssing && (
            <button
              className={'button-white-green-panel-rounded text-4'}
              onClick={showAssignPlan}
            >
						Asignar planes
            </button>
          )}
        </div>
      )}

      {showData && (
        <>
          {hasPlansToAssing ? (
            <div className="PanelCompanyCard__Table d-flex d-xxl-none">
              {sortedPlans.map((plan) => {
                return (
                  <div key={plan.id} className="PanelCompanyCard__TableItem">
                    <span className="text-2 fw-bold">
                      {plan.quantity} {plan.plan__name}
                    </span>
                    <span className="text-4 text-uppercase">
                      {plan.plan__plan_type.split(' ')[0]}
                    </span>
                    <span className="text-4">
										VTO: {moment(plan.end_date, 'YYYY-MM-DD').format('DD-MM')}
                    </span>
                  </div>
                )
              })}
            </div>
          ) : (
            <div className="text-4 d-flex d-xxl-none">No hay planes disponibles para asignar</div>
          )}
          {!isAdmin && (
            <div className="PanelCompanyCard__Buttons d-flex d-xxl-none">
              <button
                className="button-green-panel-rounded-border text-4"
                onClick={showSolicitPlan}
              >
                Comprar planes
              </button>
              {hasPlansToAssing && (
                <button
                  className={'button-white-green-panel-rounded text-4'}
                  onClick={showAssignPlan}
                >
                  Asignar planes
                </button>
              )}
            </div>
          )}
        </>
      )}

      <button
        className="PanelCompanyCard__Button d-flex d-xxl-none"
        onClick={toggleShowData}
      >
        <FontAwesomeIcon icon={showData ? faChevronUp : faChevronDown} />
      </button>
    </div>
  )
}

PanelCompanyCard.propTypes = {
  notAssingedPlans: propTypes.array.isRequired,
  showSolicitPlan: propTypes.func.isRequired,
  showAssignPlan: propTypes.func.isRequired,
}

export default PanelCompanyCard
