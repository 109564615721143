import propTypes from 'prop-types'
import { faSearch, faSliders, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FiltersCard from '../components/FiltersCard'
import { useEffect, useState } from 'react'
import DateSelectorModal from './modals/DateSelectorModal'
import ExportButton from './ExportButton'


const TableSearcher = ({
  search,
  handleSearch,
  filtersSelected,
  setFiltersSelected,
  filtersForCard,
  exportButton,
  totalItems,
  type,
  setSearchParams,
  searchSpecial,
  setSearchSpecial,
}) => {
  const [showCalendar, setShowCalendar] = useState(false)

  const toggleShowCalendar = () => {
    setShowCalendar(!showCalendar)
  }

  const handleDateSelected = (date) => {
    const findIndexFilterSelected = filtersSelected.findIndex(
      (filterSelected) => filterSelected.label === 'Por fecha'
    )
    setFiltersSelected((prev) => [
      ...prev.slice(0, findIndexFilterSelected),
      {
        ...prev[findIndexFilterSelected],
        value: date,
      },
      ...prev.slice(findIndexFilterSelected + 1),
    ])
  }

  return (
    <>
      {(filtersForCard?.length > 0 || searchSpecial !== undefined) && (
        <div className="TableSearcher__Results-Container">
          {searchSpecial !== undefined && (
            <div className='d-flex d-sm-none'>
              <SearchSpecial
                setSearchParams={setSearchParams}
                searchSpecial={searchSpecial}
                setSearchSpecial={setSearchSpecial}
              />
            </div>
          )}
          {filtersForCard?.length > 0 && (
            <Filters
              className="position-relative text-4 d-flex d-sm-none ms-auto"
              filtersSelected={filtersSelected}
              setFiltersSelected={setFiltersSelected}
              filtersForCard={filtersForCard}
              toggleShowCalendar={toggleShowCalendar}
            />
          )}
        </div>
      )}
      <div className="TableSearcher">
        <div className="TableSearcher__Input-Container text-3">
          <FontAwesomeIcon icon={faSearch} />
          <input
            className="TableSearcher__Input ms-3 text-5"
            name="search"
            type="text"
            value={search}
            onChange={handleSearch}
          />
        </div>

        {searchSpecial !== undefined && (
          <div className='d-none d-sm-flex'>
            <SearchSpecial
              setSearchParams={setSearchParams}
              searchSpecial={searchSpecial}
              setSearchSpecial={setSearchSpecial}
            />
          </div>
        )}

        {exportButton && (
          <ExportButton
            search={search}
            filtersSelected={filtersSelected}
            totalItems={totalItems}
            type={type}
          />
        )}

        {filtersForCard?.length > 0 && (
          <Filters
            className="position-relative text-4 d-none d-sm-flex ms-auto"
            filtersSelected={filtersSelected}
            setFiltersSelected={setFiltersSelected}
            filtersForCard={filtersForCard}
            toggleShowCalendar={toggleShowCalendar}
          />
        )}
      </div>
      {showCalendar && (
        <DateSelectorModal
          dateSelected={
            filtersSelected.find(
              (filterSelected) => filterSelected.label === 'Por fecha'
            ).value
          }
          handleDateSelected={handleDateSelected}
          closeModal={toggleShowCalendar}
        />
      )}
    </>
  )
}

TableSearcher.propTypes = {
  search: propTypes.string,
  handleSearch: propTypes.func,
  filtersSelected: propTypes.array,
  setFiltersSelected: propTypes.func,
  filtersForCard: propTypes.array,
  data: propTypes.array,
  exportButton: propTypes.bool,
  totalItems: propTypes.number,
  type: propTypes.string,
  setSearchParams: propTypes.func,
  searchSpecial: propTypes.object,
  setSearchSpecial: propTypes.func,
}

const SearchSpecial = ({
  setSearchParams,
  searchSpecial,
  setSearchSpecial
}) => {
  const [firstTime, setFirstTime] = useState(true)

  useEffect(() => {
    if(firstTime) return setFirstTime(false)

    const newParams = {}
    if(searchSpecial.user.id !== '') {
      newParams.user_id = searchSpecial.user.id
      newParams.user_label = searchSpecial.user.label
    }
    if(searchSpecial.company.id !== '') {
      newParams.company_id = searchSpecial.company.id
      newParams.company_label = searchSpecial.company.label
    }
    setSearchParams(newParams)
  }, [searchSpecial])

  const handleRemobesearchSpecial = (type) => {
    setSearchSpecial(prev => ({
      ...prev,
      [type]: {
        id: '',
        label: '',
      },
    }))
  }

  return (
    <>
      {searchSpecial.user.id !== '' && (
        <div className='TableSearcher__Id'>
          <span className='TableSearcher__Id-Text text-nowrap'>{searchSpecial.user.label}</span>
          <FontAwesomeIcon
            className='TableSearcher__Id-Icon'
            icon={faXmark}
            onClick={() => handleRemobesearchSpecial('user')}
          />
        </div>
      )}
      {searchSpecial.company.id !== '' && (
        <div className='TableSearcher__Id'>
          <span className='TableSearcher__Id-Text text-nowrap'>{searchSpecial.company.label}</span>
          <FontAwesomeIcon
            className='TableSearcher__Id-Icon'
            icon={faXmark}
            onClick={() => handleRemobesearchSpecial('company')}
          />
        </div>
      )}
    </>
  )
}

SearchSpecial.propTypes = {
  setSearchParams: propTypes.func,
  searchSpecial: propTypes.object,
  setSearchSpecial: propTypes.func,
}

const Filters = ({
  className,
  filtersSelected,
  setFiltersSelected,
  filtersForCard,
  toggleShowCalendar,
}) => {
  const [showFilters, setShowFilters] = useState(false)
  const [filtersLength, setFiltersLength] = useState(0)

  useEffect(() => {
    setFiltersLength(
      filtersSelected.filter(
        (filter) => filter.option?.value !== filter.defaultOption?.value || filter.value
      ).length
    )
  }, [filtersSelected])

  const toggleShowFilters = () => {
    setShowFilters(prev => !prev)
  }

  const closeShowFilters = () => {
    setShowFilters(false)
  }

  return(
    <button className={className} onBlur={closeShowFilters}>
      <div
        className={`TableSearcher__Button-Filters${
          filtersLength !== 0 ? '--Active' : ''
        }`}
        onClick={toggleShowFilters}
      >
        <span>
          {filtersLength === 0
            ? 'Filtros'
            : `Filtros (${filtersLength})`}
        </span>
        <FontAwesomeIcon icon={faSliders} />
      </div>
      <FiltersCard
        showFilters={showFilters}
        closeShowFilters={closeShowFilters}
        filtersSelected={filtersSelected}
        setFiltersSelected={setFiltersSelected}
        filtersForCard={filtersForCard}
        toggleShowCalendar={toggleShowCalendar}
        filtersLength={filtersLength}
      />
    </button>
  )
}

Filters.propTypes = {
  className: propTypes.string,
  filtersSelected: propTypes.array,
  setFiltersSelected: propTypes.func,
  filtersForCard: propTypes.array,
  toggleShowCalendar: propTypes.func,
}

export default TableSearcher
