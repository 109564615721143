import { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Navigation, Thumbs } from 'swiper'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import Media from './Media'


const Galery = ({ images, toogleShowGalery }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null)

  if (images.length === 1) return (
    <div className='Galery'>
      <Media type='image' className='Galery__Main-Image' src={images[0].image} alt='' />

      <button className='Galery__Button'>
        <FontAwesomeIcon icon={faXmark} onClick={toogleShowGalery}/>
      </button>
    </div>
  )

  return (
    <div className='Galery'>
      <Swiper
        className="Galery__Main-Swiper"
        modules={[ FreeMode, Thumbs ]}
        spaceBetween={100}
        thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
        loop={true}
      >
        {images.map((image, index) => (
          <SwiperSlide className='Galery__Main-Item' key={index} >
            <Media type='image' src={image.image} alt='' />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="Galery__Thumbs-Container">
        <Swiper
          modules={[ Navigation, Thumbs ]}
          className="Galery__Secondary-Swiper"
          onSwiper={setThumbsSwiper}
          slidesPerView={3}
          spaceBetween={10}
          breakpoints={{
            992: {
              slidesPerView: 3,
              spaceBetween: 10
            }
          }}
          navigation={true}
          watchSlidesProgress={true}
          loop={true}
        >
          {images.map((image, index) => (
            <SwiperSlide className='Galery__Main-Item' key={index} >
              <Media type='image' src={image.image} alt='' />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <button className='Galery__Button'>
        <FontAwesomeIcon icon={faXmark} onClick={toogleShowGalery}/>
      </button>
    </div>
  )
}

Galery.propTypes = {
  images: PropTypes.array.isRequired,
  toogleShowGalery: PropTypes.func.isRequired
}

export default Galery