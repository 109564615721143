import { getServices } from '../api/admin-settings'
import ServiceItem from '../components/ServiceItem'
import PropTypes from 'prop-types'
import useApi from '../hooks/useApi'


const Services = ({ category = '' }) => {
  const [services] = useApi(() => getServices({ category }), [])

  return (
    <div className='Services pt-4 pt-lg-5 pb-5 text-center'>
      <div className="Services__Content container-custom">
        <div className="Services__Container">
          {services.map(service => (
            <ServiceItem key={service.id} {...service} />
          ))}
        </div>
      </div>
    </div>
  )
}

Services.propTypes = {
  category: PropTypes.string,
}

export default Services
