import { useState } from 'react'
import PanelSwitch from '../../../components/PanelSwitch'
import SpacesGeneralAdmin from '../../../containers/SpacesGeneralAdmin'
import RoomsGeneralAdmin from '../../../containers/RoomsGeneralAdmin'
import EmailModal from '../../../components/modals/EmailModal'
import { Helmet } from 'react-helmet'

const GeneralAdminPanel = () => {
  const [tab, setTab] = useState('spaces')
  const [showEmailModal, setShowEmailModal] = useState(false)

  const changeTab = (tab) => {
    setTab(tab)
  }

  const toggleShowEmailModal = () => {
    setShowEmailModal(!showEmailModal)
  }

  return (
    <>
      <Helmet>
        <title>Vida Cowork: General</title>
      </Helmet>
      <main className="panel-main panel-main--table pt-3 pt-md-4">
        <PanelSwitch tab={tab} changeTab={changeTab} />

        {tab === 'spaces'
          ? <SpacesGeneralAdmin
            showEmailModal={toggleShowEmailModal}
          />
          : <RoomsGeneralAdmin
            showEmailModal={toggleShowEmailModal}
          />
        }

        {showEmailModal && (
          <EmailModal closeModal={toggleShowEmailModal} />
        )}
      </main>
    </>
  )
}

export default GeneralAdminPanel