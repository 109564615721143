import { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation, useParams } from 'react-router-dom'
import { AppContext } from '../../../context'
import PanelNotFound from '../../../router/PanelNotFound'
import CompanyAdminPanel from '../../../containers/CompanyAdminPanel'
import CompanyUserPanel from '../../../containers/CompanyUserPanel'


const CompanyPanel = () => {
  const { user, isAdmin } = useContext(AppContext)
  const { name } = useParams()
  const { state } = useLocation()

  const renderSection = () => {
    if(isAdmin) return <CompanyAdminPanel company_id={state?.companyId} companyData={state?.companyData} />

    const adminIn = user?.admin_in.find(company => company.name.toLowerCase() === name)
    if(adminIn) return <CompanyAdminPanel company_id={adminIn.id} />

    const employeeIn = user?.employee_in.find(company => company.name.toLowerCase() === name)
    if(employeeIn) return <CompanyUserPanel company_id={employeeIn.id} />

    return <PanelNotFound />
  }


  return (
    <>
      <Helmet>
        <title>Vida Cowork: {name}</title>
      </Helmet>
      {renderSection()}
    </>
  )

}


export default CompanyPanel