import { useContext, useEffect, useState } from 'react'
import DataAdminHeader from '../../../containers/DataAdminHeader'
import { Helmet } from 'react-helmet'
import TableSearcher from '../../../components/TableSearcher'
import Table from '../../../containers/Table'
import { AppContext } from '../../../context'
import { useSearchParams } from 'react-router-dom'
import useApi from '../../../hooks/useApi'
import { getUsersData } from '../../../api/admin-data'
import HoursAndCreditsModal from '../../../components/modals/HoursAndCreditsModal'


const PER_SIZE = 25
const TABLE_HEADERS = [
  {
    id: 0,
    label: 'Nombre y apellido',
    value: 'first_name'
  },
  'Empresas',
  {
    id: 2,
    label: 'Email',
    value: 'email'
  },
  {
    id: 3,
    label: 'Fecha de nacimiento',
    value: 'user_profile__birthdate'
  },
  'Último plan personal',
  {
    id: 5,
    label: 'Fecha de último plan',
    value: 'plan_buy_time'
  },
  {
    id: 6,
    label: 'Fecha de registro',
    value: 'date_joined'
  },
  'Estado',
  {
    id: 8,
    label: 'Deuda',
    value: 'total_debt'
  },
]
const TABLE_HEADERS_MOBILE = [
  {
    id: 0,
    label: 'Nombre y apellido',
    value: 'first_name'
  },
  'Estado',
  {
    id: 2,
    label: 'Deuda',
    value: 'total_debt'
  },
]
const FILTERS_FOR_CARD = [
  {
    label: 'Por estado',
    options: [
      {
        label: 'Activo',
        value: 'Activo',
      },
      {
        label: 'Semibloqueado',
        value: 'Semibloqueado',
      },
      {
        label: 'Bloqueado',
        value: 'Bloqueado',
      },
    ],
  }
]



const UsersDataAdminPanel = () => {
  const { token } = useContext(AppContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const [page, setPage] = useState(1)
  const [searchSpecial, setSearchSpecial] = useState({
    user: {
      id: searchParams.get('user_id') ?? '',
      label: searchParams.get('user_label') ?? '',
    },
    company: {
      id: searchParams.get('company_id') ?? '',
      label: searchParams.get('company_label') ?? '',
    },
  })
  const [search, setSearch] = useState(searchParams.get('search') ?? '')
  const [filtersSelected, setFiltersSelected] = useState([
    {
      label: 'Por estado',
      defaultOption: {
        label: 'Todos los estados',
        value: '',
      },
      option: {
        label: 'Todos los estados',
        value: '',
      },
    },
  ])
  const [order_by, setOrderBy] = useState('')
  const [userData, setUserData] = useState({})
  const [showInfoModal, setShowInfoModal] = useState(false)


  const body = {
    page,
    per_page: PER_SIZE,
    search,
    is_debtor:
      filtersSelected[0].option.value === 'Activo' ||
      filtersSelected[0].option.value === 'Semibloqueado'
        ? filtersSelected[0].option.value === 'Activo'
          ? 'false'
          : 'true'
        : '',
    is_active: filtersSelected[0].option.value === 'Bloqueado' ? 'false' : '',
    order_by,
    user_id: searchSpecial.user.id,
    company_id: searchSpecial.company.id,
  }
  const [usersData, updateUsersData] = useApi(
    () => getUsersData(token, body),
    {
      page: 1,
      total_pages: 1,
      total_items: 0,
      data: [],
    },
    [token, page, search, filtersSelected, order_by, searchSpecial]
  )


  useEffect(() => {
    setPage(1)
  }, [search])


  const handleSearch = (e) => {
    setSearch(e.target.value)
  }
  const openShowInfoModal = (data) => {
    setUserData(data)
    setShowInfoModal(true)
  }
  const closeShowInfoModal = () => {
    setUserData({})
    setShowInfoModal(false)
  }



  return (
    <>
      <Helmet>
        <title>Vida Cowork: Usuarios</title>
      </Helmet>
      <main className="DataAdminPanel--usuarios panel-main panel-main--table">
        <DataAdminHeader currentSection="usuarios">
          <TableSearcher
            search={search}
            handleSearch={handleSearch}
            filtersSelected={filtersSelected}
            setFiltersSelected={setFiltersSelected}
            filtersForCard={FILTERS_FOR_CARD}
            totalItems={usersData.total_items}
            type={'users'}
            exportButton
            setSearchParams={setSearchParams}
            searchSpecial={searchSpecial}
            setSearchSpecial={setSearchSpecial}
          />
        </DataAdminHeader>

        <Table
          type="Users"
          headers={TABLE_HEADERS}
          headersMobile={TABLE_HEADERS_MOBILE}
          data={usersData.data}
          updateData={updateUsersData}
          page={usersData.page}
          setPage={setPage}
          perPage={PER_SIZE}
          totalPages={usersData.total_pages}
          totalItems={usersData.total_items}
          setOrderBy={setOrderBy}
          showInfoMobile
          openInfoModal={openShowInfoModal}
        />

        {showInfoModal && (
          <HoursAndCreditsModal
            data={userData}
            closeModal={closeShowInfoModal}
          />
        )}
      </main>
    </>
  )
}

export default UsersDataAdminPanel
