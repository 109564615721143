const isAdmin = true
// eslint-disable-next-line no-undef
const URL = process.env.REACT_APP_URL_BACK
const URLApi = `${URL}api`

export {
  isAdmin,
  URL,
  URLApi
}
