import PropTypes from 'prop-types'
import { useContext, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import ModalHeader from '../ModalHeader'
import FinishModal from './FinishModal'
import Input from '../Input'
import { faEnvelope, faUser } from '@fortawesome/free-solid-svg-icons'
import { AppContext } from '../../context'
import { postAddEmployee } from '../../api/companies'


const AddEmployeeModal = ({ closeModal, company_id, updateCompanyInfo }) => {
  const { token } = useContext(AppContext)
  const [step, setStep] = useState(1)
  const userTypeList = ['Usuario', 'Administrador']

  const formik = useFormik({
    initialValues: {
      email: '',
      user_type: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      email: Yup.string()
        .required('El email es requerido')
        .email('Ingrese un email valido')
        .max(60, 'Máximo de caracteres: 60'),
      user_type: Yup.string()
        .required('El tipo de usuario es requerido')
        .max(60, 'Máximo de caracteres: 60'),
    }),
    onSubmit: async (data) => {
      const body = {
        company_id,
        user_email: data.email,
        is_company_admin: 'false',
      }

      if (data.user_type === 'Usuario') {
        const response = await postAddEmployee(token, body)

        if (response) {
          updateCompanyInfo()
          setStep(step + 1)
        }
      } else {
        body.is_company_admin = 'true'

        const response = await postAddEmployee(token, body)

        if (response) {
          updateCompanyInfo()
          setStep(step + 1)
        }
      }
    },
  })

  if (step === 1)
    return (
      <div className="PanelModal px-3" onClick={closeModal}>
        <div
          className="PanelModal__Card--Medium"
          onClick={(e) => e.stopPropagation()}
        >
          <ModalHeader title={'Agregar integrante'} closeModal={closeModal} />

          <div className="d-flex flex-column gap-3 mt-3 mb-5">
            <Input
              label="Email"
              name="email"
              type="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.errors.email}
              icon={faEnvelope}
            />
            <Input
              label="Tipo de usuario"
              name="user_type"
              type="select"
              value={formik.values.user_type}
              list={userTypeList}
              onChange={formik.setFieldValue}
              error={formik.errors.profession}
              icon={faUser}
            />
          </div>

          <div className="d-flex justify-content-center mt-4">
            <button
              className="button-green-panel"
              type="submit"
              onClick={formik.handleSubmit}
            >
							Agregar integrante
            </button>
          </div>
        </div>
      </div>
    )

  if (step === 2)
    return (
      <FinishModal
        title="Agregar integrante"
        message="Agregado correctamente"
        buttonText="¡Entendido!"
        closeModal={closeModal}
      />
    )
}

AddEmployeeModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  company_id: PropTypes.number.isRequired,
  updateCompanyInfo: PropTypes.func.isRequired,
}

export default AddEmployeeModal
