
import { useState, useContext } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { faLock, faEnvelope, faExclamation } from '@fortawesome/free-solid-svg-icons'
import { AppContext } from '../../context'
import { login, resendEmailConfirmation } from '../../api/auth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import InputAnimated from '../../components/InputAnimated.jsx'

const Login = () => {
  const { setToken } = useContext(AppContext)
  const [ showModal, setShowModal ] = useState(false)
  const [ generalError, setGeneralError ] = useState(null)
  const [serverError, setServerError] = useState(false)
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      // username: '',
      email: '',
      password: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      // username: Yup.string().trim().required('El nombre de usuario es requerido'),
      email: Yup.string().email('Ingrese un email valido').required('El email es requerido').max(60, 'Máximo de caracteres: 60'),
      password: Yup.string().required('La contraseña es requerida')
    }),
    onSubmit: async ({ email, password }) => {
      if (email.includes(' ')) {
        email = email.replace(/ /g, '')
      }

      try {
        const token = await login(email, password)
        navigate('/panel/', { replace: true })
        setToken(token)
        setGeneralError(null)
      } catch (error) {
        if(error.message === 'Unable to log in with provided credentials.') {
          setGeneralError('Email o contraseña incorrectos')
        } else if(error.message === 'E-mail is not verified.') {
          formik.setFieldError('email', 'Necesitamos verificar tu mail. Chequea tus correos.')
          setShowModal(true)
          setGeneralError(null)
        }else {
          setServerError(true)
        }
      }
    }
  })

  const handleResendEmail = () => {
    setShowModal(false)
    resendEmailConfirmation({email: formik.values.email})
  }

  return (
    <>
      <Helmet>
        <title>Vida Cowork: Iniciar Sesión</title>
      </Helmet>
      <main className='Login landing-content'>
        {showModal && (
          <div className="Modal">
            <div className="Modal__Content">
              <div className="Modal__Icon-Container">
                <FontAwesomeIcon icon={faExclamation}/>
              </div>
              <h2 className="Modal__Title">Confirmá tu mail 🙂</h2>
              <p className="Modal__Text">Chequea tu email para habilitar tu cuenta y comenzar a utilizar la plataforma</p>

              <span className='text-4 fst-italic'>¿No llegó el email? <button className="Modal__Button-Resend fst-italic fw-bold" onClick={handleResendEmail}>Click acá para reenviar.</button></span>

              <button className="button-orange" onClick={() => setShowModal(false)}>Entendido</button>
            </div>
          </div>
        )}

        <div className="Login__Row row mx-0 bg-white">
          <div className="col-12 col-lg-6 py-5 d-flex justify-content-center">
            <div className="Login__Content">
              <h1 className="Login__Title text-1">¡Comenzá tu experiencia!</h1>

              <form className='Login__Form pt-5 pt-md-4 pt-xxl-5' onSubmit={formik.handleSubmit}>
                <InputAnimated
                  label='Email'
                  name='email'
                  type='email'
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.errors.email}
                  icon={faEnvelope}
                />

                <InputAnimated
                  label='Contraseña'
                  name='password'
                  type='password'
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.errors.password}
                  icon={faLock}
                />

                {serverError && <p className='mb-0 text-error'>Algo salio mal 🤷‍♀️</p>}

                <div className="text-4 d-flex justify-content-center align-items-center gap-1">
                  ¿Olvidaste tu contraseña? <Link to="/recuperar-contrasena" className='Login__Restore-Password fw-bold'>Recuperar</Link>
                </div>

                <div className="Login__Button-Container">
                  <button className='Login__Button--Submit' type="submit" >
                    Iniciar Sesión
                    {generalError && (
                      <span className='Input__Error'>{generalError}</span>
                    )}
                  </button>
                  <span>o</span>
                  <Link to="/registro" className='Login__Button--Register'>Registrate</Link>
                </div>
              </form>
            </div>
          </div>
          <div className="Login__Image col-6"></div>
        </div>
      </main>
    </>
  )
}

export default Login