import { getData } from './apiVariables'

export const getTotalDebt = async (token) => {
  return await getData({
    path: '/admin-data/get-total-users-debt/',
    token,
    callback: (data) => data.data,
  })
}

// Statistics
export const getPlansPopularity = async (token, params) => {
  return await getData({
    path: '/admin-data/plans-popularity/',
    token,
    params,
    callback: (data) => data.data,
  })
}

export const getUsersAndPlansAmount = async (token, params) => {
  return await getData({
    path: '/admin-data/amount-users-and-plans/',
    token,
    params,
    callback: (data) => data.data,
  })
}

export const getActiveCreditsAndHours = async (token) => {
  return await getData({
    path: '/admin-data/active-credits-and-hours/',
    token,
    callback: (data) => data.data,
  })
}

export const getCompaniesAmount = async (token) => {
  return await getData({
    path: '/admin-data/amount-of-companies/',
    token,
    callback: (data) => data.data.amount_of_companies,
  })
}

export const getMostSolicitedShiftAndHours = async (token, params) => {
  return await getData({
    path: '/admin-data/shift-and-hours-most-requested/',
    token,
    params,
    callback: (data) => data.data,
  })
}

export const getUserFlowByMonth = async (token, params) => {
  return await getData({
    path: '/admin-data/user-flow-by-month/',
    token,
    params,
    callback: (data) => data.data,
  })
}

export const getAmountUsersAndPlans = async (token, params) => {
  return await getData({
    path: '/admin-data/amount-users-and-plans/',
    token,
    params,
    callback: (data) => data.data
  })
}

export const getAmountCompanies = async (token) => {
  return await getData({
    path: '/admin-data/amount-of-companies/',
    token,
    callback: (data) => data.data
  })
}

export const getCompanyFlowByMonth = async (token, params) => {
  return await getData({
    path: '/admin-data/company-flow-by-month/',
    token,
    params,
    callback: (data) => data.data
  })
}

export const getShiftAndHoursMostRequested = async (token, params) => {
  return await getData({
    path: '/admin-data/shift-and-hours-most-requested/',
    token,
    params,
    callback: (data) => data.data
  })
}

// Export
export const getPlansData = async (token, params) => {
  params = Object.entries(params).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {})
  return await getData({
    path: '/admin-data/plans-data/',
    token,
    params,
  })
}

export const getOfficesData = async (token, params) => {
  params = Object.entries(params).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {})
  return await getData({
    path: '/admin-data/offices-data/',
    token,
    params,
  })
}

export const getCompaniesData = async (token, params) => {
  params = Object.entries(params).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {})
  return await getData({
    path: '/admin-data/companies-data/',
    token,
    params,
  })
}

export const getUsersData = async (token, params) => {
  params = Object.entries(params).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {})
  return await getData({
    path: '/admin-data/users-data/',
    token,
    params,
  })
}
