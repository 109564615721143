import PropTypes from 'prop-types'


const Switch = ({ list, current, changeCurrent, maxWidth = 400 }) => {
  const leftPorcentage =
    (list.findIndex((item) => item === current) / list.length) * 100

  return (
    <div
      className="Switch"
      style={{ maxWidth: `${maxWidth + 6}px` }}
    >
      <div
        className="Switch__Slider"
        style={{
          left: `${leftPorcentage}%`,
          width: `${(1 / list.length) * 100}%`,
        }}
      />
      {list.map((item, index) => (
        <button
          key={index}
          className="Switch__Button text-4"
          onClick={() => changeCurrent(item)}
          disabled={item === current}
        >
          {item}
        </button>
      ))}
    </div>
  )
}

Switch.propTypes = {
  list: PropTypes.array.isRequired,
  current: PropTypes.string.isRequired,
  changeCurrent: PropTypes.func.isRequired,
  maxWidth: PropTypes.number,
}

export default Switch
