import { useState } from 'react'
import propTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortDown } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'


const DataAdminHeader = ({ currentSection, children }) => {
  const [showSections, setShowSections] = useState(false)
  const navigate = useNavigate()

  const sections = [
    {
      label: 'usuarios',
      value: 'usuarios',
    }, {
      label: 'planes',
      value: 'planes',
    }, {
      label: 'horas y créditos',
      value: 'horas-y-creditos',
    }, {
      label: 'empresas',
      value: 'empresas',
    }, {
      label: 'oficinas',
      value: 'oficinas',
    }
  ]

  const handleSectionSelected = (section) => {
    navigate(`/panel/datos/${section}`)
  }

  const toggleShowSections = () => {
    setShowSections(prev => !prev)
  }

  const closeShowSections = () => {
    setShowSections(false)
  }

  return (
    <header className="DataAdminHeader">
      <h1 className="DataAdminHeader__Title text-1">Base de datos</h1>

      <button
        className="DataAdminHeader__Selector"
        onClick={toggleShowSections}
        onBlur={closeShowSections}
      >
        <span className="first-letter-uppercase">{currentSection}</span>
        <FontAwesomeIcon icon={faSortDown} className="MonthSelector__Icon" />

        {showSections && (
          <div className="DataAdminHeader__Selector-Options">
            {sections.map((section, index) => (
              <div
                key={index}
                className={`DataAdminHeader__Selector-Option${
                  section === currentSection ? '--Active' : ''
                } first-letter-uppercase`}
                onClick={() => handleSectionSelected(section.value)}
                disabled={section.value === currentSection}
              >
                { section.label }
              </div>
            ))}
          </div>
        )}
      </button>
      {children}
    </header>
  )
}

DataAdminHeader.propTypes = {
  currentSection: propTypes.string.isRequired,
  children: propTypes.element,
}

export default DataAdminHeader
