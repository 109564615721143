import { memo, useContext } from 'react'
import propTypes from 'prop-types'
import RoomDayReservation from './RoomDayReservation'
import { AppContext } from '../context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons'


const RoomDay = ({
  data,
  dayWeekName,
  dayNumber,
  currentDayRef,
  isCurrentDay = false,
  isPastDay = false,
  isLimitDay = false,
  isDisabled,
  hours,
  disabledHours,
  filter,
  openOverlay,
  openModal,
  closeOverlay,
}) => {
  const { isAdmin, rooms } = useContext(AppContext)
  const fixedHours = hours.slice(0, -1)

  const renderItems = ({ item, isDisabledDay = false }) => {
    if(isDisabledDay) return (
      <div className="RoomDay__Item" key={`Room-Day-Item-${item.name}`}>
        <h4 className="RoomDay__Item-Tag text-4">
          <span className='RoomDay__Item-Tag-Detail' style={{ backgroundColor: item.color }}></span>
          { item.name }
        </h4>
        <div className="RoomDay__Item-Reservations">
          {fixedHours.map((hour) => (
            <div
              className="RoomDay__Item-Cell"
              key={`Room-Day-Cell-${hour}`}
            >
            </div>
          ))}
          {data && renderReservations(item, item.id)}
        </div>
      </div>
    )

    return (
      <div className="RoomDay__Item" key={`Room-Day-Item-${item.name}`}>
        <h4 className="RoomDay__Item-Tag text-4">
          <span className='RoomDay__Item-Tag-Detail' style={{ backgroundColor: item.color }}></span>
          { item.name }
        </h4>
        <div className="RoomDay__Item-Reservations">
          {fixedHours.map((hour, index) => {
            if (isCurrentDay && disabledHours.includes(hour)) return (
              <div
                className="RoomDay__Item-Cell--Disabled"
                key={`Room-Day-Cell-${hour}`}
              >
              </div>
            )
            else return (
              <div
                className="RoomDay__Item-Cell"
                key={`Room-Day-Cell-${hour}`}
                onClick={() => handleOpenModal(item.id, item.name, hour, hours[index + 1])}
              >
              </div>
            )
          })}
          {data && renderReservations(item, item.id)}
        </div>
      </div>
    )
  }

  const renderReservations = (room, id) => {
    const reservations = data.filter(item => item.room === room.name)

    if(reservations.length > 0) return reservations.map((item, index) => (
      <RoomDayReservation
        key={`Room-Day-Reservation-${item.id}-${index}`}
        room={room}
        reservation={item}
        hours={hours}
        isPastDay={isPastDay}
        openOverlay={() => handleOpenOverlay(id)}
      />
    ))
  }

  const handleOpenModal = (room_id, room_name, start_hour, end_hour) => {
    closeOverlay()
    openModal({ room_id, room_name, start_hour, end_hour, data, dayWeekName, dayNumber, isPastDay })
  }

  const handleOpenOverlay = (initialFilter) => {
    openOverlay({ data, dayWeekName, dayNumber, isCurrentDay, isPastDay, initialFilter: typeof initialFilter == 'number' ? initialFilter : '' })
  }


  if(!filter) {
    if(isPastDay) return (
      <div className="RoomDay--PastDay">
        <button className={isCurrentDay ? 'RoomDay__Header--Active' : 'RoomDay__Header'} onClick={handleOpenOverlay}>
          <h3 className='RoomDay__Title text-2'>
            { dayWeekName }<span className="text-1 ms-2">{ dayNumber }</span>
          </h3>
        </button>
        <div className="RoomDay__Content">
          {rooms.map(item => (
            renderItems({ item, isDisabledDay: true })
          ))}
        </div>
      </div>
    )

    if(isDisabled) return (
      <div className="RoomDay--Disabled" ref={currentDayRef}>
        <button className={isCurrentDay ? 'RoomDay__Header--Active' : 'RoomDay__Header'} disabled>
          <h3 className='RoomDay__Title text-2'>
            { dayWeekName }<span className="text-1 ms-2">{ dayNumber }</span>
          </h3>
          <div className="RoomDay__Error text-3">
            <div className="RoomDay__Error-Card">
              <FontAwesomeIcon icon={faTimesCircle} className='me-2'/> Día inhabilitado
            </div>
          </div>
        </button>
        <div className="RoomDay__Content">
          {rooms.map(item => (
            renderItems({ item, isDisabledDay: true })
          ))}
        </div>
      </div>
    )

    if(isLimitDay && !isAdmin) return (
      <div className="RoomDay--LimitDay" ref={currentDayRef}>
        <button className={isCurrentDay ? 'RoomDay__Header--Active' : 'RoomDay__Header'} disabled>
          <h3 className='RoomDay__Title text-2'>
            { dayWeekName }<span className="text-1 ms-2">{ dayNumber }</span>
          </h3>
        </button>
        <div className="RoomDay__Content">
          {rooms.map(item => (
            renderItems({ item, isDisabledDay: true })
          ))}
        </div>
      </div>
    )

    return (
      <div className="RoomDay" ref={currentDayRef}>
        <button className={isCurrentDay ? 'RoomDay__Header--Active' : 'RoomDay__Header'} onClick={handleOpenOverlay}>
          <h3 className='RoomDay__Title text-2'>
            { dayWeekName }<span className="text-1 ms-2">{ dayNumber }</span>
          </h3>
        </button>
        <div className="RoomDay__Content">
          {rooms.map(item => (
            renderItems({ item, isDisabledDay: false })
          ))}
        </div>
      </div>
    )
  }

  if(isPastDay) return (
    <div className="RoomDay--PastDay">
      <button className={isCurrentDay ? 'RoomDay__Header--Active' : 'RoomDay__Header'} onClick={handleOpenOverlay}>
        <h3 className='RoomDay__Title text-2'>
          { dayWeekName }<span className="text-1 ms-2">{ dayNumber }</span>
        </h3>
      </button>
      <div className="RoomDay__Content">
        {rooms.map((item, index) => {
          if(index + 1 === filter) return (
            renderItems({ item, isDisabledDay: true })
          )})}
      </div>
    </div>
  )

  if(isDisabled) return (
    <div className="RoomDay--Disabled" ref={currentDayRef}>
      <button className={isCurrentDay ? 'RoomDay__Header--Active' : 'RoomDay__Header'} disabled>
        <h3 className='RoomDay__Title text-2'>
          { dayWeekName }<span className="text-1 ms-2">{ dayNumber }</span>
        </h3>
        <div className="RoomDay__Error text-3"><FontAwesomeIcon icon={faTimes} className='me-2'/> Día inhabilitado</div>
      </button>
      <div className="RoomDay__Content">
        {rooms.map(item => (
          renderItems({ item, isDisabledDay: true })
        ))}
      </div>
    </div>
  )

  if(isLimitDay && !isAdmin) return (
    <div className="RoomDay--LimitDay"  ref={currentDayRef}>
      <button className={isCurrentDay ? 'RoomDay__Header--Active' : 'RoomDay__Header'} disabled>
        <h3 className='RoomDay__Title text-2'>
          { dayWeekName }<span className="text-1 ms-2">{ dayNumber }</span>
        </h3>
      </button>
      <div className="RoomDay__Content">
        {rooms.map((item, index) => {
          if(index + 1 === filter) return (
            renderItems({ item, isDisabledDay: true })
          )})}
      </div>
    </div>
  )

  return (
    <div className="RoomDay" ref={currentDayRef}>
      <button className={isCurrentDay ? 'RoomDay__Header--Active' : 'RoomDay__Header'} onClick={handleOpenOverlay}>
        <h3 className='RoomDay__Title text-2'>
          { dayWeekName }<span className="text-1 ms-2">{ dayNumber }</span>
        </h3>
      </button>
      <div className="RoomDay__Content">
        {rooms.map((item, index) => {
          if(index + 1 === filter) return (
            renderItems({ item, isDisabledDay: false })
          )})}
      </div>
    </div>
  )
}

RoomDay.propTypes = {
  data: propTypes.array,
  dayWeekName: propTypes.string.isRequired,
  dayNumber: propTypes.number.isRequired,
  currentDayRef: propTypes.object,
  isCurrentDay: propTypes.bool,
  isPastDay: propTypes.bool,
  isLimitDay: propTypes.bool,
  isDisabled: propTypes.bool,
  hours: propTypes.array.isRequired,
  disabledHours: propTypes.array,
  filter: propTypes.number,
  openModal: propTypes.func.isRequired,
  openOverlay: propTypes.func.isRequired,
  closeOverlay: propTypes.func.isRequired
}

export default memo(RoomDay)