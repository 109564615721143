import { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGreaterThan, faLessThan, faPlus } from '@fortawesome/free-solid-svg-icons'
import { dayNames, monthNames } from '../utils/CalendarDataTime'
import SpaceDay from '../components/SpaceDay'
import MonthSelector from '../components/MonthSelector'
import SpacesUserReservationModal from '../components/modals/SpacesUserReservationModal'
import SpacesAdminReservationModal from '../components/modals/SpacesAdminReservationModal'
import Filter from '../components/Filter'
import { AppContext } from '../context'
import Calendar from '../components/Calendar'
import { getAdminSharedAreaBooksCalendar, getCompanySharedAreaBooksCalendar, getSharedAreaBooksCalendar, getUserSharedAreaBooksCalendar } from '../api/shared-areas'
import SpacesReservationOverlay from '../components/SpacesReservationOverlay'
import SpacesReservationCancelModal from '../components/modals/SpacesReservationCancelModal'
import { getHolidays } from '../api/admin-settings'


const SpacesCalendar = () => {
  const { token, isAdmin, user, sharedAreasTime } = useContext(AppContext)

  // Calendar States
  const [days, setDays] = useState([])
  const [month, setMonth] = useState(new Date().getMonth())
  const [year, setYear] = useState(new Date().getFullYear())
  const [reservations, setReservations] = useState([])
  // Server Time State
  const [serverTime, setServerTime] = useState('')
  // Filter State
  const [filter, setFilter] = useState('')
  // Reservation Modal State
  const [reservationModal, setReservationModal] = useState({
    show: false,
    initialData: {}
  })
  const [calendarButton, setCalendarButton] = useState(false)
  // Overlay States
  const [showReservationOverlay, setShowReservationOverlay] = useState(false)
  const [overlayData, setOverlayData] = useState({})
  // Cancel Reservation Modal State
  const [cancelReservationModal, setCancelReservationModal] = useState({
    show: false,
    step: 1,
    reservation: null
  })

  const filters = ['Todas las reservas', 'Jornada completa', 'Turno mañana', 'Turno tarde']

  // Get reservations
  useEffect(() => {
    if(token) {
      getReservations()
    }
  }, [token, month])

  // Refresh reservations every 30 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      getReservations()
    }, 30000)

    return () => clearInterval(interval)
  }, [])

  // Set month days
  useEffect(() => {
    getCalendarData()
  }, [reservations])

  // Open reservation modal
  useEffect(() => {
    if(calendarButton) {
      openReservationModal({})
      setCalendarButton(false)
    }
  }, [days])


  // Get calendar data funtions
  const getReservations = async () => {
    if(isAdmin) {
      const data = await getAdminSharedAreaBooksCalendar(token, {
        month: month + 1,
        year
      })

      setReservations(data?.data)
      setServerTime(data?.server_time)
    } else if(user.admin_in.length > 0) {
      const data = await getCompanySharedAreaBooksCalendar(token, {
        month: month + 1,
        year
      })

      setReservations(data?.data)
      setServerTime(data?.server_time)
    }
    else {
      const data = await getUserSharedAreaBooksCalendar(token, {
        month: month + 1,
        year
      })

      setReservations(data?.data)
      setServerTime(data?.server_time)
    }
  }
  const getCalendarData = async () => {
    // Get days of the month
    function getDaysInMonth(year, month) {
      return new Date(year, month + 1, 0).getDate()
    }
    const daysInMonth = getDaysInMonth(year, month)

    const availabilityOfDays = await getSharedAreaBooksCalendar(token, {
      month: month + 1,
      year
    })

    const holidays = await getHolidays(token, {
      month: month + 1,
      year
    })

    // Set new days
    let newDays = []
    for(let i = 1; i < daysInMonth + 1; i++) {
      const date = new Date(year, month, i)

      // Translate the names of the days
      let dayWeekName = date.toString().slice(0, 3)
      dayWeekName = dayNames[dayWeekName]

      // Disable weekend days
      let isDisabled = false
      const dayWeekNumber = date.getDay()
      if(dayWeekNumber === 0 || dayWeekNumber === 6) {
        isDisabled = true
      }

      // Disable holidays
      const isHoliday = holidays.find(item => item.date == `${year}-${month + 1 < 10 ? `0${month + 1}` : month + 1}-${i < 10 ? '0' + i : i}`)

      // Check if current day
      const dateTime = date.getTime()
      const currentDate = new Date()
      currentDate.setHours(0, 0, 0, 0)
      const currentDateTime = currentDate.getTime()
      const isCurrentDay = dateTime === currentDateTime

      // Check if is a past day
      const isPastDay = dateTime < currentDateTime

      // Check if is a limit day (30 days after the current day)
      const milisecondsInDay = 86400000
      const limitTime = currentDateTime + (30 * milisecondsInDay)
      const isLimitDay = dateTime > limitTime

      // Get reservations data of the day
      const formatDate = `${year}-${month + 1 < 10 ? `0${month + 1}` : month + 1}-${i < 10 ? '0' + i : i}`
      const dayData = reservations?.filter(item => item.date == formatDate)

      // Get availability of the day
      const dayAvailabilityData = availabilityOfDays.find(item => item.date == formatDate)

      // Availability of the current day
      const currentHour = new Date().getHours()
      const currentDayMorningAvailable = isCurrentDay ? currentHour < Number(sharedAreasTime.morningEndTime.split(':')[0]) : true
      const currentDayAfternoonAvailable = isCurrentDay ? currentHour < Number(sharedAreasTime.afternoonEndTime.split(':')[0]) : true

      const isMorningAvailable = currentDayMorningAvailable == false ? currentDayMorningAvailable : dayAvailabilityData?.morning_available == undefined ? true : dayAvailabilityData?.morning_available
      const isAfternoonAvailable = currentDayAfternoonAvailable == false ? currentDayAfternoonAvailable : dayAvailabilityData?.afternoon_available == undefined ? true : dayAvailabilityData?.afternoon_available

      newDays.push({
        data: dayData,
        dayWeekName: dayWeekName,
        dayWeekNumber,
        dayNumber: i,
        isFill: false,
        isDisabled,
        isHoliday: isHoliday ? true : false,
        isCurrentDay,
        isPastDay,
        isLimitDay,
        isAfternoonAvailable,
        isMorningAvailable
      })
    }


    // Fill calendar with empty days
    let firstMonthDay = newDays[0].dayWeekNumber

    if(firstMonthDay === 0) {
      firstMonthDay = 7
    }
    for(let i = 1; i < firstMonthDay; i++) {
      const dayNumber = new Date(year, month, -i + 1).getDate()

      newDays.unshift({
        dayWeekName: '',
        dayWeekNumber: '',
        dayNumber,
        isDisabled: false,
        isFill: true
      })
    }

    setDays(newDays)
  }

  // Calendar actions
  const changeMonth = (direction) => {
    if(direction === 'next') {
      if(month === 11) {
        setMonth(0)
        setYear(year + 1)
      } else {
        setMonth(month + 1)
      }
    } else {
      if(month === 0) {
        setMonth(11)
        setYear(year - 1)
      } else {
        setMonth(month - 1)
      }
    }
  }
  const changeFilter = (filter) => {
    if(filter === 0) {
      setFilter(null)
    } else {
      setFilter(filter)
    }
  }
  const handleCreateReservationButton = () => {
    if(month == new Date().getMonth() && year == new Date().getFullYear()) {
      openReservationModal({})
    } else {
      setMonth(new Date().getMonth())
      setYear(new Date().getFullYear())
      setCalendarButton(true)
    }
  }

  // Overlay toggle
  const openReservationOverlay = ({ data, dayWeekName, dayNumber, isCurrentDay, isPastDay }) => {
    setOverlayData({
      data,
      dayWeekName,
      dayNumber,
      isCurrentDay,
      isPastDay
    })
    setShowReservationOverlay(true)
  }
  const closeReservationOverlay = () => {
    setShowReservationOverlay(false)
  }

  // Reservation modal toggle
  const openReservationModal = ({ data, dayWeekName, dayNumber, isPastDay }) => {
    if(data && dayWeekName && dayNumber) {
      setReservationModal({
        show: true,
        initialData: {
          data,
          dayWeekName,
          dayNumber,
          month,
          year,
          isPastDay
        }
      })
    } else {
      // Open modal with empty data
      const currentDay = days.find(day => day.dayNumber === new Date().getDate() && month === new Date().getMonth() && year === new Date().getFullYear())

      if(currentDay) {
        setReservationModal({
          show: true,
          initialData: {
            data: currentDay.data,
            dayWeekName: currentDay.dayWeekName,
            dayNumber: currentDay.dayNumber,
            month,
            year,
            isPastDay: currentDay.isPastDay
          }
        })
      }
    }
  }
  const closeReservationModal = () => {
    setReservationModal(false)
  }

  // Cancel reservation modal toggle
  const openCancelReservationModal = (reservation, isCurrentDay) => {
    setCancelReservationModal({
      show: true,
      step: 1,
      reservation,
      isCurrentDay
    })
  }
  const closeCancelReservationModal = () => {
    setCancelReservationModal({
      show: false,
      step: 1,
      reservation: null
    })
  }


  return (
    <>
      <section className="SpacesCalendar d-none d-lg-flex">
        <header className="SpacesCalendar__Header">
          <button className="button-green-panel" onClick={handleCreateReservationButton}>
            <FontAwesomeIcon icon={faPlus}/>
            Crear reserva
          </button>

          {isAdmin && (
            <Link className="button-inverted-panel" to='deshabilitar'>
              Deshabilitar día
            </Link>
          )}

          <nav className="RoomsCalendar__Nav">
            <button className="RoomsCalendar__Nav-Button pe-1" onClick={() => changeMonth('prev')} title="Mes pasado">
              <FontAwesomeIcon icon={faLessThan}/>
            </button>

            <MonthSelector data={monthNames} optionSelected={monthNames[month]} onChange={setMonth}/>

            <button className="RoomsCalendar__Nav-Button ps-1" onClick={() => changeMonth('next')} title="Mes siguiente">
              <FontAwesomeIcon icon={faGreaterThan}/>
            </button>
          </nav>

          <h2 className="RoomsCalendar__Header-Year text-2 ms-2">{ year.toString() }</h2>

          <div className="d-none d-lg-flex">
            <Filter data={filters} optionSelected={filters[filter || 0]} defaultOption="Todas las reservas" onChange={changeFilter}/>
          </div>
        </header>

        <div className="SpacesCalendar__Content">
          <div className="SpacesCalendar__Week">
            <div className="SpacesCalendar__Week-Item">
              LUN
            </div>
            <div className="SpacesCalendar__Week-Item">
              MAR
            </div>
            <div className="SpacesCalendar__Week-Item">
              MIE
            </div>
            <div className="SpacesCalendar__Week-Item">
              JUE
            </div>
            <div className="SpacesCalendar__Week-Item">
              VIE
            </div>
            <div className="SpacesCalendar__Week-Item">
              SAB
            </div>
            <div className="SpacesCalendar__Week-Item">
              DOM
            </div>
          </div>

          <div className="SpacesCalendar__Days">
            {days.map((day, index) => {
              if(day.isFill) return (
                <SpaceDay
                  key={`SpacesCalendar__Fill-Day-${month}-${index}`}
                  filter={filter}
                  {...day}
                />
              )
              if(day.disabled && day.isCurrentDay) return (
                <SpaceDay
                  key={`SpacesCalendar__Disabled-Day-${month}-${index}`}
                  filter={filter}
                  {...day}
                />
              )
              if(day.isCurrentDay) return (
                <SpaceDay
                  key={`SpacesCalendar__Current-Day-${month}-${index}`}
                  filter={filter}
                  openModal={openReservationModal}
                  openOverlay={openReservationOverlay}
                  closeOverlay={closeReservationOverlay}
                  {...day}
                />
              )
              return (
                <SpaceDay
                  key={`SpacesCalendar__Day-${month}-${index}`}
                  filter={filter}
                  openModal={openReservationModal}
                  openOverlay={openReservationOverlay}
                  closeOverlay={closeReservationOverlay}
                  {...day}
                />
              )
            })}
          </div>
        </div>
      </section>

      <Calendar
        type="spaces"
        days={days}
        month={month}
        year={year}
        changeMonth={changeMonth}
        setMonth={setMonth}
        openModal={openReservationModal}
        openOverlay={openReservationOverlay}
        closeOverlay={closeReservationOverlay}
      />

      <SpacesReservationOverlay
        showOverlay={showReservationOverlay}
        closeOverlay={closeReservationOverlay}
        openModal={openReservationModal}
        openCancelModal={openCancelReservationModal}
        {...overlayData}
      />

      {cancelReservationModal.show && (
        <SpacesReservationCancelModal
          modalState={cancelReservationModal}
          setModalState={setCancelReservationModal}
          closeModal={closeCancelReservationModal}
          refreshData={getReservations}
        />
      )}

      {reservationModal.show && isAdmin
        ? <SpacesAdminReservationModal
          days={days}
          initialData={reservationModal.initialData}
          refreshData={getReservations}
          changeMonth={changeMonth}
          closeModal={closeReservationModal}
        />
        : reservationModal.show && <SpacesUserReservationModal
          days={days}
          initialData={reservationModal.initialData}
          refreshData={getReservations}
          changeMonth={changeMonth}
          closeModal={closeReservationModal}
        />
      }
    </>
  )
}

export default SpacesCalendar