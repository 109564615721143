import { useEffect, useRef, useState } from 'react'
import propTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGreaterThan, faLessThan, faSortDown, faXmark } from '@fortawesome/free-solid-svg-icons'
import { dayNames, monthNames } from '../../utils/CalendarDataTime'

const DateSelectorModal = ({ dateSelected, handleDateSelected, closeModal }) => {
  // Calendar States
  const [ days, setDays ] = useState([])
  const [ month, setMonth ] = useState(new Date().getMonth())
  const [ year, setYear ] = useState(new Date().getFullYear())
  // Month Selector States
  const [ showOptions, setShowOptions ] = useState(false)
  // Month Selector Refs
  const listMonthsRef = useRef(null)
  const currentMonthRef = useRef(null)

  // Set selected month and year
  useEffect(() => {
    const date = dateSelected.split('/')
    if(date.length > 2) {
      setMonth(date[1] - 1)
      setYear(date[2])
    }
  }, [])

  // Set month days
  useEffect(() => {
    // Get days of the month
    function getDaysInMonth(year, month) {
      return new Date(year, month, 0).getDate()
    }
    const daysInMonth = getDaysInMonth(year, month + 1)

    // Rename days
    let renameDays = []
    for(let i = 1; i < daysInMonth + 1; i++) {
      let disabled = false
      let dayName = new Date(year, month, i).toString().slice(0, 3)
      const dayNumber = new Date(year, month, i).getDay()

      // Translate the names of the days
      dayName = dayNames[dayName]

      // Disable weekend days
      if(dayNumber === 0 || dayNumber === 6) {
        disabled = true
      }

      renameDays.push({
        day: dayName,
        dayNumber,
        number: i,
        disabled,
        isFill: false
      })
    }

    let firstMonthDay = renameDays[0].dayNumber

    // Fill calendar
    if(firstMonthDay === 0) {
      firstMonthDay = 7
    }
    for(let i = 1; i < firstMonthDay; i++) {
      const number = new Date(year, month, -i + 1).getDate()

      renameDays.unshift({
        day: '',
        dayNumber: '',
        number,
        disabled: false,
        isFill: true
      })
    }

    setDays(renameDays)
  }, [month])

  // Autoscroll Month Options
  useEffect(() => {
    setTimeout(() => {
      if(listMonthsRef.current && currentMonthRef.current) {
        const monthHeight = currentMonthRef.current.offsetTop
        listMonthsRef.current.scroll(0, (monthHeight))
      }
    }, 100)
  }, [showOptions])

  const toggleShowOptions = () => {
    setShowOptions(!showOptions)
  }

  const selectMonth = (option) => {
    const index = monthNames.indexOf(option)
    setMonth(index)

    setShowOptions(false)
  }

  const selectDate = (date) => {
    if(date !== dateSelected)
      handleDateSelected(date)
    else
      handleDateSelected('')
    closeModal()
  }

  const changeMonth = (direction) => {
    if(direction === 'next') {
      if(month === 11) {
        setMonth(0)
        setYear(year + 1)
      } else {
        setMonth(month + 1)
      }
    } else {
      if(month === 0) {
        setMonth(11)
        setYear(year - 1)
      } else {
        setMonth(month - 1)
      }
    }
  }

  return (
    <div className="PanelModal" onClick={closeModal}>
      <div className="DateSelector__Calendar" onClick={(e) => e.stopPropagation()}>
        <header className="DateSelector__Calendar-Header">
          <button
            className="DateSelector__Calendar-Month-Selector text-5"
            onClick={toggleShowOptions}
            onBlur={() => setShowOptions(false)}
          >
            {monthNames[month]} <FontAwesomeIcon icon={faSortDown} className="DateSelector__Calendar-Month-Icon"/>
            {showOptions && (
              <div className="DateSelector__Calendar-Month-Items-Container" ref={listMonthsRef}>
                {monthNames.map((item, index) => {
                  if(item === monthNames[month]) return (
                    <div
                      key={`Calendar-Selector-${item}-${index}`}
                      className="DateSelector__Calendar-Month-Item--Active text-6"
                      onClick={() => selectMonth(item)}
                      ref={currentMonthRef}
                      disabled
                    >
                      {item}
                    </div>
                  )
                  else return(
                    <div
                      key={`Calendar-Selector-${item}-${index}`}
                      className="DateSelector__Calendar-Month-Item text-6"
                      onClick={() => selectMonth(item)}
                    >
                      {item}
                    </div>
                  )
                })}
              </div>
            )}
          </button>

          <div className='d-flex'>
            <h2 className="DateSelector__Calendar-Header-Year text-4">{ year.toString() }</h2>

            <nav className="DateSelector__Calendar-Nav ms-2 me-3">
              <button className="DateSelector__Calendar-Nav-Button pe-1" onClick={() => changeMonth('prev')}>
                <FontAwesomeIcon icon={faLessThan}/>
              </button>
              <button className="DateSelector__Calendar-Nav-Button ps-1" onClick={() => changeMonth('next')}>
                <FontAwesomeIcon icon={faGreaterThan}/>
              </button>
            </nav>

            <button className='DateSelector__Calendar-Button-Close' onClick={closeModal}>
              <FontAwesomeIcon icon={faXmark}/>
            </button>
          </div>
        </header>

        <div className="DateSelector__Calendar-Week text-6 fw-semibold">
          <div>
            lun
          </div>
          <div>
            mar
          </div>
          <div>
            mie
          </div>
          <div>
            jue
          </div>
          <div>
            vie
          </div>
          <div>
            sab
          </div>
          <div>
            dom
          </div>
        </div>

        <div className="DateSelector__Calendar-Days-Container">
          {days.map((day, index) => {
            const itemDay = day.number
            const itemMonth = month + 1
            const itemYear = year
            const date = `${itemDay}/${itemMonth}/${itemYear}`

            const isSelectedDate = date === dateSelected

            if(day.isFill) return (
              <button
                className="DateSelector__Calendar-Day--Fill text-6"
                key={`Disabled-Calendar-Fill-Day-${day}-${index}`}
                disabled
              >
                { day.number }
              </button>
            )

            if(day.disabled) return (
              <button
                className="DateSelector__Calendar-Day--Fill text-6"
                key={`Disabled-Calendar-Fill-Day-${day}-${index}`}
                disabled
              >
                { day.number }
              </button>
            )

            if(isSelectedDate) return (
              <button
                className="DateSelector__Calendar-Day--Current text-6"
                key={`Disabled-Calendar-Current-Day-${day}-${index}`}
                onClick={() => selectDate(date)}
              >
                { day.number }
              </button>
            )

            return (
              <button
                className="DateSelector__Calendar-Day text-6"
                key={`Disabled-Calendar-Day-${day}-${index}`}
                onClick={() => selectDate(date)}
              >
                { day.number }
              </button>
            )
          })}
        </div>
      </div>
    </div>
  )
}

DateSelectorModal.propTypes = {
  dateSelected: propTypes.string.isRequired,
  handleDateSelected: propTypes.func.isRequired,
  closeModal: propTypes.func.isRequired
}

export default DateSelectorModal