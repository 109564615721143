import { useContext, useEffect, useRef, useState } from 'react'
import propTypes from 'prop-types'
import gsap from 'gsap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faXmark } from '@fortawesome/free-solid-svg-icons'
import { AppContext } from '../context'
import Filter from '../components/Filter'
import RoomReservationOverlayItem from './RoomReservationOverlayItem'


const RoomsReservationOverlay = ({ data, dayWeekName, dayNumber, isCurrentDay, isPastDay, showOverlay, closeOverlay, openModal, openCancelModal, initialFilter, serverTime }) => {
  const { isAdmin, rooms } = useContext(AppContext)
  const overlayRef = useRef()
  const [sortedData, setSortedData] = useState([])
  const [filter, setFilter] = useState('')

  // Set initial filter
  useEffect(() => {
    if(initialFilter) {
      setFilter(initialFilter)
    }
  }, [initialFilter, showOverlay])

  // Sort & Filter Data
  useEffect(() => {
    if(data && rooms) {
      let sortData

      if(isAdmin) {
        sortData = data.sort((a, b) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime())
      } else {
        const userReservationsData = data.filter(item => item.is_owner || item.company_owner)
        sortData = userReservationsData.sort((a, b) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime())
      }

      if(filter) {
        const filteredData = sortData.filter(item => item.room.toLowerCase() === filters[filter].split(' ')[1])
        setSortedData(filteredData)
        return
      }

      setSortedData(sortData)
    } else {
      setSortedData([])
    }
  }, [data, rooms, filter])

  useEffect(() => {
    if(overlayRef.current) {
      if(showOverlay) {
        gsap.to(overlayRef.current, { translateX: '0%', duration: 0.2 })
        setTimeout(() => {
          overlayRef.current.focus()
        }, 200)
      } else {
        gsap.to(overlayRef.current, { translateX: '100%', duration: 0.2 })
        setFilter(null)
      }
    }
  }, [showOverlay])

  const filters = rooms.map(item => `Sala ${item.name.toLowerCase()}`)
  filters.unshift('Todas las salas')

  const changeFilter = (filter) => {
    if(filter === 0) {
      setFilter(null)
    } else {
      setFilter(filter)
    }
  }

  const handleOpenModal = () => {
    openModal({ data, dayWeekName, dayNumber, isPastDay })
    closeOverlay()
  }


  return (
    <aside className="RoomsReservationOverlay" ref={overlayRef} tabIndex='0'>
      <div className="RoomsReservationOverlay__Content">
        <header className='RoomsReservationOverlay__Header pb-3'>
          <h2 className='text-1'>Reservas del día:</h2>
          <button className="RoomsReservationOverlay__Button-Close" onClick={closeOverlay}>
            <FontAwesomeIcon icon={faXmark}/>
          </button>
        </header>

        <div className="RoomsReservationOverlay__Date">
          <h3 className='text-2 fw-bold'>{dayWeekName}</h3>
          <h3 className='text-2 fw-bold'>{dayNumber}</h3>
        </div>

        <Filter data={filters} optionSelected={filters[filter || 0]} defaultOption="Todas las reservas" onChange={changeFilter}/>

        <div className="RoomsReservationOverlay__Items-Container">
          {sortedData.length > 0
            ? sortedData.map((item, index) => (
              <RoomReservationOverlayItem
                key={`RoomsReservationOverlay__Item-${item.room}-${index}`}
                item={item}
                closeOverlay={closeOverlay}
                openCancelModal={openCancelModal}
                isCurrentDay={isCurrentDay}
                isPastDay={isPastDay}
                serverTime={serverTime}
              />
            ))
            : (
              <div className='RoomsReservationOverlay__Item--No-Reservations'>
                <h4 className='RoomsReservationOverlay__Item-Title fw-bold text-4'>{isPastDay ? 'No tuviste reservas para este día' : 'No tenes reservas para este día'}</h4>
              </div>
            )
          }
        </div>
      </div>

      {!isPastDay && (
        <footer className="d-flex justify-content-center">
          <button className='RoomsReservationOverlay__Button mt-3' onMouseDown={handleOpenModal}>
            <span className="text-4">
              <FontAwesomeIcon icon={faPlus}/>
            </span>
            <span className="ms-2">Crear reserva</span>
          </button>
        </footer>
      )}
    </aside>
  )
}

RoomsReservationOverlay.propTypes = {
  data: propTypes.array,
  dayWeekName: propTypes.string,
  dayNumber: propTypes.number,
  isCurrentDay: propTypes.bool,
  isPastDay: propTypes.bool,
  showOverlay: propTypes.bool.isRequired,
  closeOverlay: propTypes.func.isRequired,
  openModal: propTypes.func.isRequired,
  openCancelModal: propTypes.func.isRequired,
  initialFilter: propTypes.oneOfType([propTypes.number, propTypes.string]),
  serverTime: propTypes.string.isRequired,
}

export default RoomsReservationOverlay