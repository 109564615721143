import { useState } from 'react'
import propTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons'
import DateSelectorModal from './modals/DateSelectorModal'

const DateSelector = ({ type = 'normal', dateSelected, handleDateSelected }) => {
  const [ showCalendar, setShowCalendar ] = useState(false)


  const toggleShowCalendar = () => {
    setShowCalendar(!showCalendar)
  }


  return (
    <>
      <button className='DateSelector text-4' onClick={toggleShowCalendar}>
        {type === 'normal' ? (
          <>
            <FontAwesomeIcon icon={faCalendarCheck} className="DateSelector__Icon"/>
            <span>{ dateSelected || 'Fecha' }</span>
          </>
        ) : (
          <span className="text-6">Elegir fecha</span>
        )}
      </button>

      { showCalendar && (
        <DateSelectorModal
          dateSelected={dateSelected}
          handleDateSelected={handleDateSelected}
          closeModal={toggleShowCalendar}
        />
      )}
    </>
  )
}

DateSelector.propTypes = {
  type: propTypes.string,
  dateSelected: propTypes.string.isRequired,
  handleDateSelected: propTypes.func.isRequired
}

export default DateSelector