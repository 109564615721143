import { useContext, useState } from 'react'
import Table from '../../../containers/Table'
import TableSearcher from '../../../components/TableSearcher'
import { AppContext } from '../../../context'
import useApi from '../../../hooks/useApi'
import { getLogs } from '../../../api/admin-settings'
import { Helmet } from 'react-helmet'
import moment from 'moment'

const HistoricAdminPanel = () => {
  const { token } = useContext(AppContext)
  const [page, setPage] = useState(1)
  const per_page = 25
  const [search, setSearch] = useState('')
  const [filtersSelected, setFiltersSelected] = useState([
    {
      label: 'Por fecha',
      value: '',
    },
    {
      label: 'Por acción',
      defaultOption: {
        label: 'Todas las acciones',
        value: '',
      },
      option: {
        label: 'Todas las acciones',
        value: '',
      },
    }
  ])
  const [order_by, setOrderBy] = useState('')
  const body = {
    page,
    per_page,
    date: filtersSelected[0].value
      ? moment(filtersSelected[0].value, 'DD/MM/YYYY').format('YYYY-MM-DD')
      : '',
    action: filtersSelected[1].option.value,
    search,
    order_by
  }
  const [logs] = useApi(
    () => getLogs(token, body),
    {
      page: 1,
      total_pages: 1,
      total_items: 0,
      data: [],
    },
    [token, page, filtersSelected, search, order_by]
  )


  const handleSearch = (e) => {
    setSearch(e.target.value)
  }


  const tableHeaders = [
    {
      id: 0,
      label: 'Fecha',
      value: 'log_date'
    },
    {
      id: 1,
      label: 'Tipo',
      value: 'user_type'
    },
    {
      id: 2,
      label: 'Usuario',
      value: 'first_name_and_last_name'
    },
    {
      id: 3,
      label: 'Acción',
      value: 'action'
    },
  ]
  const tableHeadersMobile = [
    {
      id: 0,
      label: 'Fecha',
      value: 'log_date'
    },
    {
      id: 1,
      label: 'Usuario',
      value: 'first_name_and_last_name'
    },
    {
      id: 2,
      label: 'Acción',
      value: 'action'
    },
  ]
  const filtersForCard = [
    {
      label: 'Por fecha',
      value: '',
    },
    {
      label: 'Por acción',
      options: [
        {
          label: 'Asignar',
          value: 'asignar',
        },
        {
          label: 'Eliminar',
          value: 'borrar',
        },
        {
          label: 'Cancelar',
          value: 'cancelar',
        },
        {
          label: 'Crear',
          value: 'crear',
        },
        {
          label: 'Editar',
          value: 'editar',
        },
        {
          label: 'Pagar',
          value: 'pagar',
        },
      ],
    }
  ]


  return (
    <>
      <Helmet>
        <title>Vida Cowork: Historial</title>
      </Helmet>
      <main className="HistoricAdminPanel panel-main panel-main--table">
        <header className="HistoricAdminPanel__Header">
          <h1 className="text-1">Historial de acciones</h1>
          <TableSearcher
            search={search}
            handleSearch={handleSearch}
            filtersSelected={filtersSelected}
            setFiltersSelected={setFiltersSelected}
            filtersForCard={filtersForCard}
            totalItems={logs.total_items}
            type="Historic"
            exportButton
          />
        </header>

        <Table
          type="Historic"
          headers={tableHeaders}
          headersMobile={tableHeadersMobile}
          data={logs.data}
          page={logs.page}
          setPage={setPage}
          perPage={per_page}
          totalPages={logs.total_pages}
          totalItems={logs.total_items}
          setOrderBy={setOrderBy}
          showInfoMobile
          unselectable
        />
      </main>
    </>
  )
}

export default HistoricAdminPanel
