import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'
import PropTypes from 'prop-types'
import Media from '../components/Media'


const ImagesSlider = ({ title, images }) => {
  return (
    <div className="container-custom">
      <h2 className="text-1 mb-4 mb-lg-5">{ title }</h2>
      <div className="ImagesSlider">
        <Swiper
          className="ImagesSlider__Swiper"
          modules={[ Navigation, Pagination ]}
          slidesPerView={1}
          spaceBetween={30}
          breakpoints={{
            576: {
              slidesPerView: 1,
              spaceBetween: 30
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 30
            },
            1200: {
              slidesPerView: 3,
              spaceBetween: 30
            },
            1440: {
              slidesPerView: 4,
              spaceBetween: 30
            },
            1600: {
              slidesPerView: 5,
              spaceBetween: 30
            }
          }}
          loop={true}
          navigation
          pagination={{ clickable: true }}
          grabCursor={true}
          speed={500}
        >
          {images.map((image, index) => (
            <SwiperSlide key={`ImagesSlider-${title}-${index}`} className='ImagesSlider__Item'>
              <Media type='image' className='ImagesSlider__Image swiper-lazy' src={image.image} alt='' />
              <div className="swiper-lazy-preloader"></div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

ImagesSlider.propTypes = {
  title: PropTypes.string.isRequired,
  images: PropTypes.array.isRequired
}

export default ImagesSlider