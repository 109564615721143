import { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { faExclamationCircle, faX } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DisabledCalendar from '../../../containers/DisabledCalendar'
import DisabledDay from '../../../components/DisabledDay'
import Input from '../../../components/Input'
import FinishModal from '../../../components/modals/FinishModal'
import { useEffect } from 'react'
import DisabledSelectedDaysModal from '../../../components/modals/DisabledSelectedDaysModal'
import { Helmet } from 'react-helmet'
import useApi from '../../../hooks/useApi'
import { AppContext } from '../../../context'
import { addHolidays, deleteHolidays, getSortedHolidays } from '../../../api/admin-settings'
import moment from 'moment'
import ModalHeader from '../../../components/ModalHeader'

const DisabledDaysAdminPanel = () => {
  const { token } = useContext(AppContext)
  const [isMobile, setIsMobile] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [showInfoModal, setShowInfoModal] = useState(false)
  const [showEnableDayModal, setShowEnableDayModal] = useState(false)
  const [showSelectedDaysModal, setShowSelectedDaysModal] = useState(false)
  const [daysSelected, setDaysSelected] = useState([])
  const [enableDay, setEnableDay] = useState('')
  const [name, setName] = useState('Feriado')
  const [description, setDescription] = useState('')
  const [holidays, updateHolidays] = useApi(
    () => getSortedHolidays(token),
    {
      amount: 0,
      data: [],
    },
    [token]
  )
  const [buttonDisable, setButtonDisable] = useState(false)

  const reasons = ['Feriado', 'Duelo', 'Vacaciones', 'Evento', 'Otro']

  const handleName = (inputName, name) => {
    setName(name)
  }

  const handleDescription = (e) => {
    setDescription(e.target.value)
  }

  useEffect(() => {
    const checkIsMobile = () => {
      if (window.matchMedia('(max-width: 767px)').matches) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    }
    checkIsMobile()

    window.addEventListener('resize', () => {
      checkIsMobile()
    })
  }, [])

  const selectDay = (date) => {
    if (daysSelected.includes(date)) {
      const newDaysSelected = daysSelected.filter(
        (daySelected) => daySelected !== date
      )
      setDaysSelected(newDaysSelected)
    } else {
      setDaysSelected([...daysSelected, date])
    }

    if (isMobile && !showSelectedDaysModal) {
      setShowSelectedDaysModal(true)
    }
  }

  const removeDay = (day) => {
    if (daysSelected.includes(day)) {
      const newDaysSelected = daysSelected.filter(
        (daySelected) => daySelected !== day
      )
      setDaysSelected(newDaysSelected)
    }
  }

  const openEnableDayModal = (date) => {
    setEnableDay(date)
    setShowEnableDayModal(true)
  }

  const closeEnableDayModal = () => {
    setEnableDay('')
    setShowEnableDayModal(false)
  }

  const handleEnableDayModal = async () => { 
    if(buttonDisable) return
    setButtonDisable(true)

    const response = await deleteHolidays(token, { date: enableDay })

    if (response.error) {
      // console.error(response.error)
      return
    }
    else if (response) {
      updateHolidays()
      setEnableDay('')
      setShowEnableDayModal(false)
    }

    setButtonDisable(false)
  }

  const toggleShowConfirmModal = async () => {
    if(buttonDisable) return
    setButtonDisable(true)

    let date_list = ''
    daysSelected.forEach((date) => {
      date_list += date + ','
    })
    date_list = date_list.slice(0, -1)

    const body = {
      name,
      description,
      date_list
    }

    const response = await addHolidays(token, body)

    if (response.error) {
      // console.error(response.error)
      return
    }
    else if (response) {
      updateHolidays()
      setDaysSelected([])
      setShowConfirmModal(prev => !prev)
    }

    setButtonDisable(false)
  }

  return (
    <>
      <Helmet>
        <title>Vida Cowork: Calendario</title>
      </Helmet>
      <main className="DisabledDaysAdminPanel panel-main">
        <section className="DisabledDaysAdminPanel__Content pt-4 pb-0 pb-md-4">
          <div className="d-flex justify-content-start text-4 pb-2 ps-3 ps-md-5 pe-3">
            <Link
              to="/panel/calendario"
              className="DisabledDaysAdminPanel__Link"
            >
              Calendario
            </Link>
            <span className="mx-2">{'>'}</span>
            <span className="fw-bold">Deshabilitar días</span>
          </div>

          <header className="DisabledDaysAdminPanel__Header ps-3 ps-md-5 pe-3">
            <h1 className="text-1">Deshabilitar fechas</h1>
            <button
              className="DisabledDaysAdminPanel__Header-Button text-1"
              onClick={() => setShowInfoModal(true)}
            >
              <FontAwesomeIcon icon={faExclamationCircle} />
            </button>
          </header>

          <div className="pt-3 pt-md-2 ps-3 ps-md-5 pe-3">
            <div className="DisabledDaysAdminPanel__Input-Container d-none d-sm-block">
              <Input
                label="Motivo"
                name="reason"
                type="select"
                list={reasons}
                value={name}
                onChange={handleName}
              />
            </div>

            <div className="my-3 d-none d-sm-block">
              <div className="d-flex align-items-center">
                <h4 className="text-4 fw-bold">Aclaración</h4>
                <span className="text-5 ms-2">
                  Este es el mensaje que enviaremos a los usuarios
                </span>
              </div>
              <textarea
                className="DisabledDaysAdminPanel__Textarea text-5 mt-2"
                rows={4}
                value={description}
                onChange={handleDescription}
              />
            </div>

            <div className="DisabledDaysAdminPanel__Calendar-Container pb-3 pb-md-4">
              <DisabledCalendar
                daysSelected={daysSelected}
                selectDay={selectDay}
              />
              <div className="d-none d-md-block">
                <h3 className="text-3 mb-3">Días seleccionados</h3>
                {daysSelected.length > 0 ? (
                  <div className="DisabledDaysAdminPanel__Selected-Days">
                    {daysSelected.map((date, index) => (
                      <DisabledDay
                        key={`Disabled-Day-${index}, ${date}`}
                        date={date}
                        removeDay={removeDay}
                      />
                    ))}
                  </div>
                ) : (
                  <h4 className="fw-normal text-4">
                    No has seleccionado ningun día
                  </h4>
                )}
              </div>
            </div>

            <footer className="DisabledDaysAdminPanel__Footer pt-3 d-none d-sm-block">
              <button
                className={daysSelected.length > 0 ? 'button-green-panel' : 'button-disabled-panel'}
                onClick={toggleShowConfirmModal}
                disabled={daysSelected.length === 0}
              >
                {buttonDisable ? 'Cargando' : 'Confirmar y deshabilitar días'}
              </button>
            </footer>
          </div>
        </section>

        <aside className="DisabledDaysAdminPanel__Aside p-3">
          <h2 className="DisabledDaysAdminPanel__Aside-Separator text-2 text-center pb-3">
            Fechas deshabilitadas
          </h2>
          <div className="DisabledDaysAdminPanel__Aside-Days py-3">
            <span className="text-2 fw-bold">{holidays.amount}</span>
            <span className="text-5">{`día${
              holidays.amount !== 1 ? 's' : ''
            }`}</span>
          </div>

          {
            holidays.data.map((yearGroup) => (
              <div className="DisabledDaysAdminPanel__Aside-Separator py-3" key={yearGroup.year}>
                <h4 className="text-3 text-center fw-bold mb-3">{yearGroup.year}</h4>
                {
                  yearGroup.months.map(monthGroup => (
                    <div key={monthGroup.month}>
                      <h4 className="text-3 fw-bold mb-3">{moment(monthGroup.month, 'MM').format('MMMM')}</h4>
                      {monthGroup.holidays.map((holiday) => (
                        <div
                          key={holiday.key}
                          className="d-flex justify-content-between align-items-center px-4 mb-4"
                        >
                          <div>
                            <h5 className="text-4 fw-semibold">
                              {`${holiday.date.split('-')[2]} de `}
                              <span className="text-capitalize">
                                {moment(monthGroup.month, 'MM').format('MMMM')}
                              </span>
                            </h5>
                            <h6 className="text-5 mt-2">{holiday.name}</h6>
                          </div>
                          <button
                            className="DisabledDay__Button text-5"
                            onClick={() => openEnableDayModal(holiday.date)}
                          >
                            <FontAwesomeIcon icon={faX} />
                          </button>
                        </div>
                      ))}
                    </div>
                  ))
                }
              </div>
            ))
          }
        </aside>
      </main>

      {showInfoModal && (
        <div className="PanelModal px-3" onClick={() => setShowInfoModal(false)}>
          <div className="PanelModal__Card--Medium" onClick={(e) => e.stopPropagation()}>
            <ModalHeader title={'Deshabilitar fechas'} closeModal={() => setShowInfoModal(false)} />

            <div className="PanelModal__Confirm text-start text-4">
              Utilizá esta herramienta{' '}
              <span className="fw-bold">
                para cerrar el espacio de Vida Cowork
              </span>{' '}
              en una fecha en específico, tanto{' '}
              <span className="fw-bold">feriados</span> como por{' '}
              <span className="fw-bold">motivos de fuerza mayor</span>.
            </div>

            <div className="d-flex justify-content-center pt-3">
              <button
                className="button-green-panel"
                onClick={() => setShowInfoModal(false)}
              >
                ¡Entendido!
              </button>
            </div>
          </div>
        </div>
      )}

      {showEnableDayModal && (
        <div className="PanelModal px-3" onClick={closeEnableDayModal}>
          <div className="PanelModal__Card--Medium" onClick={(e) => e.stopPropagation()}>
            <ModalHeader title={'Habilitar fechas'} closeModal={closeEnableDayModal} />

            <h3 className="PanelModal__Confirm px-3 text-center text-3 fw-semibold">
              ¿Deseás habilitar la siguiente fecha nuevamente?
            </h3>

            <div className="PanelModal__Confirm d-flex justify-content-center align-items-center">
              <h3 className="text-2 fw-semibold">
                {`${enableDay.split('-')[2]} de `}
                <span className="text-capitalize">
                  {moment(enableDay, 'YYYY-MM-DD').format('MMMM')}
                </span>
              </h3>
            </div>

            <div className="d-flex justify-content-center pt-3">
              <button
                className="button-green-panel"
                onClick={handleEnableDayModal}
              >
                Habilitar nuevamente
              </button>
            </div>
          </div>
        </div>
      )}

      {showConfirmModal && (
        <FinishModal
          title="Deshabilitar fechas"
          message="¡Fechas deshabilitadas con éxito!"
          closeModal={() => setShowConfirmModal(false)}
        />
      )}

      {isMobile && showSelectedDaysModal && (
        <DisabledSelectedDaysModal
          closeModal={() => setShowSelectedDaysModal(false)}
          daysSelected={daysSelected}
          removeDay={removeDay}
          toggleShowConfirmModal={toggleShowConfirmModal}
          buttonDisable={buttonDisable}
        />
      )}
    </>
  )
}

export default DisabledDaysAdminPanel
