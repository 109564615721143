import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import propTypes from 'prop-types'
import { useContext, useState } from 'react'
import { cancelMeetingRoomBook } from '../../api/meet-rooms'
import { cancelSharedAreaBook } from '../../api/shared-areas'
import { AppContext } from '../../context'
import ModalHeader from '../ModalHeader'
import FinishModal from './FinishModal'


const AssignCreditsHours = ({ book, updateCompanyBooks, closeModal }) => {
  const { token, isAdmin } = useContext(AppContext)
  const [step, setStep] = useState(1)

  const handleRemoveBook = async () => {
    if(book.hour_range) {
      const body = { meeting_room_book_id: book.id }
      const response = await cancelMeetingRoomBook(token, body)

      if (response.error) {
        return
      }
      else if (response) {
        updateCompanyBooks()
        setStep((prev) => prev + 1)
      }
    } else {
      const body = { shared_area_book_id: book.id }
      const response = await cancelSharedAreaBook(token, body)

      if (response.error) {
        return
      }
      else if (response) {
        updateCompanyBooks()
        setStep((prev) => prev + 1)
      }
    }
  }

  const day = book.date.split('-')[2]
  const month = book.date.split('-')[1]

  // Check if restore credits is posible
  const isCurrentDay = new Date().getDate() === parseInt(day) && new Date().getMonth() + 1 === parseInt(month)

  // Check if restore hours is posible
  const reservationTime = new Date(book.date).getTime()
  const currentTime = new Date().getTime()

  const diffTime = reservationTime - currentTime
  const limitTime = 1 * 3600000

  const lostHours = diffTime < limitTime

  if (step === 1)
    return (
      <div className="PanelModal px-3" onClick={closeModal}>
        <div
          className="PanelModal__Card--Medium"
          onClick={(e) => e.stopPropagation()}
        >
          <ModalHeader title={'Cancelar reserva'} closeModal={closeModal} />

          <div className="d-flex flex-column align-items-center gap-2 my-4 text-2">
            <FontAwesomeIcon icon={faExclamationCircle} />
						¿Estás seguro que deseas cancelar?
          </div>

          {!isAdmin && (
            <div className='d-flex justify-content-center text-center my-4'>
              {book.book_type === 'Espacio' ? (
                <>
                  {isCurrentDay
                    ? <span className="text-4 fw-normal text-danger">&#128683; Como estas cancelando el mismo día de la reserva se perderan los créditos &#128683;</span>
                    : <span className="text-4 fw-normal">Si cancelas el mismo día de la reserva, perderás tus créditos. De lo contrario te los devolveremos &#128154;</span>
                  }
                </>
              ) : (
                <>
                  {lostHours
                    ? <span className="text-4 fw-normal text-danger">&#128683; Como estas cancelando con menos de 1 hora de anticipación, perderás las horas utilizadas &#128683;</span>
                    : <span className="text-4 fw-normal">Si cancelas con menos de 1 hora de anticipación, perderás las horas utilizadas. De lo contrario te las devolveremos &#128154;</span>
                  }
                </>
              )}
            </div>
          )}

          <div className="d-flex justify-content-center mb-2">
            <button className="button-green-panel" onClick={handleRemoveBook}>
							Sí, cancelar
            </button>
          </div>

          <div className="d-flex justify-content-center">
            <button
              className="button-white-gray-dark px-4 text-decoration-underline"
              onClick={closeModal}
            >
							Cambié de opinión, no quiero cancelar
            </button>
          </div>
        </div>
      </div>
    )

  if (step === 2)
    return (
      <FinishModal
        type="--Medium"
        title="Cancelar reserva"
        message="Su reserva ha sido cancelada"
        buttonText="¡Entendido!"
        closeModal={closeModal}
      />
    )
}

AssignCreditsHours.propTypes = {
  closeModal: propTypes.func.isRequired,
  book: propTypes.object.isRequired,
  updateCompanyBooks: propTypes.func.isRequired,
}

export default AssignCreditsHours
