import { Swiper, SwiperSlide } from 'swiper/react'
import { getEvents } from '../api/admin-settings'
import { Navigation, Pagination } from 'swiper'
import EventCard from '../components/EventCard'
import useApi from '../hooks/useApi'


const EventsSlider = () => {
  const [events] = useApi(getEvents, [])

  return (
    <div className="EventsSlider mb-0 mb-lg-5 pb-5 px-3">
      <Swiper
        className='EventsSlider__Swiper'
        modules={[Navigation, Pagination]}
        slidesPerView={1}
        spaceBetween={20}
        breakpoints={{
          576: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 20
          },
          1440: {
            slidesPerView: 4,
            spaceBetween: 20
          }
        }}
        navigation
        pagination={{ clickable: true }}
        // loop={true}
        speed={500}
      >
        {events.map((event, index) => (
          <SwiperSlide key={`EventCard-${index}`}>
            <EventCard event={event}/>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default EventsSlider