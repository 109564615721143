import { useEffect, useState, useContext } from 'react'
import { useLocation, Outlet, useNavigate } from 'react-router'
import { AppContext } from '../context'
import PanelHeader from '../containers/PanelHeader'
import PanelAdminMenu from '../containers/PanelAdminMenu'
import PanelUserMenu from '../containers/PanelUserMenu'
import PanelNotFound from '../router/PanelNotFound'


const PanelLayout = () => {
  const { token, firstCharge, user, isAdmin } = useContext(AppContext)
  const [firstLoad, setFirstLoad] = useState(false)
  const [showMenu, setShowMenu] = useState(true)
  const [showLogoutModal, setShowLogoutModal] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()


  useEffect(() => {
    if(!firstCharge && !token) {
      navigate('/iniciar-sesion')
    }
  }, [token, firstCharge])

  useEffect(() => {
    window.scrollTo(0, 0)

    if(window.matchMedia('(max-width: 767px)').matches) {
      setShowMenu(false)
    }
  }, [location])

  useEffect(() => {
    const getShowMenu = async () => {
      const savedShowMenu = localStorage.getItem('showMenu')

      if(savedShowMenu !== undefined && savedShowMenu !== null)
        setShowMenu(JSON.parse(savedShowMenu))
      setFirstLoad(true)
    }

    getShowMenu()
  }, [])

  useEffect(() => {
    if(firstLoad)
      localStorage.setItem('showMenu', `${showMenu}`)
  }, [showMenu])

  useEffect(() => {
    if(user && !user?.data?.is_admin) {
      const pathsDisabled = [
        '/panel',
        '/panel/',
        '/panel/planes',
      ]
      const hasACompany = user?.admin_in?.length !== 0 || user?.employee_in?.length !== 0
      const isADisabledPath = pathsDisabled.includes(location.pathname)

      if(hasACompany && isADisabledPath) {
        const company = user?.admin_in?.length !== 0 ? user?.admin_in[0]?.name?.toLowerCase() : user?.employee_in[0]?.name?.toLowerCase()
        navigate(`/panel/empresa/${company}`)
      }
    }
  }, [user?.admin_in, user?.employee_in, location])


  const toggleMenu = () => {
    setShowMenu(!showMenu)
  }


  if(firstCharge || !user)
    return (
      <div className="panel-content">
        <PanelHeader />
        <PanelNotFound />
      </div>
    )
  else
    return (
      <div className="panel-content">
        <PanelHeader showMenu={showMenu} toggleMenu={toggleMenu} showLogoutModal={showLogoutModal} setShowLogoutModal={setShowLogoutModal} />
        {isAdmin ? (
          <PanelAdminMenu showMenu={showMenu} toggleMenu={toggleMenu} setShowLogoutModal={setShowLogoutModal} />
        ): (
          <PanelUserMenu showMenu={showMenu} toggleMenu={toggleMenu} setShowLogoutModal={setShowLogoutModal} />
        )}
        <Outlet />
      </div>
    )
}

export default PanelLayout