import { faCheck, faExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment/moment'
import 'moment/locale/es'
import PropTypes from 'prop-types'


const NotificationsModal = ({ notifications, closeModal }) => {
  const notRead = notifications.filter((noti) => noti.is_read === false)

  const read = notifications.filter((noti) => noti.is_read === true)

  const notificationAlert = ['Booking_canceled', 'Benefit', 'New_message']

  return (
    <div className="NotificationsModal" onClick={closeModal}>
      <div className="NotificationsModal__Container">
        {notifications.length ? (
          <div
            className="NotificationsModal__Card-Container"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="NotificationsModal__Card">
              <h4 className='NotificationsModal__Title text-3 d-block d-md-none'>
                Notificaciones
              </h4>
              {notRead.length !== 0 && (
                <>
                  <div className='NotificationsModal__Item text-4'>Nuevas</div>
                  {notRead.map((noti, index) => (
                    <div className="NotificationsModal__Item" key={index}>
                      <div className="NotificationsModal__Item-Icon">
                        {notificationAlert.some(
                          (notification) =>
                            notification === noti.notification_type
                        ) ? (
                            <FontAwesomeIcon icon={faExclamation} />
                          ) : (
                            <FontAwesomeIcon icon={faCheck} />
                          )}
                      </div>
                      <div className="d-flex flex-column gap-1 w-100">
                        <h5 className="text-4">{noti.title}</h5>
                        <span className="text-5">{noti.message}</span>
                        <span className="text-5 text-end">
                          {moment(noti.date, 'YYYY-MM-DDThh:mm:ss')
                            .locale('es')
                            .fromNow()}
                        </span>
                      </div>
                    </div>
                  ))}
                </>
              )}
              {notifications.length !== read.length && read.length !== 0 && <div className='NotificationsModal__Item text-4'>Leídas</div>}
              {read.map((noti, index) => (
                <div className="NotificationsModal__Item" key={index}>
                  <div className="NotificationsModal__Item-Icon">
                    {notificationAlert.some(
                      (notification) =>
                        notification === noti.notification_type
                    ) ? (
                        <FontAwesomeIcon icon={faExclamation} />
                      ) : (
                        <FontAwesomeIcon icon={faCheck} />
                      )}
                  </div>
                  <div className="d-flex flex-column gap-1 w-100">
                    <h5 className="text-4">{noti.title}</h5>
                    <span className="text-5">{noti.message}</span>
                    <span className="text-5 text-end">
                      {moment(noti.date, 'YYYY-MM-DDThh:mm:ss')
                        .locale('es')
                        .fromNow()}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="NotificationsModal__Empty">
            <h4>No hay notificaciones</h4>
          </div>
        )}
      </div>
    </div>
  )
}

NotificationsModal.propTypes = {
  notifications: PropTypes.array.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default NotificationsModal
