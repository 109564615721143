import axios from 'axios'
import { URLApi } from '../constants'
import { postData } from './apiVariables'

export const login = async (email, password) => {
  try {
    const { data } = await axios.post(`${URLApi}/login/`, {
      email,
      password
    })

    return data.key
  } catch (error) {
    const errorMessage = error.response.data.non_field_errors[0]
    throw new Error(errorMessage)
  }
}

export const register = async (data) => {
  const newBody = {
    ...data,
    password1: data.password,
    password2: data.password,
    is_store: '0',
    is_office: '0'
  }

  return await postData({
    path: '/signup/',
    body: newBody
  })
}

export const logout = async (token) => {
  try {
    const { data } = await axios.post(`${URLApi}/logout/`, {
      headers: {
        Authorization: `Token ${token}`
      }
    })

    return data
  } catch (error) {
    // console.error(error)
  }
}

export const resendEmailConfirmation = async (body) => {
  return await postData({
    path: '/resend-email/',
    body
  })
}

export const confirmEmail = async (token) => {
  return await postData({
    path: `/signup/account-confirm-email/${token}/`,
    body: {
      key: token
    }
  })
}



export const postPasswordChange = async (token, body) => {
  return await postData({
    path: '/users/password-change/',
    token,
    body
  })
}

export const postCheckEmail = async (body) => {
  return await postData({
    path: '/users/password-recovery/',
    body
  })
}

export const postCheckToken = async (body) => {
  return await postData({
    path: '/users/password-recovery/check-token/',
    body
  })
}

export const postPasswordRecovery = async (body) => {
  return await postData({
    path: '/users/password-recovery/confirm/',
    body
  })
}