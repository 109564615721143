import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faLock,
  faAddressCard,
  faUser,
  faBriefcase,
  faPhone,
  faChevronLeft,
  faCakeCandles,
} from '@fortawesome/free-solid-svg-icons'
import GmailIcon from '../../assets/icons/gmail.svg'
import OutlookIcon from '../../assets/icons/outlook.svg'
import YahooIcon from '../../assets/icons/yahoo.svg'
import { register, resendEmailConfirmation } from '../../api/auth'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { useEffect } from 'react'
import { getProfessions } from '../../api/admin-settings'
import { phoneRegExp } from '../../utils/regExp'
import useApi from '../../hooks/useApi'
import InputAnimated from '../../components/InputAnimated'


const Register = () => {
  const [step, setStep] = useState(1)
  const [showModal, setShowModal] = useState(false)
  const [professions] = useApi(getProfessions, [])
  const [professionsList, setProfessionsList] = useState([])
  const [disableReSend, setDisableReSend] = useState(false)
  const [serverError, setServerError] = useState(false)
  const milisegInYear = 31536000000


  useEffect(() => {
    setProfessionsList(professions.map((item) => item.name))
  }, [professions])


  const toogleStep = () => {
    if (step === 1) {
      setStep(2)
    } else {
      setStep(1)
    }
  }


  const firstFormik = useFormik({
    initialValues: {
      dni: '',
      email: '',
      password: '',
      passwordConfirm: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      dni: Yup.number('Solo se permite números')
        .required('Su DNI es requrido')
        .positive('Ingrese un DNI valido')
        .integer('Ingrese un DNI valido, sin puntos ni comas')
        .min(1000000, 'DNI invalido')
        .max(100000000, 'DNI invalido'),
      email: Yup.string()
        .required('Su email es requerido')
        .email('Ingrese un email valido')
        .max(60, 'Máximo de caracteres: 60'),
      password: Yup.string()
        .required('Su contraseña es requerida')
        .min(5, 'Mínimo de caracteres: 5')
        .max(40, 'Máximo de caracteres: 40'),
      passwordConfirm: Yup.string()
        .required('Repetir la contraseña es requerido')
        .min(5, 'Mínimo de caracteres: 5')
        .max(40, 'Máximo de caracteres: 40'),
    }),
    onSubmit: async ({ email, password, passwordConfirm }) => {
      if (email.includes(' ')) {
        email = email.replace(/ /g, '')
      }

      if (password !== passwordConfirm) {
        firstFormik.setFieldError('password', 'Las contraseñas no coinciden')
        firstFormik.setFieldError(
          'passwordConfirm',
          'Las contraseñas no coinciden'
        )
      } else {
        toogleStep()
      }
    },
  })

  const secondFormik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      birthdate: '',
      profession: '',
      other_profession: '',
      phone: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      first_name: Yup.string()
        .required('Su nombre es requerido')
        .max(60, 'Máximo de caracteres: 60'),
      last_name: Yup.string()
        .required('Su apellido es requerido')
        .max(60, 'Máximo de caracteres: 60'),
      birthdate: Yup.date()
        .required('Su fecha de cumpleaños es requerida')
        .max(
          new Date(),
          'La fecha de cumpleaños no puede ser mayor a la actual'
        ),
      profession: Yup.string()
        .min(3, 'Mínimo caracteres: 3')
        .max(50, 'Máximo de caracteres: 50'),
      phone: Yup.string()
        .required('Su celular es requerido')
        .matches(phoneRegExp, 'Ingrese un numero de telefono valido')
        .max(40, 'Máximo de números: 40'),
      other_profession: Yup.string()
        .min(3, 'Mínimo caracteres: 3')
        .max(50, 'Máximo de caracteres: 50'),
    }),
    onSubmit: async ({
      first_name,
      last_name,
      birthdate,
      profession,
      other_profession,
      phone,
    }) => {
      const hoy = new Date()
      const date = new Date(birthdate)
      // Milisegundos de la fecha actual - (milisegundos de 18 años + milisegundos de 4.5 días a razon de 1 año bisiesto cada 4 años)
      const adult = hoy.getTime() - (milisegInYear * 13 + 86400000 * 4.5)

      if (date >= adult) {
        secondFormik.setFieldError('birthdate', 'Debes ser mayor de 13 años')
        return
      }

      let professionId
      if (profession) {
        professionId = professions.find((item) => item.name === profession).id
      }

      // Your password can’t be too similar to your other personal information.
      // Your password must contain at least 5 characters.
      // Your password can’t be a commonly used password.
      // Your password can’t be entirely numeric.

      try {
        const result = await register({
          first_name,
          last_name,
          email: firstFormik.values.email,
          password: firstFormik.values.password,
          dni: firstFormik.values.dni,
          birthdate,
          phone,
          profession: professionId,
          other_profession
        })

        if (result?.dni?.length && result?.dni[0]?.includes('user_profile with this dni already exists.')) {
          firstFormik.setFieldError('dni', 'Este DNI no está disponible')
          toogleStep()
          return
        }

        if (result?.error || result?.e) {
          const error = result.error
          const errorE = result.e

          if (error === 'Email already exists' || result.e.includes('email')) {
            firstFormik.setFieldError('email', 'El email ya existe')
            toogleStep()
          } else if (
            errorE.includes('common') ||
            errorE.includes('commonly') ||
            errorE.includes('numeric') ||
            errorE.includes('similar') ||
            errorE.includes('personal') ||
            errorE.includes('password is too common') ||
            errorE.includes('password can’t be entirely numeric') ||
            errorE.includes('password is entirely numeric') ||
            errorE.includes('password can’t be a commonly used password') ||
            errorE.includes('password can’t be too similar to your other personal information')
          ) {
            firstFormik.setFieldError('password', 'La contraseña es insegura')
            firstFormik.setFieldError(
              'passwordConfirm',
              'La contraseña es insegura'
            )
            toogleStep()
          }
        } else {
          setShowModal(true)
          setStep(1)
        }
      } catch (error) {
        if (error.response.data?.dni[0]) {
          firstFormik.setFieldError('dni', 'Este DNI no está disponible')
          toogleStep()
        } else {
          setServerError(true)
        }
      }
    },
  })


  const reSend = async () => {
    setDisableReSend(true)

    const response = await resendEmailConfirmation({
      email: firstFormik.values.email,
    })

    if (response.error) {
      // console.error(response.error)
      return
    }

    setDisableReSend(false)
  }


  return (
    <>
      <Helmet>
        <title>Vida Cowork: Registro</title>
      </Helmet>
      <main className="Register landing-content">
        {/* Modal */}
        {showModal && (
          <div className="Modal">
            <div className="Modal__Content--Successful">
              <FontAwesomeIcon className="Modal__Icon" icon={faCheckCircle} />
              <h2 className="Modal__Title">Registro exitoso</h2>
              <p className="Modal__Text">
                Chequea tu email para habilitar tu cuenta y comenzar a utilizar
                la plataforma
              </p>

              <div className="w-100 d-flex flex-wrap flex-md-nowrap justify-content-center justify-content-md-evenly gap-2">
                <a
                  className="button-gmail"
                  href="https://www.gmail.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>
                    <img
                      className="Modal__Button-Image"
                      src={GmailIcon}
                      alt="Gmail"
                      title="Gmail"
                    />
                    Gmail
                  </span>
                </a>
                <a
                  className="button-outlook"
                  href="https://www.outlook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="Modal__Button-Image"
                    src={OutlookIcon}
                    alt="Outlook"
                    title="Outlook"
                  />
                  Outlook
                </a>
                <a
                  className="button-yahoo"
                  href="https://mail.yahoo.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="Modal__Button-Image"
                    src={YahooIcon}
                    alt="Yahoo"
                    title="Yahoo"
                  />
                  Yahoo
                </a>
              </div>

              <span className="text-2">ó</span>

              <Link to="/iniciar-sesion" className="button-orange">
                Ir a Iniciar Sesión
              </Link>

              <button
                className={`button-white-gray-dark ${
                  !disableReSend ? 'text-decoration-underline' : ''
                }`}
                disabled={disableReSend}
                onClick={reSend}
              >
                {disableReSend
                  ? 'Reenviando...'
                  : 'No recibí el email de confirmación. Reenviar'}
              </button>
            </div>
          </div>
        )}

        {/* Content */}
        <div className="Register__Row row mx-0 bg-white">
          <div className="col-12 col-lg-6 py-5 d-flex justify-content-center">
            <div className="Register__Content">
              <h1 className="Register__Title text-1">
                <Link
                  to="/iniciar-sesion"
                  className="Register__GoBack d-none d-sm-flex"
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </Link>
                Comenza tu experiencia en Vida Cowork
              </h1>
              {step === 1 ? (
                <div className="Register__Form pt-5 pt-md-4 pt-xxl-5">
                  <InputAnimated
                    label="Email"
                    name="email"
                    type="email"
                    value={firstFormik.values.email}
                    onChange={firstFormik.handleChange}
                    error={firstFormik.errors.email}
                    icon={faEnvelope}
                  />

                  <InputAnimated
                    label="DNI"
                    name="dni"
                    type="number"
                    value={firstFormik.values.dni}
                    onChange={firstFormik.handleChange}
                    error={firstFormik.errors.dni}
                    tip="Sin puntos ni comas"
                    icon={faAddressCard}
                  />

                  <InputAnimated
                    label="Contraseña"
                    name="password"
                    type="password"
                    value={firstFormik.values.password}
                    onChange={firstFormik.handleChange}
                    error={firstFormik.errors.password}
                    tip="Mínimo 5 caracteres"
                    icon={faLock}
                  />

                  <InputAnimated
                    label="Repetir contraseña"
                    name="passwordConfirm"
                    type="password"
                    value={firstFormik.values.passwordConfirm}
                    onChange={firstFormik.handleChange}
                    error={firstFormik.errors.passwordConfirm}
                    icon={faLock}
                  />
                  <div className="Register__Steps-Container">
                    <div className="Register__Step--Active"></div>
                    <div className="Register__Step"></div>
                  </div>

                  <button
                    className="Register__Button--Next mt-4"
                    type="submit"
                    onClick={firstFormik.handleSubmit}
                  >
                    Continuar
                  </button>
                </div>
              ) : (
                <form
                  className="Register__Form pt-5 pt-md-4 pt-xxl-5"
                  onSubmit={secondFormik.handleSubmit}
                >
                  <InputAnimated
                    label="Nombre/s"
                    name="first_name"
                    type="text"
                    value={secondFormik.values.first_name}
                    onChange={secondFormik.handleChange}
                    error={secondFormik.errors.first_name}
                    icon={faUser}
                  />

                  <InputAnimated
                    label="Apellido/s"
                    name="last_name"
                    type="text"
                    value={secondFormik.values.last_name}
                    onChange={secondFormik.handleChange}
                    error={secondFormik.errors.last_name}
                    icon={faUser}
                  />

                  <InputAnimated
                    label="Fecha de cumpleaños"
                    name="birthdate"
                    type="date"
                    value={secondFormik.values.birthdate}
                    onChange={secondFormik.handleChange}
                    error={secondFormik.errors.birthdate}
                    icon={faCakeCandles}
                  />

                  <InputAnimated
                    label="Profesión/Rubro (Opcional)"
                    name="profession"
                    type="select"
                    value={secondFormik.values.profession}
                    list={professionsList}
                    onChange={secondFormik.setFieldValue}
                    error={secondFormik.errors.profession}
                    icon={faBriefcase}
                  />

                  {secondFormik.values.profession.toLowerCase() === 'otros' ? (
                    <InputAnimated
                      label="¿A qué te dedicás?"
                      name="other_profession"
                      type="text"
                      value={secondFormik.values.other_profession}
                      onChange={secondFormik.handleChange}
                      error={secondFormik.errors.other_profession}
                      icon={faBriefcase}
                    />
                  ) : (
                    <></>
                  )}

                  <InputAnimated
                    label="Celular"
                    name="phone"
                    type="tel"
                    value={secondFormik.values.phone}
                    onChange={secondFormik.handleChange}
                    error={secondFormik.errors.phone}
                    icon={faPhone}
                  />
                  <div className="Register__Steps-Container">
                    <div
                      className="Register__Step"
                      onClick={() => setStep(step - 1)}
                    ></div>
                    <div className="Register__Step--Active"></div>
                  </div>

                  {serverError && (
                    <p className="mb-0 text-error">Algo salio mal 🤷‍♀️</p>
                  )}

                  <button
                    className="Register__Button--Next mt-4"
                    type="submit"
                    disabled={showModal}
                  >
                    Registrarse
                  </button>
                </form>
              )}
            </div>
          </div>
          <div className="Register__Image col-6"></div>
        </div>
      </main>
    </>
  )
}

export default Register
