import propTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import OfficeCard from '../components/OfficeCard'


const OfficeCards = ({
  offices,
  openAddOfficeModal,
  openDeleteOfficeModal,
  openCreateOfficeBooks,
  openCancelOfficeBooks,
}) => {
  return (
    <section className="OfficeCards">
      <div className="OfficeCards__Title">
        <h2 className="text-1">Oficinas</h2>
        <button className="button-green-panel" onClick={openAddOfficeModal}>
          <FontAwesomeIcon icon={faPlus} />
          Agregar oficina
        </button>
      </div>
      <div className="OfficeCards__Content">
        {offices.map((office, index) => (
          <OfficeCard
            {...office}
            office={office}
            openDeleteOfficeModal={openDeleteOfficeModal}
            openCreateOfficeBooks={openCreateOfficeBooks}
            openCancelOfficeBooks={openCancelOfficeBooks}
            key={`Office-Card-${office.id}-${index}`}
          />
        ))}
      </div>
    </section>
  )
}

OfficeCards.propTypes = {
  offices: propTypes.array.isRequired,
  openAddOfficeModal: propTypes.func.isRequired,
  openDeleteOfficeModal: propTypes.func.isRequired,
  openCreateOfficeBooks: propTypes.func.isRequired,
  openCancelOfficeBooks: propTypes.func.isRequired,
}

export default OfficeCards
