import { useContext } from 'react'
import propTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faExclamationCircle, faXmark } from '@fortawesome/free-solid-svg-icons'
import { cancelMeetingRoomBook } from '../../api/meet-rooms'
import { AppContext } from '../../context'
import chroma from 'chroma-js'


const RoomReservationCancelModal = ({ modalState, setModalState, refreshData, closeModal, serverTime }) => {
  const { token, isAdmin, updateUser, rooms } = useContext(AppContext)

  // Check if restore hours is posible
  const reservationTime = new Date(modalState.reservation.start_date).getTime()
  const currentTime = new Date(serverTime).getTime()

  const diffTime = reservationTime - currentTime
  const limitTime = 1 * 3600000

  const lostHours = diffTime < limitTime

  const cancelReservation = async () => {
    setModalState({
      ...modalState,
      step: 2,
    })

    const response = await cancelMeetingRoomBook(token, {
      meeting_room_book_id: modalState.reservation.id
    })

    if(!response.error) {
      updateUser()
      refreshData()
    }
  }

  const date = new Date(modalState.reservation.start_date)
  const day = date.getDate()
  const month = date.getMonth() + 1

  if(modalState.step == 1) return (
    <div className="PanelModal px-3" onClick={closeModal}>
      <div className='PanelModal__Card--Medium d-flex flex-column' onClick={(e) => e.stopPropagation()}>
        <header>
          <button className='PanelModal__Button-Close' onClick={closeModal}>
            <FontAwesomeIcon icon={faXmark}/>
          </button>
          <h2 className="PanelModal__Title text-1 text-center">Cancelar reserva</h2>
        </header>

        <div className="PanelModal__Check PanelModal__Confirm caption text-center">
          <FontAwesomeIcon icon={faExclamationCircle}/>
          <h3 className='text-2'>¿Estás seguro que deseas cancelar esta reserva?</h3>

          {!isAdmin && (
            <div className='d-flex justify-content-center my-4'>
              {lostHours
                ? <span className="text-4 fw-normal text-danger">&#128683; Como estas cancelando con menos de 1 hora de anticipación, perderás las horas utilizadas &#128683;</span>
                : <span className="text-4 fw-normal">Si cancelas con menos de 1 hora de anticipación, perderás las horas utilizadas. De lo contrario te las devolveremos &#128154;</span>
              }
            </div>
          )}
        </div>

        <div className='PanelModal__Confirm text-3'>
          <h3 className='text-2'>La reserva que vas a cancelar:</h3>

          <div
            className={
              isAdmin
                ? 'PanelModal__Cancel-Rooms-Reservation-Item--Admin text-5 mt-2'
                : 'PanelModal__Cancel-Rooms-Reservation-Item text-5 mt-2'
            }
            style={{
              backgroundColor: rooms.find(room => room.name == modalState.reservation.room).color,
              borderLeftColor: chroma(rooms.find(room => room.name == modalState.reservation.room).color).darken(1).hex(),
            }}
          >
            {isAdmin && (
              <>
                {modalState.reservation.reference
                  ? (
                    <span>Referencia: <br/><span className="fw-bold">{ modalState.reservation.reference }</span></span>)
                  : (modalState.reservation.user || (modalState.reservation.first_name && modalState.reservation.last_name)) && (
                    <span>Usuario: <br/><span className="fw-bold">{modalState.reservation.user || `${modalState.reservation.first_name} ${modalState.reservation.last_name}`}</span></span>
                  )
                }
              </>
            )}
            <span>Fecha: <br/><span className="fw-bold">{`${day}/${month}`}</span></span>
            <span>Sala: <br/><span className="fw-bold">{ modalState.reservation.room }</span></span>
            <span>Horario: <br/><span className="fw-bold">{ modalState.reservation.start_hour } a { modalState.reservation.end_hour }</span></span>
          </div>
        </div>

        <div className='mt-3'>
          <div className="d-flex justify-content-center">
            <button className='button-green-panel' onClick={cancelReservation}>Si, cancelar</button>
          </div>

          <div className="d-flex justify-content-center">
            <button className='button-underline' onClick={closeModal}>Cambié de opinión, no quiero cancelar</button>
          </div>
        </div>
      </div>
    </div>
  )

  if(modalState.step == 2) return (
    <div className="PanelModal px-3" onClick={closeModal}>
      <div className='PanelModal__Card--Medium d-flex flex-column gap-3' onClick={(e) => e.stopPropagation()}>
        <header>
          <button className='PanelModal__Button-Close' onClick={closeModal}>
            <FontAwesomeIcon icon={faXmark}/>
          </button>
          <h2 className="PanelModal__Title text-1 text-center">Reserva cancelada</h2>
        </header>

        <div className="FinishModal__Check caption text-center py-5">
          <FontAwesomeIcon icon={faCheckCircle}/>
          <h3 className='text-1 mt-3'>Su reserva ha sido cancelada</h3>
        </div>

        <div className="d-flex justify-content-center">
          <button className='button-green-panel' onClick={closeModal}>Entendido</button>
        </div>
      </div>
    </div>
  )
}

RoomReservationCancelModal.propTypes = {
  modalState: propTypes.object.isRequired,
  setModalState: propTypes.func.isRequired,
  refreshData: propTypes.func.isRequired,
  closeModal: propTypes.func.isRequired,
  serverTime: propTypes.string.isRequired,
}

export default RoomReservationCancelModal