import { memo } from 'react'
import PropTypes from 'prop-types'
import Media from './Media'

const ServiceItem = ({ icon, name, description }) => {
  return (
    <div className="ServiceItem">
      <Media type='image' className='ServiceItem__Image' src={icon} alt={name} title={name} width={60} height={60} />
      <div className="ServiceItem__Content">
        <h3 className="text-3 m-0 mb-sm-2">{ name }</h3>
        <p className="text-4 m-0 d-none d-sm-block">{ description }</p>
      </div>
    </div>
  )
}

ServiceItem.propTypes = {
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
}

export default memo(ServiceItem)