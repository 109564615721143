import propTypes from 'prop-types'
import { useEffect, useState } from 'react'
import TableActions from '../components/TableActions'
import TableItem from '../components/TableItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleLeft,
  faAngleRight,
  faSort,
  faSortDown,
  faSortUp,
} from '@fortawesome/free-solid-svg-icons'


const Table = ({
  type,
  headers,
  headersMobile,
  showInfoMobile,
  data,
  updateData = () => {},
  openInfoModal,
  isEmptyMenssage = 'No hay información para mostrar',
  rounded,
  unselectable,
  selectedParentItems,
  handleParentItems,
  page = 1,
  perPage = 12,
  totalPages = 1,
  totalItems = 0,
  setPage,
  setOrderBy = () => {},
}) => {
  const [selectedItemsId, setSelectedItemsId] = useState([])
  const [selectedItems, setSelectedItems] = useState([])
  const [sort, setSort] = useState({
    value: '',
    bool: true,
  })

  useEffect(() => {
    setOrderBy(
      sort.value === ''
        ? ''
        : sort.bool === true
          ? `${sort.value}`
          : `-${sort.value}`
    )
  }, [sort])

  useEffect(() => {
    setSelectedItems(
      selectedItemsId.map((id) => data?.find((item) => item.id === id))
    )
  }, [selectedItemsId, data])

  useEffect(() => {
    setSelectedItems([])
    setSelectedItemsId([])
  }, [data])

  const handleSelectItem = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItemsId((prev) => prev.filter((id) => id !== item.id))
    } else {
      setSelectedItemsId((prev) => [...prev, item.id])
    }
  }

  const handlePrevPage = () => {
    if (page < 2) return
    setPage((prev) => prev - 1)
  }

  const handleNextPage = () => {
    if (page >= totalPages) return
    setPage((prev) => prev + 1)
  }

  const handleSort = (value) => {
    setSort((prev) => {
      if (prev.value !== value) 
        return {
          value: value,
          bool: true,
        }

      if (prev.bool)
        return {
          value: value,
          bool: false,
        }
      else
        return {
          value: '',
          bool: true,
        }
    })
  }

  const TablePages = () =>
    totalItems !== 0 ? (
      <div className="Table__Pages">
        <button
          className="Table__Pages-Arrow"
          onClick={handlePrevPage}
          disabled={page < 2}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        <div className="Table__Pages-Text">
          <span>{`${(page - 1) * perPage + 1}-${
            (page - 1) * perPage + data?.length
          }`}</span>
          <span>{' de '}</span>
          <span>{totalItems}</span>
        </div>
        <button
          className="Table__Pages-Arrow"
          onClick={handleNextPage}
          disabled={page >= totalPages}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
    ) : (
      <></>
    )

  const TableHeader = () => (
    <>
      <div
        className={`Table__Header ${headersMobile ? 'd-none d-md-grid' : ''}`}
      >
        {!unselectable && <div className="Table__Header-Item text-4" />}
        {headers.map((header, index) => {
          if (typeof header === 'object')
            return (
              <div
                key={header.id}
                className="Table__Header-Item cursor-pointer text-4"
                onClick={() => handleSort(header.value)}
              >
                <span>{header.label}</span>
                {sort.value === header.value ? (
                  sort.bool ? (
                    <FontAwesomeIcon icon={faSortUp} />
                  ) : (
                    <FontAwesomeIcon icon={faSortDown} />
                  )
                ) : (
                  <div className="opacity-50" >
                    <FontAwesomeIcon icon={faSort} />
                  </div>
                )}
              </div>
            )
          else
            return (
              <div
                className="Table__Header-Item text-4"
                key={`Table-Header-Item-${index}`}
              >
                {header}
              </div>
            )
        })}
      </div>

      {headersMobile && (
        <div className="Table__Header d-grid d-md-none">
          {!unselectable && <div className="Table__Header-Item text-4" />}
          {headersMobile.map((header, index) => {
            if (typeof header === 'object')
              return (
                <div
                  key={header.id}
                  className="Table__Header-Item cursor-pointer text-4"
                  onClick={() => handleSort(header.value)}
                >
                  <span>{header.label}</span>
                  {sort.value === header.value ? (
                    sort.bool ? (
                      <FontAwesomeIcon icon={faSortUp} />
                    ) : (
                      <FontAwesomeIcon icon={faSortDown} />
                    )
                  ) : (
                    <div className="opacity-50" >
                      <FontAwesomeIcon icon={faSort} />
                    </div>
                  )}
                </div>
              )
            else
              return (
                <div
                  className="Table__Header-Item text-4"
                  key={`Table-Header-Item-${index}`}
                >
                  {header}
                </div>
              )
          })}
          {showInfoMobile && <div className="Table__Header-Item text-4" />}
        </div>
      )}
    </>
  )

  const Empty = () => (
    <div className="Table__Content-Empty text-5">{isEmptyMenssage}</div>
  )

  if (unselectable)
    return (
      <>
        <section
          className={`Table Table--${type} ${rounded ? 'Table--Rounded' : ''}`}
        >
          <TableHeader />

          {data?.length !== 0 ? (
            <div className="Table__Content">
              {data.map((item, index) => (
                <TableItem
                  key={`Table-Item-${item.id}-${index}`}
                  data={item}
                  type={type}
                  openInfoModal={openInfoModal}
                  unselectable
                />
              ))}
            </div>
          ) : (
            <Empty />
          )}
        </section>
        <TablePages />
      </>
    )

  // console.log(selectedItems)
  // console.log(data[0])
  // console.log('------------\n\n')
  const handleCheckSelected = (item) => {
    if (selectedItems.includes(item)) return true
    // Check by stringify
    return selectedItems?.some((selectedItem) => JSON.stringify(selectedItem) === JSON.stringify(item)) || selectedParentItems?.some((selectedItem) => JSON.stringify(selectedItem) === JSON.stringify(item))
  }

  return (
    <>
      <TableActions
        selectedItems={selectedParentItems ?? selectedItems}
        updateData={updateData}
        type={type}
      />
      <section
        className={`Table Table--${type} ${rounded ? 'Table--Rounded' : ''}`}
      >
        <TableHeader />

        {data?.length !== 0 ? (
          <div className="Table__Content">
            {data.map((item, index) => {
              return (
                <TableItem
                  key={`Table-Item-${item.id}-${index}`}
                  data={item}
                  type={type}
                  selected={
                    handleCheckSelected(item)
                  }
                  handleSelectItem={handleParentItems ?? handleSelectItem}
                  openInfoModal={openInfoModal}
                />
              )
            })}
          </div>
        ) : (
          <Empty />
        )}
      </section>
      <TablePages />
    </>
  )
}

Table.propTypes = {
  type: propTypes.string.isRequired,
  headers: propTypes.array.isRequired,
  headersMobile: propTypes.array,
  showInfoMobile: propTypes.bool,
  data: propTypes.array.isRequired,
  updateData: propTypes.func,
  openInfoModal: propTypes.func,
  rounded: propTypes.bool,
  unselectable: propTypes.bool,
  selectedParentItems: propTypes.array,
  handleParentItems: propTypes.func,
  isEmptyMenssage: propTypes.string,
  page: propTypes.number,
  perPage: propTypes.number,
  totalPages: propTypes.number,
  totalItems: propTypes.number,
  setPage: propTypes.func,
  setOrderBy: propTypes.func,
}

export default Table
