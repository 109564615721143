import { getData, patchData, postData } from './apiVariables'

export const getUserData = async (token) => {
  return await getData({
    path: '/users/me/',
    token
  })
}

export const getUserHoursAndCreditsAtDate = async (token, params) => {
  return await getData({
    path: '/users/get-user-hours-credits-by-date/',
    token,
    params
  })
}

export const patchUpdateUser = async (token, body) => {
  return await patchData({
    path: '/users/me/',
    token,
    body
  })
}

export const getAllUsers = async (token) => {
  return await getData({
    path: '/users/get-users/',
    token,
  })
}

export const getUserDetail = async (token, id) => {
  return await getData({
    path: `/users/get-user/${id}`,
    token,
  })
}

export const patchDebtorUser = async (token, body) => {
  return await patchData({
    path: '/users/debtor-user/',
    token,
    body
  })
}

export const patchBlockUser = async (token, body) => {
  return await patchData({
    path: '/users/block-user/',
    token,
    body
  })
}

export const postAssingHoursAndCredits = async (token, body) => {
  return await postData({
    path: '/users/assing-hours-credits/',
    token,
    body
  })
}

export const postRemoveHoursAndCredits = async (token, body) => {
  return await postData({
    path: '/users/remove-hours-credits/',
    token,
    body
  })
}
