import {
  faChevronLeft,
  faEnvelope,
  faKey,
  faLock,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useState } from 'react'
import {
  postCheckEmail,
  postCheckToken,
  postPasswordRecovery,
} from '../../api/auth'
import InputAnimated from '../../components/InputAnimated'

const ForgotPassword = () => {
  const [step, setStep] = useState(1)
  const [buttonDisable, setButtonDisable] = useState(false)
  const [serverError, setServerError] = useState(false)

  const formik1 = useFormik({
    initialValues: {
      email: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      email: Yup.string()
        .required('El email es requerido')
        .email('Ingrese un email valido')
        .max(60, 'Máximo de caracteres: 60'),
    }),
    onSubmit: async ({ email }) => {
      if(buttonDisable) return 
      setButtonDisable(true)

      if (email.includes(' ')) {
        email = email.replace(/ /g, '')
      }

      const response = await postCheckEmail({ email })

      if (response.e === 'No User matches the given query.')
        formik1.setFieldError('email', 'Mail no encontrado')
      else if (response.error)
        setServerError(true)
      else if (response.message === 'Email sent') setStep((prev) => prev + 1)

      setButtonDisable(false)
    },
  })

  const formik2 = useFormik({
    initialValues: {
      token: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      token: Yup.string()
        .required('El token es requerido')
        .max(60, 'Máximo de caracteres: 60'),
    }),
    onSubmit: async ({ token }) => {
      if(buttonDisable) return 
      setButtonDisable(true)
      const response = await postCheckToken({
        email: formik1.values.email,
        token,
      })

      if (response.error == 'Token is invalid')
        formik2.setFieldError('token', 'El token no es valido')
      else if (response.error == 'Token expired')
        formik2.setFieldError('token', 'El token ha expirado')
      else if (response.error)
        setServerError(true)
      else if (response.message === 'Token is valid')
        setStep((prev) => prev + 1)

      setButtonDisable(false)
    },
  })

  const formik3 = useFormik({
    initialValues: {
      new_password1: '',
      new_password2: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      new_password1: Yup.string()
        .required('la contraseña es requerida')
        .min(5, 'Mínimo de caracteres: 5')
        .max(40, 'Máximo de caracteres: 40'),
      new_password2: Yup.string()
        .required('Repetir la contraseña es requerido')
        .min(5, 'Mínimo de caracteres: 5')
        .max(40, 'Máximo de caracteres: 40'),
    }),
    onSubmit: async ({ new_password1, new_password2 }) => {
      if(buttonDisable) return 
      setButtonDisable(true)
      if (new_password1 !== new_password2) {
        formik3.setFieldError('new_password1', 'Las contraseñas no coinciden')
        formik3.setFieldError('new_password2', 'Las contraseñas no coinciden')
        return null
      }
      const response = await postPasswordRecovery({
        email: formik1.values.email,
        token: formik2.values.token,
        password: new_password1,
      })

      if (
        Array.isArray(response.error) &&
        response.error[0] === 'The password is too similar to the username.'
      )
        formik3.setFieldError(
          'new_password1',
          'La contraseña es muy similar el nombre de usuario'
        )
      else if (
        Array.isArray(response.error) &&
        response.error[0] === 'This password is too common.'
      )
        formik3.setFieldError('new_password1', 'La contraseña es poco segura')
      else if (response.error)
        setServerError(true)
      else if (response) setStep((prev) => prev + 1)

      setButtonDisable(false)
    },
  })

  return (
    <>
      <Helmet>
        <title>Vida Cowork: Recuperar Contraseña</title>
      </Helmet>
      <main className="Login landing-content">
        <div className="Login__Row row mx-0 bg-white">
          <div className="col-12 col-lg-6 py-5 d-flex justify-content-center">
            <div className="Register__Content">
              <h1 className="Register__Title text-1 w-100">
                <Link
                  to="/iniciar-sesion"
                  className="Register__GoBack d-none d-sm-flex"
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </Link>
                Recuperá tu contraseña
              </h1>
              {step === 1 && (
                <form
                  className="Register__Form pt-5 pt-md-4 pt-xxl-5"
                  onSubmit={formik1.handleSubmit}
                >
                  <InputAnimated
                    label="Email"
                    name="email"
                    type="email"
                    value={formik1.values.email}
                    onChange={formik1.handleChange}
                    error={formik1.errors.email}
                    icon={faEnvelope}
                  />

                  {serverError && <p className='mb-0 text-error'>Algo salio mal 🤷‍♀️</p>}

                  <div className="Login__Button-Container">
                    <button className="Login__Button--Submit" type="submit">
                      Enviar mail
                    </button>
                  </div>
                </form>
              )}
              {step === 2 && (
                <form
                  className="Register__Form pt-5 pt-md-4 pt-xxl-5"
                  onSubmit={formik2.handleSubmit}
                >
                  <div className="text-4">
                    Hemos enviado un mail a <span className="fw-semibold">{formik1.values.email}</span> con un token,
                    ingresalo aquí para cambiar tu contraseña. Recuerda respetar las
                    mayusculas y minúsculas.
                  </div>
                  <InputAnimated
                    label="Token"
                    name="token"
                    type="token"
                    value={formik2.values.token}
                    onChange={formik2.handleChange}
                    error={formik2.errors.token}
                    icon={faKey}
                  />

                  {serverError && <p className='mb-0 text-error'>Algo salio mal 🤷‍♀️</p>}

                  <div className="Login__Button-Container">
                    <button className="Login__Button--Submit" type="submit">
                      Confirmar token
                    </button>
                  </div>
                </form>
              )}
              {step === 3 && (
                <div className="Register__Form pt-5 pt-md-4 pt-xxl-5">
                  <InputAnimated
                    label="Contraseña nueva"
                    name="new_password1"
                    type="password"
                    value={formik3.values.new_password1}
                    onChange={formik3.handleChange}
                    error={formik3.errors.new_password1}
                    tip={'Debe contener al menos 5 caracteres'}
                    icon={faLock}
                  />

                  <InputAnimated
                    label="Repetir contraseña"
                    name="new_password2"
                    type="password"
                    value={formik3.values.new_password2}
                    onChange={formik3.handleChange}
                    error={formik3.errors.new_password2}
                    icon={faLock}
                  />

                  {serverError && <p className='mb-0 text-error'>Algo salio mal 🤷‍♀️</p>}

                  <div className="Login__Button-Container">
                    <button
                      className="Login__Button--Submit"
                      type="button"
                      onClick={formik3.handleSubmit}
                    >
                      Cambiar contraseña
                    </button>
                  </div>
                </div>
              )}
              {step === 4 && (
                <div className="Register__Form pt-5 pt-md-4 pt-xxl-5">
                  <div className="text-4">¡Tu contraseña ha sido modificada exitosamente!</div>
                  <div className="Login__Button-Container">
                    <Link
                      to="/iniciar-sesion"
                      className="Login__Button--Submit"
                    >
                      Iniciar sesión
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="Login__Image col-6" />
        </div>
      </main>
    </>
  )
}

export default ForgotPassword
