import { useState, useContext } from 'react'
import { Autoplay, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import SolicitPlanFreelanModal from '../components/modals/SolicitPlanFreelanModal'
import OtherPlanCard from '../components/OtherPlanCard'
import useApi from '../hooks/useApi'
import { getSystemPlans } from '../api/plans'
import { AppContext } from '../context'


const OtherPlans = () => {
  const { user } = useContext(AppContext)
  const [plans] = useApi(getSystemPlans, {
    data: {},
    is_selling_allowed: true,
  })
  const [showSolicitPlanFreelan, setShowSolicitPlanFreelan] = useState(false)
  const [planNameSelected, setPlanNameSelected] = useState('')

  const toggleSolicitPlanFreelan = (planName) => {
    setPlanNameSelected(planName)
    setShowSolicitPlanFreelan(!showSolicitPlanFreelan)
  }

  return (
    <>
      {!plans.is_selling_allowed && (
        <p className="text-5 text-error mt-2">
          Los planes no se encuentran habilitados para compra
        </p>
      )}
      <div className="OtherPlans">
        <Swiper
          className="OtherPlans__Swiper"
          modules={[Navigation, Pagination, Autoplay]}
          slidesPerView={1}
          spaceBetween={20}
          breakpoints={{
            576: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1100: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1300: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
            1600: {
              slidesPerView: 5,
              spaceBetween: 30,
            },
            1700: {
              slidesPerView: 5,
              spaceBetween: 40,
            },
          }}
          navigation
          pagination={{ clickable: true }}
          autoplay={{ delay: 3000 }}
          grabCursor={true}
          speed={1000}
        >
          {Object.keys(plans.data).map((plan, index) => (
            <SwiperSlide
              key={`OtherPlanCard-${plan}-${index}`}
              className="OtherPlans__Item"
            >
              <OtherPlanCard
                name={plan}
                info={plans.data[plan]}
                showSolicitPlanFreelan={toggleSolicitPlanFreelan}
                disable={
                  user.plans.find((item) => item.plan.name === plan) && true
                }
                is_selling_allowed={plans.is_selling_allowed}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {showSolicitPlanFreelan && (
        <SolicitPlanFreelanModal
          planName={planNameSelected}
          closeModal={toggleSolicitPlanFreelan}
        />
      )}
    </>
  )
}

export default OtherPlans
