import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { getPercentage } from '../pages/panel/admin/StatisticsAdminPanel'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'

ChartJS.register(ArcElement, Tooltip, Legend)

const activeColor1 = '#2291EB'
const activeColor2 = '#FF6F91'

const emptyColor = '#ECECEC'

const CreditsAndHoursGraphic = ({ activeCreditsAndHours }) => {
  const [creditsAndHours, setCreditsAndHours] = useState({
    credits: [],
    hours: [],
  })

  useEffect(() => {
    setCreditsAndHours({
      credits: [
        {
          label: 'Activos',
          count: activeCreditsAndHours.active_credits,
          color: activeColor1,
        },
        {
          label: 'Restantes',
          count:
            activeCreditsAndHours.total_credits -
            activeCreditsAndHours.active_credits,
          color: emptyColor,
        },
      ],
      hours: [
        {
          label: 'Activos',
          count: activeCreditsAndHours.active_hours,
          color: activeColor2,
        },
        {
          label: 'Restantes',
          count:
            activeCreditsAndHours.total_hours -
            activeCreditsAndHours.active_hours,
          color: emptyColor,
        },
      ],
    })
  }, [activeCreditsAndHours])

  return (
    <div className="StatisticsAdminPanel__Graphic-Container">
      <h2 className="text-3">Créditos y horas vendidos</h2>

      <div className="StatisticsAdminPanel__Mini-Graphic-Cards">
        <div className="StatisticsAdminPanel__Mini-Graphic-Card">
          <h3 className="text-4">Créditos</h3>
          <Pie
            style={{
              position: 'relative',
              width: '100%',
              maxWidth: '80vw',
              aspectRatio: 7 / 3,
            }}
            options={{
              responsive: true,
              aspectRatio: 7 / 3,
              plugins: {
                legend: {
                  display: true,
                  position: 'right',
                  labels: {
                    boxWidth: 25,
                    boxHeight: 25,
                    padding: 25,
                    usePointStyle: true,
                    pieceLabel: {
                      mode: 'percentage',
                      render: 'value',
                    },
                  },
                },
                tooltip: {
                  usePointStyle: true,
                  boxPadding: 3,
                  callbacks: {
                    title: (context) => {
                      const hiddenIndices = []

                      for (const index in context[0].chart._hiddenIndices) {
                        if (context[0].chart._hiddenIndices[index])
                          hiddenIndices.push(index)
                      }

                      hiddenIndices.reverse()

                      const data = [...context[0].dataset.data]
                      hiddenIndices.forEach((index) => {
                        data.splice(index, 1)
                      })

                      let totalCount = 0
                      data.forEach((number) => (totalCount += number))

                      const title = `${context[0].formattedValue} credito${
                        context[0].formattedValue !== '1' ? 's' : ''
                      }, ${getPercentage(context[0].parsed, totalCount)}%`
                      return title
                    },
                    label: (context) => context.label,
                  },
                },
              },
            }}
            data={{
              labels: creditsAndHours.credits.map((credit) => credit.label),
              datasets: [
                {
                  label: creditsAndHours.credits.map((credit) => credit.label),
                  data: creditsAndHours.credits.map((credit) => credit.count),
                  backgroundColor: creditsAndHours.credits.map(
                    (credit) => credit.color
                  ),
                  hoverOffset: 4,
                },
              ],
            }}
          />
        </div>

        <div className="StatisticsAdminPanel__Mini-Graphic-Card">
          <h3 className="text-4">Horas</h3>
          <Pie
            style={{
              position: 'relative',
              width: '100%',
              maxWidth: '80vw',
              aspectRatio: 7 / 3,
            }}
            options={{
              responsive: true,
              aspectRatio: 7 / 3,
              plugins: {
                legend: {
                  display: true,
                  position: 'right',
                  labels: {
                    boxWidth: 25,
                    boxHeight: 25,
                    padding: 25,
                    usePointStyle: true,
                    pieceLabel: {
                      mode: 'percentage',
                      render: 'value',
                    },
                  },
                },
                tooltip: {
                  usePointStyle: true,
                  boxPadding: 3,
                  callbacks: {
                    title: (context) => {
                      const hiddenIndices = []

                      for (const index in context[0].chart._hiddenIndices) {
                        if (context[0].chart._hiddenIndices[index])
                          hiddenIndices.push(index)
                      }

                      hiddenIndices.reverse()

                      const data = [...context[0].dataset.data]
                      hiddenIndices.forEach((index) => {
                        data.splice(index, 1)
                      })

                      let totalCount = 0
                      data.forEach((number) => (totalCount += number))

                      const title = `${context[0].formattedValue} hora${
                        context[0].formattedValue !== '1' ? 's' : ''
                      }, ${getPercentage(context[0].parsed, totalCount)}%`
                      return title
                    },
                    label: (context) => context.label,
                  },
                },
              },
            }}
            data={{
              labels: creditsAndHours.hours.map((hour) => hour.label),
              datasets: [
                {
                  label: creditsAndHours.hours.map((hour) => hour.label),
                  data: creditsAndHours.hours.map((hour) => hour.count),
                  backgroundColor: creditsAndHours.hours.map(
                    (hour) => hour.color
                  ),
                  hoverOffset: 4,
                },
              ],
            }}
          />
        </div>
      </div>
    </div>
  )
}

CreditsAndHoursGraphic.propTypes = {
  activeCreditsAndHours: PropTypes.object.isRequired,
}

export default CreditsAndHoursGraphic
