import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import {
  getActiveCreditsAndHours,
  getAmountUsersAndPlans,
  getCompanyFlowByMonth,
  getPlansPopularity,
  getShiftAndHoursMostRequested,
  getUserFlowByMonth,
} from '../../../api/admin-data'
import CreditsAndHoursGraphic from '../../../components/CreditsAndHoursGraphic'
import DateSelector from '../../../components/DateSelector'
import Dropdown from '../../../components/Dropdown'
import MettingRoomsGraphic from '../../../components/MettingRoomsGraphic'
import PlansGraphic from '../../../components/PlansGraphic'
import ShiftsAndHoursGraphic from '../../../components/ShiftsAndHoursGraphic'
import { AppContext } from '../../../context'
import useApi from '../../../hooks/useApi'

export const getPercentage = (toCalc, total = 1) => {
  if (total === 0) {
    return 0
  }
  return ((toCalc / total) * 100).toFixed(2)
}

const StatisticsAdminPanel = () => {
  const { token } = useContext(AppContext)
  const [firstTime, setFirstTime] = useState(true)
  const [globalDate, setGlobalDate] = useState(
    {
      date1: moment().subtract(1, 'M').add(1, 'd').format('YYYY-MM-DD'),
      date2: moment().format('YYYY-MM-DD'),
    }
  )
  const [globalDateSelected, setGlobalDateSelected] = useState({
    id: 1,
    label: 'Ultimo mes',
    value: '1 mount',
  })

  useEffect(() => {
    if(firstTime) return setFirstTime(false)

    const cuurentDate = moment().format('YYYY-MM-DD')

    switch (globalDateSelected.value) {
    case '1 week': {
      const date1Week = moment().subtract(6, 'd').format('YYYY-MM-DD')
      return setGlobalDate({
        date1: date1Week,
        date2: cuurentDate,
      })
    }
    case '1 mount': {
      const date1Mount = moment().subtract(1, 'M').add(1, 'd').format('YYYY-MM-DD')
      return setGlobalDate({
        date1: date1Mount,
        date2: cuurentDate,
      })
    }
    case '3 mounts': {
      const date3Mount = moment().subtract(3, 'M').add(1, 'd').format('YYYY-MM-DD')
      return setGlobalDate({
        date1: date3Mount,
        date2: cuurentDate,
      })
    }
    case '6 mounts': {
      const date6Mount = moment().subtract(6, 'M').add(1, 'd').format('YYYY-MM-DD')
      return setGlobalDate({
        date1: date6Mount,
        date2: cuurentDate,
      })
    }
    case '1 year': {
      const date1Year = moment().subtract(1, 'Y').add(1, 'd').format('YYYY-MM-DD')
      return setGlobalDate({
        date1: date1Year,
        date2: cuurentDate,
      })
    }
    
    default:
      break
    }
  }, [globalDateSelected])

  const [amountUsersAndPlans] = useApi(
    () => getAmountUsersAndPlans(token, globalDate),
    {
      cant_users_plans: 0,
      cant_users: 0,
    },
    [token, globalDate]
  )
  const [userFlowByMonth] = useApi(
    () => getUserFlowByMonth(token, globalDate),
    {
      users: 0,
      user_plans: 0,
    },
    [token, globalDate]
  )
  const [companyFlowByMonth] = useApi(
    () => getCompanyFlowByMonth(token, globalDate),
    {
      amount_of_companies: 0,
    },
    [token, globalDate]
  )
  const [plansPopularity] = useApi(
    () => getPlansPopularity(token, globalDate),
    [],
    [token, globalDate]
  )
  const [activeCreditsAndHours] = useApi(
    () => getActiveCreditsAndHours(token),
    {
      total_hours: 0,
      active_hours: 0,
      total_credits: 0,
      active_credits: 0,
    },
    [token]
  )
  const [shiftAndHoursMostRequested] = useApi(
    () => getShiftAndHoursMostRequested(token, globalDate),
    {
      shifts: {
        morning_shift: 0,
        afternoon_shift: 0
      },
      most_solicited_mt: [],
      total_hours: 0,
      hours: [
        {
          most_solicited_hour: 0,
          count: 0
        },
      ],
    },
    [token, globalDate]
  )

  const options = [
    {
      id: 0,
      label: 'Ultima semana',
      value: '1 week'
    },
    {
      id: 1,
      label: 'Ultimo mes',
      value: '1 mount',
    },
    {
      id: 2,
      label: 'Ultimos 3 mes',
      value: '3 mounts',
    },
    {
      id: 3,
      label: 'Ultimos 6 mes',
      value: '6 mounts',
    },
    {
      id: 4,
      label: 'Ultimo año',
      value: '1 year',
    },
  ]


  const handleGlobalDateSelected = (dateIndex) => {
    setGlobalDateSelected(options[dateIndex])
  }

  const handleGlobalDateCustomSelected1 = (value) => {
    if(value === '') return
    let date1 = moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD')

    if(date1 > globalDate.date2)
      date1 = moment(globalDate.date2).format('YYYY-MM-DD')

    setGlobalDate(prev => ({
      date1,
      date2: prev.date2
    }))

    setGlobalDateSelected({
      id: 5,
      label: 'Período personalizado ',
      value: 'custom',
    })
  }

  const handleGlobalDateCustomSelected2 = (value) => {
    if(value === '') return
    let date2 = moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD')

    if(date2 < globalDate.date1)
      date2 = moment(globalDate.date1).format('YYYY-MM-DD')

    setGlobalDate(prev => ({
      date1: prev.date1,
      date2,
    }))
    setGlobalDateSelected({
      id: 5,
      label: 'Período personalizado',
      value: 'custom',
    })
  }

  return (
    <>
      <Helmet>
        <title>Vida Cowork: Estadísticas</title>
      </Helmet>
      <main className="StatisticsAdminPanel panel-main pt-4 pb-5">
        <div className="w-100 px-3 px-md-5">
          <div className='d-flex flex-wrap align-items-center gap-3 mb-4'>
            <h2 className="text-1">Datos generales</h2>
            <Dropdown
              options={options}
              optionSelected={globalDateSelected}
              onChange={handleGlobalDateSelected}
              type={'Rounded'}
              maxItems={5}
            />

            <div className='d-flex flex-wrap align-items-center gap-3'>
              <div className='d-flex align-items-center gap-3'>
                <span>Desde:</span>
                <DateSelector
                  dateSelected={moment(globalDate.date1, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                  handleDateSelected={handleGlobalDateCustomSelected1}
                />
              </div>

              <div className='d-flex align-items-center gap-3'>
                <span>Hasta:</span>
                <DateSelector
                  dateSelected={moment(globalDate.date2, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                  handleDateSelected={handleGlobalDateCustomSelected2}
                />
              </div>
            </div>
          </div>

          <div className="StatisticsAdminPanel__General-Cards">
            <div className='StatisticsAdminPanel__General-Card-group'>
              <div className="StatisticsAdminPanel__General-Card fw-bold">
                <h3 className="text-2">{amountUsersAndPlans.cant_users}</h3>
                <h4 className="text-3 text-center">Usuarios activos</h4>
              </div>

              <div className="StatisticsAdminPanel__General-Card fw-bold">
                <h3 className="text-2">{userFlowByMonth.users}</h3>
                <h4 className="text-3 text-center">Nuevos Usuarios</h4>
              </div>
            </div>

            <div className='StatisticsAdminPanel__General-Card-group'>
              <div className="StatisticsAdminPanel__General-Card fw-bold">
                <h3 className="text-2">{amountUsersAndPlans.cant_users_plans}</h3>
                <h4 className="text-3 text-center">Planes activos</h4>
              </div>

              <div className="StatisticsAdminPanel__General-Card fw-bold">
                <h3 className="text-2">{userFlowByMonth.user_plans}</h3>
                <h4 className="text-3 text-center">Nuevos Planes</h4>
              </div>
            </div>

            <div className='StatisticsAdminPanel__General-Card-group'>
              <div className="StatisticsAdminPanel__General-Card fw-bold">
                <h3 className="text-2">{companyFlowByMonth.companies}</h3>
                <h4 className="text-3 text-center">Empresas activas</h4>
              </div>

              <div className="StatisticsAdminPanel__General-Card fw-bold">
                <h3 className="text-2">{companyFlowByMonth.new_companies}</h3>
                <h4 className="text-3 text-center">Nuevas empresas</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="StatisticsAdminPanel__Graphics px-3 px-md-5">
          <PlansGraphic plansPopularity={plansPopularity} />

          <MettingRoomsGraphic shiftAndHoursMostRequested={shiftAndHoursMostRequested} />

          <CreditsAndHoursGraphic activeCreditsAndHours={activeCreditsAndHours} />

          <ShiftsAndHoursGraphic shiftAndHoursMostRequested={shiftAndHoursMostRequested} />
        </div>
      </main>
    </>
  )
}

export default StatisticsAdminPanel
