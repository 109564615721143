import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'
import { getPercentage } from '../pages/panel/admin/StatisticsAdminPanel'

ChartJS.register(ArcElement, Tooltip, Legend)

const ShiftsAndHoursGraphic = ({ shiftAndHoursMostRequested }) => {
  const [shiftAnsHours, setShiftAnsHours] = useState({
    shifts: [],
    hours: [],
  })

  useEffect(() => {
    let hoursCount = 0
    shiftAndHoursMostRequested.hours.forEach(
      (hour) => (hoursCount += hour.count)
    )
    const shiftsCount =
      shiftAndHoursMostRequested.shifts.morning_shift +
      shiftAndHoursMostRequested.shifts.afternoon_shift

    const colors = ['#00AF62', '#9A5FE8', '#0BA4C6', '#E6A900']

    const shifts = [
      {
        label: 'Mañana',
        count: shiftAndHoursMostRequested.shifts.morning_shift,
        color: colors[2],
      },
      {
        label: 'Tarde',
        count: shiftAndHoursMostRequested.shifts.afternoon_shift,
        color: colors[3],
      },
    ]

    const hours = shiftAndHoursMostRequested.hours.map((hour) => ({
      label: `${hour.most_solicited_hour}:00 hs`,
      count: hour.count,
      color: colors.shift(),
    }))
    if (shiftAndHoursMostRequested.total_hours - hoursCount !== 0)
      hours.push({
        label: 'Restantes',
        count: shiftAndHoursMostRequested.total_hours - hoursCount,
        color: colors.shift(),
      })

    const emptyInfo = [
      {
        label: 'Sin información',
        color: '#ECECEC',
        count: 1,
      },
    ]

    if (
      shiftsCount === 0 &&
      shiftAndHoursMostRequested.total_hours === 0
    )
      setShiftAnsHours({
        shifts: emptyInfo,
        hours: emptyInfo,
      })
    else if (shiftsCount === 0)
      setShiftAnsHours({
        shifts: emptyInfo,
        hours,
      })
    else if (shiftAndHoursMostRequested.total_hours === 0)
      setShiftAnsHours({
        shifts,
        hours: emptyInfo,
      })
    else
      setShiftAnsHours({
        shifts,
        hours,
      })
  }, [shiftAndHoursMostRequested])

  return (
    <div className="StatisticsAdminPanel__Graphic-Container">
      <h2 className="text-3">Turnos y horas más solicitados</h2>

      <div className="StatisticsAdminPanel__Mini-Graphic-Cards">
        <div className="StatisticsAdminPanel__Mini-Graphic-Card">
          <h3 className="text-4">Espacio compartido</h3>
          <Pie
            style={{
              position: 'relative',
              width: '100%',
              maxWidth: '80vw',
              aspectRatio: 7 / 3,
            }}
            options={{
              responsive: true,
              aspectRatio: 7 / 3,
              plugins: {
                legend: {
                  display: true,
                  position: 'right',
                  labels: {
                    boxWidth: 25,
                    boxHeight: 25,
                    padding: 25,
                    usePointStyle: true,
                    pieceLabel: {
                      mode: 'percentage',
                      render: 'value',
                    },
                  },
                },
                tooltip: {
                  usePointStyle: true,
                  boxPadding: 3,
                  callbacks: {
                    title: (context) => {
                      const hiddenIndices = []

                      for (const index in context[0].chart._hiddenIndices) {
                        if (context[0].chart._hiddenIndices[index])
                          hiddenIndices.push(index)
                      }

                      hiddenIndices.reverse()

                      const data = [...context[0].dataset.data]
                      hiddenIndices.forEach((index) => {
                        data.splice(index, 1)
                      })

                      let totalCount = 0
                      data.forEach((number) => (totalCount += number))

                      const title = `${context[0].formattedValue} turno${
                        context[0].formattedValue !== '1' ? 's' : ''
                      }, ${getPercentage(context[0].parsed, totalCount)}%`
                      return title
                    },
                    label: (context) => context.label,
                  },
                },
              },
            }}
            data={{
              labels: shiftAnsHours.shifts.map((shift) => shift.label),
              datasets: [
                {
                  label: shiftAnsHours.shifts.map((shift) => shift.label),
                  data: shiftAnsHours.shifts.map((shift) => shift.count),
                  backgroundColor: shiftAnsHours.shifts.map(
                    (shift) => shift.color
                  ),
                  hoverOffset: 4,
                },
              ],
            }}
          />
        </div>

        <div className="StatisticsAdminPanel__Mini-Graphic-Card">
          <h3 className="text-4">Sala de reunión</h3>
          <Pie
            style={{
              position: 'relative',
              width: '100%',
              maxWidth: '80vw',
              aspectRatio: 7 / 3,
            }}
            options={{
              responsive: true,
              aspectRatio: 7 / 3,
              plugins: {
                legend: {
                  display: true,
                  position: 'right',
                  labels: {
                    boxWidth: 25,
                    boxHeight: 25,
                    padding: 10,
                    usePointStyle: true,
                    pieceLabel: {
                      mode: 'percentage',
                      render: 'value',
                    },
                  },
                },
                tooltip: {
                  usePointStyle: true,
                  boxPadding: 3,
                  callbacks: {
                    title: (context) => {
                      const hiddenIndices = []

                      for (const index in context[0].chart._hiddenIndices) {
                        if (context[0].chart._hiddenIndices[index])
                          hiddenIndices.push(index)
                      }

                      hiddenIndices.reverse()

                      const data = [...context[0].dataset.data]
                      hiddenIndices.forEach((index) => {
                        data.splice(index, 1)
                      })

                      let totalCount = 0
                      data.forEach((number) => (totalCount += number))

                      const title = `${context[0].formattedValue} hora${
                        context[0].formattedValue !== '1' ? 's' : ''
                      }, ${getPercentage(context[0].parsed, totalCount)}%`
                      return title
                    },
                    label: (context) => context.label,
                  },
                },
              },
            }}
            data={{
              labels: shiftAnsHours.hours.map((hour) => hour.label),
              datasets: [
                {
                  label: shiftAnsHours.hours.map((hour) => hour.label),
                  data: shiftAnsHours.hours.map((hour) => hour.count),
                  backgroundColor: shiftAnsHours.hours.map(
                    (hour) => hour.color
                  ),
                  hoverOffset: 4,
                },
              ],
            }}
          />
        </div>
      </div>
    </div>
  )
}

ShiftsAndHoursGraphic.propTypes = {
  shiftAndHoursMostRequested: PropTypes.object.isRequired,
}

export default ShiftsAndHoursGraphic
