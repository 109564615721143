import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { useContext } from 'react'
import { AppContext } from '../context'
import { findContact } from '../utils/findContact'


const WhatsappCard = () => {
  const { networks } = useContext(AppContext)

  return (
    <a
      href={`https://api.whatsapp.com/send?phone=${
        findContact(networks, 'whatsapp')
      }&text=${
        encodeURIComponent('Hola Vida Cowork 🍃 Visité su página web y quisiera más info de:')
      }`}
      target='_blank'
      rel='noreferrer'
      className='WhatsappCard'
    >
      <span className='WhatsappCard__Icon'><FontAwesomeIcon icon={ faWhatsapp }/></span>
      <div className="d-flex flex-column">
        <p className="text-4 fw-bold text-orange m-0">¡Escribinos!</p>
        <p className="text-5 m-0">El #TeamVida te asesora</p>
      </div>
    </a>
  )
}

export default WhatsappCard