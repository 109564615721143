import { deleteData, getData, postData } from './apiVariables'

export const postCreateOfficeBooking = async (token, body) => {
  return await postData({
    path: '/offices/office-booking/',
    token,
    body,
  })
}

export const delDeleteOfficeBooking = async (token, body) => {
  return await deleteData({
    path: '/offices/office-booking/',
    token,
    body
  })
}

export const getOffices = async (token) => {
  return await getData({
    path: '/offices/',
    token,
    callback: (data) => data.data,
  })
}

export const postAddOffice = async (token, body) => {
  return await postData({
    path: '/offices/',
    token,
    body,
  })
}

export const delDeleteOffice = async (token, officeId) => {
  return await deleteData({
    path: `/offices/${officeId}/`,
    token,
  })
}

export const postChargeHoursToOfficePlan = async (token, body) => {
  return await postData({
    path: '/offices/charge-hours-to-office-plan/',
    token,
    body,
  })
}
