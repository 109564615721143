import { useEffect } from 'react'
import { useLocation, Outlet } from 'react-router'
import Header from '../containers/Header'
import Footer from '../containers/Footer'
import WhatsappCard from '../components/WhatsappCard'
import { Helmet } from 'react-helmet'
import { URL } from '../constants'


const Layout = () => {
  const location = useLocation()


  useEffect(() => {
    if (!location.hash) return window.scrollTo(0, 0)
    const element = document.getElementById(location.hash.slice(1))
    element?.scrollIntoView({ behavior: 'smooth' })
    setTimeout(() => {
      element?.scrollIntoView({ behavior: 'smooth' })
    }, 1000)
  }, [location])


  return (
    <>
      <Helmet>
        <title>Coworking en Córdoba | Vida Cowork</title>
        <meta name="description" content="Espacios compartidos de trabajo y oficinas privadas. Ideal Freelancers, Empresas y Sturtups. Trabajá en un coworking y potenciá el crecimiento personal y profesional." />
        <link rel="preconnect" href={URL} crossOrigin='anonymous'></link>
      </Helmet>
      <Header/>
      <WhatsappCard/>
      <Outlet/>
      <Footer/>
    </>
  )
}

export default Layout