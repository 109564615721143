import { useContext, useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import propTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons'
import { faArrowLeft, faArrowRightFromBracket, faUser } from '@fortawesome/free-solid-svg-icons'
import EditUserModal from '../components/modals/EditUserModal'
import CompanyIcon from '../assets/icons/panel-company.svg'
import GeneralIcon from '../assets/icons/panel-general.svg'
import ReservationsIcon from '../assets/icons/panel-reservations.svg'
import PlansIcon from '../assets/icons/panel-plans.svg'
import { CSSTransition, Transition } from 'react-transition-group'
import { AppContext } from '../context'
import { findContact } from '../utils/findContact'
import Media from '../components/Media'


const PanelUserMenu = ({ showMenu, toggleMenu, setShowLogoutModal }) => {
  const { user, networks } = useContext(AppContext)
  const { pathname } = useLocation()
  const [showEditUserModal, setShowEditUserModal] = useState(false)
  const [showCompanies, setShowCompanies] = useState(false)
  const [hasACompany, setHasACompany] = useState(false)

  const toggleShowEditUserModal = () => {
    setShowEditUserModal((prev) => !prev)
  }

  const toggleShowCompanies = () => {
    if (showMenu) return setShowCompanies((prev) => !prev)

    toggleMenu()
    setShowCompanies(true)
  }

  useEffect(() => {
    setHasACompany(user?.admin_in?.length !== 0 || user?.employee_in?.length !== 0)
  }, [user?.admin_in, user?.employee_in])

  const transitionStyles = {
    entering: {
      opacity: 1,
      maxHeight: `${(user?.admin_in?.length + user?.employee_in?.length) * 44}px`,
      transition: 'opacity 200ms 100ms, max-height 200ms',
    },
    entered: {
      opacity: 1,
      maxHeight: `${(user?.admin_in?.length + user?.employee_in?.length) * 44}px`,
    },
    exiting: {
      opacity: 0,
      maxHeight: 0,
      transition: 'opacity 200ms, max-height 200ms',
    },
    exited: {
      opacity: 0,
      maxHeight: 0,
    },
  }

  return (
    <>
      <CSSTransition in={showMenu} timeout={300} className="PanelMenu" appear>
        <nav>
          <button className="PanelMenu__Button" onClick={toggleMenu}>
            <CSSTransition
              in={showMenu}
              timeout={300}
              className="PanelMenu__Button-Icon"
              appear
            >
              <span>
                <FontAwesomeIcon icon={faArrowLeft} />
              </span>
            </CSSTransition>
          </button>

          <CSSTransition
            in={showMenu}
            timeout={300}
            className="PanelMenu__Content pt-4"
            appear
          >
            <div>
              <div className="PanelMenu__User">
                <div className="d-flex">
                  <div className="PanelMenu__User-Image-Container">
                    {user?.profile?.image && (
                      <Media
                        type="image"
                        className="PanelMenu__User-Image"
                        src={user?.profile?.image ?? ''}
                        alt="imagen de usuario"
                      />
                    )}
                    <FontAwesomeIcon icon={faUser} />
                  </div>
                  <div className="PanelMenu__User-Info d-flex flex-column align-items-start">
                    <h4 className="PanelMenu__User-Name">
                      {user?.data?.first_name ?? 'Nombre'}
                    </h4>
                    <h4 className="PanelMenu__User-Name">
                      {user?.data?.last_name ?? 'Apellido'}
                    </h4>
                    <span className="PanelMenu__User-Username">Usuario</span>
                  </div>
                </div>
                <button
                  className="PanelMenu__User-Edit"
                  onClick={toggleShowEditUserModal}
                >
                  <FontAwesomeIcon icon={faPenToSquare} />
                </button>
              </div>

              {hasACompany &&
                (user?.admin_in?.length + user?.employee_in?.length === 1 ? (
                  <div className="PanelMenu__Company">
                    <NavLink
                      to={`/panel/empresa/${encodeURIComponent(
                        (
                          user.admin_in[0] ?? user.employee_in[0]
                        ).name.toLowerCase()
                      )}`}
                      className={({ isActive }) =>
                        isActive ? 'PanelMenu__Link--Active' : 'PanelMenu__Link'
                      }
                    >
                      <img
                        className="PanelMenu__Link-Icon"
                        src={CompanyIcon}
                        alt="Empresa"
                        title="Empresa"
                      />
                      <span className="PanelMenu__Link-text">Empresa</span>
                    </NavLink>
                  </div>
                ) : (
                  <div className="PanelMenu__Company">
                    <div
                      className={`PanelMenu__Link${
                        pathname.startsWith('/panel/empresa/') ? '--Active' : ''
                      }`}
                      onClick={toggleShowCompanies}
                    >
                      <img
                        className="PanelMenu__Link-Icon"
                        src={CompanyIcon}
                        alt="Empresa"
                        title="Empresa"
                      />
                      <span className="PanelMenu__Link-text">Empresas</span>
                    </div>
                    <Transition
                      in={showMenu && showCompanies}
                      timeout={300}
                      unmountOnExit
                      appear
                    >
                      {(state) => (
                        <div
                          className="PanelMenu__Sublinks"
                          style={{ ...transitionStyles[state] }}
                        >
                          {user?.admin_in?.length + user?.employee_in?.length >
                            1 &&
                            [...user.admin_in, ...user.employee_in].map(
                              (company) => (
                                <NavLink
                                  key={company.id}
                                  to={`/panel/empresa/${encodeURIComponent(
                                    company.name.toLowerCase()
                                  )}`}
                                  className={({ isActive }) =>
                                    `PanelMenu__Sublink${
                                      isActive ? '--Active' : ''
                                    }`
                                  }
                                >
                                  <span className="PanelMenu__Sublink-first-letter">
                                    {company.name[0]}
                                  </span>
                                  <span className="PanelMenu__Sublink-text">
                                    {company.name.slice(1)}
                                  </span>
                                </NavLink>
                              )
                            )}
                        </div>
                      )}
                    </Transition>
                  </div>
                ))}

              <div
                className={`PanelMenu__Links${!hasACompany ? '--Default' : ''}`}
              >
                {!hasACompany && (
                  <NavLink
                    to="/panel/"
                    className={({ isActive }) =>
                      isActive ? 'PanelMenu__Link--Active' : 'PanelMenu__Link'
                    }
                  >
                    <img
                      className="PanelMenu__Link-Icon"
                      src={GeneralIcon}
                      alt={hasACompany ? 'Personal' : 'General'}
                      title={hasACompany ? 'Personal' : 'General'}
                    />
                    <span className="PanelMenu__Link-text">
                      {hasACompany ? 'Personal' : 'General'}
                    </span>
                  </NavLink>
                ) }
                <NavLink
                  to="/panel/reservas"
                  className={({ isActive }) =>
                    isActive ? 'PanelMenu__Link--Active' : 'PanelMenu__Link'
                  }
                >
                  <img
                    className="PanelMenu__Link-Icon"
                    src={ReservationsIcon}
                    alt="Reservas"
                    title="Reservas"
                  />
                  <span className="PanelMenu__Link-text">Reservas</span>
                </NavLink>
                {!hasACompany && (
                  <NavLink
                    to="/panel/planes"
                    className={({ isActive }) =>
                      isActive ? 'PanelMenu__Link--Active' : 'PanelMenu__Link'
                    }
                  >
                    <img
                      className="PanelMenu__Link-Icon"
                      src={PlansIcon}
                      alt="Planes"
                      title="Planes"
                    />
                    <span className="PanelMenu__Link-text">Planes</span>
                  </NavLink>
                )}
              </div>

              <a
                href={`https://api.whatsapp.com/send?phone=${findContact(networks, 'whatsapp')}`}
                target="_blank"
                rel="noreferrer"
                className="PanelMenu__Whatsapp"
              >
                <FontAwesomeIcon
                  className="PanelMenu__Whatsapp-Icon"
                  icon={faWhatsapp}
                />
                <span className="PanelMenu__Whatsapp-text">
                  ¡Necesito ayuda!
                </span>
              </a>

              <button
                className="button-white-green d-flex d-md-none text-1 py-3 mt-auto"
                onClick={() => setShowLogoutModal(true)}
              >
                <FontAwesomeIcon icon={faArrowRightFromBracket} />
                <span className='text-4 ms-4'>Cerrar sesión</span>
              </button>

              <div className="PanelMenu__Linkchar text-center">
                Powered By
                <a
                  className="PanelMenu__Linkchar-Link fw-bold ms-1"
                  href="https://linkchar.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  LinkChar
                </a>
              </div>
            </div>
          </CSSTransition>
        </nav>
      </CSSTransition>

      {showEditUserModal && (
        <EditUserModal closeModal={toggleShowEditUserModal} />
      )}
    </>
  )
}

PanelUserMenu.propTypes = {
  showMenu: propTypes.bool.isRequired,
  toggleMenu: propTypes.func.isRequired,
  setShowLogoutModal: propTypes.func.isRequired,
}

export default PanelUserMenu
