import { getBenefits } from '../api/community'
import BenefitCard from '../components/BenefitCard'
import useApi from '../hooks/useApi'

const Benefits = () => {
  const [benefits] = useApi(getBenefits, [])

  return (
    <div className="Benefits container-custom py-4 py-lg-5">
      {benefits.map(benefit => (
        <BenefitCard key={benefit.id} {...benefit}/>
      ))}
    </div>
  )
}

export default Benefits