import { Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react'
import propTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBriefcase, faCaretLeft, faCaretRight, faChevronLeft, faCircleExclamation, faTimes, faUser, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import { getUserHoursAndCreditsAtDate } from '../api/users'
import { createMeetingRoomBook, getMeetingRoomsFreeRanges } from '../api/meet-rooms'
import { dayNames } from '../utils/CalendarDataTime'
import FinishModal from './modals/FinishModal'
import ErrorModal from './modals/ErrorModal'
import { AppContext } from '../context'
import Input from './Input'
import Filter from './Filter'
import Steps from './Steps'
import Loader from './Loader'
import { getCompanyDetail, getCompanyEmployees, getCompanyHoursAndCreditsAtDate } from '../api/companies'
import chroma from 'chroma-js'


const RoomsUserReservationModal = ({ days, hours, initialData, refreshData, changeMonth, closeModal, serverTime }) => {
  const { token, user, updateUser, rooms } = useContext(AppContext)
  const [ step, setStep ] = useState(1)
  const [ loading, setLoading ] = useState(true)
  const [ reservation, setReservation ] = useState({
    room_id: initialData.room_id,
    room_name: initialData.room_name,
    user_id: user.data.id,
    user_type: 'user',
    company_id: '',
    start_date: '',
    start_hour: initialData.start_hour,
    end_date: '',
    end_hour: initialData.end_hour,
    first_name: user.data.first_name,
    last_name: user.data.last_name,
  })
  const [ dateState, setDateState ] = useState({
    day: initialData.dayWeekName,
    dayNumber: initialData.dayNumber,
    month: initialData.month,
    year: initialData.year
  })
  const [ quantity, setQuantity ] = useState(1)
  const [ employees, setEmployees ] = useState([])
  const [ currentDay, setCurrentDay ] = useState([])
  const [ hoursAtDate, setHoursAtDate ] = useState('')
  const [ hoursAvailable, setHoursAvailable ] = useState({
    companies: {},
    user: 0
  })
  const [ roomsWithSchedule, setRoomsWithSchedule ] = useState([])
  const [ limitHours, setLimitHours ] = useState({
    startHours: [],
    endHours: []
  })
  const [ totalHours, setTotalHours ] = useState(0)

  const roomsContainerRef = useRef(null)
  const roomRef = useRef(null)

  const modalDate = `${dateState.day} ${dateState.dayNumber < 10 ? `0${dateState.dayNumber}` : dateState.dayNumber}/${dateState.month + 1 < 10 ? `0${dateState.month + 1}` : dateState.month + 1}`
  const requestDate = `${dateState.year}-${dateState.month + 1 < 10 ? `0${dateState.month + 1}` : dateState.month + 1}-${dateState.dayNumber < 10 ? `0${dateState.dayNumber}` : dateState.dayNumber}`

  const roomsFilter = ['todas las salas', 'sala bosque', 'sala río', 'sala cosmos', 'sala cielo']

  // Get data and hours available at date
  useEffect(() => {
    const getData = async () => {
      // Find user reservations for the current day
      const currentDayData = days.find(day => {
        if(day.dayWeekName == dateState.day && day.dayNumber == dateState.dayNumber) {
          // const existUserReservation = day.data.find(reservation => reservation.is_owner)
          return day
        }
      })

      if(currentDayData && user) {
        setCurrentDay(currentDayData == undefined ? currentDay : currentDayData)

        // Get user credits for the current day
        if(user.admin_in?.length !== 0) {
          const { total_hours_to_assign } = await getCompanyDetail(token, user.admin_in[0].id, {
            date: requestDate
          })
          if(reservation.user_type == 'company')
            setHoursAtDate(total_hours_to_assign)
        } else if(user.employee_in?.length !== 0) {
          const { available_hours } = await getCompanyHoursAndCreditsAtDate(token, {
            company_id: user.employee_in[0].id,
            date: requestDate
          })

          setHoursAtDate(available_hours)
        } else {
          const { available_hours } = await getUserHoursAndCreditsAtDate(token, {
            date: requestDate
          })

          setHoursAtDate(available_hours)
        }

        // Set rooms schedule
        if(!currentDayData.isPastDay && !currentDayData.isLimitDay) {
          const response = await getMeetingRoomsFreeRanges(token, {
            date: requestDate
          })

          if(response) {
            const newRoomsData = rooms.map(room => {
              const date = `${requestDate}T00:00:00`

              const scheduleHours = response[room.id]?.map(range => [range.start_time, range.end_time])
              const scheduleTimes = response[room.id]?.map(range => {
                const startDate = new Date(date)
                startDate.setHours(range.start_time.split(':')[0], range.start_time.split(':')[1], 0, 0)
                const startTime = startDate.getTime()

                const endDate = new Date(date)
                endDate.setHours(range.end_time.split(':')[0], range.end_time.split(':')[1], 0, 0)
                const endTime = endDate.getTime()

                return [startTime, endTime]
              })

              return ({
                ...room,
                scheduleHours,
                scheduleTimes
              })
            })
            setRoomsWithSchedule(newRoomsData)
          }
        }
      }
    }

    getData()
  }, [days, dateState, user])


  // Set start hours
  useEffect(() => {
    const currentRoom = roomsWithSchedule.find(item => item.id == reservation.room_id)
    const scheduleTimes = currentRoom?.scheduleTimes || []
    const startHours = []

    const date = new Date(serverTime)
    const currentTime = date.getTime()
    const timeLimit = 1200000 // 20 minutes

    if(scheduleTimes) {
      for (let index = 0; index < hours.length; index++) {
        const hour = hours[index]
        const date = new Date(reservation.start_date)
        date.setHours(hour.split(':')[0], hour.split(':')[1], 0, 0)
        const hourTime = date.getTime()

        let hoursIsAvailable = false

        for (let index = 0; index < scheduleTimes.length; index++) {
          const startLimit = scheduleTimes[index][0]
          const endLimit = scheduleTimes[index][1]

          // if(index == scheduleTimes.length - 1) {
          //   console.log(startLimit, endLimit)
          //   console.log(hourTime, currentTime)

          //   console.log(hourTime > currentTime)
          //   console.log(hourTime >= startLimit)
          //   console.log(hourTime < endLimit)
          // }

          if(hourTime > (currentTime - timeLimit) && hourTime >= startLimit && hourTime < endLimit) {
            hoursIsAvailable = true
          }
        }

        if(hoursIsAvailable) {
          startHours.push(hours[index])
        }
      }

      setLimitHours({
        startHours,
        endHours: []
      })
    }
  }, [reservation.room_id, roomsWithSchedule])

  // Set hours available for the current day
  useEffect(() => {
    if(reservation.company_id) {
      if(reservation.user_type == 'company') {
        setHoursAtDate(hoursAvailable[reservation.company_id])
      } else {
        setHoursAtDate(hoursAvailable[`user-${reservation.company_id}`])
      }
    } else {
      setHoursAtDate(hoursAvailable.user)
    }
  }, [reservation.company_id, reservation.user_type, hoursAvailable])

  // Set end hours
  useEffect(() => {
    const currentRoom = roomsWithSchedule.find(item => item.id == reservation.room_id)
    const scheduleTimes = currentRoom?.scheduleTimes || []

    if(reservation.start_hour && limitHours.startHours.length > 0) {
      const endHours = []

      const startTime = new Date(reservation.start_date).getTime()

      // Get reservation free range for the selected start hour
      const reservationFreeRange = scheduleTimes.find((item) => {
        const startLimit = item[0]
        const endLimit = item[1]

        if(startLimit >= startLimit && startTime <= endLimit) {
          return item
        }
      })

      // Set end hours by reservation free range
      for (let index = 0; index < hours.length; index++) {
        const hour = hours[index]
        const date = new Date(reservation.start_date)
        date.setHours(hour.split(':')[0], hour.split(':')[1], 0, 0)
        const hourTime = date.getTime()

        let hoursIsAvailable = false

        const startLimit = reservationFreeRange[0]
        const endLimit = reservationFreeRange[1]
        const startReservationTime = date.getTime()

        // check if hour is available
        if(startReservationTime > startTime && hourTime > startLimit && hourTime <= endLimit && endHours.length < 8) {
          hoursIsAvailable = true
        }

        if(hoursIsAvailable) {
          endHours.push(hours[index])
        }
      }

      setLimitHours({
        startHours: limitHours.startHours,
        endHours
      })
    }
  }, [reservation.start_hour, limitHours.startHours])

  // Reset hours when change room
  useEffect(() => {
    setReservation(prev => ({
      ...prev,
      start_hour: '',
      end_hour: ''
    }))
  }, [reservation.room_id])

  // Check hours difference and set limit hours
  useEffect(() => {
    const checkHoursAndSetLimit = async () => {
      const start = new Date(reservation.start_date).getTime()
      const end = new Date(reservation.end_date).getTime()
      const diff = (end - start) / 1000 / 60 / 60

      setTotalHours(diff)
    }

    checkHoursAndSetLimit()
  }, [reservation.end_date])

  // Scroll to selected room
  useEffect(() => {
    const roomsContainer = roomsContainerRef.current
    const room = roomRef.current

    if(roomsContainer && room && !user.admin_in.length > 0) {
      const optionOffsetTop = room.offsetTop
      const containerOffsetTop = roomsContainer.offsetTop
      const offsetTop = optionOffsetTop - containerOffsetTop

      roomsContainer.scroll(0, offsetTop)
    }
  }, [roomsContainerRef.current, roomRef.current])

  // Set initial date
  useEffect(() => {
    setReservation(prev => ({
      ...prev,
      // room_id: initialData.room_id,
      start_date: `${dateState.year}-${dateState.month + 1 < 10 ? `0${dateState.month + 1}` : dateState.month + 1}-${dateState.dayNumber < 10 ? `0${dateState.dayNumber}` : dateState.dayNumber} ${initialData.start_hour || '00'}:00`,
      start_hour: initialData.start_hour,
      end_date: `${dateState.year}-${dateState.month + 1 < 10 ? `0${dateState.month + 1}` : dateState.month + 1}-${dateState.dayNumber < 10 ? `0${dateState.dayNumber}` : dateState.dayNumber} ${initialData.end_hour}:00`,
      end_hour: initialData.end_hour
    }))
  }, [initialData])

  // Get user and company hours for the current day
  useEffect(() => {
    const getData = async () => {
      const hours = {}
      const employees = {}
      const promises = []
      let available_hours = 0

      // Admin Company: Get company employees and hours for the current day
      if(user.admin_in?.length !== 0) {
        user.admin_in?.forEach((company) => {
          const newPromise = async () => {
            const response = await getCompanyEmployees(token, { company_id: company.id })
            const companyEmployees = response.map(item => item.user)
            companyEmployees.unshift({
              id: user.data.id,
              first_name: user.data.first_name,
              last_name: user.data.last_name,
              email: 'Tu',
            })

            // Get company hours for the current day
            const { total_hours_to_assign } = await getCompanyDetail(token, company.id, { date: requestDate })

            hours[company.id] = total_hours_to_assign
            employees[company.id] = companyEmployees
          }

          promises.push(newPromise())
        })
      }

      // Employee Company: Get company hours for the current day
      if(user.admin_in?.length !== 0 || user.employee_in?.length !== 0) {
        user.employee_in?.forEach(company => {
          const newPromise = async () => {
            const { available_hours } = await getCompanyHoursAndCreditsAtDate(token, {
              company_id: company.id,
              date: requestDate
            })
            hours[`user-${company.id}`] = available_hours
          }

          promises.push(newPromise())
        })
        user.admin_in?.forEach(company => {
          const newPromise = async () => {
            const { available_hours } = await getCompanyHoursAndCreditsAtDate(token, {
              company_id: company.id,
              date: requestDate
            })
            hours[`user-${company.id}`] = available_hours
          }

          promises.push(newPromise())
        })
      }
      // Get user hours for the current day
      else {
        const response = await getUserHoursAndCreditsAtDate(token, {
          date: requestDate
        })
        available_hours = response.available_hours
      }

      await Promise.all(promises)

      const firstAdminCompanyWithHours = user.admin_in.find(company => hours[company.id] > 0)
      const firstAdminInvididualCompanyWithHours = user.admin_in.find(company => hours[`user-${company.id}`] > 0)
      const firstEmployeeCompanyWithHours = user.employee_in.find(company => hours[`user-${company.id}`] > 0)

      // if(!available_hours && !reservation.company_id) {
      //   setReservation({
      //     ...reservation,
      //     user_type: 'company',
      //     user_id: '',
      //     company_id: user.admin_in[0]?.id,
      //   })
      // } else if(!reservation.company_id) {
      //   setReservation({
      //     ...reservation,
      //     user_type: 'user',
      //     user_id: user.data.id,
      //     company_id: '',
      //   })
      // }
      if(!available_hours) {
        if(reservation.user_type !== 'company' && reservation.user_type !== 'user-company') {
          setReservation(prev => ({
            ...prev,
            user_type: firstAdminCompanyWithHours ? 'company' : 'user-company',
            company_id: firstAdminCompanyWithHours?.id || (firstAdminInvididualCompanyWithHours?.id || firstEmployeeCompanyWithHours?.id)
          }))
        }
      } else {
        setReservation(prev => ({
          ...prev,
          user_type: 'user',
          company_id: null,
        }))
      }

      setHoursAvailable({
        ...hours,
        user: available_hours
      })
      setEmployees({
        ...employees,
      })

      setLoading(false)
    }

    if(token && reservation.start_date) getData()
  }, [token, dateState, reservation.start_date])


  const changeDate = (direction) => {
    setLoading(true)
    const daysInMonth = new Date(dateState.year, dateState.month + 1, 0).getDate()

    let day
    let month
    let year
    let dayName

    if(direction == 'next') {
      let step = 1
      if(dateState.day == 'Viernes') {
        step = 3
      }

      day = dateState.dayNumber + step
      month = dateState.month
      year = dateState.year

      if(day > daysInMonth) {
        day = day - daysInMonth
        month = month + 1
        changeMonth(direction)
      }

      if(month > 11) {
        month = 0
        year = year + 1
      }

      const date = new Date(year, month, day)
      dayName = date.toString().slice(0, 3)

      setDateState({
        day: dayNames[dayName],
        dayNumber: day,
        month: month,
        year: year
      })
    } else {
      let step = 1
      if(dateState.day == 'Lunes') {
        step = 3
      }

      day = dateState.dayNumber - step
      month = dateState.month
      year = dateState.year

      if(day < 1 && dateState.day == 'Lunes') {
        day = new Date(year, month, day).getDate()
        month = month - 1
        changeMonth(direction)
      } else if(day < 1) {
        day = new Date(year, month, 0).getDate()
        month = month - 1
        changeMonth(direction)
      }

      if(month < 0) {
        month = 11
        year = year - 1
      }

      const date = new Date(year, month, day)
      dayName = date.toString().slice(0, 3)

      // Reset reservation hours when change date
      setDateState({
        day: dayNames[dayName],
        dayNumber: day,
        month: month,
        year: year
      })
    }

    setReservation(prev => ({
      ...prev,
      start_date: `${year}-${month + 1 < 10 ? `0${month + 1}` : month + 1}-${day < 10 ? `0${day}` : day} 00:00`,
      start_hour: '',
      end_date: `${year}-${month + 1 < 10 ? `0${month + 1}` : month + 1}-${day < 10 ? `0${day}` : day} 00:00`,
      end_hour: ''
    }))
  }

  const handleSelectCompany = (type, id) => {
    setReservation(prev => ({
      ...prev,
      user_type: type,
      company_id: id,
    }))
  }

  const handleUserType = () => {
    setReservation(prev => ({
      ...prev,
      user_type: 'user',
      first_name: user.data.first_name,
      last_name: user.data.last_name,
      company_id: '',
      users_id: user.data.id
    }))
    setQuantity(1)
  }

  const handleEmployeeSelector = (id) => {
    setReservation(prev => ({
      ...prev,
      user_id: id || prev.user_id,
      first_name: '',
      last_name: '',
    }))
  }

  const handleRoomOption = (value) => {
    const room_name = roomsWithSchedule.find(item => item.id == value)?.name

    setReservation(prev => ({
      ...prev,
      room_id: value,
      room_name: room_name
    }))
  }

  const handleHourStart = (name, start) => {
    setReservation(prev => ({
      ...prev,
      start_date: `${dateState.year}-${dateState.month + 1 < 10 ? `0${dateState.month + 1}` : dateState.month + 1}-${dateState.dayNumber < 10 ? `0${dateState.dayNumber}` : dateState.dayNumber} ${start}:00`,
      start_hour: start,
      end_date: '',
      end_hour: '',
    }))
  }

  const handleHourEnd = (name, end) => {
    setReservation(prev => ({
      ...prev,
      end_date: `${dateState.year}-${dateState.month + 1 < 10 ? `0${dateState.month + 1}` : dateState.month + 1}-${dateState.dayNumber < 10 ? `0${dateState.dayNumber}` : dateState.dayNumber} ${end}:00`,
      end_hour: end
    }))
  }

  const scrollToBottom = () => {
    if(roomsContainerRef.current) {
      setTimeout(() => {
        roomsContainerRef.current?.scroll(0, roomsContainerRef.current.scrollHeight)
      }, [100])
    }
  }

  const createReservation = async (e) => {
    e.target.disabled = true

    const data = await createMeetingRoomBook(token, reservation)

    if(!data.error) {
      setStep(step + 1)
      updateUser()
      refreshData()
    } else {
      setStep(4)
    }
  }

  const renderAdminCompanyOptions = (option, index) => {
    const hasGeneralCompanyHours = hoursAvailable[option.id] > 0
    const hasInvidivualCompanyHours = hoursAvailable[`user-${option.id}`] > 0

    if(option.id === reservation.company_id) return (
      <>
        {reservation.user_type == 'company' && (
          <>
            {hasGeneralCompanyHours && (
              <button
                className="SpacesReservationModal__Option--Active"
                key={`Spaces-Reservation-Modal-Option-${option.id}-${index}`}
                disabled
              >
                <div className="SpacesReservationModal__Option-Circle--Active"></div>
                <span className='text-4'>
                  <FontAwesomeIcon icon={faBriefcase} className='me-2'/>
                  {option.name}
                </span>
              </button>
            )}
            {hasInvidivualCompanyHours && (
              <button
                className="SpacesReservationModal__Option"
                key={`Spaces-Reservation-Modal-Option-User-${option.id}-${index}`}
                onClick={() => handleSelectCompany('user-company', option.id)}
              >
                <div className="SpacesReservationModal__Option-Circle"></div>
                <span className='text-4'>
                  <FontAwesomeIcon icon={faBriefcase} className='me-2'/>
                  {option.name} (Tu plan asignado)
                </span>
              </button>
            )}
          </>
        )}
        {reservation.user_type == 'user-company' && (
          <>
            {hasGeneralCompanyHours && (
              <button
                className="SpacesReservationModal__Option"
                key={`Spaces-Reservation-Modal-Option-${option.id}-${index}`}
                onClick={() => handleSelectCompany('company', option.id)}
              >
                <div className="SpacesReservationModal__Option-Circle"></div>
                <span className='text-4'>
                  <FontAwesomeIcon icon={faBriefcase} className='me-2'/>
                  {option.name}
                </span>
              </button>
            )}
            {hasInvidivualCompanyHours && (
              <button
                className="SpacesReservationModal__Option--Active"
                key={`Spaces-Reservation-Modal-Option-User-${option.id}-${index}`}
                disabled
              >
                <div className="SpacesReservationModal__Option-Circle--Active"></div>
                <span className='text-4'>
                  <FontAwesomeIcon icon={faBriefcase} className='me-2'/>
                  {option.name} (Tu plan asignado)
                </span>
              </button>
            )}
          </>
        )}
      </>
    )
    else return (
      <Fragment key={`RoomsReservationModal__Admin-Option-${index}`}>
        {hasGeneralCompanyHours && (
          <button
            className="SpacesReservationModal__Option"
            key={`Spaces-Reservation-Modal-Option-${option.id}-${index}`}
            onClick={() => handleSelectCompany('company', option.id)}
          >
            <div className="SpacesReservationModal__Option-Circle"></div>
            <span className='text-4'>
              <FontAwesomeIcon icon={faBriefcase} className='me-2'/>
              {option.name}
            </span>
          </button>
        )}
        {hasInvidivualCompanyHours && (
          <button
            className="SpacesReservationModal__Option"
            key={`Spaces-Reservation-Modal-Option-User-${option.id}-${index}`}
            onClick={() => handleSelectCompany('user-company', option.id)}
          >
            <div className="SpacesReservationModal__Option-Circle"></div>
            <span className='text-4'>
              <FontAwesomeIcon icon={faBriefcase} className='me-2'/>
              {option.name} (Individual)
            </span>
          </button>
        )}
      </Fragment>
    )
  }

  const renderEmployeeCompanyOptions = (option, index) => {
    if(option.id === reservation.company_id) return (
      <button
        className="SpacesReservationModal__Option--Active"
        key={`Spaces-Reservation-Modal-Option-${option.id}-${index}`}
        disabled
      >
        <div className="SpacesReservationModal__Option-Circle--Active"></div>
        <span className='text-4'>
          <FontAwesomeIcon icon={faBriefcase} className='me-2'/>
          {option.name}
        </span>
      </button>
    )
    else return (
      <button
        className="SpacesReservationModal__Option"
        key={`Spaces-Reservation-Modal-Option-${option.id}-${index}`}
        onClick={() => handleSelectCompany(option.id)}
      >
        <div className="SpacesReservationModal__Option-Circle"></div>
        <span className='text-4'>
          <FontAwesomeIcon icon={faBriefcase} className='me-2'/>
          {option.name}
        </span>
      </button>
    )
  }


  const hasHours = useMemo(() => {
    return Object.values(hoursAvailable).some(credits => credits > 0)
  }, [hoursAvailable])

  const hasCompaniesWithHours = useMemo(() => {
    const companiesHours = {
      ...hoursAvailable
    }
    delete companiesHours.user

    return Object.values(companiesHours).some(company => company > 0)
  }, [hoursAvailable])


  if(loading) return (
    <div className="PanelModal px-3">
      <div className="PanelModal__Card">
        <header>
          <button className='PanelModal__Button-Close' onClick={closeModal}>
            <FontAwesomeIcon icon={faXmark}/>
          </button>
          <h2 className="PanelModal__Title text-1 text-center">Reserva tu sala de reunión</h2>
        </header>

        <div className='PanelModal__Date-Selector PanelModal__Confirm'>
          <button className='PanelModal__Button-Arrow text-1' onClick={() => changeDate('prev')}>
            <FontAwesomeIcon icon={faCaretLeft}/>
          </button>
          <h3 className="mx-2 text-1">{ modalDate }</h3>
          <button className='PanelModal__Button-Arrow text-1' onClick={() => changeDate('next')}>
            <FontAwesomeIcon icon={faCaretRight}/>
          </button>
        </div>

        <div className="title text-center py-5">
          <Loader/>
        </div>
      </div>
    </div>
  )

  if(step === 1) return (
    <div className="PanelModal px-3">
      <div className="PanelModal__Card">
        <header>
          <button className='PanelModal__Button-Close' onClick={closeModal}>
            <FontAwesomeIcon icon={faXmark}/>
          </button>
          <h2 className="PanelModal__Title text-1 text-center">Reserva de sala de reunión</h2>
        </header>

        <div className='PanelModal__Date-Selector PanelModal__Confirm'>
          <button className='PanelModal__Button-Arrow text-1' onClick={() => changeDate('prev')}>
            <FontAwesomeIcon icon={faCaretLeft}/>
          </button>
          <h3 className="mx-2 text-1">{ modalDate }</h3>
          <button className='PanelModal__Button-Arrow text-1' onClick={() => changeDate('next')}>
            <FontAwesomeIcon icon={faCaretRight}/>
          </button>
        </div>

        <div className="PanelModal__Confirm py-2">
          {hoursAtDate > 0 && hoursAtDate == 1
            ? <div className="PanelModal__Credits-Detail text-5 fw-bold">
              {hoursAtDate} hora disponible
            </div>
            : <div className="PanelModal__Credits-Detail text-5 fw-bold">
              {hoursAtDate || 0} horas disponibles
            </div>
          }
        </div>

        <div className="d-inline d-none mx-auto">
          <Filter data={roomsFilter} optionSelected={roomsFilter[0]} type='salas'/>
        </div>

        {currentDay?.isPastDay ? (
          <div className="PanelModal__Alert title text-center">
            <FontAwesomeIcon icon={faXmarkCircle}/>
            <span className="text-2 mt-2">No podés reservar una sala de reunión para una fecha en el pasado</span>
          </div>
        ) : currentDay?.isDisabled ? (
          <div className="PanelModal__Alert title text-center">
            <FontAwesomeIcon icon={faXmarkCircle}/>
            <span className="text-2 mt-2">Esta fecha está inhabilitada para realizar reservas</span>
          </div>
        ) : currentDay?.isLimitDay ? (
          <div className="PanelModal__Alert title text-center">
            <FontAwesomeIcon icon={faXmarkCircle}/>
            <span className="text-2 mt-2">No podés reservar una sala de reunión 30 días después de la fecha actual</span>
          </div>
        ) : (
          <>
            {hasHours
              ? (
                <>
                  {hasCompaniesWithHours
                    ? (
                      <>
                        <div className="PanelModal__Scroll-Content--Small pt-3" ref={roomsContainerRef}>
                          <h4 className='text-3'>¿Con las horas de que empresa vas a reservar?</h4>

                          {/* User Types */}
                          <div className="PanelModal__Options-Container PanelModal__Confirm">
                            {user?.admin_in?.length > 0 && user?.admin_in?.map((option, index) => renderAdminCompanyOptions(option, index))}
                            {user?.employee_in?.length > 0 && user?.employee_in?.map((option, index) => renderEmployeeCompanyOptions(option, index))}

                            {hoursAvailable.user > 0 && (
                              <button
                                className={
                                  reservation.user_type == 'user'
                                    ? 'SpacesReservationModal__Option--Active'
                                    : 'SpacesReservationModal__Option'
                                }
                                onClick={reservation.user_type != 'user' ? handleUserType : undefined}
                                disabled={reservation.user_type == 'user'}
                              >
                                <div className={
                                  reservation.user_type == 'user'
                                    ? 'SpacesReservationModal__Option-Circle--Active'
                                    : 'SpacesReservationModal__Option-Circle'
                                }
                                ></div>
                                <span className='text-4'>
                                  <FontAwesomeIcon icon={faUser} className='me-2'/>
                                  Para mi
                                </span>
                              </button>
                            )}
                          </div>

                          {reservation.user_type == 'company' && (
                            <>
                              {employees[reservation.company_id]?.length > 0
                                ? <div className="PanelModal__Confirm">
                                  <Input
                                    label='Empleado de referencia'
                                    name='user'
                                    placeholder='Empleado referente para la reserva'
                                    search={employees[reservation.company_id]}
                                    value={reservation.date}
                                    onChange={handleEmployeeSelector}
                                    icon={faUser}
                                  />
                                </div>
                                : <div className="pt-3">
                                  <Input
                                    label='Nombre del usuario'
                                    name='user'
                                    value='Empresa sin empleados'
                                    icon={faUser}
                                    disabled={true}
                                  />
                                </div>
                              }
                            </>
                          )}

                          <h4 className='w-100 text-3 py-3 text-start'>Elegí tu sala:</h4>

                          <div className="RoomsReservationModal__Options-Container">
                            {roomsWithSchedule?.length > 0
                              ? roomsWithSchedule.map((room, index) => {
                                if (room?.scheduleHours?.length == 0) return (
                                  <button
                                    key={`Spaces-Reservation-Modal-Option--Disabled-${room.name}-${index}`}
                                    className='RoomsReservationModal__Option--Disabled'
                                  >
                                    <div className="RoomsReservationModal__Option-Xmark">
                                      <FontAwesomeIcon icon={faTimes}/>
                                    </div>
                                    <span className='text-4'>
                                      Sala {room.name} - Capacidad: {room.capacity}<br/>
                                      <div className='text-5 d-flex flex-column align-items-start'>
                                        No hay horas disponibles
                                      </div>
                                    </span>
                                  </button>
                                )

                                if(room.id === reservation.room_id) return (
                                  <button
                                    key={`Spaces-Reservation-Modal-Option--Active-${room.name}-${index}`}
                                    className={'RoomsReservationModal__Option--Active'}
                                    style={{
                                      backgroundColor: room.color,
                                      borderLeftColor: chroma(room.color).darken(1).hex(),
                                    }}
                                    ref={roomRef}
                                  >
                                    <div className="RoomsReservationModal__Option-Circle--Active"></div>
                                    <span className='text-4'>
                                      Sala {room.name} - Capacidad: {room.capacity}<br/>
                                      <div className='text-5 d-flex flex-column align-items-start'>
                                        Libre de:<br/>
                                        {room?.scheduleHours?.map((time, index) => (
                                          <span key={`Rooms-Reservation-Modal-Option-Active-${index}`}>{time[0]} - {time[1]}hs</span>
                                        ))}
                                      </div>
                                    </span>
                                  </button>
                                )

                                return (
                                  <button
                                    key={`Spaces-Reservation-Modal-Option-${room.name}-${index}`}
                                    className='RoomsReservationModal__Option'
                                    style={{
                                      backgroundColor: chroma(room.color).luminance(0.8).hex()
                                    }}
                                    onClick={() => handleRoomOption(room.id)}
                                  >
                                    <div className="RoomsReservationModal__Option-Circle"></div>
                                    <span className='text-4'>
                                      Sala {room.name} - Capacidad: {room.capacity}<br/>
                                      <div className='text-5 d-flex flex-column align-items-start'>
                                        Libre de:<br/>
                                        {room?.scheduleHours?.map((time, index) => (
                                          <span key={`Rooms-Reservation-Modal-Option-${index}`}>{time[0]} - {time[1]}hs</span>
                                        ))}
                                      </div>
                                    </span>
                                  </button>
                                )})
                              : <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                <Loader/>
                              </div>
                            }
                          </div>
                        </div>

                        <div className="RoomsReservationModal__Inputs-Container pt-2 pb-4 position-relative" onClick={scrollToBottom}>
                          <Input
                            label='Desde'
                            name='hour-start'
                            list={limitHours.startHours}
                            value={reservation.start_hour}
                            onChange={handleHourStart}
                            icon={faClock}
                            disabled={!reservation.room_id}
                          />
                          <Input
                            label='Hasta'
                            name='hour-end'
                            list={limitHours.endHours}
                            value={reservation.end_hour}
                            onChange={handleHourEnd}
                            icon={faClock}
                            disabled={!reservation.start_hour}
                          />

                          {totalHours > hoursAtDate && (
                            <div className="Input__Error text-start pt-2" style={{ bottom: 0, top: 'unset' }}>
                              <FontAwesomeIcon icon={ faCircleExclamation } className="Input__Error-Icon"/>
                              No tenés horas suficientes para realizar esta reserva
                            </div>
                          )}
                        </div>

                        <div className="d-flex flex-column justify-content-center align-items-center py-3">
                          {reservation.user_type == 'user' || reservation.user_type == 'user-company'
                            ? reservation.room_id && reservation.start_hour && reservation.end_hour && totalHours <= hoursAtDate
                              ? <button className='button-green-panel' onClick={() => setStep(step + 1)}>Siguiente</button>
                              : <button className='button-disabled-panel' disabled>Siguiente</button>
                            : reservation.room_id && reservation.start_hour && reservation.end_hour && totalHours <= hoursAtDate && reservation?.user_id
                              ? <button className='button-green-panel' onClick={() => setStep(step + 1)}>Siguiente</button>
                              : <button className='button-disabled-panel' disabled>Siguiente</button>
                          }

                          {!reservation.room_id ? (
                            <span className='text-6 mb-2'>Selecciona una sala</span>
                          ) : !reservation.start_hour ? (
                            <span className='text-6 mb-2'>Selecciona el horario de inicio</span>
                          ) : !reservation.end_hour && (
                            <span className='text-6 mb-2'>Selecciona el horario de fin</span>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="PanelModal__Scroll-Content--Small pt-3" ref={roomsContainerRef}>
                          <h4 className='w-100 text-3 mb-3 text-start'>Elegí tu sala:</h4>

                          <div className="RoomsReservationModal__Options-Container">
                            {roomsWithSchedule.length > 0
                              ? roomsWithSchedule.map((room, index) => {
                                if (room?.scheduleHours?.length == 0) return (
                                  <button
                                    key={`Spaces-Reservation-Modal-Option--Disabled-${room.name}-${index}`}
                                    className='RoomsReservationModal__Option--Disabled'
                                  >
                                    <div className="RoomsReservationModal__Option-Xmark">
                                      <FontAwesomeIcon icon={faTimes}/>
                                    </div>
                                    <span className='text-4'>
                                      Sala {room.name} - Capacidad: {room.capacity}<br/>
                                      <div className='text-5 d-flex flex-column align-items-start'>
                                        No hay horas disponibles
                                      </div>
                                    </span>
                                  </button>
                                )

                                if(room.id === reservation.room_id) return (
                                  <button
                                    key={`Spaces-Reservation-Modal-Option--Active-${room.name}-${index}`}
                                    className={'RoomsReservationModal__Option--Active'}
                                    style={{
                                      backgroundColor: room.color,
                                      borderLeftColor: chroma(room.color).darken(1).hex(),
                                    }}
                                    ref={roomRef}
                                  >
                                    <div className="RoomsReservationModal__Option-Circle--Active"></div>
                                    <span className='text-4'>
                                      Sala {room.name} - Capacidad: {room.capacity}<br/>
                                      <div className='text-5 d-flex flex-column align-items-start'>
                                        Libre de:<br/>
                                        {room?.scheduleHours?.map((time, roomIndex) => (
                                          <span key={`Rooms-Reservation-Modal-Option-Active-${index}-${roomIndex}`}>{time[0]} - {time[1]}hs</span>
                                        ))}
                                      </div>
                                    </span>
                                  </button>
                                )

                                return (
                                  <button
                                    key={`Spaces-Reservation-Modal-Option-${room.name}-${index}`}
                                    className='RoomsReservationModal__Option'
                                    style={{
                                      backgroundColor: chroma(room.color).luminance(0.8).hex()
                                    }}
                                    onClick={() => handleRoomOption(room.id)}
                                  >
                                    <div className="RoomsReservationModal__Option-Circle"></div>
                                    <span className='text-4'>
                                      Sala {room.name} - Capacidad: {room.capacity}<br/>
                                      <div className='text-5 d-flex flex-column align-items-start'>
                                        Libre de:<br/>
                                        {room?.scheduleHours?.map((time, index) => (
                                          <span key={`Rooms-Reservation-Modal-Option-${index}`}>{time[0]} - {time[1]}hs</span>
                                        ))}
                                      </div>
                                    </span>
                                  </button>
                                )})
                              : <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                <Loader/>
                              </div>
                            }
                          </div>

                        </div>

                        <div className="RoomsReservationModal__Inputs-Container mt-2 pb-4 position-relative" onClick={scrollToBottom}>
                          <Input
                            label='Desde'
                            name='hour-start'
                            list={limitHours.startHours}
                            value={reservation.start_hour}
                            onChange={handleHourStart}
                            icon={faClock}
                            disabled={!reservation.room_id}
                          />
                          <Input
                            label='Hasta'
                            name='hour-end'
                            list={limitHours.endHours}
                            value={reservation.end_hour}
                            onChange={handleHourEnd}
                            icon={faClock}
                            disabled={!reservation.start_hour}
                          />

                          {(totalHours > hoursAtDate || (quantity * totalHours) > hoursAtDate) && (
                            <div className="Input__Error text-start pt-2">
                              <FontAwesomeIcon icon={ faCircleExclamation } className="Input__Error-Icon"/>
                              No tenes horas suficientes para realizar esta reserva
                            </div>
                          )}
                        </div>

                        <div className="d-flex flex-column justify-content-center align-items-center">
                          {reservation.room_id && reservation.start_hour && reservation.end_hour && totalHours <= hoursAtDate
                            ? <button className='button-green-panel my-3' onClick={() => setStep(step + 1)}>Siguiente</button>
                            : <button className='button-disabled-panel my-3' disabled>Siguiente</button>
                          }

                          {!reservation.room_id ? (
                            <span className='text-6 mb-2'>Selecciona una sala</span>
                          ) : !reservation.start_hour ? (
                            <span className='text-6 mb-2'>Selecciona el horario de inicio</span>
                          ) : !reservation.end_hour ? (
                            <span className='text-6 mb-2'>Selecciona el horario de fin</span>
                          ) : (totalHours > hoursAtDate) && (
                            <span className='text-6 mb-2'>No tenés horas suficientes para realizar esta reserva</span>
                          )}
                        </div>
                      </>
                    )
                  }
                  <Steps totalCount={2} currentCount={1} />
                </>
              )
              : (
                <div className="PanelModal__Alert title text-center">
                  <FontAwesomeIcon icon={faXmarkCircle}/>
                  <span className="text-2 mt-2">No tenés horas disponibles para esta fecha</span>
                </div>
              )
            }
          </>
        )
        }
      </div>
    </div>
  )

  if(step === 2) return (
    <div className="PanelModal px-3">
      <div className="PanelModal__Card">
        <header>
          <button className='PanelModal__Button-GoBack' onClick={() => setStep(step - 1)}>
            <FontAwesomeIcon icon={faChevronLeft}/>
          </button>
          <button className='PanelModal__Button-Close' onClick={closeModal}>
            <FontAwesomeIcon icon={faXmark}/>
          </button>
          <h2 className="PanelModal__Title text-1 text-center">Reserva sala de reunión</h2>
        </header>

        <div className='PanelModal__Confirm'>
          <h3 className="mx-2 text-1">{ modalDate }</h3>
        </div>

        <div className="PanelModal__Confirm">
          <h3 className="mx-2 text-1">{ reservation.room_name }</h3>
          <span className="text-4">
            De <span className="fw-bold">{reservation.start_hour}</span> a <span className="fw-bold">{reservation.end_hour}</span> hs
          </span>
        </div>

        {employees[reservation.company_id]?.length > 0 && employees[reservation.company_id].map((item) => {
          if(item.id == reservation.user_id) return (
            <div className="PanelModal__Confirm" key={`Reservation-Employee-Reference-${item.id}`}>
              <span>Empleado de referencia: <span className="fw-bold">{item.first_name} {item.last_name}</span></span>
            </div>
          )}
        )}

        <div className="PanelModal__Confirm">
          <span>Total: <span className="fw-bold">{totalHours == 1 ? `${totalHours} hora` : `${totalHours} horas`}</span></span>
        </div>

        <div className="d-flex justify-content-center">
          <button className='button-green-panel my-3' onClick={createReservation}>Reservar</button>
        </div>

        <Steps totalCount={2} currentCount={2} />
      </div>
    </div>
  )

  if(step === 3) return (
    <FinishModal
      title='Reserva sala de reunión'
      message='¡Reserva exitosa!'
      closeModal={closeModal}
    />
  )

  if(step === 4) return (
    <ErrorModal
      title='Algo salió mal'
      message='Por favor anota los datos de tu reserva y contactanos por whatsapp para que podamos ayudarte'
      closeModal={closeModal}
      data={reservation}
      type='rooms'
    />
  )
}

RoomsUserReservationModal.propTypes = {
  days: propTypes.array,
  hours: propTypes.array.isRequired,
  initialData: propTypes.object,
  refreshData: propTypes.func,
  changeMonth: propTypes.func,
  closeModal: propTypes.func.isRequired,
  serverTime: propTypes.string.isRequired,
}

export default RoomsUserReservationModal