import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { getSystemPlans } from '../../../api/plans'
import useApi from '../../../hooks/useApi'
import ModalHeader from '../../ModalHeader'
import PlanOptions from '../../PlanOptions'
import Steps from '../../Steps'


const SolicitPlanModalStep1 = ({
  closeModal,
  setStep,
  userSelected,
  setUserSelected,
  plansSelected,
  setPlansSelected,
}) => {
  const [firstTime, setFirstTime] = useState(true)
  const [plansListed] = useApi(() => getSystemPlans({ listed: 'true' }), {
    data: [],
    is_selling_allowed: true,
  })
  const [availablePlans, setAvailablePlans] = useState([])
  const [availablePlansNameList, setAvailablePlansNameList] = useState([])
  const [error, setError] = useState('')

  useEffect(() => {
    const list = plansListed.data.filter((plan) =>
      plansSelected.every((plan1) => plan.id !== Number(plan1.id))
    )
    const nameList = []

    list.forEach((plan) => {
      if (!nameList.some((name) => name === plan.name))
        nameList.push(plan.name)
    })

    setAvailablePlans(list)
    setAvailablePlansNameList(nameList)
  }, [plansSelected, plansListed])

  const planOptions = (planName) =>
    plansListed.data.filter((plan) => plan.name === planName)

  useEffect(() => {
    if (
      plansSelected.length === 0 &&
      availablePlans.length !== 0 &&
      firstTime
    ) {
      setFirstTime(false)
      handleAddplan()
    }
  }, [plansListed.data, availablePlans])

  const handlePlanName = (planNameIndex, planIndex) => {
    const planName = availablePlansNameList[planNameIndex]

    setPlansSelected((prev) => {
      return [
        ...prev.slice(0, planIndex),
        {
          ...availablePlans.find((plan) => plan.name === planName),
          quantity: 1,
        },
        ...prev.slice(planIndex + 1),
      ]
    })
  }

  const handlePlanQuantity = (newQuantity, planIndex) => {
    if(isNaN(Number(newQuantity))) return

    const min = 0
    const max = 50
    setPlansSelected((prev) => {
      return [
        ...prev.slice(0, planIndex),
        {
          ...prev[planIndex],
          quantity: Math.max(min, Math.min(max, Number(newQuantity))),
        },
        ...prev.slice(planIndex + 1),
      ]
    })
  }

  const handlePlanOption = (option, planIndex) => {
    const isTheSame = option.id === plansSelected[planIndex].id
    const isAlreadySelected = availablePlans.some(
      (plan) => plan.id === option.id
    )
    if (!isTheSame && isAlreadySelected) {
      setPlansSelected((prev) => {
        return [
          ...prev.slice(0, planIndex),
          {
            ...prev[planIndex],
            ...availablePlans.find((plan) => plan.id === option.id),
          },
          ...prev.slice(planIndex + 1),
        ]
      })
    }
  }

  const handleAddplan = () => {
    if (availablePlans.length === 0) return null

    setPlansSelected((prev) => [
      ...prev,
      {
        ...availablePlans[0],
        quantity: 1,
      },
    ])
  }

  const handleRemove = (e, plan_index) => {
    e.stopPropagation()
    setPlansSelected((prev) =>
      prev.filter((item, index) => index !== plan_index)
    )
  }

  // const userOptions = ['Mi cuenta personal', 'Mi empresa']
  const userOptions = ['Mi empresa']

  useEffect(() => {
    if (userSelected === 'Mi cuenta personal') {
      setPlansSelected((prev) => [...prev.slice(0, 1)])
    }
  }, [userSelected])

  const handleUserOption = (value) => {
    setUserSelected(value)
  }

  useEffect(() => {
    const checkQuantity = plansSelected.some((plan) => plan.quantity >= 1)
    const checkUniquePlans =
      new Set(plansSelected.map((item) => item.id)).size !==
      plansSelected.length

    if (checkUniquePlans) setError('Los planes tienen que ser distintos')
    else if (userSelected === 'Mi empresa' && !checkQuantity) {
      setError('Tiene que haber al menos un plan seleccionado')
    } else setError('')
  }, [plansSelected, userSelected])

  const handleNextStep = () => {
    if (error.length !== 0) return

    setStep((step) => step + 1)
  }

  return (
    <div className="PanelModal px-3" onClick={closeModal}>
      <div
        className="PanelModal__Card--Big"
        onClick={(e) => e.stopPropagation()}
      >
        <ModalHeader title={'Solicitá un plan'} closeModal={closeModal} />

        <h3 className="text-4 my-3 my-md-5 text-center">
          Elegí el plan que más se adapte a tus necesidades
        </h3>

        <div className="PanelModal__SolicitPlanModal">
          <div className="w-100">
            <p className="text-4 fw-bold">Para:</p>
            <div className="PanelModal__Options-Container">
              {userOptions &&
                userOptions.map((user, index) => {
                  return (
                    <button
                      className={`PanelModal__Option${
                        user === userSelected ? '--Active' : ''
                      } PanelModal__Option-left text-4`}
                      key={index}
                      onClick={() => handleUserOption(user)}
                    >
                      <div
                        className={`PanelModal__Option-Circle${
                          user === userSelected ? '--Active' : ''
                        }`}
                      ></div>
                      <span>{user}</span>
                    </button>
                  )
                })}
            </div>
          </div>
          <div className="PanelModal__SolicitPlanModal-Line" />
          <div className="PanelModal__SolicitPlanModal-Plans-Container">
            {plansSelected.map((plan, index) => {
              return (
                <PlanOptions
                  key={index}
                  planIndex={index}
                  plansName={availablePlansNameList}
                  planNameSelected={plan.name}
                  handlePlanName={handlePlanName}
                  isQuantity={userSelected === 'Mi empresa'}
                  quantity={plan.quantity}
                  handlePlanQuantity={handlePlanQuantity}
                  planPrice={plan.price}
                  handleRemove={
                    plansSelected.length !== 1
                      ? (e) => handleRemove(e, index)
                      : undefined
                  }
                  planOptions={planOptions(plan.name)}
                  planOptionSelected={plan.id}
                  handlePlanOption={handlePlanOption}
                  availablePlans={availablePlans}
                />
              )
            })}
            {userSelected === 'Mi empresa' && availablePlans.length !== 0 && (
              <button
                className="PanelModal__SolicitPlanModal-Button text-5"
                onClick={handleAddplan}
              >
                Añadir otro plan
              </button>
            )}
          </div>
        </div>

        {error.length !== 0 && (
          <div className="d-flex justify-content-center">
            <span className="PanelModal__Error text-5 text-center mt-3">
              {error}
            </span>
          </div>
        )}

        <div className="d-flex justify-content-center">
          <button
            className={`button-green-panel ${
              error.length !== 0 ? 'button-disabled' : ''
            } my-3 `}
            onClick={handleNextStep}
          >
            Siguiente
          </button>
        </div>

        <Steps totalCount={2} currentCount={1} />
      </div>
    </div>
  )
}

SolicitPlanModalStep1.propTypes = {
  closeModal: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  userSelected: PropTypes.string.isRequired,
  setUserSelected: PropTypes.func.isRequired,
  plansSelected: PropTypes.array.isRequired,
  setPlansSelected: PropTypes.func.isRequired,
}

export default SolicitPlanModalStep1
