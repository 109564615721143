import { useContext, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import propTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons'
import { faArrowLeft, faArrowRightFromBracket, faUser } from '@fortawesome/free-solid-svg-icons'
import GeneralIcon from '../assets/icons/panel-general.svg'
import ReservationsIcon from '../assets/icons/panel-reservations.svg'
import DataIcon from '../assets/icons/panel-data.svg'
import StatisticsIcon from '../assets/icons/panel-statistics.svg'
import PlacesIcon from '../assets/icons/panel-places.svg'
import HistoricIcon from '../assets/icons/panel-historic.svg'
import EditUserModal from '../components/modals/EditUserModal'
import { CSSTransition, Transition } from 'react-transition-group'
import { AppContext } from '../context'
import Media from '../components/Media'


const SECTIONS = ['usuarios', 'planes', 'horas y créditos', 'empresas', 'oficinas']
const TRANSITION_STYLES = {
  entering: {
    opacity: 1,
    maxHeight: `${SECTIONS.length * 44}px`,
    transition: 'opacity 200ms 100ms, max-height 200ms',
  },
  entered: {
    opacity: 1,
    maxHeight: `${SECTIONS.length * 44}px`,
  },
  exiting: {
    opacity: 0,
    maxHeight: 0,
    transition: 'opacity 200ms, max-height 200ms',
  },
  exited: {
    opacity: 0,
    maxHeight: 0,
  },
}


const PanelAdminMenu = ({ showMenu, toggleMenu, setShowLogoutModal }) => {
  const { user } = useContext(AppContext)
  const { pathname } = useLocation()
  const [showEditUserModal, setShowEditUserModal] = useState(false)
  const [showDataTypes, setShowDataTypes] = useState(false)
  const navigate = useNavigate()


  const toggleShowEditUserModal = () => { 
    setShowEditUserModal(prev => !prev)
  }

  const toggleShowDataTypes = () => {
    if(!showMenu) return navigate('/panel/datos/usuarios')
    setShowDataTypes((prev) => !prev)
  }


  return (
    <>
      <CSSTransition
        in={showMenu}
        timeout={300}
        className='PanelMenu'
        appear
      >
        <nav>
          <button className='PanelMenu__Button d-none d-md-flex' onClick={toggleMenu}>
            <CSSTransition
              in={showMenu}
              timeout={300}
              className='PanelMenu__Button-Icon'
              appear
            >
              <span><FontAwesomeIcon icon={faArrowLeft}/></span>
            </CSSTransition>
          </button>

          <CSSTransition
            in={showMenu}
            timeout={300}
            className='PanelMenu__Content pt-4'
            appear
          >
            <div>
              <div className="PanelMenu__User">
                <div className='d-flex'>
                  <div className="PanelMenu__User-Image-Container" >
                    {user?.profile?.image && (
                      <Media type='image' className='PanelMenu__User-Image' src={user?.profile?.image ?? ''} alt='imagen de usuario' />
                    )}
                    <FontAwesomeIcon icon={faUser}/>
                  </div>
                  <div className="PanelMenu__User-Info d-flex flex-column align-items-start">
                    <h4 className="PanelMenu__User-Name">{user?.data.first_name ?? 'Nombre'}</h4>
                    <h4 className="PanelMenu__User-Name">{user?.data.last_name ?? 'Apellido'}</h4>
                    <span className="PanelMenu__User-Username">Administrador</span>
                    {/* <span className="PanelMenu__User-Username">Usuario</span> */}
                  </div>
                </div>
                <button className="PanelMenu__User-Edit" onClick={toggleShowEditUserModal}>
                  <FontAwesomeIcon icon={faPenToSquare} />
                </button>
              </div>

              <div className="PanelMenu__Links--Default">
                <NavLink to="/panel/" className={({ isActive }) => isActive ? 'PanelMenu__Link--Active' : 'PanelMenu__Link'}>
                  <img className="PanelMenu__Link-Icon" src={GeneralIcon} alt="General" title='General'/>
                  <span className='PanelMenu__Link-text'>General</span>
                </NavLink>
                <NavLink to="/panel/calendario" className={({ isActive }) => isActive ? 'PanelMenu__Link--Active' : 'PanelMenu__Link'}>
                  <img className="PanelMenu__Link-Icon" src={ReservationsIcon} alt="Calendario" title='Calendario'/>
                  <span className='PanelMenu__Link-text'>Calendario</span>
                </NavLink>
                <div className='w-100'>
                  <div
                    className={`PanelMenu__Link${
                      pathname.startsWith('/panel/datos/') ? '--Active' : ''
                    }`}
                    onClick={toggleShowDataTypes}
                  >
                    <img
                      className="PanelMenu__Link-Icon"
                      src={DataIcon}
                      alt="Datos"
                      title="Datos"
                    />
                    <span className="PanelMenu__Link-text">Datos</span>
                  </div>
                  <Transition in={showDataTypes && showMenu} timeout={300} unmountOnExit appear>
                    {(state) => (
                      <div
                        className="PanelMenu__Sublinks"
                        style={{ ...TRANSITION_STYLES[state] }}
                      >
                        {SECTIONS.map(
                          (section, index) => (
                            <NavLink
                              key={index}
                              to={`/panel/datos/${encodeURIComponent(
                                section.replace(/ /g, '-').normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                              )}`}
                              className={({ isActive }) =>
                                `PanelMenu__Sublink${
                                  isActive ? '--Active' : ''
                                }`
                              }
                            >
                              <span className="PanelMenu__Sublink-first-letter text-uppercase">
                                {section[0]}
                              </span>
                              <span className="PanelMenu__Sublink-text">
                                {section.slice(1)}
                              </span>
                            </NavLink>
                          )
                        )}
                      </div>
                    )}
                  </Transition>
                </div>
                <NavLink to="/panel/estadisticas" className={({ isActive }) => isActive ? 'PanelMenu__Link--Active' : 'PanelMenu__Link'}>
                  <img className="PanelMenu__Link-Icon" src={StatisticsIcon} alt="Estadísticas" title='Estadísticas'/>
                  <span className='PanelMenu__Link-text'>Estadísticas</span>
                </NavLink>
                <NavLink to="/panel/espacios" className={({ isActive }) => isActive ? 'PanelMenu__Link--Active' : 'PanelMenu__Link'}>
                  <img className="PanelMenu__Link-Icon" src={PlacesIcon} alt="Espacios" title='Espacios'/>
                  <span className='PanelMenu__Link-text'>Espacios</span>
                </NavLink>
                <NavLink to="/panel/historial" className={({ isActive }) => isActive ? 'PanelMenu__Link--Active' : 'PanelMenu__Link'}>
                  <img className="PanelMenu__Link-Icon" src={HistoricIcon} alt="Historial" title='Historial'/>
                  <span className='PanelMenu__Link-text'>Historial</span>
                </NavLink>
              </div>

              <button
                className="button-white-green d-flex d-md-none text-1 py-3 mt-auto"
                onClick={() => setShowLogoutModal(true)}
              >
                <FontAwesomeIcon icon={faArrowRightFromBracket} />
                <span className='text-4 ms-4'>Cerrar sesión</span>
              </button>

              <div className="PanelMenu__Linkchar text-center">
                Powered By
                <a
                  className='PanelMenu__Linkchar-Link fw-bold ms-1'
                  href="https://linkchar.com/"
                  target='_blank'
                  rel="noreferrer"
                >
                  LinkChar
                </a>
              </div>
            </div>
          </CSSTransition>
        </nav>
      </CSSTransition>

      {showEditUserModal && <EditUserModal closeModal={toggleShowEditUserModal} />}
    </>
  )
}

PanelAdminMenu.propTypes = {
  showMenu: propTypes.bool.isRequired,
  toggleMenu: propTypes.func.isRequired,
  setShowLogoutModal: propTypes.func.isRequired,
}

export default PanelAdminMenu