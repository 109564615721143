const dayNames = {
  'Mon': 'Lunes',
  'Tue': 'Martes',
  'Wed': 'Miercoles',
  'Thu': 'Jueves',
  'Fri': 'Viernes',
  'Sat': 'Sabado',
  'Sun': 'Domingo'
}
const monthNames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

export {
  dayNames,
  monthNames,
}