import { useContext, useEffect, useState } from 'react'
import DataAdminHeader from '../../../containers/DataAdminHeader'
import { Helmet } from 'react-helmet'
import TableSearcher from '../../../components/TableSearcher'
import Table from '../../../containers/Table'
import { AppContext } from '../../../context'
import { useSearchParams } from 'react-router-dom'
import useApi from '../../../hooks/useApi'
import { getPlansData } from '../../../api/admin-data'
import { getSystemPlans } from '../../../api/plans'

const PlansDataAdminPanel = () => {
  const { token } = useContext(AppContext)
  const [page, setPage] = useState(1)
  const per_page = 25
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchSpecial, setSearchSpecial] = useState({
    user: {
      id: searchParams.get('user_id') ?? '',
      label: searchParams.get('user_label') ?? '',
    },
    company: {
      id: searchParams.get('company_id') ?? '',
      label: searchParams.get('company_label') ?? '',
    },
  })
  const [search, setSearch] = useState(searchParams.get('search') ?? '')
  const [filtersSelected, setFiltersSelected] = useState([
    {
      label: 'Por plan',
      defaultOption: {
        label: 'Todos los planes',
        value: '',
      },
      option: {
        label: 'Todos los planes',
        value: '',
      },
    },
    {
      label: 'Por pago',
      defaultOption: {
        label: 'Todos los pagos',
        value: '',
      },
      option: {
        label: 'Todos los pagos',
        value: '',
      },
    },
  ])
  const [order_by, setOrderBy] = useState('')
  const body = {
    page,
    per_page,
    search,
    plan: filtersSelected[0].option.value,
    is_paid: filtersSelected[1].option.value,
    order_by,
    user_id: searchSpecial.user.id,
  }
  const [plansData, updatePlansData] = useApi(
    () => getPlansData(token, body),
    {
      page: 1,
      total_pages: 1,
      total_items: 0,
      data: [],
    },
    [token, page, search, filtersSelected, order_by, searchSpecial]
  )
  const [plans] = useApi(getSystemPlans, {
    data: {},
    is_selling_allowed: true,
  })
  const plansNames = Object.keys(plans.data)

  useEffect(() => {
    setPage(1)
  }, [search])

  const handleSearch = (e) => {
    setSearch(e.target.value)
  }

  const tableHeaders = [
    {
      id: 0,
      label: 'Nombre y Apellido',
      value: 'full_name'
    },
    'Plan',
    'Créditos',
    'Horas',
    {
      id: 4,
      label: 'Inicio',
      value: 'start_date'
    },
    {
      id: 5,
      label: 'Fin',
      value: 'end_date'
    },
    {
      id: 6,
      label: 'Pago',
      value: 'paid'
    },
    {
      id: 7,
      label: 'Precio',
      value: 'payment_amount'
    },
  ]
  const tableHeadersMobile = [
    {
      id: 0,
      label: 'Nombre y Apellido',
      value: 'first_name_and_last_name'
    },
    'Plan',
    {
      id: 2,
      label: 'Pago',
      value: 'paid'
    },
  ]
  const filtersForCard = [
    {
      label: 'Por plan',
      options: plansNames.map((planName) => ({
        label: `Plan ${planName.toLowerCase()}`,
        value: planName,
      })),
    },
    {
      label: 'Por pago',
      options: [
        {
          label: 'Si',
          value: 'true',
        },
        {
          label: 'No',
          value: 'false',
        },
      ],
    },
  ]

  return (
    <>
      <Helmet>
        <title>Vida Cowork: Planes</title>
      </Helmet>
      <main className="DataAdminPanel--planes panel-main panel-main--table">
        <DataAdminHeader currentSection="planes">
          <TableSearcher
            search={search}
            handleSearch={handleSearch}
            filtersSelected={filtersSelected}
            setFiltersSelected={setFiltersSelected}
            filtersForCard={filtersForCard}
            totalItems={plansData.total_items}
            type={'plans'}
            exportButton
            setSearchParams={setSearchParams}
            searchSpecial={searchSpecial}
            setSearchSpecial={setSearchSpecial}
          />
        </DataAdminHeader>

        <Table
          type="Plans"
          headers={tableHeaders}
          headersMobile={tableHeadersMobile}
          data={plansData.data}
          updateData={updatePlansData}
          page={plansData.page}
          setPage={setPage}
          perPage={per_page}
          totalPages={plansData.total_pages}
          totalItems={plansData.total_items}
          setOrderBy={setOrderBy}
          showInfoMobile
        />
      </main>
    </>
  )
}

export default PlansDataAdminPanel
