import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleCheck,
  faCircleXmark
} from '@fortawesome/free-regular-svg-icons'
import { faBan, faX } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import TableItemSpecial from './TableItemSpecial'


const TablesEmails = ({
  data,
  selected = false,
  handleSelectItem,
  unselectable,
  isMobile,
  onlyUnselect,
}) => {
  return (
    <TableItemSpecial
      data={data}
      unselectable={unselectable}
      selected={selected}
      handleSelectItem={handleSelectItem}
    >
      {!isMobile ? (
        <>
          <div className="TableItem__Cell--Checkbox">
            {onlyUnselect ? (
              <FontAwesomeIcon icon={faX} />
            ) : (
              <div className="TableItem__Checkbox" />
            )}
          </div>
          <div className="TableItem__Cell">
            <span className="text-nowrap">{`${data.first_name} ${data.last_name}`}</span>
          </div>
          <div className="TableItem__Cell">
            <span>{data.email || '-'}</span>
          </div>
          <div className="TableItem__Cell">
            <span>
              {data.birthdate
                ? moment(data.birthdate, 'YYYY-MM-DD').format('DD/MM/YY')
                : '-'}
            </span>
          </div>
          <div
            className="TableItem__Cell"
            title={
              data.is_active
                ? !data.is_debtor
                  ? 'Activo'
                  : 'El usuario no puede realizar reservas'
                : 'El usuario no tiene acceso a la plataforma'
            }
          >
            {data.is_active ? (
              !data.is_debtor ? (
                <span className="TableItem__Status--Active">
                  <FontAwesomeIcon icon={faCircleCheck} />
                </span>
              ) : (
                <span className="TableItem__Status--Debtor">
                  <FontAwesomeIcon icon={faCircleXmark} />
                </span>
              )
            ) : (
              <span className="TableItem__Status--Locked">
                <FontAwesomeIcon icon={faBan} />
              </span>
            )}
            <span className="ms-1">
              {data.is_active
                ? !data.is_debtor
                  ? 'Activo'
                  : 'Semibloqueado'
                : 'Bloqueado'}
            </span>
          </div>
        </>
      ) : (
        <>
          <div className="TableItem__Cell--Checkbox">
            {onlyUnselect ? (
              <FontAwesomeIcon icon={faX} />
            ) : (
              <div className="TableItem__Checkbox" />
            )}
          </div>
          <div className="TableItem__Cell">
            <span className="text-nowrap">{`${data.first_name} ${data.last_name}`}</span>
          </div>
          <div className="TableItem__Cell">
            <span>
              {data.birthdate
                ? moment(data.birthdate, 'YYYY-MM-DD').format('DD/MM/YY')
                : '-'}
            </span>
          </div>
          <div
            className="TableItem__Cell"
            title={
              data.is_active
                ? !data.is_debtor
                  ? 'Activo'
                  : 'El usuario no puede realizar reservas'
                : 'El usuario no tiene acceso a la plataforma'
            }
          >
            {data.is_active ? (
              !data.is_debtor ? (
                <span className="TableItem__Status--Active">
                  <FontAwesomeIcon icon={faCircleCheck} />
                </span>
              ) : (
                <span className="TableItem__Status--Debtor">
                  <FontAwesomeIcon icon={faCircleXmark} />
                </span>
              )
            ) : (
              <span className="TableItem__Status--Locked">
                <FontAwesomeIcon icon={faBan} />
              </span>
            )}
            <span className="ms-1">
              {data.is_active
                ? !data.is_debtor
                  ? 'Activo'
                  : 'Semibloqueado'
                : 'Bloqueado'}
            </span>
          </div>
        </>
      )}
    </TableItemSpecial>
  )
}

TablesEmails.propTypes = {
  data: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  handleSelectItem: PropTypes.func,
  openInfoModal: PropTypes.func,
  isMobile: PropTypes.bool,
  unselectable: PropTypes.bool,
  onlyUnselect: PropTypes.bool,
}

export default TablesEmails
