import { useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortDown, faXmark } from '@fortawesome/free-solid-svg-icons'


const Dropdown = ({
  type,
  maxItems = 3,
  options,
  optionSelected,
  onChange,
  handleRemove,
}) => {
  const [showOptions, setShowOptions] = useState(false)

  const toggleShowOptions = () => {
    setShowOptions((prev) => !prev)
  }

  const selectOption = (e, option) => {
    e.stopPropagation()
    if (onChange) {
      const index = options.indexOf(option)
      onChange(index)
    }

    setShowOptions(false)
  }

  return (
    <button
      className={`Dropdown${showOptions ? '--Active' : ''}
      ${handleRemove ? 'Dropdown--Remove' : ' '}
      ${type ? `Dropdown--${type}` : ' '}
      text-3`}
      onClick={toggleShowOptions}
      onBlur={() => setShowOptions(false)}
    >
      <span className="d-flex align-items-center gap-1 gap-md-3">
        <span className="Dropdown__Title">{optionSelected.label || options[0]?.label}</span>
        <FontAwesomeIcon icon={faSortDown} className="Dropdown__Icon" />
      </span>

      {handleRemove && (
        <div className="Dropdown__Button-Arrow" onClick={handleRemove}>
          <FontAwesomeIcon icon={faXmark} />
        </div>
      )}

      {showOptions && (
        <div
          className="Dropdown__Items-Container"
          style={{
            maxHeight: `${maxItems * 37}px`,
          }}
        >
          {options.map((item, index) => (
            <div
              key={index}
              className={`Dropdown__Item${
                item.value === optionSelected.value ? '--Active' : ''
              } text-5`}
              onClick={(e) => selectOption(e, item)}
              disabled={item.value === optionSelected.value}
            >
              {item.label}
            </div>
          ))}
        </div>
      )}
    </button>
  )
}

Dropdown.propTypes = {
  type: PropTypes.string,
  maxItems: PropTypes.number,
  options: PropTypes.array.isRequired,
  optionSelected: PropTypes.object,
  onChange: PropTypes.func,
  handleRemove: PropTypes.func,
}

export default Dropdown
