import { useContext, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import {
  faCircleExclamation,
  faSearch,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Table from '../../containers/Table'
import FinishModal from './FinishModal'
import PanelModalSelector from '../PanelModalSelector'
import ModalHeader from '../ModalHeader'
import { AppContext } from '../../context'
import useApi from '../../hooks/useApi'
import { getUsersData } from '../../api/admin-data'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { postSendAnnouncements } from '../../api/community'
import TablesEmails from '../tables/TablesEmails'
import useIsMobile from '../../hooks/useIsMobile'


const EmailModal = ({ closeModal }) => {
  const { token } = useContext(AppContext)
  const [step, setStep] = useState(1)
  const [optionSelected, setOptionSelected] = useState('Para todos')
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const per_page = 5
  const [usersData, updateUsersData] = useApi(
    () => getUsersData(token, { search, page, per_page }),
    {
      page: 1,
      total_pages: 1,
      total_items: 0,
      data: [],
    },
    [token, search, page]
  )
  const [selectedItemsId, setSelectedItemsId] = useState([])
  const [selectedItems, setSelectedItems] = useState([])
  const [error, setError] = useState('')

  const [savedItems, setSavedItems] = useState([])

  const isMobile = useIsMobile()

  useEffect(() => {
    setSavedItems((prev) => [
      ...prev,
      ...selectedItems.reduce(
        (acc, cur) =>
          prev.some((elem) => elem.id === cur.id) ? acc : [...acc, cur],
        []
      ),
    ])
  }, [selectedItems])

  const handleRemoveSaved = (value) => {
    setSavedItems((prev) => prev.filter((item) => item.id !== value.id))
  }

  useEffect(() => {
    if (optionSelected !== specialOption) return setError('')
    if (savedItems.length === 0)
      return setError('No hay usuarios seleccionados')
    else setError('')
  }, [optionSelected, savedItems])

  useEffect(() => {
    setSelectedItems(
      selectedItemsId.map((id) => usersData.data.find((item) => item.id === id))
    )
  }, [selectedItemsId, usersData.data])

  useEffect(() => {
    setSelectedItems([])
    setSelectedItemsId([])
  }, [usersData.data])

  const handleSelectItem = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItemsId((prev) => prev.filter((id) => id !== item.id))
    } else {
      setSelectedItemsId((prev) => [...prev, item.id])
    }
  }

  const options = [
    'Para todos',
    'Para freelancers',
    'Para empresas',
    'Para oficinas',
    'Elegir usuarios',
  ]

  const specialOption = 'Elegir usuarios'

  const optionsTypes = {
    'Para todos': 'all',
    'Para freelancers': 'freelancers',
    'Para empresas': 'companies',
    'Para oficinas': 'offices',
    'Elegir usuarios': 'to_users',
  }

  const handleSearch = (e) => {
    setPage(1)
    setSearch(e.target.value)
  }

  const tableHeaders = [
    'Nombre y Apellido',
    'Email',
    'Fecha de Nacimiento',
    'Estado',
  ]
  const tableHeadersMobile = [
    'Nombre y Apellido',
    'Fecha de Nacimiento',
    'Estado',
  ]

  const changeOptionSelected = (filterIndex) => {
    setOptionSelected(options[filterIndex])
  }

  const formik = useFormik({
    initialValues: {
      text_email: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      text_email: Yup.string()
        .required('Es necesario un mensaje')
        .max(1000, 'Máximo: 1000 caracteres'),
    }),
    onSubmit: async ({ text_email }) => {
      if (error.length !== 0) return

      const body = {
        text_email,
        to_users: optionsTypes[optionSelected],
      }

      if (optionSelected === specialOption) {
        body.users_list = savedItems.reduce((acc, cur) => acc + cur.email + ',', '').slice(0, -1)
      }

      const response = await postSendAnnouncements(token, body)

      if (response.error) {
        // console.error(response.error)
        return
      }
      else if (response) setStep((prev) => prev + 1)
    },
  })

  if (step === 1)
    return (
      <div className="PanelModal px-3" onClick={closeModal}>
        <div
          className="PanelModal__Card--Big"
          onClick={(e) => e.stopPropagation()}
        >
          <ModalHeader title={'Enviar email'} closeModal={closeModal} />

          <div className="d-flex justify-content-center my-2">
            <PanelModalSelector
              options={options}
              optionSelected={optionSelected || options[0]}
              onChange={changeOptionSelected}
            />
          </div>

          {optionSelected === specialOption ? (
            <>
              <div className="w-100 d-flex align-items-center text-3 my-3">
                <FontAwesomeIcon icon={faSearch} />
                <input
                  className="PanelModal__Search-Input ms-3 text-5"
                  name="search"
                  type="text"
                  value={search}
                  onChange={handleSearch}
                />
              </div>

              <Table
                type="Email-Users"
                headers={tableHeaders}
                headersMobile={tableHeadersMobile}
                data={usersData.data}
                updateData={updateUsersData}
                page={usersData.page}
                setPage={setPage}
                perPage={per_page}
                totalPages={usersData.total_pages}
                totalItems={usersData.total_items}
                selectedParentItems={selectedItems}
                handleParentItems={handleSelectItem}
                rounded
              />

              {savedItems.length !== 0 ? (
                <div className="Table Table--Email-Users Table--Rounded" style={{maxHeight: '157px'}}>
                  <div className="Table__Content">
                    {savedItems.map((item) => (
                      <TablesEmails
                        key={item.id}
                        data={item}
                        selected
                        isMobile={isMobile}
                        handleSelectItem={handleRemoveSaved}
                        onlyUnselect
                      />
                    ))}
                  </div>
                </div>
              ) : (
                <></>
              )}

              <p className='mt-2 text-center'>{`${savedItems.length} usuario${savedItems.length !== 1? 's': ''} seleccionado${savedItems.length !== 1? 's': ''}`}</p>
            </>
          ) : (
            <></>
          )}

          <div
            className={`PanelModal__Textarea-Container
            ${optionSelected !== specialOption ? 'mt-3' : ''}
            mb-3`}
          >
            <h4 className="text-3">Mensaje</h4>
            <textarea
              className="PanelModal__Textarea mt-2 text-5"
              type="text"
              name="text_email"
              id="text_email"
              style={{ height: 100 }}
              value={formik.values.text_email}
              onChange={formik.handleChange}
            />
            {formik.errors.text_email ? (
              <span className="Input__Error">
                <FontAwesomeIcon
                  icon={faCircleExclamation}
                  className="Input__Error-Icon"
                />
                {formik.errors.text_email}
              </span>
            ) : (
              <span className="Input__Tip">
                {formik.values.text_email.length}/1000 caracteres
              </span>
            )}
          </div>

          {error.length !== 0 && (
            <div className="d-flex justify-content-center">
              <span className="PanelModal__Error text-5 text-center my-3">
                {error}
              </span>
            </div>
          )}

          <div className="d-flex justify-content-center">
            <button
              className={`button-green-panel ${
                error.length !== 0 ||
                (formik.errors.text_email &&
                  formik.values.text_email.length === 0)
                  ? 'button-disabled'
                  : ''
              } my-3 `}
              type="submit"
              onClick={formik.handleSubmit}
            >
              Enviar email
            </button>
          </div>
        </div>
      </div>
    )

  if (step === 2)
    return (
      <FinishModal
        title="Enviar email"
        message="¡Email enviado con éxito!"
        closeModal={closeModal}
      />
    )
}

EmailModal.propTypes = {
  closeModal: propTypes.func.isRequired,
}

export default EmailModal
