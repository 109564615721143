import { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  faUser,
  faBriefcase,
  faEnvelope,
  faPhone,
  faCalendarCheck,
  faUsers,
} from '@fortawesome/free-solid-svg-icons'
import Helmet from 'react-helmet'
import Banner from '../../components/Banner'
import Galery from '../../components/Galery'
import Services from '../../containers/Services'
import Plant1 from '../../assets/details/plant-1.webp'
import Plant2 from '../../assets/details/plant-2.webp'
import useApi from '../../hooks/useApi'
import { getEvents } from '../../api/admin-settings'
import Media from '../../components/Media'
import { postEventsForm } from '../../api/community'
import FinishModal from '../../components/modals/FinishModal'
import InputAnimated from '../../components/InputAnimated'

const Events = () => {
  const [events] = useApi(getEvents, [])
  const [showGalery, setShowGalery] = useState(false)
  const [eventImages, setEventImages] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [eventsCategorys, setEventsCategorys] = useState([])


  useEffect(() => {
    const categorys = []

    events.forEach(event => categorys.some(category => category === event.category) ? null : categorys.push(event.category))

    categorys.sort((a, b) => {
      const nameA = a.toUpperCase()
      const nameB = b.toUpperCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })

    setEventsCategorys(categorys)
  }, [events])


  const toogleShowGalery = () => {
    setShowGalery(!showGalery)
  }

  const handleShowImages = (event) => {
    if (event.images.length === 0) {
      setShowGalery(true)
      setEventImages([{ image: event.main_image }])
      return null
    }
    setShowGalery(true)
    setEventImages(event.images)
  }

  const toggleShowmodal = () => {
    setShowModal((prev) => !prev)
  }

  const closeShowmodal = () => {
    setShowModal(false)
  }


  const formik = useFormik({
    initialValues: {
      name: '',
      last_name: '',
      company_name: '',
      event_type: '',
      email: '',
      phone: '',
      quantity: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Tu nombre es requerido')
        .max(50, 'Máximo de caracteres: 50'),
      last_name: Yup.string()
        .required('Tu apellido es requerido')
        .max(50, 'Máximo de caracteres: 50'),
      company_name: Yup.string().max(50, 'Máximo de caracteres: 50'),
      event_type: Yup.string().required('Seleccione un tipo de evento'),
      email: Yup.string()
        .email('Email no valido')
        .required('Tu email es requerido')
        .max(50, 'Máximo de caracteres: 50'),
      phone: Yup.number(),
      quantity: Yup.number()
        .required('Ingrese una cantidad')
        .min(1, 'Ingrese una cantidad válida'),
    }),
    onSubmit: async (data) => {
      if (data.email.includes(' ')) {
        data.email = data.email.replace(/ /g, '')
      }
      const formFiltered = Object.entries(data).reduce(
        (a, [k, v]) => (v ? ((a[k] = v), a) : a),
        {}
      )
      const response = await postEventsForm(formFiltered)
      if (response) {
        toggleShowmodal()
      }
      formik.resetForm()
    },
  })


  return (
    <>
      <Helmet>
        <title>Vida Cowork: Eventos</title>
      </Helmet>
      <main className="Events landing-content">
        <Banner videoType="EVENTOS" textType="evento" />

        {/* Info */}
        <section className="background-white container-fluid position-relative pt-5 px-3 px-xl-0">
          <div className="container-custom pt-0 pt-lg-5 mb-0 mb-lg-5">
            <h2 className="text-1 mb-5">
              Mirá los distintos tipos de eventos que podes realizar en nuestro
              espacio.
            </h2>

            {eventsCategorys.map((Category, index) => (
              <div key={index}>
                <h2 className="text-1 text-orange">{Category}</h2>
                <div className="Events__Swiper-Container container-custom pb-5">
                  <Swiper
                    className="Events__Swiper"
                    modules={[Navigation, Pagination]}
                    slidesPerView={1}
                    spaceBetween={30}
                    breakpoints={{
                      576: {
                        slidesPerView: 1,
                        spaceBetween: 30,
                      },
                      768: {
                        slidesPerView: 2,
                        spaceBetween: 40,
                      },
                      1200: {
                        slidesPerView: 3,
                        spaceBetween: 40,
                      },
                      1440: {
                        slidesPerView: 4,
                        spaceBetween: 40,
                      },
                    }}
                    navigation
                    pagination={{ clickable: true }}
                    loop={true}
                    speed={500}
                  >
                    {events
                      .filter((event) => event.category === Category)
                      .map((event, index) => (
                        <SwiperSlide key={`Event-${event}-${index}`}>
                          <div
                            className="Events__Swiper-Item"
                            onClick={() => handleShowImages(event)}
                          >
                            <Media
                              type="image"
                              className="Events__Swiper-Image"
                              src={event.main_image}
                              alt={event.title}
                            />
                            <div className="Events__Swiper-Title-Container">
                              <h3 className="text-2">{event.title}</h3>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                  </Swiper>
                </div>
              </div>
            ))}
          </div>
        </section>

        {showGalery && (
          <Galery images={eventImages} toogleShowGalery={toogleShowGalery} />
        )}

        {/* Services */}
        <section className="background-white container-fluid">
          <div className="container-custom pt-4 pt-lg-5">
            <h2 className="text-1">Nuestro espacio ofrece:</h2>
            <Services category="eventos" />
          </div>
        </section>

        {/* Form */}
        <section className="background-white container-fluid position-relative pb-0 pb-lg-5 px-3 px-xl-0">
          <div className="Events-Form py-5">
            <h2 className="text-1">Si querés realizar tu evento en VIDA</h2>
            <p className="text-4">
              ¡Completá este formulario y nos pondremos en contacto con vos!
            </p>
            <form className="d-flex flex-column" onSubmit={formik.handleSubmit}>
              <div className="Events-Form__Container">
                <InputAnimated
                  label="Nombre*"
                  name="name"
                  type="text"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.errors.name}
                  icon={faUser}
                />

                <InputAnimated
                  label="Apellido*"
                  name="last_name"
                  type="text"
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                  error={formik.errors.last_name}
                  icon={faUser}
                />

                <InputAnimated
                  label="Nombre de tu empresa o marca"
                  name="company_name"
                  type="text"
                  value={formik.values.company_name}
                  onChange={formik.handleChange}
                  error={formik.errors.company_name}
                  icon={faBriefcase}
                />

                <InputAnimated
                  label="Tipo de evento*"
                  name="event_type"
                  type="select"
                  value={formik.values.event_type}
                  list={['Event 1', 'Event 2', 'Event 3']}
                  onChange={formik.setFieldValue}
                  error={formik.errors.event_type}
                  icon={faCalendarCheck}
                />

                <InputAnimated
                  label="Email/correo electrónico*"
                  name="email"
                  type="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.errors.email}
                  icon={faEnvelope}
                />

                <InputAnimated
                  label="Número telefónico"
                  name="phone"
                  type="tel"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  error={formik.errors.phone}
                  icon={faPhone}
                />

                <InputAnimated
                  label="Cantidad de personas*"
                  name="quantity"
                  type="number"
                  value={formik.values.quantity}
                  onChange={formik.handleChange}
                  error={formik.errors.quantity}
                  icon={faUsers}
                />
              </div>
              <button
                className="Events-Form__Button button-orange mt-5"
                type="submit"
                onClick={formik.handleSubmit}
              >
                Finalizar formulario
              </button>
            </form>
          </div>
        </section>

        {showModal && (
          <FinishModal
            type="--Big"
            title="Formulario consulta"
            message="Formulario enviado"
            description="Tus datos fueron correctamente enviados. En breve nos comunicaremos con vos."
            buttonText="¡Entendido!"
            closeModal={closeShowmodal}
          />
        )}

        <img className="Offices__Plant-1" src={Plant1} alt="Plant 1" />
        <img className="Offices__Plant-2" src={Plant2} alt="Plant 2" />
      </main>
    </>
  )
}

export default Events
