import { useState, memo, useEffect, useRef } from 'react'
import propTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faCircleExclamation, faEye, faEyeSlash, faXmark } from '@fortawesome/free-solid-svg-icons'


const Input = ({
  label,
  name,
  type,
  placeholder,
  value,
  list,
  search,
  onClick,
  onChange,
  reset,
  error,
  tip,
  icon,
  disabled,
  required = false,
  min
}) => {
  const inputRef = useRef()
  const [ showPassword, setShowPassword ] = useState(false)
  const [ showList, setShowList ] = useState(false)
  const [ selectedValue, setSelectedValue ] = useState(value)
  const [ searchValue, setSearchValue ] = useState('')
  const [ searchList, setSearchList ] = useState(search)

  useEffect(() => {
    setSelectedValue(value)
  }, [value])

  useEffect(() => {
    if(disabled) {
      setShowList(false)
      setSelectedValue('')
    }
  }, [disabled])

  useEffect(() => {
    if(inputRef.current) {
      if(showList) {
        setTimeout(() => {
          inputRef.current.focus()
        }, 200)
      }
    }
  }, [showList])

  useEffect(() => {
    if(search) {
      if(searchValue) {
        const filteredList = search.filter(item => `${item.first_name.toLowerCase()} ${item.last_name.toLowerCase()}`.includes(searchValue.toLowerCase()))

        setSearchList(filteredList || [])

        if(searchList?.length == 0) {
          onChange('')
        }
      } else {
        setSearchList(search)
        onChange('')
      }
    }
  }, [searchValue, search])

  useEffect(() => {
    if(reset) {
      setSelectedValue('')
      setSearchValue('')
    }
  }, [reset])

  const toogleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const selectValue = (value) => {
    setShowList(false)
    setSelectedValue(value)
    onChange(name, value)
  }

  const selectSearchValue = (value) => {
    setShowList(false)
    setSearchValue(`${value.first_name} ${value.last_name} (${value.email})`)
    onChange(value.id)
  }

  if(list) return (
    <div className="w-100 d-flex flex-column align-items-start position-relative">
      {error && (
        <span className="Input__Error">{ error }</span>
      )}
      <label htmlFor={ name } className="text-4 fw-normal">{ label }</label>
      <div
        className={!disabled ? 'Input__List-Container' : 'Input__List-Container--Disabled'}
        ref={inputRef}
        onClick={() => !disabled && setShowList(!showList)}
        onBlur={() => {
          setTimeout(() => {
            setShowList(false)
          }, 150)
        }}
        tabIndex="0"
      >
        <div
          className={icon ? 'Input--With-Icon text-4' : 'Input text-4'}
          id={ name }
        >{ selectedValue }</div>
        {icon ? (
          <div className="Input__Icon_container">
            <FontAwesomeIcon icon={icon} className="Input__Icon" />
          </div>
        ) : (
          <></>
        )}
        <FontAwesomeIcon
          icon={ faAngleDown }
          className={ showList ? 'Input__List-Icon--Active' : 'Input__List-Icon' }
        />
        {showList && (
          <div className="Input__List">
            {list.map((item, index) => (
              <span
                key={`Input__List-${item}-${index}`}
                className={ item === selectedValue ? 'Input__List-Item--Active' : 'Input__List-Item' }
                onClick={() => selectValue(item)}
              >
                { item }
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  )

  if(search) return (
    <div className="w-100 d-flex flex-column align-items-start position-relative">
      <label htmlFor={ name } className="text-4 fw-normal">{ label }</label>
      <div className="w-100 position-relative">
        <input
          className={icon ? 'Input--With-Icon text-4' : 'Input text-4'}
          type={ showPassword ? 'text' : type }
          name={ name }
          placeholder={ placeholder }
          id={ name }
          value={ searchValue }
          onChange={ e => {
            setSearchValue(e.target.value)
            setShowList(true)
          }}
          required={ required }
          autoComplete="off"
        />
        {icon ? (
          <div className="Input__Icon_container">
            <FontAwesomeIcon icon={icon} className="Input__Icon" />
          </div>
        ) : (
          <></>
        )}

        {searchList?.length > 0
          ? (
            <FontAwesomeIcon
              icon={ faAngleDown }
              className={ showList ? 'Input__List-Icon--Active' : 'Input__List-Icon' }
              onClick={() => setShowList(!showList)}
            />
          ) : (
            <FontAwesomeIcon
              icon={ faXmark }
              className='Input__List-Icon'
              onClick={() => {
                setSearchValue('')
                onChange('')
              }}
            />
          )
        }

        {showList && searchList?.length > 0 && (
          <div className="Input__List">
            {searchList.map((item, index) => (
              <span
                key={`Input__List-${item}-${index}`}
                className={ item === selectedValue ? 'Input__List-Item--Active' : 'Input__List-Item' }
                onClick={() => selectSearchValue(item)}
              >
                {item.first_name} {item.last_name} ({item.email})
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  )

  return (
    <div className="w-100 d-flex flex-column align-items-start position-relative">
      <label htmlFor={ name } className="text-4 fw-normal">{ label }</label>
      <div className='w-100 position-relative'>
        <input
          className={disabled ? 'Input--Disabled text-4' : icon ? 'Input--With-Icon text-4' : 'Input text-4'}
          type={ showPassword ? 'text' : type }
          name={ name }
          placeholder={ placeholder }
          id={ name }
          value={ value }
          min={min}
          onClick={onClick}
          onChange={ onChange }
          required={ required }
          disabled={disabled}
        />
        {icon ? (
          <div className="Input__Icon_container">
            <FontAwesomeIcon icon={icon} className="Input__Icon" />
          </div>
        ) : (
          <></>
        )}
        {type === 'password' && (
          showPassword
            ? <button className="Input__Password-Icon" onClick={toogleShowPassword}>
              <FontAwesomeIcon
                icon={ faEye }
              />
            </button>
            : <button className="Input__Password-Icon" onClick={toogleShowPassword}>
              <FontAwesomeIcon
                icon={ faEyeSlash }
              />
            </button>
        )}
        {(tip && !error) && (
          <div className='Input__Tip' dangerouslySetInnerHTML={{ __html: tip }} />
        )}
      </div>
      {error && (
        <span className="Input__Error">
          <FontAwesomeIcon icon={ faCircleExclamation } className="Input__Error-Icon"/>
          { error }
        </span>
      )}
    </div>
  )
}

Input.propTypes = {
  label: propTypes.string.isRequired,
  name: propTypes.string.isRequired,
  type: propTypes.string,
  placeholder: propTypes.string,
  value: propTypes.any,
  list: propTypes.array,
  search: propTypes.array,
  onClick: propTypes.func,
  onChange: propTypes.func,
  reset: propTypes.bool,
  error: propTypes.string,
  tip: propTypes.string,
  icon: propTypes.object,
  disabled: propTypes.bool,
  required: propTypes.bool,
  min: propTypes.string,
  max: propTypes.string,
}

export default memo(Input)