import { useContext } from 'react'
import propTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faExclamationCircle, faXmark } from '@fortawesome/free-solid-svg-icons'
import { cancelSharedAreaBook } from '../../api/shared-areas'
import { AppContext } from '../../context'

const SpacesReservationCancelModal = ({ modalState, setModalState, refreshData, closeModal }) => {
  const { token, isAdmin, updateUser } = useContext(AppContext)

  const reservationTypes = {
    'Mañana': 'Morning',
    'Tarde': 'Afternoon',
    'Completo': 'Complete'
  }

  const cancelReservation = async () => {
    setModalState({
      ...modalState,
      step: 2,
    })

    const response = await cancelSharedAreaBook(token, {
      shared_area_book_id: modalState.reservation.id
    })

    if(!response.error) {
      updateUser()
      refreshData()
    }
  }

  const day = modalState.reservation.date.split('-')[2]
  const month = modalState.reservation.date.split('-')[1]

  if(modalState.step == 1) return (
    <div className="PanelModal px-3" onClick={closeModal}>
      <div className='PanelModal__Card--Medium d-flex flex-column' onClick={(e) => e.stopPropagation()}>
        <header>
          <button className='PanelModal__Button-Close' onClick={closeModal}>
            <FontAwesomeIcon icon={faXmark}/>
          </button>
          <h2 className="PanelModal__Title text-1 text-center">Cancelar reserva</h2>
        </header>

        <div className="PanelModal__Check PanelModal__Confirm caption text-center">
          <FontAwesomeIcon icon={faExclamationCircle}/>
          <h3 className='text-2'>¿Estás seguro que deseas cancelar esta reserva?</h3>

          {!isAdmin && (
            <div className='d-flex justify-content-center my-4'>
              {modalState.isCurrentDay
                ? (
                  <span className="text-4 fw-normal text-danger">&#128683; Como estas cancelando el mismo día de la reserva perderás tus créditos &#128683;</span>
                )
                : (
                  <span className="text-4 fw-normal">Si cancelas el mismo día de la reserva, perderás tus créditos. De lo contrario te los devolveremos &#128154;</span>
                )}
            </div>
          )}
        </div>

        <div className='PanelModal__Confirm text-3'>
          <h3 className='text-2'>La reserva que vas a cancelar:</h3>

          <div className={`PanelModal__Cancel-Spaces-Reservation-Item--${reservationTypes[modalState.reservation.booking_type]} text-5 mt-2`}>
            <span>Fecha: <span className="fw-bold">{`${day}/${month}`}</span></span>
            <span>Turno: <span className="fw-bold">{ modalState.reservation.booking_type }</span></span>
            <span>Puesto: <span className="fw-bold">N° { modalState.reservation.chair__number }</span></span>
          </div>
        </div>

        <div className='mt-3'>
          <div className="d-flex justify-content-center">
            <button className='button-green-panel' onClick={cancelReservation}>Si, cancelar</button>
          </div>

          <div className="d-flex justify-content-center">
            <button className='button-underline' onClick={closeModal}>Cambié de opinión, no quiero cancelar</button>
          </div>
        </div>
      </div>
    </div>
  )

  if(modalState.step == 2) return (
    <div className="PanelModal px-3" onClick={closeModal}>
      <div className='PanelModal__Card--Medium d-flex flex-column gap-3' onClick={(e) => e.stopPropagation()}>
        <header>
          <button className='PanelModal__Button-Close' onClick={closeModal}>
            <FontAwesomeIcon icon={faXmark}/>
          </button>
          <h2 className="PanelModal__Title text-1 text-center">Reserva cancelada</h2>
        </header>

        <div className="FinishModal__Check caption text-center py-5">
          <FontAwesomeIcon icon={faCheckCircle}/>
          <h3 className='text-1 mt-3'>Su reserva ha sido cancelada</h3>
        </div>

        <div className="d-flex justify-content-center">
          <button className='button-green-panel' onClick={closeModal}>Entendido</button>
        </div>
      </div>
    </div>
  )
}

SpacesReservationCancelModal.propTypes = {
  modalState: propTypes.object.isRequired,
  setModalState: propTypes.func.isRequired,
  refreshData: propTypes.func.isRequired,
  closeModal: propTypes.func.isRequired
}

export default SpacesReservationCancelModal