import { useState, useEffect, memo, useContext } from 'react'
import propTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleCheck,
  faCircleXmark,
  faMessage,
} from '@fortawesome/free-regular-svg-icons'
import EterIcon from '../assets/icons/eter-green.svg'
import AireIcon from '../assets/icons/aire-green.svg'
import AguaIcon from '../assets/icons/agua-green.svg'
import FuegoIcon from '../assets/icons/fuego-green.svg'
import TierraIcon from '../assets/icons/tierra-green.svg'
import {
  faArrowRightLong,
  faBan,
  faCaretDown,
  faChevronDown,
  faCircleExclamation,
  faMinus,
  faPlus,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { Link, useNavigate } from 'react-router-dom'
import { AppContext } from '../context'
import classNames from 'classnames'


export const getAction = (data) => {
  let action = ''

  switch (data.action) {
  case 'asignar':
    if (data.related_object_type !== 'User_profile') action += 'Asignó'
    if (data.action_description === 'Aumento mensual de creditos')
      action += ' horas a'
    break

  case 'cancelar':
    action += 'Canceló'
    break

  case 'borrar':
    action += 'Eliminó'
    break

  case 'crear':
    action += 'Creó'
    break

  case 'Semibloqueado':
    break

  case 'editar':
    if (data.action_description === 'Usuario bloqueado') action += 'Bloqueó'
    else if (data.action_description === 'Usuario desbloqueado')
      action += 'Desbloqueó'
    else if (
      data.action_description === 'Marcado como Semibloqueado' ||
        data.action_description === 'marco Semibloqueado'
    )
      action += 'Marcó como Semibloqueado'
    else if (
      data.action_description === 'Desmarcado como Semibloqueado' ||
        data.action_description === 'desmarco Semibloqueado'
    )
      action += 'Desmarcó como Semibloqueado'
    else action += 'Editó'
    break

  case 'pagar':
    action += 'Pagó'
    break
  case 'quitar':
    if (data.action_description === 'Quitar creditos') action += 'Quito creditos'
    else  action += 'Quito horas'
    break

  default:
    action += 'sin acción'
    break
  }

  switch (data.related_object_type) {
  case 'Chair':
    action += ` Puesto n° ${data.related_object.number}`
    break

  case 'Company':
    action += ` a empresa ${data.related_object.name}`
    break

  case 'Holiday':
    action += ` día deshabilitado por ${
      data.related_object.name
    } en el día ${moment(data.related_object.date).format('DD/MM/YY')}`
    break

  case 'MeetingRoomBooking':
    action += ` reserva en sala ${
      data.related_object.meeting_room.name
    }. Dia: ${moment(data.related_object.start_date).format(
      'DD/MM/YY'
    )}, Horarios: ${moment(data.related_object.start_date).format(
      'HH:mm'
    )} - ${moment(data.related_object.end_date).format('HH:mm')}`
    break

  case 'Office_booking':
    action += ` oficina ${data.related_object.office.name} a empresa ${data.related_object.company.name}`
    break

  case 'Shared_area_book':
    action += ` reserva en espacio compartido. Dia: ${data.related_object.date}, Jornada: ${data.related_object.booking_type}, Puesto: ${data.related_object.chair.number}`
    break

  case 'Users_plan':
    if (data.related_object.plan)
      action += ` plan ${data.related_object.plan.name} ${
        data.related_object.plan.plan_type.split(' ')[0]
      }`
    else action += ' plan'
    if (data.related_object.user && data.related_object.company)
      action += ` a usuario ${data.related_object.user.first_name} ${data.related_object.user.last_name} en empresa ${data.related_object.company.name}`
    else {
      if (data.related_object.user)
        action += ` a usuario ${data.related_object.user.first_name} ${data.related_object.user.last_name}`
      if (data.related_object.company)
        action += ` a empresa ${data.related_object.company.name}`
    }
    break

  case 'User_profile':
    if (data.action === 'asignacion' || data.action === 'asignar')
      action += `${
        data.action_description === 'Devolución' ? 'Devolvió' : 'Compró'
      } horas/creditos a usuario ${data.related_object.first_name} ${
        data.related_object.last_name
      }`
    else
      action += ` a usuario ${data.related_object.first_name} ${data.related_object.last_name}`
    break

  case 'User':
    action += ` a usuario ${data.related_object.first_name} ${data.related_object.last_name}`
    break

  default:
    action += `, ${data.related_object_type || 'sin objeto'}`
    break
  }

  return action
}

const TableItem = ({
  data,
  type,
  selected = false,
  handleSelectItem,
  openInfoModal,
  unselectable,
}) => {
  const { user } = useContext(AppContext)
  const [ planIcon, setPlanIcon ] = useState(null)
  const [ showMobileInfo, setShowMobileInfo ] = useState(false)
  const [ isMobile, setIsMobile ] = useState(false)
  const [ historic, setHistoric ] = useState('')
  const navigate = useNavigate()


  useEffect(() => {
    if (data.plan) {
      switch (data.plan) {
      case 'Eter':
        setPlanIcon(EterIcon)
        break

      case 'Aire':
        setPlanIcon(AireIcon)
        break

      case 'Agua':
        setPlanIcon(AguaIcon)
        break

      case 'Fuego':
        setPlanIcon(FuegoIcon)
        break

      case 'Tierra':
        setPlanIcon(TierraIcon)
        break

      default:
        break
      }
    }

    if (data.last_plan) {
      switch (data.last_plan) {
      case 'Eter':
        setPlanIcon(EterIcon)
        break

      case 'Aire':
        setPlanIcon(AireIcon)
        break

      case 'Agua':
        setPlanIcon(AguaIcon)
        break

      case 'Fuego':
        setPlanIcon(FuegoIcon)
        break

      case 'Tierra':
        setPlanIcon(TierraIcon)
        break

      default:
        break
      }
    }
  }, [data.plan, data.last_plan])

  useEffect(() => {
    const checkIsMobile = () => {
      if (window.matchMedia('(max-width: 767px)').matches) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    }
    checkIsMobile()

    window.addEventListener('resize', checkIsMobile)

    return () => window.removeEventListener('resize', checkIsMobile)
  })

  useEffect(() => {
    if (type !== 'Historic') return
    setHistoric(getAction(data))
  }, [data])


  const handleInfoModal = (event, data) => {
    event.stopPropagation()
    openInfoModal(data)
  }

  const toggleShowMobileInfo = (event) => {
    event.stopPropagation()
    setShowMobileInfo((prev) => !prev)
  }

  const closeShowMobileInfo = () => {
    setTimeout(() => setShowMobileInfo(false))
  }

  const planPath = (data) => {
    if (data.plan && data.plan !== '-') {
      let path = '/panel/datos'
      if (data.company_id !== '-' && data.company_id)
        path += `/empresas/?company_id=${
          data.company_id
        }&company_label=${encodeURIComponent(
          data.company_name?.toLowerCase()
        )}`
      else
        path += `/planes/?user_id=${
          data.user_id
        }&user_label=${encodeURIComponent(
          `${data.name_reference?.toLowerCase()} ${data.last_name?.toLowerCase()}`
        )}`
      return path
    }
  }


  if (type === 'Spaces')
    return (
      <>
        {!isMobile ? (
          <button
            className={`TableItem ${
              unselectable ? 'TableItem--unselectable' : ''
            } text-5`}
          >
            <div className="TableItem__Cell">
              <span>
                {data.date
                  ? moment(data.date, 'YYYY-MM-DD').format('DD-MM')
                  : '-'}
              </span>
            </div>
            <div
              className="TableItem__Cell justify-content-between"
              title={`${data.name_reference} ${data.last_name}`}
            >
              <span className="text-nowrap">{`${data.name_reference} ${data.last_name}`}</span>

              {data.description ? (
                <FontAwesomeIcon
                  icon={faMessage}
                  title={data.description}
                />
              ) : (
                <></>
              )}
            </div>
            <div className="TableItem__Cell" title={data.company_name}>
              <span>{data.company_name || '-'}</span>
            </div>
            <div className="TableItem__Cell">
              <span>{data.booking_type === 'Test Day' ? 'Día de prueba' : data.booking_type || '-'}</span>
            </div>
            <div className="TableItem__Cell">
              <span>{data.chair_number || '-'}</span>
            </div>
            <div className="TableItem__Cell">
              <span>{data.credits || '-'}</span>
            </div>
            <div className="TableItem__Cell">
              <span>{data.hours || '-'}</span>
            </div>
            {data.plan && data.plan !== '-' ? (
              <Link
                to={planPath(data)}
                className="TableItem__Cell justify-content-between"
              >
                <div>
                  <img
                    className="TableItem__Plan-Icon"
                    src={planIcon}
                    alt={data.plan}
                    title={data.plan}
                  />
                  <span className="ms-1">{data.plan}</span>
                </div>
                <FontAwesomeIcon
                  className="rotate-45"
                  icon={faArrowRightLong}
                />
              </Link>
            ) : (
              <div className="TableItem__Cell">-</div>
            )}
            <div className="TableItem__Cell gap-1">
              {typeof data.is_paid === 'boolean' &&
                (data.is_paid ? (
                  <span className="TableItem__Status--Active">
                    <FontAwesomeIcon icon={faCircleCheck} />
                  </span>
                ) : (
                  <span className="TableItem__Status--Debtor">
                    <FontAwesomeIcon icon={faCircleXmark} />
                  </span>
                ))}
              <span>
                {typeof data.is_paid === 'boolean'
                  ? data.is_paid
                    ? 'Si'
                    : 'No'
                  : '-'}
              </span>
            </div>
            <div className="TableItem__Cell" title={data.status}>
              {data.status !== '-' &&
                (data.status === 'Activo' ? (
                  <span className="TableItem__Status--Active">
                    <FontAwesomeIcon icon={faCircleCheck} />
                  </span>
                ) : (
                  <span className="TableItem__Status--Debtor">
                    <FontAwesomeIcon icon={faCircleXmark} />
                  </span>
                ))}
              <span className="ms-1">{data.status || '-'}</span>
            </div>
          </button>
        ) : (
          <button
            className={`TableItem ${
              unselectable ? 'TableItem--unselectable' : ''
            } text-5`}
            onBlur={closeShowMobileInfo}
          >
            <div className="TableItem__Cell">
              <span>
                {data.date
                  ? moment(data.date, 'YYYY-MM-DD').format('DD-MM')
                  : '-'}
              </span>
            </div>
            <div
              className="TableItem__Cell justify-content-between"
              title={`${data.name_reference} ${data.last_name}`}
            >
              <span className="text-nowrap">{`${data.name_reference} ${data.last_name}`}</span>

              {data.description ? (
                <FontAwesomeIcon
                  icon={faMessage}
                  title={data.description}
                />
              ) : (
                <></>
              )}
            </div>
            <div className="TableItem__Cell">
              <span>{data.chair || '-'}</span>
            </div>
            <div className="TableItem__Cell gap-1">
              {typeof data.is_paid === 'boolean' &&
                (data.is_paid ? (
                  <span className="TableItem__Status--Active">
                    <FontAwesomeIcon icon={faCircleCheck} />
                  </span>
                ) : (
                  <span className="TableItem__Status--Debtor">
                    <FontAwesomeIcon icon={faCircleXmark} />
                  </span>
                ))}
              <span>
                {typeof data.is_paid === 'boolean'
                  ? data.is_paid
                    ? 'Si'
                    : 'No'
                  : '-'}
              </span>
            </div>
            <div
              className={`TableItem__Cell--ArrowDown ${
                showMobileInfo ? 'TableItem__arrowUp' : ''
              } `}
              onClick={toggleShowMobileInfo}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
            {showMobileInfo && (
              <>
                <div className="TableItem__Dropdown--Unselectable">
                  <span className="TableItem__Cell fw-bold">Empresa</span>
                  <span className="TableItem__Cell" title={data.company_name}>
                    {data.company_name || '-'}
                  </span>
                </div>
                <div className="TableItem__Dropdown--Unselectable">
                  <span className="TableItem__Cell fw-bold">Jornada</span>
                  <span className="TableItem__Cell">
                    {data.booking_type === 'Test Day' ? 'Día de prueba' : data.booking_type || '-'}
                  </span>
                </div>
                <div className="TableItem__Dropdown--Unselectable">
                  <span className="TableItem__Cell fw-bold">Créditos</span>
                  <span className="TableItem__Cell">{data.credits || '-'}</span>
                </div>
                <div className="TableItem__Dropdown--Unselectable">
                  <span className="TableItem__Cell fw-bold">Horas</span>
                  <span className="TableItem__Cell">{data.hours || '-'}</span>
                </div>
                <div className="TableItem__Dropdown--Unselectable">
                  <span className="TableItem__Cell fw-bold">Plan</span>
                  {data.plan && data.plan !== '-' ? (
                    <span
                      className="TableItem__Cell cursor-pointer"
                      onClick={() => navigate(planPath(data))}
                    >
                      <span>{data.plan}</span>
                      <FontAwesomeIcon
                        className="rotate-45 ms-2"
                        icon={faArrowRightLong}
                      />
                    </span>
                  ) : (
                    <div className="TableItem__Cell">-</div>
                  )}
                </div>
                <div className="TableItem__Dropdown--Unselectable">
                  <span className="TableItem__Cell fw-bold">Estado</span>
                  <span className="TableItem__Cell" title={data.status}>
                    {data.status || '-'}
                  </span>
                </div>
              </>
            )}
          </button>
        )}
      </>
    )

  if (type === 'Rooms')
    return (
      <>
        {!isMobile ? (
          <button
            className={`TableItem ${
              unselectable ? 'TableItem--unselectable' : ''
            } text-5`}
          >
            <div className="TableItem__Cell">
              <span>
                {data.date
                  ? moment(data.date, 'YYYY-MM-DD').format('DD-MM')
                  : '-'}
              </span>
            </div>
            <div
              className="TableItem__Cell justify-content-between"
              title={`${data.name_reference} ${data.last_name}`}
            >
              <span className="text-nowrap">{`${data.name_reference} ${data.last_name}`}</span>

              {data.description ? (
                <FontAwesomeIcon
                  icon={faMessage}
                  title={data.description}
                />
              ) : (
                <></>
              )}
            </div>
            <div className="TableItem__Cell" title={data.company_name}>
              <span>{data.company_name || '-'}</span>
            </div>
            <div className="TableItem__Cell">
              <span>{data.hours || '-'}</span>
            </div>
            <div className="TableItem__Cell">
              <span className={`TableItem__Room-Detail--${data.room}`}></span>
              <span className="ms-1">{data.room || '-'}</span>
            </div>
            <div className="TableItem__Cell">
              <span>{data.hour_range || '-'}</span>
            </div>
            {data.plan && data.plan !== '-' ? (
              <Link
                to={planPath(data)}
                className="TableItem__Cell justify-content-between"
              >
                <div>
                  <img
                    className="TableItem__Plan-Icon"
                    src={planIcon}
                    alt={data.plan}
                    title={data.plan}
                  />
                  <span className="ms-1">{data.plan}</span>
                </div>
                <FontAwesomeIcon
                  className="rotate-45"
                  icon={faArrowRightLong}
                />
              </Link>
            ) : (
              <div className="TableItem__Cell">-</div>
            )}
            <div className="TableItem__Cell gap-1">
              {typeof data.is_paid === 'boolean' &&
                (data.is_paid ? (
                  <span className="TableItem__Status--Active">
                    <FontAwesomeIcon icon={faCircleCheck} />
                  </span>
                ) : (
                  <span className="TableItem__Status--Debtor">
                    <FontAwesomeIcon icon={faCircleXmark} />
                  </span>
                ))}
              <span>
                {typeof data.is_paid === 'boolean'
                  ? data.is_paid
                    ? 'Si'
                    : 'No'
                  : '-'}
              </span>
            </div>
            <div className="TableItem__Cell" title={data.status}>
              {data.status !== '-' &&
                (data.status === 'Activo' ? (
                  <span className="TableItem__Status--Active">
                    <FontAwesomeIcon icon={faCircleCheck} />
                  </span>
                ) : (
                  <span className="TableItem__Status--Debtor">
                    <FontAwesomeIcon icon={faCircleXmark} />
                  </span>
                ))}
              <span className="ms-1">{data.status || '-'}</span>
            </div>
          </button>
        ) : (
          <button
            className={`TableItem ${
              unselectable ? 'TableItem--unselectable' : ''
            } text-5`}
            onBlur={closeShowMobileInfo}
          >
            <div className="TableItem__Cell">
              <span>
                {data.date
                  ? moment(data.date, 'YYYY-MM-DD').format('DD-MM')
                  : '-'}
              </span>
            </div>
            <div
              className="TableItem__Cell justify-content-between"
              title={`${data.name_reference} ${data.last_name}`}
            >
              <span className="text-nowrap">{`${data.name_reference} ${data.last_name}`}</span>

              {data.description ? (
                <FontAwesomeIcon
                  icon={faMessage}
                  title={data.description}
                />
              ) : (
                <></>
              )}
            </div>
            <div className="TableItem__Cell">
              <span>{data.hour_range || '-'}</span>
            </div>
            <div className="TableItem__Cell">
              <span>{data.room || '-'}</span>
            </div>
            <div
              className={`TableItem__Cell--ArrowDown ${
                showMobileInfo ? 'TableItem__arrowUp' : ''
              } `}
              onClick={toggleShowMobileInfo}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
            {showMobileInfo && (
              <>
                <div className="TableItem__Dropdown--Unselectable">
                  <span className="TableItem__Cell fw-bold">Plan</span>
                  {data.plan && data.plan !== '-' ? (
                    <span
                      className="TableItem__Cell cursor-pointer"
                      onClick={() => navigate(planPath(data))}
                    >
                      <span>{data.plan}</span>
                      <FontAwesomeIcon
                        className="rotate-45 ms-2"
                        icon={faArrowRightLong}
                      />
                    </span>
                  ) : (
                    <div className="TableItem__Cell">-</div>
                  )}
                </div>
                <div className="TableItem__Dropdown--Unselectable">
                  <span className="TableItem__Cell fw-bold">Pago</span>
                  <span className="TableItem__Cell">
                    {typeof data.is_paid === 'boolean'
                      ? data.is_paid
                        ? 'Si'
                        : 'No'
                      : '-'}
                  </span>
                </div>
                <div className="TableItem__Dropdown--Unselectable">
                  <span className="TableItem__Cell fw-bold">Empresa</span>
                  <span className="TableItem__Cell" title={data.company_name}>
                    {data.company_name || '-'}
                  </span>
                </div>
                <div className="TableItem__Dropdown--Unselectable">
                  <span className="TableItem__Cell fw-bold">Horas</span>
                  <span className="TableItem__Cell">{data.hours || '-'}</span>
                </div>
                <div className="TableItem__Dropdown--Unselectable">
                  <span className="TableItem__Cell fw-bold">Estado</span>
                  <span className="TableItem__Cell" title={data.status}>
                    {data.status || '-'}
                  </span>
                </div>
              </>
            )}
          </button>
        )}
      </>
    )

  if (type === 'Users')
    return (
      <>
        {!isMobile ? (
          <button
            className={`TableItem ${
              selected ? 'TableItem--Selected' : ''
            }  text-5`}
            onClick={() => handleSelectItem(data)}
          >
            <div className="TableItem__Cell--Checkbox">
              <div className="TableItem__Checkbox"></div>
            </div>
            <div
              className="TableItem__Cell"
              title={`${data.first_name} ${data.last_name}`}
            >
              <span className="text-nowrap">{`${data.first_name} ${data.last_name}`}</span>
            </div>
            <div className="TableItem__Cell">
              <span
                className="text-nowrap"
                title={
                  typeof data.companies === 'object'
                    ? data.companies.join(', ')
                    : data.companies
                }
              >
                {typeof data.companies === 'object'
                  ? data.companies.join(', ')
                  : data.companies}
              </span>
            </div>
            <div className="TableItem__Cell" title={data.email}>
              <span>{data.email || '-'}</span>
            </div>
            <div className="TableItem__Cell">
              <span>
                {data.birthdate
                  ? moment(data.birthdate, 'YYYY-MM-DD').format('DD/MM/YY')
                  : '-'}
              </span>
            </div>
            <Link
              to={`/panel/datos/planes/?user_id=${
                data.id
              }&user_label=${encodeURIComponent(
                `${data.first_name?.toLowerCase()} ${data.last_name?.toLowerCase()}`
              )}`}
              className="TableItem__Cell justify-content-between"
            >
              <div>
                {data.last_plan !== '-' && (
                  <img
                    className="TableItem__Plan-Icon"
                    src={planIcon}
                    alt={data.last_plan}
                    title={data.last_plan}
                  />
                )}
                <span className="ms-1">{data.last_plan || '-'}</span>
              </div>
              <FontAwesomeIcon className="rotate-45" icon={faArrowRightLong} />
            </Link>
            <div className="TableItem__Cell">
              <span>
                {data.plan_buy_time
                  ? moment(data.plan_buy_time, 'YYYY-MM-DD').format('DD/MM/YY')
                  : '-'}
              </span>
            </div>
            <div className="TableItem__Cell">
              <span>
                {data.date_joined
                  ? moment(data.date_joined, 'YYYY-MM-DD').format('DD/MM/YY')
                  : '-'}
              </span>
            </div>
            <div
              className="TableItem__Cell"
              title={
                data.is_active
                  ? !data.is_debtor
                    ? 'Activo'
                    : 'El usuario no puede realizar reservas'
                  : 'El usuario no tiene acceso a la plataforma'
              }
            >
              {data.is_active ? (
                !data.is_debtor ? (
                  <span className="TableItem__Status--Active">
                    <FontAwesomeIcon icon={faCircleCheck} />
                  </span>
                ) : (
                  <span className="TableItem__Status--Debtor">
                    <FontAwesomeIcon icon={faCircleXmark} />
                  </span>
                )
              ) : (
                <span className="TableItem__Status--Locked">
                  <FontAwesomeIcon icon={faBan} />
                </span>
              )}
              <span className="ms-1">
                {data.is_active
                  ? !data.is_debtor
                    ? 'Activo'
                    : 'Semibloqueado'
                  : 'Bloqueado'}
              </span>
            </div>
            <div className="TableItem__Cell">
              {typeof data.total_debt === 'number' ? (
                <div className='w-100 d-flex justify-content-between align-items-center'>
                  <span
                    className={classNames({ 'text-error': data.total_debt > 0 })}
                  >
                    {`$${data.total_debt}`}
                  </span>
                  <div className='button-table'>
                    <FontAwesomeIcon
                      icon={faCircleExclamation}
                      onClick={(e) => handleInfoModal(e, data)}
                    />
                  </div>
                </div>
              ) : (
                <span>-</span>
              )}
            </div>
          </button>
        ) : (
          <button
            className={`TableItem ${
              selected ? 'TableItem--Selected' : ''
            } text-5`}
            onClick={() => handleSelectItem(data)}
            onBlur={closeShowMobileInfo}
          >
            <div className="TableItem__Cell--Checkbox">
              <div className="TableItem__Checkbox" />
            </div>
            <div
              className="TableItem__Cell"
              title={`${data.first_name} ${data.last_name}`}
            >
              <span className="text-nowrap">{`${data.first_name} ${data.last_name}`}</span>
            </div>
            <div
              className="TableItem__Cell"
              title={
                data.is_active
                  ? !data.is_debtor
                    ? 'Activo'
                    : 'El usuario no puede realizar reservas'
                  : 'El usuario no tiene acceso a la plataforma'
              }
            >
              {data.is_debtor ? (
                <span className="TableItem__Status--Debtor">
                  <FontAwesomeIcon icon={faCircleXmark} />
                </span>
              ) : (
                <span className="TableItem__Status--Active">
                  <FontAwesomeIcon icon={faCircleCheck} />
                </span>
              )}
              <span className="ms-1">
                {!data.is_debtor ? 'Activo' : 'Semibloqueado'}
              </span>
            </div>
            <div className="TableItem__Cell">
              {typeof data.total_debt === 'number' ? (
                <div className='w-100 d-flex justify-content-between align-items-center'>
                  <span
                    className={classNames({ 'text-error': data.total_debt > 0 })}
                  >
                    {`$${data.total_debt}`}
                  </span>
                  <div className='button-table'>
                    <FontAwesomeIcon
                      icon={faCircleExclamation}
                      onClick={(e) => handleInfoModal(e, data)}
                    />
                  </div>
                </div>
              ) : (
                <span>-</span>
              )}
            </div>
            <div
              className={`TableItem__Cell--ArrowDown ${
                showMobileInfo ? 'TableItem__arrowUp' : ''
              } `}
              onClick={toggleShowMobileInfo}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
            {showMobileInfo && (
              <>
                <div className="TableItem__Dropdown">
                  <span />
                  <span className="TableItem__Cell fw-bold">Empresas</span>
                  <span className="TableItem__Cell">
                    <span className="text-nowrap">
                      {typeof data.companies === 'object'
                        ? data.companies.join(', ')
                        : data.companies}
                    </span>
                  </span>
                </div>
                <div className="TableItem__Dropdown">
                  <span />
                  <span className="TableItem__Cell fw-bold">Email</span>
                  <span className="TableItem__Cell" title={data.email}>
                    {data.email || '-'}
                  </span>
                </div>
                <div className="TableItem__Dropdown">
                  <span />
                  <span className="TableItem__Cell--Big fw-bold">
                    Fecha de Nacimiento
                  </span>
                  <span className="TableItem__Cell">
                    {data.birthdate
                      ? moment(data.birthdate, 'YYYY-MM-DD').format('DD/MM/YY')
                      : '-'}
                  </span>
                </div>
                <div className="TableItem__Dropdown">
                  <span />
                  <span className="TableItem__Cell fw-bold">Ultimo Plan</span>
                  {data.last_plan && data.last_plan !== '-' ? (
                    <span
                      className="TableItem__Cell cursor-pointer"
                      onClick={() =>
                        navigate(
                          `/panel/datos/planes/?user_id=${
                            data.id
                          }&user_label=${encodeURIComponent(
                            `${data.first_name?.toLowerCase()} ${data.last_name?.toLowerCase()}`
                          )}`
                        )
                      }
                    >
                      <span>{data.last_plan}</span>
                      <FontAwesomeIcon
                        className="rotate-45 ms-2"
                        icon={faArrowRightLong}
                      />
                    </span>
                  ) : (
                    <div className="TableItem__Cell">-</div>
                  )}
                </div>
                <div className="TableItem__Dropdown">
                  <span />
                  <span className="TableItem__Cell--Big fw-bold">
                    Fecha de Ultimo Plan
                  </span>
                  <span className="TableItem__Cell">
                    {data.plan_buy_time
                      ? moment(data.plan_buy_time, 'YYYY-MM-DD').format(
                        'DD/MM/YY'
                      )
                      : '-'}
                  </span>
                </div>
                <div className="TableItem__Dropdown">
                  <span />
                  <span className="TableItem__Cell--Big fw-bold">
                    Fecha de Registro
                  </span>
                  <span className="TableItem__Cell">
                    {data.date_joined
                      ? moment(data.date_joined, 'YYYY-MM-DD').format(
                        'DD/MM/YY'
                      )
                      : '-'}
                  </span>
                </div>
              </>
            )}
          </button>
        )}
      </>
    )

  if (type === 'Plans')
    return (
      <>
        {!isMobile ? (
          <button
            className={`TableItem ${
              selected ? 'TableItem--Selected' : ''
            }  text-5`}
            onClick={() => handleSelectItem(data)}
          >
            <div className="TableItem__Cell--Checkbox">
              <div className="TableItem__Checkbox"></div>
            </div>
            <Link
              to={`/panel/datos/usuarios/?user_id=${
                data.user_id
              }&user_label=${encodeURIComponent(
                `${data.first_name?.toLowerCase()} ${data.last_name?.toLowerCase()}`
              )}`}
              className="TableItem__Cell justify-content-between"
              title={`${data.first_name} ${data.last_name}`}
            >
              <span className="text-nowrap">{`${data.first_name} ${data.last_name}`}</span>
              <FontAwesomeIcon className="rotate-45" icon={faArrowRightLong} />
            </Link>
            <div className="TableItem__Cell">
              <img
                className="TableItem__Plan-Icon"
                src={planIcon}
                alt={data.last_plan}
                title={data.last_plan}
              />
              <span className="ms-1">{data.last_plan || '-'}</span>
            </div>
            <div className="TableItem__Cell">
              <span>{data.available_credits || '-'}</span>
            </div>
            <div className="TableItem__Cell">
              <span>{data.available_hours || '-'}</span>
            </div>
            <div className="TableItem__Cell">
              <span>
                {moment(data.plan_start_date, 'YYYY-MM-DD').format(
                  'DD/MM/YY'
                ) || '-'}
              </span>
            </div>
            <div className="TableItem__Cell">
              <span>
                {moment(data.plan_end_date, 'YYYY-MM-DD').format('DD/MM/YY') ||
                  '-'}
              </span>
            </div>
            <div className="TableItem__Cell">
              {data.paid ? (
                <span className="TableItem__Status--Active">
                  <FontAwesomeIcon icon={faCircleCheck} />
                </span>
              ) : (
                <span className="TableItem__Status--Debtor">
                  <FontAwesomeIcon icon={faCircleXmark} />
                </span>
              )}
              <span className="ms-1">{data.paid ? 'Si' : 'No'}</span>
            </div>
            <div className="TableItem__Cell">
              <span>
                {data.payment_amount ? `$${data.payment_amount}` : '-'}
              </span>
            </div>
          </button>
        ) : (
          <button
            className={`TableItem ${
              selected ? 'TableItem--Selected' : ''
            } text-5`}
            onClick={() => handleSelectItem(data)}
            onBlur={closeShowMobileInfo}
          >
            <div className="TableItem__Cell--Checkbox">
              <div className="TableItem__Checkbox" />
            </div>
            <Link
              to={`/panel/datos/usuarios/?user_id=${
                data.user_id
              }&user_label=${encodeURIComponent(
                `${data.first_name?.toLowerCase()} ${data.last_name?.toLowerCase()}`
              )}`}
              className="TableItem__Cell justify-content-between"
              title={`${data.first_name} ${data.last_name}`}
            >
              <span className="text-nowrap">{`${data.first_name} ${data.last_name}`}</span>
              <FontAwesomeIcon className="rotate-45" icon={faArrowRightLong} />
            </Link>
            <div className="TableItem__Cell">
              <span>{data.last_plan || '-'}</span>
            </div>
            <div className="TableItem__Cell">
              {data.paid ? (
                <span className="TableItem__Status--Active">
                  <FontAwesomeIcon icon={faCircleCheck} />
                </span>
              ) : (
                <span className="TableItem__Status--Debtor">
                  <FontAwesomeIcon icon={faCircleXmark} />
                </span>
              )}
              <span className="ms-1">{data.paid ? 'Si' : 'No'}</span>
            </div>
            <div
              className={`TableItem__Cell--ArrowDown ${
                showMobileInfo ? 'TableItem__arrowUp' : ''
              } `}
              onClick={toggleShowMobileInfo}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
            {showMobileInfo && (
              <>
                <div className="TableItem__Dropdown">
                  <span />
                  <span className="TableItem__Cell fw-bold">Créditos</span>
                  <span className="TableItem__Cell">
                    {data.available_credits || '-'}
                  </span>
                </div>
                <div className="TableItem__Dropdown">
                  <span />
                  <span className="TableItem__Cell fw-bold">Horas</span>
                  <span className="TableItem__Cell">
                    {data.available_hours || '-'}
                  </span>
                </div>
                <div className="TableItem__Dropdown">
                  <span />
                  <span className="TableItem__Cell fw-bold">Inicio</span>
                  <span className="TableItem__Cell">
                    {moment(data.plan_start_date, 'YYYY-MM-DD').format(
                      'DD/MM/YY'
                    ) || '-'}
                  </span>
                </div>
                <div className="TableItem__Dropdown">
                  <span />
                  <span className="TableItem__Cell fw-bold">Fin</span>
                  <span className="TableItem__Cell">
                    {moment(data.plan_end_date, 'YYYY-MM-DD').format(
                      'DD/MM/YY'
                    ) || '-'}
                  </span>
                </div>
                <div className="TableItem__Dropdown">
                  <span />
                  <span className="TableItem__Cell fw-bold">Precio</span>
                  <span className="TableItem__Cell">
                    {data.payment_amount ? `$${data.payment_amount}` : '-'}
                  </span>
                </div>
              </>
            )}
          </button>
        )}
      </>
    )

  if (type === 'Hours-And-Credits')
    return (
      <>
        {!isMobile ? (
          <button
            className={`TableItem text-5 ${selected ? 'TableItem--Selected' : ''}`}
            onClick={() => handleSelectItem(data)}
          >
            <div className="TableItem__Cell--Checkbox">
              <div className="TableItem__Checkbox"></div>
            </div>
            <Link
              to={`/panel/datos/usuarios/?user_id=${data?.id}&user_label=${encodeURIComponent(`${data?.full_name?.toLowerCase()}`)}`}
              className="TableItem__Cell justify-content-between"
              title={`${data?.full_name}`}
            >
              <span className="text-nowrap">
                {`${data?.full_name ? data?.full_name : '-'}`}
              </span>
              <FontAwesomeIcon className="rotate-45" icon={faArrowRightLong} />
            </Link>
            {data?.company?.name ? (
              <Link
                to={`/panel/datos/empresas/?company_id=${data?.company?.id}`}
                className="TableItem__Cell justify-content-between"
                title={`${data?.company?.name}`}
              >
                <span className="text-nowrap">
                  { data?.company?.name }
                </span>
                <FontAwesomeIcon className="rotate-45" icon={faArrowRightLong} />
              </Link>
            ) : (
              <div className="TableItem__Cell">
                <span>-</span>
              </div>
            )}
            <div className="TableItem__Cell">
              <span>{data?.email || '-'}</span>
            </div>
            <div className="TableItem__Cell">
              <span>{data.available_hours || '-'}</span>
            </div>
            <div className="TableItem__Cell">
              <span>{data.available_credits || '-'}</span>
            </div>
            <div className="TableItem__Cell">
              <div className='w-100 d-flex justify-content-between align-items-center'>
                <span
                  className={classNames({ 'text-error': data.debt > 0 })}
                >
                  {data.debt ? `$${data.debt}` : '-'}
                </span>
                {data?.id && (
                  <div className='button-table'>
                    <FontAwesomeIcon
                      icon={faCircleExclamation}
                      onClick={(e) => handleInfoModal(e, data)}
                    />
                  </div>
                )}
              </div>
            </div>
          </button>
        ) : (
          <button
            className={`TableItem ${
              selected ? 'TableItem--Selected' : ''
            } text-5`}
            onClick={() => handleSelectItem(data)}
            onBlur={closeShowMobileInfo}
          >
            <div className="TableItem__Cell--Checkbox">
              <div className="TableItem__Checkbox" />
            </div>
            <Link
              to={`/panel/datos/usuarios/?user_id=${data?.id}&user_label=${encodeURIComponent(`${data?.full_name?.toLowerCase()}`)}`}
              className="TableItem__Cell justify-content-between"
              title={`${data?.full_name}`}
            >
              <span className="text-nowrap">
                {`${data?.full_name ? data?.full_name : '-'}`}
              </span>
              <FontAwesomeIcon className="rotate-45" icon={faArrowRightLong} />
            </Link>
            {data?.company?.name ? (
              <Link
                to={`/panel/datos/empresas/?company_id=${data?.company?.id}`}
                className="TableItem__Cell justify-content-between"
                title={`${data?.company?.name}`}
              >
                <span className="text-nowrap">
                  { data?.company?.name }
                </span>
                <FontAwesomeIcon className="rotate-45" icon={faArrowRightLong} />
              </Link>
            ) : (
              <div className="TableItem__Cell">
                <span>-</span>
              </div>
            )}
            <div className="TableItem__Cell">
              <span
                className={classNames({ 'text-error': data.debt > 0 })}
              >
                {data.debt ? `$${data.debt}` : '-'}
              </span>
            </div>
            <div
              className={`TableItem__Cell--ArrowDown ${
                showMobileInfo ? 'TableItem__arrowUp' : ''
              } `}
              onClick={toggleShowMobileInfo}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
            {showMobileInfo && (
              <>
                <div className="TableItem__Dropdown">
                  <span />
                  <span className="TableItem__Cell fw-bold">Email</span>
                  <span className="TableItem__Cell">
                    { data?.email || '-' }
                  </span>
                </div>
                <div className="TableItem__Dropdown">
                  <span />
                  <span className="TableItem__Cell fw-bold">Horas activas</span>
                  <span className="TableItem__Cell">
                    { data.available_hours || '-' }
                  </span>

                </div>
                <div className="TableItem__Dropdown">
                  <span />
                  <span className="TableItem__Cell fw-bold">Créditos activos</span>
                  <span className="TableItem__Cell">
                    { data.available_credits || '-' }
                  </span>
                </div>
                <div className="TableItem__Dropdown">
                  <span />
                  <span className="TableItem__Cell fw-bold">Deuda</span>
                  <div className='w-100 d-flex justify-content-between align-items-center'>
                    <span
                      className={classNames({ 'text-error': data.debt > 0 })}
                    >
                      {data.debt ? `$${data.debt}` : '-'}
                    </span>
                    {data?.id && (
                      <div className='button-table'>
                        <FontAwesomeIcon
                          icon={faCircleExclamation}
                          onClick={(e) => handleInfoModal(e, data)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </button>
        )}
      </>
    )

  if (type === 'Hours-And-Credits-Detail')
    return (
      <>
        {!isMobile ? (
          <div
            className={classNames(
              'TableItem text-5',
              { 'TableItem--Selected': selected }
            )}
          >
            <div className="TableItem__Cell">
              <span>{moment(data.start_date, 'YYYY-MM-DD').format('DD/MM/YY') || '-'}</span>
            </div>
            <div className="TableItem__Cell">
              <span className='text-success me-1'>+</span>
              <span>Agregar</span>
            </div>
            <div className="TableItem__Cell">
              <span>
                {
                  data.plan?.id
                    ? `${data.plan.name} - ${data.plan.plan_type.includes('Part') ? 'Part' : 'Full'}`
                    : '-'
                }
              </span>
            </div>
            <div className="TableItem__Cell">
              <span>{data.available_hours || '-'}</span>
              {/* <span>{data.available_hours ? `${data.available_hours} ($${Math.round(data.payment_amount / data.available_hours)})` : '-'}</span> */}
            </div>
            <div className="TableItem__Cell">
              <span>{data.available_credits || '-'}</span>
              {/* <span>{data.available_credits ? `${data.available_credits} ($${Math.round(data.payment_amount / data.available_credits)})` : '-'}</span> */}
            </div>
            <div className="TableItem__Cell">
              <span>{moment(data.end_date, 'YYYY-MM-DD').format('DD/MM/YY') || '-'}</span>
            </div>
            <div className="TableItem__Cell">
              <span>
                {data.paid
                  ? <>
                    <FontAwesomeIcon icon={faCircleCheck} className='text-success'/>{' '}
                    Sí
                  </>
                  : <>
                    <FontAwesomeIcon icon={faCircleXmark} className='text-error'/>{' '}
                    No
                  </>
                }
              </span>
            </div>
            <div className="TableItem__Cell">
              <span className={classNames({ 'text-error': data.payment_amount > 0 && !data.paid })}>{data.payment_amount ? `$${data.payment_amount}` : '-'}</span>
            </div>
          </div>
        ) : (
          <div
            className={classNames(
              'TableItem text-5',
              { 'TableItem--Selected': selected }
            )}
            onBlur={closeShowMobileInfo}
          >
            <div className="TableItem__Cell">
              <span>{moment(data.start_date, 'YYYY-MM-DD').format('DD/MM/YY') || '-'}</span>
            </div>
            <div className="TableItem__Cell">
              <span className='text-success me-1'>+</span>
              <span>Agregar</span>
            </div>
            <div className="TableItem__Cell">
              <span>
                {data.paid
                  ? <>
                    <FontAwesomeIcon icon={faCircleCheck} className='text-success'/>{' '}
                    Sí
                  </>
                  : <>
                    <FontAwesomeIcon icon={faCircleXmark} className='text-error'/>{' '}
                    No
                  </>
                }
              </span>
            </div>
            <div className="TableItem__Cell">
              <span className={classNames({ 'text-error': data.payment_amount > 0 && !data.paid })}>{data.payment_amount ? `$${data.payment_amount}` : '-'}</span>
            </div>
            <div
              className={`TableItem__Cell--ArrowDown ${
                showMobileInfo ? 'TableItem__arrowUp' : ''
              } `}
              onClick={toggleShowMobileInfo}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
            {showMobileInfo && (
              <>
                <div className="TableItem__Dropdown">
                  <span className="TableItem__Cell fw-bold">Plan</span>
                  <span className="TableItem__Cell">
                    {
                      data.plan?.id
                        ? `${data.plan.name} - ${data.plan.plan_type.includes('Part') ? 'Part' : 'Full'}`
                        : '-'
                    }
                  </span>
                </div>
                <div className="TableItem__Dropdown">
                  <span className="TableItem__Cell fw-bold">Horas</span>
                  <span className="TableItem__Cell">
                    {data.available_hours || '-'}
                  </span>
                </div>
                <div className="TableItem__Dropdown">
                  <span className="TableItem__Cell fw-bold">Créditos</span>
                  <span className="TableItem__Cell">
                    {data.available_credits || '-'}
                  </span>
                </div>
                <div className="TableItem__Dropdown">
                  <span className="TableItem__Cell fw-bold">Vencimiento</span>
                  <span className="TableItem__Cell">
                    {moment(data.end_date, 'YYYY-MM-DD').format('DD/MM/YY') || '-'}
                  </span>
                </div>
              </>
            )}
          </div>
        )}
      </>
    )

  if (type === 'Hours-And-Credits-Paid')
    return (
      <>
        {!isMobile ? (
          <button
            className={classNames(
              'TableItem text-5',
              { 'TableItem--Selected': selected }
            )}
            onClick={() => handleSelectItem(data)}
          >
            <div className="TableItem__Cell--Checkbox">
              <div className="TableItem__Checkbox"></div>
            </div>
            <div className="TableItem__Cell">
              <span>{moment(data.start_date, 'YYYY-MM-DD').format('DD/MM/YY') || '-'}</span>
            </div>
            <div className="TableItem__Cell">
              {data.status === 'Agregar'
                ? <span className='text-success me-1'>+</span>
                : <span className='text-red me-1'>-</span>
              }
              <span>{data.status || '-'}</span>
            </div>
            <div className="TableItem__Cell">
              <span>
                {
                  data.plan?.id
                    ? `${data.plan.name} - ${data.plan.plan_type.includes('Part') ? 'Part' : 'Full'}`
                    : '-'
                }
              </span>
            </div>
            <div className="TableItem__Cell">
              <span>{data.available_hours || '-'}</span>
              {/* <span>{data.available_hours ? `${data.available_hours} ($${Math.round(data.payment_amount / data.available_hours)})` : '-'}</span> */}
            </div>
            <div className="TableItem__Cell">
              <span>{data.available_credits || '-'}</span>
              {/* <span>{data.available_credits ? `${data.available_credits} ($${Math.round(data.payment_amount / data.available_credits)})` : '-'}</span> */}
            </div>
            <div className="TableItem__Cell">
              <span>{moment(data.end_date, 'YYYY-MM-DD').format('DD/MM/YY') || '-'}</span>
            </div>
            <div className="TableItem__Cell">
              <span>
                {data.paid
                  ? <>
                    <FontAwesomeIcon icon={faCircleCheck} className='text-success'/>{' '}
                    Sí
                  </>
                  : <>
                    <FontAwesomeIcon icon={faCircleXmark} className='text-error'/>{' '}
                    No
                  </>
                }
              </span>
            </div>
            <div className="TableItem__Cell">
              <span className={classNames({ 'text-error': data.payment_amount > 0 && !data.paid })}>{data.payment_amount ? `$${data.payment_amount}` : '-'}</span>
            </div>
          </button>
        ) : (
          <button
            className={`TableItem ${
              selected ? 'TableItem--Selected' : ''
            } text-5`}
            onClick={() => handleSelectItem(data)}
            // onBlur={closeShowMobileInfo}
          >
            <div className="TableItem__Cell--Checkbox">
              <div className="TableItem__Checkbox"></div>
            </div>
            <div className="TableItem__Cell">
              <span>{moment(data.start_date, 'YYYY-MM-DD').format('DD/MM/YY') || '-'}</span>
            </div>
            <div className="TableItem__Cell">
              {data.status === 'Agregar'
                ? <span className='text-success me-1'>+</span>
                : <span className='text-red me-1'>-</span>
              }
              <span>{data.status || '-'}</span>
            </div>
            <div className="TableItem__Cell">
              <span>
                {data.paid
                  ? <>
                    <FontAwesomeIcon icon={faCircleCheck} className='text-success'/>{' '}
                    Sí
                  </>
                  : <>
                    <FontAwesomeIcon icon={faCircleXmark} className='text-error'/>{' '}
                    No
                  </>
                }
              </span>
            </div>
            <div className="TableItem__Cell">
              <span className={classNames({ 'text-error': data.payment_amount > 0 && !data.paid })}>{data.payment_amount ? `$${data.payment_amount}` : '-'}</span>
            </div>
            <div
              className={`TableItem__Cell--ArrowDown ${
                showMobileInfo ? 'TableItem__arrowUp' : ''
              } `}
              onClick={toggleShowMobileInfo}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
            {showMobileInfo && (
              <>
                <div className="TableItem__Dropdown">
                  <span></span>
                  <span className="TableItem__Cell fw-bold">Plan</span>
                  <span className="TableItem__Cell">
                    {
                      data.plan?.id
                        ? `${data.plan.name} - ${data.plan.plan_type.includes('Part') ? 'Part' : 'Full'}`
                        : '-'
                    }
                  </span>
                </div>
                <div className="TableItem__Dropdown">
                  <span></span>
                  <span className="TableItem__Cell fw-bold">Horas</span>
                  <span className="TableItem__Cell">
                    {data.available_hours || '-'}
                  </span>
                </div>
                <div className="TableItem__Dropdown">
                  <span></span>
                  <span className="TableItem__Cell fw-bold">Créditos</span>
                  <span className="TableItem__Cell">
                    {data.available_credits || '-'}
                  </span>
                </div>
                <div className="TableItem__Dropdown">
                  <span></span>
                  <span className="TableItem__Cell fw-bold">Vencimiento</span>
                  <span className="TableItem__Cell">
                    {moment(data.end_date, 'YYYY-MM-DD').format('DD/MM/YY') || '-'}
                  </span>
                </div>
              </>
            )}
          </button>
        )}
      </>
    )

  if (type === 'Companies')
    return (
      <>
        {!isMobile ? (
          <button
            className={`TableItem ${
              selected ? 'TableItem--Selected' : ''
            }  text-5`}
            onClick={() => handleSelectItem(data)}
          >
            <div className="TableItem__Cell--Checkbox">
              <div className="TableItem__Checkbox"></div>
            </div>
            <div className="TableItem__Cell" title={data.name}>
              <span>{data.name || '-'}</span>
            </div>
            <Link
              to={`/panel/datos/usuarios/?company_id=${
                data.id
              }&company_label=${encodeURIComponent(data.name?.toLowerCase())}`}
              className="TableItem__Cell justify-content-between"
            >
              <span>{data.total_people || '-'}</span>
              <FontAwesomeIcon className="rotate-45" icon={faArrowRightLong} />
            </Link>
            <div
              className="TableItem__Cell"
              title={
                `(${data.admines.length})  ${data.admines[0]?.user__first_name} ${data.admines[0]?.user__last_name}` ||
                '-'
              }
            >
              <span className="text-nowrap">
                {(data.admines.length > 0) ? `(${data.admines.length}) ${data.admines?.[0]?.user__first_name} ${data.admines?.[0]?.user__last_name}` : '(0) -'}
              </span>
            </div>
            <div className="TableItem__Cell">
              <span>
                {data.date_created
                  ? moment(data.date_created, 'YYYY-MM-DD').format('DD/MM/YY')
                  : '-'}
              </span>
            </div>
            <div className="TableItem__Cell">
              <span>{data.phone || '-'}</span>
            </div>
            <div
              className="TableItem__Cell"
              title={!data.is_debtor ? 'Activo' : 'Semibloqueado'}
            >
              {data.is_debtor ? (
                <span className="TableItem__Status--Debtor">
                  <FontAwesomeIcon icon={faCircleXmark} />
                </span>
              ) : (
                <span className="TableItem__Status--Active">
                  <FontAwesomeIcon icon={faCircleCheck} />
                </span>
              )}
              <span className="ms-1">
                {!data.is_debtor ? 'Activo' : 'Semibloqueado'}
              </span>
            </div>
            <div className="TableItem__Cell">
              {typeof data.total_debt === 'number' ? (
                <span
                  className={classNames({ 'text-error': data.total_debt > 0 })}
                >
                  {`$${data.total_debt}`}
                </span>
              ) : (
                <span>-</span>
              )}
            </div>
            {/* <div className="TableItem__Cell justify-content-center"> */}
            <Link
              className="TableItem__Cell--Action gap-2 text-5"
              to={`/panel/datos/empresa/${data.name.replace(/\s/g, '-')}`}
              state={{
                companyId: data.id,
                companyData: data
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
              <span>Ver más</span>
            </Link>
          </button>
        ) : (
          <button
            className={`TableItem ${
              selected ? 'TableItem--Selected' : ''
            } text-5`}
            onClick={() => handleSelectItem(data)}
            onBlur={closeShowMobileInfo}
          >
            <div className="TableItem__Cell--Checkbox">
              <div className="TableItem__Checkbox" />
            </div>
            <div className="TableItem__Cell" title={data.name}>
              <span>{data.name || '-'}</span>
            </div>
            <div className="TableItem__Cell">
              {data.is_debtor ? (
                <span
                  className="TableItem__Status--Debtor"
                  title={!data.is_debtor ? 'Activo' : 'Semibloqueado'}
                >
                  <FontAwesomeIcon icon={faCircleXmark} />
                </span>
              ) : (
                <span className="TableItem__Status--Active">
                  <FontAwesomeIcon icon={faCircleCheck} />
                </span>
              )}
              <span className="ms-1">
                {!data.is_debtor ? 'Activo' : 'Semibloqueado'}
              </span>
            </div>
            <div className="TableItem__Cell">
              {typeof data.total_debt === 'number' ? (
                <span
                  className={classNames({ 'text-error': data.total_debt > 0 })}
                >
                  {`$${data.total_debt}`}
                </span>
              ) : (
                <span>-</span>
              )}
            </div>
            <div
              className={`TableItem__Cell--ArrowDown ${
                showMobileInfo ? 'TableItem__arrowUp' : ''
              } `}
              onClick={toggleShowMobileInfo}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
            {showMobileInfo && (
              <>
                <div className="TableItem__Dropdown">
                  <span />
                  <span className="TableItem__Cell fw-bold">Miembros</span>
                  <span
                    className="TableItem__Cell"
                    onClick={() =>
                      navigate(
                        `/panel/datos/usuarios/?company_id=${
                          data.id
                        }&company_label=${encodeURIComponent(
                          data.name?.toLowerCase()
                        )}`
                      )
                    }
                  >
                    {data.total_people || '-'}
                    <FontAwesomeIcon
                      className="rotate-45 ms-2"
                      icon={faArrowRightLong}
                    />
                  </span>
                </div>
                <div className="TableItem__Dropdown">
                  <span />
                  <span className="TableItem__Cell--Big fw-bold">
                    Administrador/ es
                  </span>
                  <span
                    className="TableItem__Cell"
                    title={
                      `(${data.admines.length})  ${data.admines[0]?.user__first_name} ${data.admines[0]?.user__last_name}` ||
                      '-'
                    }
                  >
                    {`(${data.admines.length}) ${data.admines[0]?.user__first_name} ${data.admines[0]?.user__last_name}` ||
                      '-'}
                  </span>
                </div>
                <div className="TableItem__Dropdown">
                  <span />
                  <span className="TableItem__Cell--Big fw-bold">
                    Fecha de Registro
                  </span>
                  <span className="TableItem__Cell">
                    {data.date_created
                      ? moment(data.date_created, 'YYYY-MM-DD').format(
                        'DD/MM/YY'
                      )
                      : '-'}
                  </span>
                </div>
                <div className="TableItem__Dropdown">
                  <span />
                  <span className="TableItem__Cell fw-bold">Teléfono</span>
                  <span className="TableItem__Cell">{data.phone || '-'}</span>
                </div>
                <div className="TableItem__Dropdown">
                  <span />
                  <span />
                  <div
                    className="TableItem__Cell--Action justify-content-end fw-bold"
                    onClick={(e) => handleInfoModal(e, data)}
                  >
                    <span className="me-4">+ Ver más</span>
                  </div>
                </div>
              </>
            )}
          </button>
        )}
      </>
    )

  if (type === 'Offices')
    return (
      <>
        {!isMobile ? (
          <button
            className={`TableItem ${
              unselectable ? 'TableItem--unselectable' : ''
            } text-5`}
          >
            <div className="TableItem__Cell">
              <span className="text-nowrap" title={data.full_name}>
                {data.full_name || '-'}
              </span>
            </div>
            <div className="TableItem__Cell">
              <span className="text-nowrap" title={data.company_name}>
                {data.company_name || '-'}
              </span>
            </div>
            <div
              className="TableItem__Cell"
              title={data.office_name && `Oficina ${data.office_name}`}
            >
              <span>
                {(data.office_name && `Oficina ${data.office_name}`) || '-'}
              </span>
            </div>
            <div className="TableItem__Cell">
              <span>{data.type === 'admin' ? 'admin' : 'usuario'}</span>
            </div>
          </button>
        ) : (
          <button
            className={`TableItem ${
              unselectable ? 'TableItem--unselectable' : ''
            } text-5`}
            onBlur={closeShowMobileInfo}
          >
            <div className="TableItem__Cell" title={data.full_name}>
              <span className="text-nowrap">{data.full_name || '-'}</span>
            </div>
            <div className="TableItem__Cell" title={data.company_name}>
              <span className="text-nowrap">{data.company_name || '-'}</span>
            </div>
            <div
              className="TableItem__Cell"
              title={data.office_name && `Oficina ${data.office_name}`}
            >
              <span className="text-nowrap">
                {(data.office_name && `Oficina ${data.office_name}`) || '-'}
              </span>
            </div>
            <div
              className={`TableItem__Cell--ArrowDown ${
                showMobileInfo ? 'TableItem__arrowUp' : ''
              } `}
              onClick={toggleShowMobileInfo}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
            {showMobileInfo && (
              <>
                <div className="TableItem__Dropdown--Unselectable">
                  <span className="TableItem__Cell fw-bold">
                    Tipo de Usuario
                  </span>
                  <span className="TableItem__Cell">
                    {data.type === 'admin' ? 'admin' : 'usuario'}
                  </span>
                </div>
              </>
            )}
          </button>
        )}
      </>
    )

  if (type === 'Historic')
    return (
      <>
        {!isMobile ? (
          <button
            className={`TableItem ${
              unselectable ? 'TableItem--unselectable' : ''
            } text-5`}
          >
            <div className="TableItem__Cell">
              <span>
                {data.log_date
                  ? moment(data.log_date, 'YYYY-MM-DD').format('DD-MM-YY')
                  : '-'}
              </span>
            </div>
            <div className="TableItem__Cell">
              <span className="first-letter-uppercase">
                {data.user_type === 'admin_empresa'
                  ? 'admin de empresa'
                  : data.user_type}
              </span>
            </div>
            <div
              className="TableItem__Cell"
              title={
                data.user?.first_name
                  ? `${data.user?.first_name} ${data.user?.last_name}`
                  : 'Servidor'
              }
            >
              <span className="text-nowrap">
                {data.user?.first_name
                  ? `${data.user?.first_name} ${data.user?.last_name}`
                  : 'Servidor'}
              </span>
            </div>
            <div className="TableItem__Cell" title={historic}>
              <span className="text-nowrap">{historic}</span>
            </div>
          </button>
        ) : (
          <button
            className={`TableItem ${
              unselectable ? 'TableItem--unselectable' : ''
            } text-5`}
            onBlur={closeShowMobileInfo}
          >
            <div className="TableItem__Cell">
              <span>
                {data.log_date
                  ? moment(data.log_date, 'YYYY-MM-DD').format('DD-MM-YY')
                  : '-'}
              </span>
            </div>
            <div
              className="TableItem__Cell"
              title={
                data.user?.first_name
                  ? `${data.user?.first_name} ${data.user?.last_name}`
                  : 'Servidor'
              }
            >
              <span className="text-nowrap">
                {data.user?.first_name
                  ? `${data.user?.first_name} ${data.user?.last_name}`
                  : 'Servidor'}
              </span>
            </div>
            <div className="TableItem__Cell" title={historic}>
              <span className="text-nowrap">{historic}</span>
            </div>
            <div
              className={`${
                data.hour_range ? 'TableItem__Cell--Big' : ''
              } TableItem__Cell--ArrowDown ${
                showMobileInfo ? 'TableItem__arrowUp' : ''
              } `}
              onClick={toggleShowMobileInfo}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
            {showMobileInfo && (
              <>
                <div className="TableItem__Dropdown--Unselectable">
                  <span className="TableItem__Cell--Extra-Big fw-bold">
                    acción
                  </span>
                  <span
                    className="TableItem__Cell--Extra-Big first-letter-uppercase"
                    title={historic}
                  >
                    {historic}
                  </span>
                </div>
                <div className="TableItem__Dropdown--Unselectable">
                  <span className="TableItem__Cell fw-bold">
                    Tipo de usuario
                  </span>
                  <span className="TableItem__Cell first-letter-uppercase">
                    {data.user_type === 'admin_empresa'
                      ? 'admin de empresa'
                      : data.user_type}
                  </span>
                </div>
              </>
            )}
          </button>
        )}
      </>
    )

  if (type === 'Email-Users')
    return (
      <>
        {!isMobile ? (
          <button
            className={`TableItem ${
              selected ? 'TableItem--Selected' : ''
            }  text-5`}
            onClick={() => handleSelectItem(data)}
          >
            <div className="TableItem__Cell--Checkbox">
              <div className="TableItem__Checkbox"></div>
            </div>
            <div
              className="TableItem__Cell"
              title={`${data.first_name} ${data.last_name}`}
            >
              <span className="text-nowrap">{`${data.first_name} ${data.last_name}`}</span>
            </div>
            <div className="TableItem__Cell" title={data.email}>
              <span>{data.email || '-'}</span>
            </div>
            <div className="TableItem__Cell">
              <span>
                {data.birthdate
                  ? moment(data.birthdate, 'YYYY-MM-DD').format('DD/MM/YY')
                  : '-'}
              </span>
            </div>
            <div
              className="TableItem__Cell"
              title={
                data.is_active
                  ? !data.is_debtor
                    ? 'Activo'
                    : 'El usuario no puede realizar reservas'
                  : 'El usuario no tiene acceso a la plataforma'
              }
            >
              {data.is_active ? (
                !data.is_debtor ? (
                  <span className="TableItem__Status--Active">
                    <FontAwesomeIcon icon={faCircleCheck} />
                  </span>
                ) : (
                  <span className="TableItem__Status--Debtor">
                    <FontAwesomeIcon icon={faCircleXmark} />
                  </span>
                )
              ) : (
                <span className="TableItem__Status--Locked">
                  <FontAwesomeIcon icon={faBan} />
                </span>
              )}
              <span className="ms-1">
                {data.is_active
                  ? !data.is_debtor
                    ? 'Activo'
                    : 'Semibloqueado'
                  : 'Bloqueado'}
              </span>
            </div>
          </button>
        ) : (
          <button
            className={`TableItem ${
              selected ? 'TableItem--Selected' : ''
            }  text-5`}
            onClick={() => handleSelectItem(data)}
          >
            <div className="TableItem__Cell--Checkbox">
              <div className="TableItem__Checkbox" />
            </div>
            <div
              className="TableItem__Cell"
              title={`${data.first_name} ${data.last_name}`}
            >
              <span className="text-nowrap">{`${data.first_name} ${data.last_name}`}</span>
            </div>
            <div className="TableItem__Cell">
              <span>
                {data.birthdate
                  ? moment(data.birthdate, 'YYYY-MM-DD').format('DD/MM/YY')
                  : '-'}
              </span>
            </div>
            <div
              className="TableItem__Cell"
              title={
                data.is_active
                  ? !data.is_debtor
                    ? 'Activo'
                    : 'El usuario no puede realizar reservas'
                  : 'El usuario no tiene acceso a la plataforma'
              }
            >
              {data.is_active ? (
                !data.is_debtor ? (
                  <span className="TableItem__Status--Active">
                    <FontAwesomeIcon icon={faCircleCheck} />
                  </span>
                ) : (
                  <span className="TableItem__Status--Debtor">
                    <FontAwesomeIcon icon={faCircleXmark} />
                  </span>
                )
              ) : (
                <span className="TableItem__Status--Locked">
                  <FontAwesomeIcon icon={faBan} />
                </span>
              )}
              <span className="ms-1">
                {data.is_active
                  ? !data.is_debtor
                    ? 'Activo'
                    : 'Semibloqueado'
                  : 'Bloqueado'}
              </span>
            </div>
          </button>
        )}
      </>
    )

  if (type === 'Return-Credits')
    return (
      <>
        {!isMobile ? (
          <button
            className={`TableItem ${
              selected ? 'TableItem--Selected' : ''
            } text-5`}
            onClick={() => handleSelectItem(data)}
          >
            <div className="TableItem__Cell--Checkbox">
              <div className="TableItem__Checkbox"></div>
            </div>
            <div className="TableItem__Cell">
              <span>{data.date || '-'}</span>
            </div>
            <div className="TableItem__Cell" title={data.first_name}>
              <span className="text-nowrap">{data.first_name || '-'}</span>
            </div>
            <div className="TableItem__Cell" title={data.last_name}>
              <span className="text-nowrap">{data.last_name || '-'}</span>
            </div>
            <div className="TableItem__Cell">
              <span>{data.service || '-'}</span>
            </div>
            <div className="TableItem__Cell">
              <span>{data.schedule || '-'}</span>
            </div>
          </button>
        ) : (
          <button
            className={`TableItem ${
              selected ? 'TableItem--Selected' : ''
            } text-5`}
            onClick={() => handleSelectItem(data)}
          >
            <div className="TableItem__Cell--Checkbox">
              <div className="TableItem__Checkbox" />
            </div>
            <div className="TableItem__Cell">
              <span>{data.date || '-'}</span>
            </div>
            <div className="TableItem__Cell" title={data.last_name}>
              <span className="text-nowrap">{data.last_name || '-'}</span>
            </div>
            <div className="TableItem__Cell">
              <span>{data.service || '-'}</span>
            </div>
            <div className="TableItem__Cell">
              <span>{data.schedule || '-'}</span>
            </div>
          </button>
        )}
      </>
    )

  if (type === 'Assign-Office')
    return (
      <button
        className={`TableItem ${selected ? 'TableItem--Selected' : ''} text-5`}
        onClick={() => handleSelectItem(data)}
      >
        <div className="TableItem__Cell--Checkbox">
          <div className="TableItem__Checkbox"></div>
        </div>
        <div className="TableItem__Cell" title={data.name}>
          <span>{data.name || '-'}</span>
        </div>
        <div className="TableItem__Cell">
          <span>{data.admines.length + data.employees.length || '-'}</span>
        </div>
      </button>
    )

  if (type === 'Add-Company')
    return (
      <button
        className={`TableItem ${selected ? 'TableItem--Selected' : ''} text-5`}
        onClick={() => handleSelectItem(data)}
      >
        <div className="TableItem__Cell--Checkbox">
          <div className="TableItem__Checkbox"></div>
        </div>
        <div
          className="TableItem__Cell"
          title={`${data.first_name} ${data.last_name}`}
        >
          <span className="text-nowrap">{`${data.first_name} ${data.last_name}`}</span>
        </div>
        <div className="TableItem__Cell" title={data.email}>
          <span>{data.email || '-'}</span>
        </div>
      </button>
    )

  //User

  if (type === 'Assign-Plan')
    return (
      <button
        className={`TableItem ${selected ? 'TableItem--Selected' : ''} text-5`}
        onClick={() => handleSelectItem(data)}
      >
        <div className="TableItem__Cell--Checkbox">
          <div className="TableItem__Checkbox" />
        </div>
        <div
          className="TableItem__Cell"
          title={`${data.first_name} ${data.last_name}`}
        >
          <span className="text-nowrap">{`${data.first_name} ${data.last_name}`}</span>
        </div>
        <div className="TableItem__Cell">
          <span>{data.type === 'Company_admin' ? 'Admin' : 'Usuario'}</span>
        </div>
        <div
          className="TableItem__Cell--Dropdown"
          onClick={(e) => handleInfoModal(e, data)}
        >
          <span>{data.plan || '-'}</span>
          <div className="TableItem__Button-Arrow text-2">
            <FontAwesomeIcon icon={faCaretDown} />
          </div>
        </div>
      </button>
    )

  if (type === 'Assign-Credits')
    return (
      <button
        className={`TableItem ${selected ? 'TableItem--Selected' : ''} text-5`}
        onClick={() => handleSelectItem(data)}
      >
        <div className="TableItem__Cell--Checkbox">
          <div className="TableItem__Checkbox"></div>
        </div>
        <div
          className="TableItem__Cell"
          title={`${data.first_name} ${data.last_name}`}
        >
          <span className="text-nowrap">{`${data.first_name} ${data.last_name}`}</span>
        </div>
        <div className="TableItem__Cell">
          <span>{data.type === 'Company_admin' ? 'Admin' : 'Usuario'}</span>
        </div>
      </button>
    )

  if (type === 'Company-Users')
    return (
      <>
        {!isMobile ? (
          <button
            className={`TableItem--Big ${
              unselectable ? 'TableItem--unselectable' : ''
            } text-5`}
          >
            <div
              className="TableItem__Cell"
              title={`${data.first_name} ${data.last_name}`}
            >
              <span className="text-nowrap">{`${data.first_name} ${data.last_name}`}</span>
            </div>
            <div className="TableItem__Cell--Plans">
              {data.plans?.slice(0, 2).map((plan) => (
                <span key={plan.id}>{plan.parent_plan || '-'}</span>
              ))}
              {data.plans?.length === 0 && '-'}
            </div>
            <div className="TableItem__Cell--Plans">
              {data.plans?.slice(0, 2).map((plan) => (
                <span key={plan.id}>
                  {moment(plan.end_date, 'YYYY-MM-DD').format('DD/MM/YY') ||
                    '-'}
                </span>
              ))}
              {data.plans?.length === 0 && '-'}
            </div>
            <div className="TableItem__Cell--Plans">
              {data.plans?.slice(0, 2).map((plan) => (
                <span key={plan.id}>{plan.available_credits}</span>
              ))}
              {data.plans?.length === 0 && '-'}
            </div>
            <div className="TableItem__Cell--Plans">
              {data.plans?.slice(0, 2).map((plan) => (
                <span key={plan.id}>{plan.available_hours}</span>
              ))}
              {data.plans?.length === 0 && '-'}
            </div>
            <div className="TableItem__Cell">
              <span>{data.assigned_credits || '0'}</span>
            </div>
            <div className="TableItem__Cell">
              <span>{data.assigned_hours || '0'}</span>
            </div>
            <div className="TableItem__Cell">
              <span>{data.type === 'Company_admin' ? 'Admin' : 'Usuario'}</span>
            </div>
            {data.id === user.data.id ? (
              <>
                <div className="TableItem__Cell" />
                <div className="TableItem__Cell" />
              </>
            ) : (
              <>
                <div
                  className="TableItem__Cell--Checkbox"
                  onClick={(e) =>
                    handleInfoModal(e, { action: 'view user', user: data })
                  }
                >
                  <FontAwesomeIcon
                    className="rotate-90"
                    icon={faCircleExclamation}
                  />
                </div>
                <div
                  className="TableItem__Cell--Checkbox"
                  onClick={(e) =>
                    handleInfoModal(e, { action: 'remove user', user: data })
                  }
                >
                  <FontAwesomeIcon icon={faXmark} />
                </div>
              </>
            )}
          </button>
        ) : (
          <button
            className={`TableItem--Big ${
              unselectable ? 'TableItem--unselectable' : ''
            } text-5`}
            onBlur={closeShowMobileInfo}
          >
            <div
              className="TableItem__Cell"
              title={`${data.first_name} ${data.last_name}`}
            >
              <span className="text-nowrap">{`${data.first_name} ${data.last_name}`}</span>
            </div>
            <div className="TableItem__Cell--Plans">
              {data.plans?.slice(0, 2).map((plan) => (
                <span key={plan.id}>{plan.parent_plan || '-'}</span>
              ))}
              {data.plans.length === 0 && '-'}
            </div>
            <div className="TableItem__Cell--Plans">
              {data.plans?.slice(0, 2).map((plan) => (
                <span key={plan.id}>
                  {moment(plan.end_date, 'YYYY-MM-DD').format('DD/MM/YY') ||
                    '-'}
                </span>
              ))}
              {data.plans?.length === 0 && '-'}
            </div>
            <div
              className={`TableItem__Cell TableItem__Cell--ArrowDown ${
                showMobileInfo ? 'TableItem__arrowUp' : ''
              } `}
              onClick={toggleShowMobileInfo}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
            {showMobileInfo && (
              <>
                <div className="TableItem__Dropdown--Unselectable">
                  <span className="TableItem__Cell fw-bold">
                    Créditos del plan
                  </span>
                  <span className="TableItem__Cell--Plans">
                    {data.plans?.slice(0, 2).map((plan) => (
                      <span key={plan.id}>{plan.available_credits}</span>
                    ))}
                    {data.plans?.length === 0 && '-'}
                  </span>
                </div>
                <div className="TableItem__Dropdown--Unselectable">
                  <span className="TableItem__Cell fw-bold">
                    Horas del plan
                  </span>
                  <span className="TableItem__Cell--Plans">
                    {data.plans?.slice(0, 2).map((plan) => (
                      <span key={plan.id}>{plan.available_hours}</span>
                    ))}
                    {data.plans?.length === 0 && '-'}
                  </span>
                </div>
                <div className="TableItem__Dropdown--Unselectable">
                  <span className="TableItem__Cell--Big fw-bold">
                    Créditos asignados
                  </span>
                  <span className="TableItem__Cell">
                    {data.assigned_credits || '0'}
                  </span>
                </div>
                <div className="TableItem__Dropdown--Unselectable">
                  <span className="TableItem__Cell--Big fw-bold">
                    Horas asignadas
                  </span>
                  <span className="TableItem__Cell">
                    {data.assigned_hours || '0'}
                  </span>
                </div>
                <div className="TableItem__Dropdown--Unselectable">
                  <span className="TableItem__Cell--Big fw-bold">
                    Tipo de usuario
                  </span>
                  <span className="TableItem__Cell">
                    {data.type === 'Company_admin' ? 'Admin' : 'Usuario'}
                  </span>
                </div>
                {!(data.id === user.data.id) && (
                  <>
                    <div className="TableItem__Dropdown--Unselectable">
                      <span />
                      <div
                        className="TableItem__Cell--Action justify-content-end gap-2 fw-bold"
                        onClick={(e) =>
                          handleInfoModal(e, {
                            action: 'view user',
                            user: data,
                          })
                        }
                      >
                        <FontAwesomeIcon icon={faPlus} />
                        <span className="me-4">Ver detalle de usuario</span>
                      </div>
                    </div>
                    <div className="TableItem__Dropdown--Unselectable">
                      <span />
                      <div
                        className="TableItem__Cell--Action justify-content-end gap-2 fw-bold"
                        onClick={(e) =>
                          handleInfoModal(e, {
                            action: 'remove user',
                            user: data,
                          })
                        }
                      >
                        <FontAwesomeIcon icon={faMinus} />
                        <span className="me-4">Eliminar integrante</span>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </button>
        )}
      </>
    )

  if (type === 'Reservation-Admin')
    return (
      <>
        {!isMobile ? (
          <button
            className={`TableItem ${
              unselectable ? 'TableItem--unselectable' : ''
            } text-5`}
          >
            <div className="TableItem__Cell">
              <span>
                {moment(data.date, 'YYYY-MM-DD').format('DD-MM') || '-'}
              </span>
            </div>
            <div
              className="TableItem__Cell"
              title={`${data.first_name} ${data.last_name}`}
            >
              <span className="text-nowrap">{`${data.first_name} ${data.last_name}`}</span>
            </div>
            <div className="TableItem__Cell">
              <span>{data.book_type || '-'}</span>
            </div>
            <div className="TableItem__Cell">
              <span>{data.turn || data.hour_range || '-'}</span>
            </div>
            <div className="TableItem__Cell">
              <span>{data.credits || data.hours || '-'}</span>
            </div>
            <div
              className="TableItem__Cell--Checkbox"
              onClick={(e) => handleInfoModal(e, data)}
            >
              <FontAwesomeIcon icon={faXmark} />
            </div>
          </button>
        ) : (
          <button
            className={`TableItem ${
              unselectable ? 'TableItem--unselectable' : ''
            } text-5`}
            onBlur={closeShowMobileInfo}
          >
            <div className={`TableItem__Cell${data.hour_range ? '--Big' : ''}`}>
              <span>
                {moment(data.date, 'YYYY-MM-DD').format('DD-MM') || '-'}
              </span>
            </div>
            <div
              className={`TableItem__Cell${data.hour_range ? '--Big' : ''}`}
              title={`${data.first_name} ${data.last_name}`}
            >
              <span className="text-nowrap">{`${data.first_name} ${data.last_name}`}</span>
            </div>
            <div className={`TableItem__Cell${data.hour_range ? '--Big' : ''}`}>
              <span>{data.book_type || '-'}</span>
            </div>
            <div className={`TableItem__Cell${data.hour_range ? '--Big' : ''}`}>
              {data.turn || data.hour_range || '-'}
            </div>
            <div
              className={`${
                data.hour_range ? 'TableItem__Cell--Big' : ''
              } TableItem__Cell--ArrowDown ${
                showMobileInfo ? 'TableItem__arrowUp' : ''
              } `}
              onClick={toggleShowMobileInfo}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
            {showMobileInfo && (
              <>
                <div className="TableItem__Dropdown--Unselectable">
                  <span className="TableItem__Cell--Big fw-bold">
                    Horas/créditos utilizados
                  </span>
                  <span className="TableItem__Cell">
                    {data.credits || data.hours || '-'}
                  </span>
                </div>
                <div className="TableItem__Dropdown--Unselectable">
                  <span />
                  <div
                    className="TableItem__Cell justify-content-end gap-2 fw-bold"
                    onClick={(e) => handleInfoModal(e, data)}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                    <span className="me-4">Cancelar reserva</span>
                  </div>
                </div>
              </>
            )}
          </button>
        )}
      </>
    )

  if (type === 'Reservation-User')
    return (
      <>
        {!isMobile ? (
          <button
            className={`TableItem ${
              unselectable ? 'TableItem--unselectable' : ''
            } text-5`}
          >
            <div className="TableItem__Cell">
              <span>
                {moment(data.date, 'YYYY-MM-DD').format('DD-MM') || '-'}
              </span>
            </div>
            <div className="TableItem__Cell">
              <span>{data.book_type || '-'}</span>
            </div>
            <div className="TableItem__Cell">
              <span>{data.turn || data.hour_range || '-'}</span>
            </div>
            <div className="TableItem__Cell">
              <span>{data.credits || data.hours || '-'}</span>
            </div>
            <div
              className="TableItem__Cell--Checkbox"
              onClick={(e) => handleInfoModal(e, data)}
            >
              <FontAwesomeIcon icon={faXmark} />
            </div>
          </button>
        ) : (
          <button
            className={`TableItem ${
              unselectable ? 'TableItem--unselectable' : ''
            } text-5`}
            onBlur={closeShowMobileInfo}
          >
            <div className={`TableItem__Cell${data.hour_range ? '--Big' : ''}`}>
              <span>
                {moment(data.date, 'YYYY-MM-DD').format('DD-MM') || '-'}
              </span>
            </div>
            <div className={`TableItem__Cell${data.hour_range ? '--Big' : ''}`}>
              <span>{data.book_type || '-'}</span>
            </div>
            <div className={`TableItem__Cell${data.hour_range ? '--Big' : ''}`}>
              {data.turn || data.hour_range || '-'}
            </div>
            <div
              className={`${
                data.hour_range ? 'TableItem__Cell--Big' : ''
              } TableItem__Cell--ArrowDown ${
                showMobileInfo ? 'TableItem__arrowUp' : ''
              } `}
              onClick={toggleShowMobileInfo}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
            {showMobileInfo && (
              <>
                <div className="TableItem__Dropdown--Unselectable">
                  <span className="TableItem__Cell--Big fw-bold">
                    Horas/créditos utilizados
                  </span>
                  <span className="TableItem__Cell">
                    {data.credits || data.hours || '-'}
                  </span>
                </div>
                <div className="TableItem__Dropdown--Unselectable">
                  <span />
                  <div
                    className="TableItem__Cell justify-content-end gap-2 fw-bold"
                    onClick={(e) => handleInfoModal(e, data)}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                    <span className="me-4">Cancelar reserva</span>
                  </div>
                </div>
              </>
            )}
          </button>
        )}
      </>
    )
}

TableItem.propTypes = {
  data: propTypes.object.isRequired,
  type: propTypes.string.isRequired,
  selected: propTypes.bool,
  handleSelectItem: propTypes.func,
  openInfoModal: propTypes.func,
  isMobile: propTypes.array,
  unselectable: propTypes.bool,
}

export default memo(TableItem)
