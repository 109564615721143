import moment from 'moment'
import PropTypes from 'prop-types'
import { useContext, useState } from 'react'
import { postCreateOfficeBooking } from '../../../api/offices'
import { AppContext } from '../../../context'
import ModalHeader from '../../ModalHeader'
import Steps from '../../Steps'


const CreateOfficeBookModalStep2 = ({
  closeModal,
  offices,
  companiesData,
  body,
  updateOfficesData,
  updateOffices,
  setStep,
}) => {
  const { token } = useContext(AppContext)
  const [error, setError] = useState('')
  const [buttonDisable, setButtonDisable] = useState(false)

  const handleAssignOffice = async () => {
    if(buttonDisable) return
    setButtonDisable(true)

    const response = await postCreateOfficeBooking(token, body)

    if (response.error === 'Company hasalready a office book')
      setError('La empresa ya tiene una oficina')
    else if (response) {
      updateOfficesData()
      updateOffices()

      setButtonDisable(false)
      setStep((prev) => prev + 1)
    }
  }

  const currentOffice = offices.find(
    (office) => `${office.id}` === body.office_id
  )
  const currentCompany = companiesData.data.find(
    (company) => `${company.id}` === body.company_id
  )

  return (
    <div className="PanelModal px-3" onClick={closeModal}>
      <div className="PanelModal__Card" onClick={(e) => e.stopPropagation()}>
        <ModalHeader
          title={'Asignar oficina'}
          closeModal={closeModal}
          goBack={() => setStep(3)}
        />

        <h3 className="PanelModal__Confirm text-2 py-4 text-center">
          {currentOffice.name} - {currentCompany.name}
        </h3>

        <div className="PanelModal__Confirm py-4 text-center">
          <h4 className="text-3">
            {currentOffice.hours_by_month} horas mensuales
          </h4>
          <h5 className="text-4 my-3">
            Asignada a: {`${currentCompany.name}`}
          </h5>
          <span className="text-4">
            De{' '}
            <span className="text-3 fw-bold">
              {moment(body.start_date, 'YYYY-MM-DD').format('DD/MM/YY')}
            </span>{' '}
            a{' '}
            <span className="text-3 fw-bold">
              {moment(body.end_date, 'YYYY-MM-DD').format('DD/MM/YY')}
            </span>
          </span>
        </div>

        {error.length !== 0 && (
          <div className="d-flex justify-content-center">
            <span className="PanelModal__Error text-5 text-center mt-3">
              {error}
            </span>
          </div>
        )}

        <div className="d-flex justify-content-center pt-3">
          <button
            className={`button-green-panel ${
              error.length !== 0 ? 'button-disabled' : ''
            } my-3 `}
            onClick={handleAssignOffice}
          >
            Asignar
          </button>
        </div>
        <Steps totalCount={2} currentCount={2} />
      </div>
    </div>
  )
}

CreateOfficeBookModalStep2.propTypes = {
  closeModal: PropTypes.func.isRequired,
  offices: PropTypes.array.isRequired,
  companiesData: PropTypes.object.isRequired,
  body: PropTypes.object.isRequired,
  updateOfficesData: PropTypes.func.isRequired,
  updateOffices: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
}

export default CreateOfficeBookModalStep2
