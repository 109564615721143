import { useState, useEffect, useRef } from 'react'
import propTypes from 'prop-types'
import { faGreaterThan, faLessThan, faSortDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { monthNames, dayNames } from '../utils/CalendarDataTime'


const DisabledCalendar = ({ daysSelected, selectDay }) => {
  // Calendar States
  const [ days, setDays ] = useState([])
  const [ month, setMonth ] = useState(new Date().getMonth())
  const [ year, setYear ] = useState(new Date().getFullYear())
  // Month Selector States
  const [ showOptions, setShowOptions ] = useState(false)
  // Month Selector Refs
  const listMonthsRef = useRef(null)
  const currentMonthRef = useRef(null)

  // Set month days
  useEffect(() => {
    // Get days of the month
    function getDaysInMonth(year, month) {
      return new Date(year, month, 0).getDate()
    }
    const daysInMonth = getDaysInMonth(year, month + 1)

    // Rename days
    let renameDays = []
    for(let i = 1; i < daysInMonth + 1; i++) {
      let disabled = false
      let dayName = new Date(year, month, i).toString().slice(0, 3)
      const dayNumber = new Date(year, month, i).getDay()

      // Translate the names of the days
      dayName = dayNames[dayName]

      // Disable weekend days
      if(dayNumber === 0 || dayNumber === 6) {
        disabled = true
      }

      renameDays.push({
        day: dayName,
        dayNumber,
        number: i,
        disabled,
        isFill: false
      })
    }

    let firstMonthDay = renameDays[0].dayNumber

    // Fill calendar
    if(firstMonthDay === 0) {
      firstMonthDay = 7
    }
    for(let i = 1; i < firstMonthDay; i++) {
      const number = new Date(year, month, -i + 1).getDate()

      renameDays.unshift({
        day: '',
        dayNumber: '',
        number,
        disabled: false,
        isFill: true
      })
    }

    setDays(renameDays)
  }, [month])

  // Autoscroll Month Options
  useEffect(() => {
    setTimeout(() => {
      if(listMonthsRef.current && currentMonthRef.current) {
        const monthHeight = currentMonthRef.current.offsetTop
        listMonthsRef.current.scroll(0, (monthHeight))
      }
    }, 100)
  }, [showOptions])

  const toggleShowOptions = () => {
    setShowOptions(!showOptions)
  }

  const selectMonth = (option) => {
    const index = monthNames.indexOf(option)
    setMonth(index)

    setShowOptions(false)
  }


  const changeMonth = (direction) => {
    if(direction === 'next') {
      if(month === 11) {
        setMonth(0)
        setYear(year + 1)
      } else {
        setMonth(month + 1)
      }
    } else {
      if(month === 0) {
        setMonth(11)
        setYear(year - 1)
      } else {
        setMonth(month - 1)
      }
    }
  }


  return (
    <div className="DisabledCalendar">
      <header className="DisabledCalendar__Header">
        <div className="DisabledCalendar__Month-Selector text-5" onClick={toggleShowOptions}>
          {monthNames[month]} <FontAwesomeIcon icon={faSortDown} className="DisabledCalendar__Month-Icon"/>
          {showOptions && (
            <div className="DisabledCalendar__Month-Items-Container" ref={listMonthsRef}>
              {monthNames.map((item, index) => {
                if(item === monthNames[month]) return (
                  <button
                    key={`Calendar-Selector-${item}-${index}`}
                    className="DisabledCalendar__Month-Item--Active text-6"
                    onClick={() => selectMonth(item)}
                    ref={currentMonthRef}
                    disabled
                  >
                    {item}
                  </button>
                )
                else return(
                  <button
                    key={`Calendar-Selector-${item}-${index}`}
                    className="DisabledCalendar__Month-Item text-6"
                    onClick={() => selectMonth(item)}
                  >
                    {item}
                  </button>
                )
              })}
            </div>
          )}
        </div>

        <h2 className="DisabledCalendar__Header-Year text-4 ms-2">{ year.toString() }</h2>

        <nav className="DisabledCalendar__Nav">
          <button className="DisabledCalendar__Nav-Button pe-1" onClick={() => changeMonth('prev')}>
            <FontAwesomeIcon icon={faLessThan}/>
          </button>
          <button className="DisabledCalendar__Nav-Button ps-1" onClick={() => changeMonth('next')}>
            <FontAwesomeIcon icon={faGreaterThan}/>
          </button>
        </nav>
      </header>

      <div className="DisabledCalendar__Week text-6 fw-semibold">
        <div>
          lun
        </div>
        <div>
          mar
        </div>
        <div>
          mie
        </div>
        <div>
          jue
        </div>
        <div>
          vie
        </div>
        <div>
          sab
        </div>
        <div>
          dom
        </div>
      </div>

      <div className="DisabledCalendar__Days-Container">
        {days.map((day, index) => {
          const isCurrentDay = day.number === new Date().getDate()
          const isCurrentMonth = month === new Date().getMonth()
          const isCurrentYear = year === new Date().getFullYear()

          if(day.isFill) return (
            <button
              className="DisabledCalendar__Day--Fill text-6"
              key={`Disabled-Calendar-Fill-Day-${day}-${index}`}
            >
              { day.number }
            </button>
          )

          if(daysSelected.includes(`${year}-${month + 1}-${day.number}`)) return (
            <button
              className="DisabledCalendar__Day--Selected text-6"
              key={`Disabled-Calendar-Day-${day}-${index}`}
              onClick={() => selectDay(`${year}-${month + 1}-${day.number}`)}
            >
              { day.number }
            </button>
          )

          if(isCurrentDay && isCurrentMonth && isCurrentYear) return (
            <button
              className="DisabledCalendar__Day--Current text-6"
              key={`Disabled-Calendar-Current-Day-${day}-${index}`}
              onClick={() => selectDay(`${year}-${month + 1}-${day.number}`)}
            >
              { day.number }
            </button>
          )

          return (
            <button
              className="DisabledCalendar__Day text-6"
              key={`Disabled-Calendar-Day-${day}-${index}`}
              onClick={() => selectDay(`${year}-${month + 1}-${day.number}`)}
            >
              { day.number }
            </button>
          )
        })}
      </div>
    </div>
  )
}

DisabledCalendar.propTypes = {
  daysSelected: propTypes.array.isRequired,
  selectDay: propTypes.func.isRequired
}

export default DisabledCalendar