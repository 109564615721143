import { useState } from 'react'
import { Helmet } from 'react-helmet'
import PanelSwitch from '../../../components/PanelSwitch'
import RoomsCalendar from '../../../containers/RoomsCalendar'
import SpacesCalendar from '../../../containers/SpacesCalendar'

const CalendarAdminPanel = () => {
  const [tab, setTab] = useState('spaces')

  const changeTab = (tab) => {
    setTab(tab)

    if(tab !== 'spaces') {
      const interval = setInterval(() => {
        const container = document.querySelector('.CalendarAdminPanel')
        container.scrollTo(0, 1000)
        if(container.offsetTop !== 0) clearTimeout(interval)
      }, 100)
    }
  }

  return (
    <>
      <Helmet>
        <title>Vida Cowork: Calendario</title>
      </Helmet>
      <main className="CalendarAdminPanel panel-main panel-main--table d-flex flex-column gap-3 pt-3">
        <PanelSwitch tab={tab} changeTab={changeTab}/>

        {tab === 'spaces'
          ? <SpacesCalendar/>
          : <RoomsCalendar/>
        }
      </main>
    </>
  )
}

export default CalendarAdminPanel