import { useEffect, useRef, useState } from 'react'
import propTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortDown } from '@fortawesome/free-solid-svg-icons'
import { monthNames } from '../utils/CalendarDataTime.js'


const MonthSelector = ({ data, optionSelected, onChange, isMini }) => {
  const listMonthsRef = useRef(null)
  const currentMonthRef = useRef(null)
  const [ showOptions, setShowOptions ] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      if(listMonthsRef.current && currentMonthRef.current) {
        const monthHeight = currentMonthRef.current.offsetTop
        listMonthsRef.current.scroll(0, (monthHeight))
      }
    }, 100)
  }, [showOptions])

  const toggleShowOptions = () => {
    setShowOptions(!showOptions)
  }

  const selectOption = (option) => {
    if(onChange) {
      const index = data.indexOf(option)
      onChange(index)
    }

    setShowOptions(false)
  }

  if(isMini) return (
    <button
      className="DisabledCalendar__Month-Selector text-5"
      onClick={toggleShowOptions}
      onBlur={() => setShowOptions(false)}
    >
      {optionSelected} <FontAwesomeIcon icon={faSortDown} className="DisabledCalendar__Month-Icon"/>
      {showOptions && (
        <div className="DisabledCalendar__Month-Items-Container" ref={listMonthsRef}>
          {monthNames.map((item, index) => {
            if(item === optionSelected) return (
              <div
                key={`Calendar-Selector-${item}-${index}`}
                className="DisabledCalendar__Month-Item--Active text-6"
                onClick={() => selectOption(item)}
                ref={currentMonthRef}
                disabled
              >
                {item}
              </div>
            )
            else return(
              <div
                key={`Calendar-Selector-${item}-${index}`}
                className="DisabledCalendar__Month-Item text-6"
                onClick={() => selectOption(item)}
              >
                {item}
              </div>
            )
          })}
        </div>
      )}
    </button>
  )

  return (
    <button
      className={ showOptions ? 'MonthSelector--Active text-2' : 'MonthSelector text-2'}
      onClick={toggleShowOptions}
      onBlur={() => setShowOptions(false)}
    >
      { optionSelected || data[0]}
      <FontAwesomeIcon icon={faSortDown} className="MonthSelector__Icon"/>
      { showOptions && (
        <div className='MonthSelector__Items-Container' ref={listMonthsRef}>
          { data.map((item, index) => {
            if(item === optionSelected) return (
              <div
                key={`Calendar-Selector-${item}-${index}`}
                className="MonthSelector__Item--Active text-4"
                ref={currentMonthRef}
                onClick={() => selectOption(item)}
                disabled
              >
                {item}
              </div>
            )
            else return(
              <div
                key={`Calendar-Selector-${item}-${index}`}
                className="MonthSelector__Item text-4"
                onClick={() => selectOption(item)}
              >
                {item}
              </div>
            )
          })}
        </div>
      )}
    </button>
  )
}

MonthSelector.propTypes = {
  data: propTypes.array.isRequired,
  optionSelected: propTypes.string,
  type: propTypes.string,
  onChange: propTypes.func,
  isMini: propTypes.bool
}

export default MonthSelector