import { useState } from 'react'
import PropTypes from 'prop-types'
import FinishModal from '../FinishModal'
import AssignPlanAdminModalStep1 from './AssignPlanAdminModalStep1'
import AssignPlanAdminModalStep2 from './AssignPlanAdminModalStep2'

const AssignPlanAdminModal = ({ type = 'user', closeModal, selectedItems, updateData }) => {
  const [step, setStep] = useState(1)
  const [planSelected, setPlanSelected] = useState({})

  if (step === 1)
    return (
      <AssignPlanAdminModalStep1
        setPlanSelected={setPlanSelected}
        setStep={setStep}
        closeModal={closeModal}
      />
    )

  if (step === 2)
    return (
      <AssignPlanAdminModalStep2
        type={type}
        planSelected={planSelected}
        setStep={setStep}
        selectedItems={selectedItems}
        updateData={updateData}
        closeModal={closeModal}
      />
    )

  if (step === 3)
    return (
      <FinishModal
        type="--Big"
        title="Cargar plan"
        message="¡Plan cargado con éxito!"
        closeModal={closeModal}
      />
    )
}

AssignPlanAdminModal.propTypes = {
  type: PropTypes.oneOf(['user', 'company']),
  closeModal: PropTypes.func.isRequired,
  selectedItems: PropTypes.array.isRequired,
  updateData: PropTypes.func.isRequired,
}

export default AssignPlanAdminModal
