import { getData, postData } from './apiVariables'

export const getSystemPlans = async (params = {}) => {
  return await getData({
    path: '/plans',
    params,
  })
}
export const getPlansDescriptions = async () => {
  return await getData({
    path: '/plans/descriptions/',
    callback: (data) => data.data
  })
}

export const getPlansList = async () => {
  return await getData({
    path: '/plans/?listed=true',
    callback: (data) => data.data
  })
}

export const getMyUsersPlans = async (token, params) => {
  return await getData({
    path: '/plans/user-plans/',
    callback: (data) => data.data,
    token,
    params
  })
}

export const getExtraHoursAndCreditsList = async (token, params) => {
  return await getData({
    path: '/plans/hours-and-credits-user-plans/',
    callback: (data) => data,
    token,
    params
  })
}

export const getExtraHoursAndCreditsDetail = async (token, id, params) => {
  return await getData({
    path: `/plans/user-plans-by-admin/${id}/`,
    token,
    params
  })
}

export const getUnpaidExtraHoursAndCreditsDetail = async (token, id, params) => {
  return await getData({
    path: `/plans/hours-and-credits-user-plans-unpaid/${id}/`,
    token,
    params
  })
}

export const postBuyPlanByUser = async (token, body) => {
  return await postData({
    path: '/plans/buy-user-plan/',
    token,
    body
  })
}

export const postBuyPlanByAdmin = async (token, body) => {
  return await postData({
    path: '/plans/buy-user-plan-for-admins/',
    token,
    body
  })
}

export const postBuyPlanByCompany = async (token, body) => {
  return await postData({
    path: '/plans/buy-company-plans/',
    token,
    body
  })
}

export const postMarkPlanAsPaid = async (token, body) => {
  return await postData({
    path: '/plans/mark-plans-as-paid/',
    token,
    body
  })
}

export const postCancelPlan = async (token, body) => {
  return await postData({
    path: '/plans/cancel-plan/',
    token,
    body
  })
}