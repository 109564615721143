import propTypes from 'prop-types'
import ModalHeader from '../ModalHeader'
import { useContext, useState } from 'react'
import Table from '../../containers/Table'
import FinishModal from './FinishModal'
import { AppContext } from '../../context'
import { getUnpaidExtraHoursAndCreditsDetail, postMarkPlanAsPaid } from '../../api/plans'
import useApi from '../../hooks/useApi'


const PAGE_SIZE = 8
const TABLE_HEADERS = [
  {
    id: 0,
    label: 'Fecha',
    value: 'date'
  },
  'Tarea',
  {
    id: 2,
    label: 'Plan',
    value: 'plan'
  }, {
    id: 3,
    label: 'Horas',
    value: 'hours'
  }, {
    id: 4,
    label: 'Créditos',
    value: 'credits'
  }, {
    id: 5,
    label: 'Vencimiento',
    value: 'end_date'
  }, {
    id: 6,
    label: 'Pago',
    value: 'is_paid'
  }, {
    id: 7,
    label: 'Total',
    value: 'total'
  }
]
const TABLE_HEADERS_MOBILE = [
  {
    id: 0,
    label: 'Fecha',
    value: 'date'
  },
  'Tarea',
  {
    id: 2,
    label: 'Pago',
    value: 'is_paid'
  }, {
    id: 3,
    label: 'Total',
    value: 'total'
  }
]


const MarkAsPaidModal = ({ data, updateData, closeModal }) => {
  const { token } = useContext(AppContext)
  const [ page, setPage ] = useState(1)
  const [ order_by, setOrderBy ] = useState('')
  const [ step, setStep ] = useState(1)
  const [ selectedItems, setSelectedItems ] = useState([])
  const params = {
    page,
    per_page: PAGE_SIZE,
    order_by,
  }


  const [ extrasData, updateExtrasData ] = useApi(
    () => getUnpaidExtraHoursAndCreditsDetail(token, data?.id, params),
    {
      page: 1,
      total_pages: 1,
      total_items: 0,
      data: [],
    },
    [token, page, order_by]
  )


  const handleSelectItem = (item) => {
    if (selectedItems.find(selectedItem => selectedItem.id === item.id)) {
      setSelectedItems((prev) => prev.filter((selectedItem) => selectedItem.id !== item.id))
    } else {
      setSelectedItems(prev => [...prev, item])
    }
  }
  const handleMarkAsPaid = async () => {
    let plan_ids = ''
    selectedItems.forEach((plan) => {
      plan_ids += plan.id + ','
    })
    plan_ids = plan_ids.slice(0, -1)

    await postMarkPlanAsPaid(token, { plan_ids })
    setStep(3)
    updateData()
  }


  if(step === 1) return (
    <div className="PanelModal px-3" onClick={closeModal}>
      <div className="PanelModal__Card--ExtraBig" onClick={(e) => e.stopPropagation()}>
        <ModalHeader
          title='Marcar como pago'
          closeModal={closeModal}
        />

        <div className='d-flex flex-column gap-4 mt-3 text-center'>
          <div className="d-flex justify-content-center flex-wrap gap-3">
            <div className="d-flex flex-column">
              <span className='text-5 text-start'>Nombre</span>
              <span className='text-4 fw-medium text-black'>
                { data?.full_name }
              </span>
            </div>
            <div className="d-flex flex-column">
              <span className='text-5 text-start'>Tipo</span>
              <span className='text-4 fw-medium text-black'>
                { data?.company?.name ? 'Empresa' : 'Freelancer' }
              </span>
            </div>
            <div className="d-flex flex-column">
              <span className='text-5 text-start'>Empresa</span>
              <span className='text-4 fw-medium text-black'>
                { data?.company?.name ?? '-' }
              </span>
            </div>
          </div>

          <div className="w-100 d-flex flex-column align-items-start position-relative">
            <Table
              type="Hours-And-Credits-Paid"
              headers={TABLE_HEADERS}
              headersMobile={TABLE_HEADERS_MOBILE}
              data={extrasData.data}
              page={extrasData.page}
              setPage={setPage}
              perPage={PAGE_SIZE}
              totalPages={extrasData.total_pages}
              totalItems={extrasData.total_items}
              setOrderBy={setOrderBy}
              showInfoMobile
              selectedParentItems={selectedItems}
              handleParentItems={handleSelectItem}
            />
          </div>

          <button
            className="button-green-panel mx-auto"
            onClick={() => setStep(2)}
            disabled={selectedItems.length === 0}
          >
            Continuar
          </button>
        </div>
      </div>
    </div>
  )

  if(step === 2) return (
    <div className="PanelModal px-3" onClick={closeModal}>
      <div className="PanelModal__Card--ExtraBig" onClick={(e) => e.stopPropagation()}>
        <ModalHeader
          title='Marcar como pago'
          goBack={() => setStep(1)}
          closeModal={closeModal}
        />

        <div className='d-flex flex-column gap-4 mt-3 text-center'>
          <span className='text-4'>¿Desea marcar como pago?</span>
          <div className="d-flex justify-content-center flex-wrap gap-3">
            <div className="d-flex flex-column">
              <span className='text-5 text-start'>Nombre</span>
              <span className='text-4 fw-medium text-black'>
                { data?.full_name }
              </span>
            </div>
            <div className="d-flex flex-column">
              <span className='text-5 text-start'>Tipo</span>
              <span className='text-4 fw-medium text-black'>
                { data?.company?.name ? 'Empresa' : 'Freelancer' }
              </span>
            </div>
            <div className="d-flex flex-column">
              <span className='text-5 text-start'>Empresa</span>
              <span className='text-4 fw-medium text-black'>
                { data?.company?.name ?? '-' }
              </span>
            </div>
          </div>

          <div className="w-100 d-flex flex-column align-items-start position-relative">
            <Table
              type="Hours-And-Credits-Detail"
              headers={TABLE_HEADERS}
              headersMobile={TABLE_HEADERS_MOBILE}
              data={selectedItems}
              unselectable
            />
          </div>

          <div className="d-flex gap-3 mx-auto">
            <button className="button-inverted-panel" onClick={() => setStep(1)}>
              Cancelar
            </button>
            <button className="button-green-panel" onClick={handleMarkAsPaid}>
              Confirmar
            </button>
          </div>
        </div>
      </div>
    </div>
  )

  if(step === 3) return (
    <FinishModal
      title='Marcar como pago'
      message='Pago exitoso'
      assignPlan='Volver al panel'
      closeModal={closeModal}
    />
  )
}

MarkAsPaidModal.propTypes = {
  data: propTypes.object.isRequired,
  updateData: propTypes.func.isRequired,
  closeModal: propTypes.func.isRequired,
}

export default MarkAsPaidModal