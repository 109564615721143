import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

const PlanDetailCard = ({ name, info, description }) => {
  const [infoKeys, setInfoKeys] = useState([])

  useEffect(() => {
    Object.keys(info).map(key => setInfoKeys(prev => [...prev, key])) 
  }, [])

  return (
    <div className="PlanDetailCard">
      <div className="PlanDetailCard__Title-Container">
        <div className="text-center">
          <h2 className="PlanDetailCard__Title text-1">{ name }</h2>
          <p
            className="text-5 mb-0"
            dangerouslySetInnerHTML={{
              __html: description
            }}
          />
        </div>
      </div>
      <div className="PlanDetailCard__Data-Container">
        <div className="PlanDetailCard__Data-Item">
          <div className="PlanDetailCard__Price-Container">
            <span className="text-4"><span className="fw-bold">PART</span> | medio día</span>
            <span className="PlanDetailCard__Price text-2 fw-bold">
              <span className="text-3">$</span>{infoKeys.map(key => info[key].type === 'Part plan' ? info[key].price : '')}
              <span className="text-6 fw-light ms-1">(*)</span>
            </span>
          </div>
          <div className='PlanDetailCard__Hours d-flex align-items-start'>
            <span className="text-4">{infoKeys.map(key => info[key].type === 'Part plan' ? info[key].description : '')}</span>
          </div>
        </div>
        <div className="PlanDetailCard__Data-Divider"></div>
        <div className="PlanDetailCard__Data-Item">
          <div className="PlanDetailCard__Price-Container">
            <span className="text-4"><span className="fw-bold">FULL</span> | día completo</span>
            <span className="PlanDetailCard__Price text-2 fw-bold">
              <span className="text-3">$</span>{infoKeys.map(key => info[key].type === 'Full plan' ? info[key].price : '')}
              <span className="text-6 fw-light ms-1">(*)</span>
            </span>
          </div>
          <div className='PlanDetailCard__Hours d-flex align-items-start'>
            <span className="text-4">{infoKeys.map(key => info[key].type === 'Full plan' ? info[key].description : '')}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

PlanDetailCard.propTypes = {
  name: PropTypes.string.isRequired,
  info: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
}

export default PlanDetailCard