import moment from 'moment'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { delDeleteOfficeBooking } from '../../api/offices'
import { AppContext } from '../../context'
import ModalHeader from '../ModalHeader'
import FinishModal from './FinishModal'


const CancelOfficeBook = ({
  closeModal,
  officeSelected,
  updateOfficesData,
  updateOffices,
}) => {
  const { token } = useContext(AppContext)
  const [step, setStep] = useState(1)
  const [selectedBooksId, setSelectedBooksId] = useState(0)
  const [booksToDelete, setBooksToDelete] = useState([])
  const [buttonDisable, setButtonDisable] = useState(false)

  useEffect(() => {
    const today = new Date().getTime()
    const date = new Date(officeSelected.books[0].start_date).getTime()

    if (date > today) {
      setSelectedBooksId(officeSelected.books[0].id)
      setBooksToDelete(officeSelected.books)
    }
    else {
      setSelectedBooksId(officeSelected.books[1].id)
      setBooksToDelete([officeSelected.books[1]])
    }
  }, [])

  const handleSelectItem = (bookId) => {
    setSelectedBooksId(bookId)
  }

  const handleCancelOfficeBook = async () => {
    if(buttonDisable) return
    setButtonDisable(true)
    const body = {
      office_book_id: selectedBooksId
    }

    const response = await delDeleteOfficeBooking(token, body)

    if (response.error) {
      return
    }
    else if (response) {
      updateOfficesData()
      updateOffices()

      setStep((prev) => prev + 1)
    }
    setButtonDisable(false)
  }

  if (step === 1)
    return (
      <div className="PanelModal px-3" onClick={closeModal}>
        <div
          className="PanelModal__Card--Medium"
          onClick={(e) => e.stopPropagation()}
        >
          <ModalHeader title={'Cancelar reserva'} closeModal={closeModal} />

          <div
            className="d-flex flex-column py-4 gap-4 mx-auto"
            style={{ width: 'max-content' }}
          >
            {booksToDelete.map((book, index) => (
              <div
                key={index}
                className={`d-flex align-items-center gap-3 ${selectedBooksId === book.id ? 'cursor-default' : 'cursor-pointer'}`}
                onClick={() => handleSelectItem(book.id)}
              >
                <div
                  className={`Radio${
                    selectedBooksId === book.id ? '--Selected' : ''
                  }`}
                />
                <span>
                  {moment(book.start_date, 'YYYY-MM-DD').format('DD/MM/YY')} -{' '}
                  {book.company_name}
                </span>
              </div>
            ))}
          </div>

          <div className="text-4 text-center mb-4">
            Sólo se pueden cancelar reservas futuras a la fecha actual
          </div>

          <div className="d-flex justify-content-center mb-2">
            <button
              className="button-green-panel"
              onClick={handleCancelOfficeBook}
            >
              Sí, cancelar
            </button>
          </div>

          <div className="d-flex justify-content-center">
            <button
              className="button-white-gray-dark px-4 text-decoration-underline"
              onClick={closeModal}
            >
              Cambié de opinión, no quiero cancelar
            </button>
          </div>
        </div>
      </div>
    )

  if (step === 2)
    return (
      <FinishModal
        type="--Medium"
        title="Cancelar reserva"
        message="La reserva ha sido cancelada"
        buttonText="¡Entendido!"
        closeModal={closeModal}
      />
    )
}

CancelOfficeBook.propTypes = {
  closeModal: PropTypes.func.isRequired,
  officeSelected: PropTypes.object.isRequired,
  updateOfficesData: PropTypes.func.isRequired,
  updateOffices: PropTypes.func.isRequired,
}

export default CancelOfficeBook
