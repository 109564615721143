import PropTypes from 'prop-types'
import oneCreditIcon from '../assets/icons/1-CREDITO.svg'
import twoCreditIcon from '../assets/icons/2-CREDITOS.svg'


const CreditsInfo = ({className}) => {
  return (
    <div className={'CreditsInfo gap-3 gap-xxl-5 ' + className}>
      <div className="d-flex align-items-center gap-2">
        <img
          src={oneCreditIcon}
          width={30}
          height={30}
          alt="icono de un credito"
        />
        <img
          src={twoCreditIcon}
          width={30}
          height={30}
          alt="icono de dos creditos"
        />
        <div className="d-flex flex-column">
          <span className="text-4 fw-bold text-orange">
                  Créditos Vida
          </span>
          <span className="text-6 text-gray-dark">
                  Válidos para reservas en el espacio compartido
          </span>
        </div>
      </div>

      <div className='CreditsInfo__Items'>
        <div className="d-flex align-items-center gap-2 text-5 text-gray-dark">
          <img
            src={oneCreditIcon}
            width={20}
            height={20}
            alt="icono de un credito"
          />
          <p className="flex-shrink-0 mb-0 fw-bold">1 Crédito</p>
          <p className="mb-0">=</p>
          <p className="flex-shrink-0 mb-0">Medio día</p>
        </div>

        <div className="d-flex align-items-center gap-2 text-5 text-gray-dark">
          <img
            src={twoCreditIcon}
            width={20}
            height={20}
            alt="icono de dos creditos"
          />
          <p className="flex-shrink-0 mb-0 fw-bold">2 Créditos</p>
          <p className="mb-0">=</p>
          <p className="flex-shrink-0 mb-0">Día completo</p>
        </div>
      </div>
    </div>
  )
}

CreditsInfo.propTypes = {
  className: PropTypes.string
}

export default CreditsInfo