import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper'
import { getPartners } from '../api/community'
import useApi from '../hooks/useApi'
import Media from '../components/Media'


const Partners = () => {
  const [partners] = useApi(getPartners, [])

  return (
    <div className="Partners container-custom my-4 my-md-5">
      <Swiper
        className='Partners__Swiper'
        modules={[ Autoplay ]}
        slidesPerView={1}
        spaceBetween={20}
        breakpoints={{
          576: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 40
          },
          1200: {
            slidesPerView: 5,
            spaceBetween: 50
          },
          1440: {
            slidesPerView: 6,
            spaceBetween: 65
          }
        }}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        allowTouchMove={false}
        loop={true}
        speed={1500}
      >
        {partners.map(partner => (
          <SwiperSlide key={partner.id} className='Partners__Item'>
            <a href={partner.url} target="_blank" rel='noreferrer' className='Partners__Image swiper-lazy'>
              <Media type='image' className='Partners__Image' src={partner.image} alt={partner.name} />
            </a>
            <div className="swiper-lazy-preloader"></div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default Partners