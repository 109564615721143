import { deleteData, getData, postData } from './apiVariables'

export const getImages = async (type) => {
  const callback = (data) => data.filter((image) => image.image_type === type)

  return await getData({
    path: '/admin-settings/get-images/',
    cache: 604800,
    callback,
  })
}

export const getVideos = async (type, size = 'GRANDE') => {
  const findVideo = (data, type, size) => {
    return data.find(
      (item) => item.video_type === type && item.video_size === size
    ).video
  }

  return await getData({
    path: '/admin-settings/get-videos/',
    cache: 604800,
    callback: (data) => findVideo(data, type, size),
  })
}

export const getLandingTexts = async (type) => {
  const findText = (data, type) =>
    data.filter((item) => item.text_type.startsWith(type.toLowerCase()))

  return await getData({
    path: '/admin-settings/get-landing-texts/',
    cache: 604800,
    callback: (data) => findText(data, type),
  })
}

export const getContactInformation = async () => {
  return await getData({
    path: '/admin-settings/get-contact-information/',
    cache: 604800,
  })
}

export const getEvents = async () => {
  return await getData({
    path: '/admin-settings/get-events/',
    cache: 604800,
  })
}

export const getEventsCategories = async () => {
  return await getData({
    path: '/admin-settings/get-events-categories/',
  })
}

export const getAllowSelling = async (token) => {
  return await getData({
    path: '/admin-settings/allow-selling/',
    token,
    callback: (data) => data.selling_allowed,
  })
}

export const changeAllowSelling = async (token, body) => {
  return await postData({
    path: '/admin-settings/allow-selling/',
    token,
    body,
    callback: (data) => data.message,
  })
}

export const getSharedAreasTime = async () => {
  return await getData({
    path: '/admin-settings/get-shared-area-time/',
    cache: 604800,
    callback: (data) => data.data,
  })
}

export const getMeetRoomsTime = async () => {
  return await getData({
    path: '/admin-settings/get-meeting-room-time/',
    cache: 604800,
    callback: (data) => data.data,
  })
}

export const getLogs = async (token, params) => {
  params = Object.entries(params).reduce(
    (a, [k, v]) => (v ? ((a[k] = v), a) : a),
    {}
  )
  return await getData({
    path: '/admin-settings/get-logs/',
    token,
    params,
  })
}

export const getChairsGroupsImage = async (token) => {
  return await getData({
    path: '/admin-settings/chairs-group-image/',
    token,
    callback: (data) => data.data,
  })
}

export const changeChairsGroupImage = async (token, body) => {
  return await postData({
    path: '/admin-settings/chairs-group-image/',
    token,
    body,
  })
}

export const getFaqs = async () => {
  return await getData({
    path: '/admin-settings/get-faqs/',
    cache: 604800,
    callback: (data) => data.data,
  })
}

export const getServices = async (params) => {
  params = Object.entries(params).reduce(
    (a, [k, v]) => (v ? ((a[k] = v), a) : a),
    {}
  )
  return await getData({
    path: '/admin-settings/services/',
    params,
    callback: (data) => data.data,
  })
}

export const getProfessions = async () => {
  return await getData({
    path: '/admin-settings/professions/',
    callback: (data) => data.data,
  })
}

// Holidays
export const getHolidays = async (token, params) => {
  return await getData({
    path: '/admin-settings/holidays/',
    token,
    params,
    callback: (data) => data,
  })
}

export const getSortedHolidays = async (token) => {
  const sortHolidays = (response) => {
    const amount = response.length
    let data = response.sort((a, b) => a.date - b.date)

    let groups = []

    data.forEach((holiday) => {
      const yearId = groups.findIndex(
        (yearGroup) => yearGroup.year === holiday.date.split('-')[0]
      )
      if (yearId === -1)
        groups.push({
          year: holiday.date.split('-')[0],
          months: [
            {
              month: holiday.date.split('-')[1],
              holidays: [holiday],
            },
          ],
        })
      else {
        const monthId = groups[yearId].months.findIndex(
          (monthGroup) => monthGroup.month === holiday.date.split('-')[1]
        )
        if (monthId === -1)
          groups = [
            ...groups.slice(0, yearId),
            {
              year: groups[yearId].year,
              months: [
                ...groups[yearId].months,
                {
                  month: holiday.date.split('-')[1],
                  holidays: [holiday],
                },
              ],
            },
            ...groups.slice(yearId + 1),
          ]
        else
          groups = [
            ...groups.slice(0, yearId),
            {
              year: groups[yearId].year,
              months: [
                ...groups[yearId].months.slice(0, monthId),
                {
                  month: groups[yearId].months[monthId].month,
                  holidays: [
                    ...groups[yearId].months[monthId].holidays,
                    holiday,
                  ],
                },
                ...groups[yearId].months.slice(monthId + 1),
              ],
            },
            ...groups.slice(yearId + 1),
          ]
      }
    })

    data = groups

    return { amount, data }
  }

  return await getData({
    path: '/admin-settings/holidays/',
    token,
    callback: (data) => sortHolidays(data),
  })
}

export const addHolidays = async (token, body) => {
  return await postData({
    path: '/admin-settings/holidays/',
    token,
    body,
  })
}

export const deleteHolidays = async (token, body) => {
  return await deleteData({
    path: '/admin-settings/holidays/',
    token,
    body,
  })
}
