import { deleteData, getData, postData } from './apiVariables'

export const postCreateChair = async (token) => {
  return await postData({
    path: '/shared-area/chairs/',
    token,
  })
}

export const delDeleteChair = async (token) => {
  return await deleteData({
    path: '/shared-area/chairs/',
    token,
  })
}

export const getSharedAreaBooksCalendar = async (token, params) => {
  return await getData({
    path: '/shared-area/get-shared-area-books-calendar/',
    token,
    params,
    callback: (data) => data.data,
  })
}

export const getUserSharedAreaBooksCalendar = async (token, params) => {
  return await getData({
    path: '/shared-area/get-user-shared-area-books-calendar/',
    token,
    params,
  })
}

export const getAdminSharedAreaBooksCalendar = async (token, params) => {
  return await getData({
    path: '/shared-area/get-admin-shared-are-books-calendar/',
    token,
    params,
  })
}

export const getCompanySharedAreaBooksCalendar = async (token, params) => {
  return await getData({
    path: '/shared-area/get-company-admin-shared-area-books-calendar/',
    token,
    params,
  })
}

export const getSharedAreaBooks = async (token, params) => {
  params = Object.entries(params).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {})
  return await getData({
    path: '/shared-area/get-shared-area-books/',
    token,
    params,
  })
}

export const createSharedAreaBook = async (token, body) => {
  return await postData({
    path: '/shared-area/create-shared-area-book/',
    token,
    body
  })
}

export const cancelSharedAreaBook = async (token, body) => {
  return await postData({
    path: '/shared-area/cancel-shared-area-book/',
    token,
    body
  })
}

// Chair groups
export const getChairGroups = async (token) => {
  return await getData({
    path: '/shared-area/chair-groups/',
    token
  })
}

// Chairs
export const getChairs = async (token) => {
  return await getData({
    path: '/shared-area/chairs/',
    token
  })
}

export const getAvailableChairsByGroup = async (token, params) => {
  return await getData({
    path: '/shared-area/get-available-chairs-by-day/',
    token,
    params,
    callback: (data) => data.data
  })
}
