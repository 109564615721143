import propTypes from 'prop-types'
import { faBuilding, faPhone } from '@fortawesome/free-solid-svg-icons'
import ModalHeader from '../ModalHeader'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { putEditCompany } from '../../api/companies'
import { phoneRegExp } from '../../utils/regExp'
import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context'
import Input from '../Input'


const CompanyInfoModal = ({ closeModal, companyInfo, updateCompaniesData }) => {
  const { token } = useContext(AppContext)
  const [saveChanges, setSaveChanges] = useState(false)

  const sortedPlans = () => {
    let list = []
    companyInfo.not_assinged_plans.forEach((plan) => {
      const planIndex = list.findIndex(
        (item) => item.plan__id === plan.plan__id
      )
      if (planIndex >= 0) {
        list = [
          ...list.slice(0, planIndex),
          {
            ...list[planIndex],
            quantity: list[planIndex].quantity + 1,
          },
          ...list.slice(planIndex + 1),
        ]
      } else {
        list = [...list, { ...plan, quantity: 1 }]
      }
    })

    return list
  }

  const formik = useFormik({
    initialValues: {
      name: companyInfo.name ?? '',
      phone: companyInfo.phone ?? '',
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      name: Yup.string().required('El nombre es requerido').max(60, 'Máximo de caracteres: 60'),
      phone: Yup.string()
        .required('El celular es requerido')
        .matches(phoneRegExp, 'Ingrese un numero de telefono valido')
        .max(40, 'Máximo de números: 40'),
    }),
    onSubmit: async ({ name, phone }) => {
      if (!saveChanges) return

      const response = await putEditCompany(token, companyInfo.id, { name, phone })

      if (response.error) {
        return
      }
      else if (response) {
        updateCompaniesData()
        closeModal()
      }
    },
  })

  useEffect(() => {
    if(!formik.values.name || !formik.values.phone) {
      return setSaveChanges(false)
    }
    const hasChanges = companyInfo.name !== formik.values.name || companyInfo.phone !== formik.values.phone
    if (hasChanges) setSaveChanges(true)
    else setSaveChanges(false)
  }, [formik.values.name, formik.values.phone])

  return (
    <div className="PanelModal px-3" onClick={closeModal}>
      <div className="PanelModal__Card--Medium" onClick={(e) => e.stopPropagation()}>
        <ModalHeader title={'Información'} closeModal={closeModal} />

        <div className='PanelModal__Confirm d-flex gap-3'>

          <div className="d-flex flex-column align-items-start w-100">
            <h3 className="text-4">{`Administrador${
              companyInfo.admines.length !== 1 ? 'es' : ''
            }`}</h3>
            {companyInfo.admines.map((admin) => (
              <span
                key={admin.user__id}
                className="text-5 mt-2"
              >{`${admin.user__first_name} ${admin.user__last_name}`}</span>
            ))}
          </div>

          <div className="d-flex flex-column align-items-start w-100">
            <h3 className="text-4">{`Integrante${
              companyInfo.employees.length !== 1 ? 's' : ''
            }`}</h3>
            {companyInfo.employees.map((employee) => (
              <span
                key={employee.user__id}
                className="text-5 mt-2"
              >{`${employee.user__first_name} ${employee.user__last_name}`}</span>
            ))}
            {companyInfo.employees.length === 0 && (
              <div className="text-5">
                <span className="text-4 fw-bold">0</span> Integrantes
              </div>
            )}
          </div>
        </div>

        <div className='PanelModal__Confirm d-flex gap-3'>
          <div className="d-flex flex-column align-items-start w-100">
            <h3 className="text-4 mb-2">Planes sin asignar</h3>
            {sortedPlans().map((plan) => (
              <div key={plan.plan__id} className="text-5">
                <span className="text-4 fw-bold">{plan.quantity}</span>{' '}
                {plan.plan__name} - {plan.plan__plan_type.split(' ')[0]}
              </div>
            ))}
            {sortedPlans().length === 0 && (
              <div className="text-5">
                <span className="text-4 fw-bold">0</span> planes
              </div>
            )}
          </div>

          <div className="d-flex flex-column align-items-start w-100">
            <h3 className="text-4 mb-2 text-start">Horas y créditos sin utilizar</h3>
            <div className="text-5">
              <span className="text-4 fw-bold">
                {companyInfo.not_used_hours ?? 0}
              </span>{' '}
							Horas
            </div>
            <div className="text-5">
              <span className="text-4 fw-bold">
                {companyInfo.not_used_credits ?? 0}
              </span>{' '}
							Créditos
            </div>
          </div>
        </div>

        <form>
          <div className="d-flex flex-column align-items-start pt-3">
            <h3 className="text-4 mb-2">Nombre</h3>
            <Input
              label=""
              name='name'
              type='text'
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.errors.name}
              icon={faBuilding}
            />
          </div>

          <div className="d-flex flex-column align-items-start pt-3">
            <h3 className="text-4 mb-2">Teléfono de contacto</h3>
            <Input
              label=""
              name="phone"
              type="tel"
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.errors.phone}
              icon={faPhone}
            />
          </div>

          <div className="d-flex justify-content-center mt-4">
            <button
              className={`button-green-panel ${
                !saveChanges ? 'button-disabled' : ''
              }`}
              type="submit"
              onClick={formik.handleSubmit}
            >
							Guardar cambios
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

CompanyInfoModal.propTypes = {
  closeModal: propTypes.func.isRequired,
  companyInfo: propTypes.object.isRequired,
  updateCompaniesData: propTypes.func.isRequired,
}

export default CompanyInfoModal
