import { memo } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

function TableItemSpecial({
  data,
  unselectable,
  selected = false,
  handleSelectItem,
  children,
}) {
  const handleDisabled = () => {
    if (unselectable) return null
    handleSelectItem(data)
  }

  return (
    <div
      className={classNames(
        'TableItem',
        { Selected: selected },
        { Unselectable: unselectable },
        'text-5'
      )}
      onClick={handleDisabled}
    >
      {children}
    </div>
  )
}

TableItemSpecial.propTypes = {
  data: PropTypes.object.isRequired,
  unselectable: PropTypes.bool,
  selected: PropTypes.bool,
  handleSelectItem: PropTypes.func,
  children: PropTypes.element,
}

export default memo(TableItemSpecial)