import { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'
import Banner from '../../components/Banner'
import Services from '../../containers/Services'
import CapacidadIcon from '../../assets/icons/Sala de Reu_Mesa de trabajo 1 copia.png'
import EquipamientoIcon from '../../assets/icons/recep_Mesa de trabajo 1 copia 2.png'
import equiIcon from '../../assets/icons/equi-06.png'
import Galery from '../../components/Galery'
import Plant1 from '../../assets/details/plant-1.webp'
import Plant2 from '../../assets/details/plant-2.webp'
import { Helmet } from 'react-helmet'
import Media from '../../components/Media'
import useApi from '../../hooks/useApi'
import { getImages } from '../../api/admin-settings'
import { useContext } from 'react'
import { AppContext } from '../../context'


const Rooms = () => {
  const { rooms } = useContext(AppContext)
  const [images] = useApi(() => getImages('SALAS BANNER 1'), [])
  const [ showGalery, setShowGalery ] = useState(false)
  const [ roomImages, setRoomImages ] = useState([])


  const toogleShowGalery = () => {
    setShowGalery(!showGalery)
  }

  const handleShowImages = (images) => {
    setShowGalery(true)
    setRoomImages(images)
  }


  return (
    <>
      <Helmet>
        <title>Vida Cowork: Salas de Reuniones</title>
      </Helmet>
      <main className='Rooms landing-content'>
        <Banner videoType='SALAS' textType="sala" />

        {/* Info */}
        <section className='background-white container-fluid d-flex align-items-center position-relative py-5 px-3 px-xl-0' id="info">
          <div className="container-custom pt-0 pt-lg-5 pb-4 pb-lg-5">
            <div className="row">
              <div className='Rooms-Info col-12 col-xl-6'>
                <h2 className='text-1 mb-3'>¿Por qué elegir una sala de reunión en Vida?</h2>
                <p className='text-4 m-0'>
                Porque creemos que el trabajo en equipo potencia los proyectos, diseñamos salas 100% equipadas para estimular, dinamizar y organizar tus reuniones.
                </p>
                <div className='Rooms-Info__List m-0'>
                  <img
                    className='Rooms-Info__List-Icon'
                    src={CapacidadIcon}
                    alt='Icono de capacidad'
                    title='Icono de capacidad'
                    width={60}
                    height={60}
                  />
                  <p className='Rooms-Info__List-Item'>Capacidad: <span className='text-4 fw-light'>Contamos con cuatro salas de reuniones con comodidades para 4 hasta 12 personas.</span></p>
                  <img
                    className='Rooms-Info__List-Icon'
                    src={EquipamientoIcon}
                    alt='Icono de recepción'
                    title='Icono de recepción'
                    width={60}
                    height={60}
                  />
                  <p className='Rooms-Info__List-Item'>Recepción: <span className='text-4 fw-light'>Nos encargamos de recibir y acompañar a tus visitas.</span></p>
                  <img
                    className='Rooms-Info__List-Icon'
                    src={equiIcon}
                    alt='Icono de equipamiento'
                    title='Icono de equipamiento'
                    width={60}
                    height={60}
                  />
                  <p className='Rooms-Info__List-Item'>Equipamiento: <span className='text-4 fw-light'>Tv con conexión HDMI, Pizarra, uso de cocinas bazar e infusiones y ambiente climatizado.</span></p>
                </div>
                <p className='text-4'>
                  Nuestros microclimas están diseñados para que puedas concentrarte y potenciar las ideas en equipo.
                </p>
              </div>
              <div className="col-12 col-xl-6 d-none d-xl-flex flex-column justify-content-start">
                <Media type='image' className='Rooms__Main-Image' src={images[0]?.image ?? ''} alt='' />
              </div>
            </div>
          </div>
        </section>

        {/* Salas */}
        <section className='background-white container-fluid pb-4'>
          <div className="container-custom">
            <h2 className="text-1 mb-4 mb-lg-5">Conocé todas nuestras salas.</h2>

            <div className="Rooms__Swiper-Container">
              <Swiper
                modules={[ Navigation, Pagination ]}
                slidesPerView={1}
                spaceBetween={30}
                breakpoints={{
                  576: {
                    slidesPerView: 1,
                    spaceBetween: 30
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 30
                  },
                  1200: {
                    slidesPerView: 3,
                    spaceBetween: 30
                  },
                  1440: {
                    slidesPerView: 4,
                    spaceBetween: 30
                  },
                }}
                loop={true}
                navigation
                pagination={{ clickable: true }}
                speed={500}
              >
                {rooms.map((room) => (
                  <SwiperSlide key={room.id} className='Rooms__Swiper-Item' onClick={() => handleShowImages(room.images)}>
                    <Media
                      type='image'
                      className='Rooms__Swiper-Image'
                      src={room.images[0]?.image ?? ''}
                      alt={room.images[0]?.image ?? ''}
                      title={room.images[0]?.image ?? ''}
                      width={308}
                      height={348}
                    />
                    <div className="Rooms__Swiper-Title-Container">
                      <h3 className="text-2">{ room.name }</h3>
                    </div>
                    <div className="Rooms__Swiper-Capacity-Container d-flex flex-column align-items-center">
                      <span className="text-5">Capacidad:</span>
                      <span className="text-2">{ room.capacity } Personas</span>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </section>

        {showGalery && (
          <Galery images={roomImages} toogleShowGalery={toogleShowGalery}/>
        )}

        {/* Services */}
        <section className='background-white container-fluid pb-0 pb-lg-5'>
          <div className="container-custom pt-4 pt-lg-5">
            <h2 className="text-1">Servicios que incluye el espacio</h2>
            <Services category="salas de reunion" />
          </div>
        </section>

        <img className='Offices__Plant-1' src={Plant1} alt='Plant 1' />
        <img className='Offices__Plant-2' src={Plant2} alt='Plant 2' />
      </main>
    </>
  )
}

export default Rooms