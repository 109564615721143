import axios from 'axios'
import { URLApi } from '../constants'

const isTokenEmpty = (token) =>
  (typeof token === 'string' && token === '') || typeof token === 'object'

export const getData = async ({
  path,
  token = undefined,
  params = undefined,
  cache = 0,
  callback = (data) => data,
}) => {
  if (isTokenEmpty(token)) return

  const headers = token ? {
    Authorization: `Token ${token}`,
    'Cache-Control': cache ? `max-age=${cache}, stale-while-revalidate=86400` : 'no-cache',
  } : {
    'Cache-Control': cache ? `max-age=${cache}, stale-while-revalidate=86400` : 'no-cache',
  }

  try {
    const { data } = await axios.get(`${URLApi}${path}`, {
      headers,
      params,
    })

    return callback(data)
  } catch (error) {
    return handleError(error)
  }
}

export const deleteData = async ({
  path,
  token = undefined,
  body = {},
  callback = (data) => data,
}) => {
  if (isTokenEmpty(token)) return

  const headers = token && {
    Authorization: `Token ${token}`,
  }

  try {
    const { data } = await axios.delete(`${URLApi}${path}`, {
      headers,
      data: body,
    })

    return callback(data)
  } catch (error) {
    return handleError(error)
  }
}

export const postData = async ({
  path,
  token = undefined,
  body = {},
  callback = (data) => data,
}) => {
  if (isTokenEmpty(token)) return

  const headers = token && {
    Authorization: `Token ${token}`,
  }

  try {
    const { data } = await axios.post(`${URLApi}${path}`, body, {
      headers,
    })

    return callback(data)
  } catch (error) {
    return handleError(error)
  }
}

export const putData = async ({
  path,
  token = undefined,
  body = {},
  callback = (data) => data,
}) => {
  if (isTokenEmpty(token)) return

  const headers = token && {
    Authorization: `Token ${token}`,
  }

  try {
    const { data } = await axios.put(`${URLApi}${path}`, body, {
      headers,
    })

    return callback(data)
  } catch (error) {
    return handleError(error)
  }
}

export const patchData = async ({
  path,
  token = undefined,
  body = {},
  callback = (data) => data,
}) => {
  if (isTokenEmpty(token)) return

  const headers = token && {
    Authorization: `Token ${token}`,
    'Content-Type': 'multipart/form-data',
  }

  try {
    const { data } = await axios.patch(`${URLApi}${path}`, body, {
      headers,
    })

    return callback(data)
  } catch (error) {
    return handleError(error)
  }
}


const handleError = (error) => {
  if (
    typeof error?.response?.data === 'string' &&
    error?.response?.data?.startsWith('<!DOCTYPE html>')
  )
    return { error: 'internal server error' }

  if (error?.response?.data?.detail == 'Invalid token.') {
    localStorage.removeItem('token')
    window.dispatchEvent(new Event('storage'))
  }

  return error?.response?.data
}