import { useContext, useState } from 'react'
import { Helmet } from 'react-helmet'
import useApi from '../../../hooks/useApi'
import { AppContext } from '../../../context'
import { getOffices } from '../../../api/offices'
import { getChairs } from '../../../api/shared-areas'
import ChairsContainer from '../../../containers/ChairsContainer'
import PlacesCardContainer from '../../../containers/PlacesCardContainer'
import AddOfficeModal from '../../../components/modals/AddOfficeModal'
import DeleteOfficeModal from '../../../components/modals/DeleteOfficeModal'

const PlacesAdminPanel = () => {
  const { token, rooms } = useContext(AppContext)
  const [offices, updateOffices] = useApi(() => getOffices(token), [], [token])
  const [showAddOfficeModal, setShowAddOfficeModal] = useState(false)
  const [showDeleteOfficeModal, setShowDeleteOfficeModal] = useState(false)
  const [officeSelected, setOfficeSelected] = useState({})
  const [chairs, updateChairs] = useApi(
    () => getChairs(token),
    [],
    [token]
  )

  const toggleShowAddOfficeModal = () => {
    setShowAddOfficeModal((prev) => !prev)
  }

  const openShowDeleteOfficeModal = (office) => {
    setOfficeSelected(office)
    setShowDeleteOfficeModal(true)
  }

  const closeShowDeleteOfficeModal = () => {
    setShowDeleteOfficeModal(false)
  }

  return (
    <>
      <Helmet>
        <title>Vida Cowork: Lugares</title>
      </Helmet>
      <main className="PlacesAdminPanel panel-main pt-4 pb-5 px-3 px-md-5">
        <h1 className="text-1">¡Administrá los espacios en Vida Cowork!</h1>

        <PlacesCardContainer
          data={offices}
          type="offices"
          openAddModal={toggleShowAddOfficeModal}
          openDeleteModal={openShowDeleteOfficeModal}
        />

        <PlacesCardContainer data={rooms} type="rooms" />

        <ChairsContainer
          chairs={chairs}
          updateChairs={updateChairs}
        />

        {showAddOfficeModal && (
          <AddOfficeModal
            updateOffices={updateOffices}
            closeModal={toggleShowAddOfficeModal}
          />
        )}

        {showDeleteOfficeModal && (
          <DeleteOfficeModal
            officeSelected={officeSelected}
            updateOffices={updateOffices}
            closeModal={closeShowDeleteOfficeModal}
          />
        )}

      </main>
    </>
  )
}

export default PlacesAdminPanel
