import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons'
import FooterIcon from '../assets/brand/map-icon-brown.svg'
import { useContext } from 'react'
import { AppContext } from '../context'
import { findContact } from '../utils/findContact'
import { NavLink } from 'react-router-dom'


const Footer = () => {
  const { networks } = useContext(AppContext)

  return (
    <>
      <footer className="Footer container-fluid">
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-center justify-content-lg-start align-items-center">
            <img
              src={FooterIcon}
              className='Footer__Address-Image'
              alt='Vida'
              width={30}
              height={45}
            />
            <div className='ms-2'>
              <p className="Footer__Address-Title m-0">
                Fernando Fader 3971
              </p>
              <p className="Footer__Address-Caption m-0">
                Cerro de Las Rosas | Córdoba
              </p>

              <p className="Footer__Address-Title m-0 mt-3">
                Hugo Wast 4557
              </p>
              <p className="Footer__Address-Caption m-0">
                Cerro de Las Rosas | Córdoba
              </p>
            </div>
          </div>
        </div>

        <div className="d-none d-lg-flex Footer__List">
          <NavLink to='/planes' className="text-3 fw-medium">Planes</NavLink>
          <NavLink to='/oficinas' className="text-3 fw-medium">Oficinas</NavLink>
          <NavLink to='/salas' className="text-3 fw-medium">Salas</NavLink>
          <NavLink to='/eventos' className="text-3 fw-medium">Eventos</NavLink>
        </div>

        <div className='d-flex flex-column gap-3'>
          <div className="Footer__Logo-Container gap-2">
            <p className='text-4 fw-medium mt-0 mb-0'>Seguinos en redes:</p>
            <div className="Footer__Network">
              <a
                className='Footer__Network-Link--Instagram'
                href={findContact(networks, 'instagram')}
                aria-label='Instagram'
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={ faInstagram }/>
              </a>
              <a
                className='Footer__Network-Link--Facebook'
                href={findContact(networks, 'facebook')}
                aria-label='Facebook'
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={ faFacebook }/>
              </a>
            </div>
          </div>

          <div className="Footer__Logo-Container gap-2">
            <p className='text-4 fw-medium mt-0 mb-0'>Contacto:</p>
            <a className='Footer__Item-Link' href="tel:+5493513548319">+54 9 351 3548319</a>
          </div>
        </div>
      </footer>
      <div className="Linkchar container-fluid d-flex justify-content-center py-2">
        Powered By <a className='Linkchar__Link d-flex ms-1' href="https://linkchar.com/" target="_blank" rel="noopener noreferrer"> LinkChar<span className='d-none d-sm-block ms-1'>Software Development</span></a>
      </div>
    </>
  )
}

export default Footer