import PropTypes from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Autoplay, EffectFade } from 'swiper'
import { getImages } from '../api/admin-settings'
import useApi from '../hooks/useApi'
import { URL } from '../constants'


const MiniBanner = ({type, right, children }) => {
  const [images] = useApi(() => getImages(type), [])

  return (
    <div className={right ? 'MiniBanner-Right container-fluid px-3 px-md-0 py-4 py-md-0' : 'MiniBanner container-fluid px-3 px-md-0 py-4 py-md-0'}>
      <div className="MiniBanner__Content">
        <Swiper
          className='MiniBanner__Swiper'
          modules={[ Navigation, Pagination, Autoplay, EffectFade ]}
          slidesPerView={1}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
            reverseDirection: right ? true : false
          }}
          loop={true}
          navigation
          pagination={{ clickable: true }}
          speed={1000}
          effect={'fade'}
        >
          {images.map((image, index) => (
            <SwiperSlide key={`MiniBanner-${image}-${index}`}>
              <picture>
                <source
                  srcSet={image.image ? `${URL}${image.image}` : ''}
                  media="(min-width: 1080px)"
                  width={1426}
                  height={742}

                />
                <source
                  srcSet={image.image ? `${URL}${image.image}` : ''}
                  media="(min-width: 768px)"
                  width={1064}
                  height={554}
                />
                <source
                  srcSet={image.image ? `${URL}${image.image}` : ''}
                  media="(min-width: 576px)"
                  width={675}
                  height={585}
                />
                <img
                  className='MiniBanner__Swiper-Image'
                  src={image.image ? `${URL}${image.image}` : ''}
                  alt=''
                  title=''
                  loading='lazy'
                  width={478}
                  height={422}
                />
              </picture>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className={right ? 'MiniBanner-Right__Title-Container' : 'MiniBanner__Title-Container'}>
          { children }
        </div>
      </div>
    </div>
  )
}

MiniBanner.propTypes = {
  type: PropTypes.string.isRequired,
  images: PropTypes.array,
  right: PropTypes.bool,
  children: PropTypes.node.isRequired
}

export default MiniBanner