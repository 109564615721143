import { memo, useState, useEffect, useContext } from 'react'
import propTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Media from './Media'
import { AppContext } from '../context'


const PlanCard = ({ name, info }) => {
  const { plansDescriptions } = useContext(AppContext)
  const [infoKeys, setInfoKeys] = useState([])


  useEffect(() => {
    Object.keys(info).map((key) => setInfoKeys((prev) => [...prev, key]))
  }, [])


  return (
    <div className="PlanCard">
      {infoKeys.length !== 0 && (
        <Media
          type="image"
          className="PlanCard__Logo"
          src={info[infoKeys[0]]?.image}
          alt={name}
        />
      )}
      <p className="PlanCard__Title text-1 m-0">Plan {name}</p>
      <p
        className="PlanCard__Text mb-0"
        dangerouslySetInnerHTML={{
          __html: plansDescriptions?.find((info) => info.plan_name === name)
            ?.description,
        }}
      />
      <div className="PlanCard__Price-Container">
        <div className="d-flex flex-column">
          <span className="PlanCard__Price-Title text-3">PART</span>
          <span className="PlanCard__Price">
            <span className="text-4">$</span>
            {infoKeys.map((key) =>
              info[key].type === 'Part plan' ? info[key].price : ''
            )}
            <span className="text-6 fw-light ms-1">(*)</span>
          </span>
        </div>
        <div className="d-flex flex-column">
          <span className="PlanCard__Price-Title text-3">FULL</span>
          <span className="PlanCard__Price">
            <span className="text-4">$</span>
            {infoKeys.map((key) =>
              info[key].type === 'Full plan' ? info[key].price : ''
            )}
            <span className="text-6 fw-light ms-1">(*)</span>
          </span>
        </div>
      </div>
      <div className="PlanCard__Button-Container">
        <span className='text-6 fw-light'>(*) los valores no incluyen IVA</span>
        <Link to="/planes" className="PlanCard__Button button-transparent">
          Más info
        </Link>
        <Link to="/panel/planes" className="PlanCard__Button button-orange">
          ¡Lo quiero!
        </Link>
      </div>
    </div>
  )
}

PlanCard.propTypes = {
  name: propTypes.string.isRequired,
  info: propTypes.object.isRequired,
}

export default memo(PlanCard)
