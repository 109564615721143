import { useContext, memo } from 'react'
import propTypes from 'prop-types'
import { AppContext } from '../context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBriefcase, faCircleExclamation, faCircleXmark, faUser } from '@fortawesome/free-solid-svg-icons'


const SpaceDay = ({
  data,
  dayWeekName,
  dayNumber,
  isCurrentDay = false,
  isFill = false,
  isDisabled = false,
  isHoliday = false,
  isPastDay = false,
  isLimitDay = false,
  isMorningAvailable = true,
  isAfternoonAvailable = true,
  filter = '',
  openModal,
  openOverlay,
  closeOverlay
}) => {
  const { isAdmin, user, sharedAreasTime } = useContext(AppContext)

  const filterData = data?.length > 0 && data.filter(item => {
    if(filter == 1) {
      return item.booking_type === 'Completo'
    }
    if(filter == 2) {
      return item.booking_type === 'Mañana'
    }
    if(filter == 3) {
      return item.booking_type === 'Tarde'
    }
    return item
  })

  const renderReservation = (reservation) => {

    const renderReservationIcon = () => {
      if(user.admin_in.length !== 0 || user.employee_in.length !== 0) return (
        <FontAwesomeIcon icon={faBriefcase} className='me-2' size='1x'/>
      )
    }

    // console.log(reservation)
    if(reservation.booking_type === 'Completo') return (
      <div className="SpaceDay__Data-Container" key={`SpaceDay-Data-Item-${reservation.date}`}>
        <div className="SpaceDay__Data-Item--Complete">
          <span className="text-4">
            {renderReservationIcon()}
            Jornada Completa<br/>
          </span>
          <span className="text-5">{sharedAreasTime.morningStartTime} - {sharedAreasTime.afternoonEndTime}</span>
        </div>
      </div>
    )
    else if(reservation.booking_type === 'Mañana') return (
      <div className="SpaceDay__Data-Container" key={`SpaceDay-Data-Item-${reservation.date}`}>
        <div className="SpaceDay__Data-Item--Morning">
          <span className="text-4">
            {renderReservationIcon()}
            Turno Mañana<br/>
          </span>
          <span className="text-5">{sharedAreasTime.morningStartTime} - {sharedAreasTime.morningEndTime}</span>
        </div>
      </div>
    )
    else if(reservation.booking_type === 'Tarde') return (
      <div className="SpaceDay__Data-Container" key={`SpaceDay-Data-Item-${reservation.date}`}>
        <div className="SpaceDay__Data-Item--Afternoon">
          <span className="text-4">
            {renderReservationIcon()}
            Turno Tarde<br/>
          </span>
          <span className="text-5">{sharedAreasTime.afternoonStartTime} - {sharedAreasTime.afternoonEndTime}</span>
        </div>
      </div>
    )
  }

  const renderMultipleReservations = () => {
    const morningReservations = filterData.filter(item => item.booking_type === 'Mañana')
    const afternoonReservations = filterData.filter(item => item.booking_type === 'Tarde')
    const completeReservations = filterData.filter(item => item.booking_type === 'Completo')

    return (
      <div className='SpaceDay__Multiple-Reservations-Container'>
        {morningReservations.length > 0 && (
          <div className="SpaceDay__Data-Item--Reservations-Morning">
            <span className="text-6">
              {morningReservations.length} Turno Mañana
            </span>
          </div>
        )}

        {afternoonReservations.length > 0 && (
          <div className="SpaceDay__Data-Item--Reservations-Afternoon">
            <span className="text-6">
              {afternoonReservations.length} Turno Tarde
            </span>
          </div>
        )}

        {completeReservations.length > 0 && (
          <div className="SpaceDay__Data-Item--Reservations-Complete">
            <span className="text-6">
              {completeReservations.length} Jornada Completa
            </span>
          </div>
        )}
      </div>
    )
  }

  const renderWarnings = () => {
    if(isHoliday) return (
      <div className="SpaceDay__Data-Container" key={`SpaceDay-Data-Item-Afternoon-${dayNumber}`}>
        <div className="SpaceDay__Data-Item--Disabled">
          <span className="text-3">
            <FontAwesomeIcon icon={faCircleXmark}/>
          </span>
          <span className="text-5">
            Inhabilitado
          </span>
        </div>
      </div>
    )
    else if(!isAfternoonAvailable && !isMorningAvailable) return (
      <div className="SpaceDay__Data-Container" key={`SpaceDay-Data-Item-Complete-${dayNumber}`}>
        <div className="SpaceDay__Data-Item--Disabled">
          <span className="text-3">
            <FontAwesomeIcon icon={faCircleXmark}/>
          </span>
          <span className="text-5">
            {isCurrentDay
              ? 'El día ya terminó'
              : 'Sin lugar'
            }
          </span>
        </div>
      </div>
    )
    else if(!isMorningAvailable) return (
      <div className="SpaceDay__Data-Container" key={`SpaceDay-Data-Item-Morning-${dayNumber}`}>
        <div className="SpaceDay__Data-Item--Alert">
          <span className="text-3">
            <FontAwesomeIcon icon={faCircleExclamation}/>
          </span>
          {isCurrentDay
            ? <span  className="text-5">Turno <span className="fw-bold">mañana</span> no disponible</span>
            : <span  className="text-5">Sin lugar a la <span className="fw-bold">mañana</span></span>
          }
        </div>
      </div>
    )
    else if(!isAfternoonAvailable) return (
      <div className="SpaceDay__Data-Container" key={`SpaceDay-Data-Item-Afternoon-${dayNumber}`}>
        <div className="SpaceDay__Data-Item--Alert">
          <span className="text-3">
            <FontAwesomeIcon icon={faCircleExclamation}/>
          </span>
          {isCurrentDay
            ? <span  className="text-5">Turno <span className="fw-bold">tarde</span> no disponible</span>
            : <span  className="text-5">Sin lugar a la <span className="fw-bold">tarde</span></span>
          }
        </div>
      </div>
    )
  }

  const handleOpenModal = () => {
    closeOverlay()
    openModal({ data, dayWeekName, dayNumber, isPastDay, isMorningAvailable, isAfternoonAvailable })
  }
  const handleOpenOverlay = () => {
    openOverlay({ data, dayWeekName, dayNumber, isCurrentDay, isPastDay, isMorningAvailable, isAfternoonAvailable })
  }


  if(isFill) return (
    <button className="SpaceDay--Fill" disabled>
      <span className="SpaceDay__Number">{dayNumber}</span>
    </button>
  )

  if(isDisabled) return (
    <button className="SpaceDay--Disabled" disabled>
      <span className={isCurrentDay ? 'SpaceDay__Number--Current' : 'SpaceDay__Number'}>{dayNumber}</span>
    </button>
  )

  if(isHoliday) return (
    <button className="SpaceDay--Disabled" disabled>
      <span className="SpaceDay__Number">{dayNumber}</span>
      {renderWarnings()}
    </button>
  )

  if(isPastDay) return (
    <button className="SpaceDay--PastDay" onClick={data.length == 0 ? handleOpenModal : handleOpenOverlay}>
      <span className="SpaceDay__Number">{dayNumber}</span>
      {data?.length == 0 && renderWarnings()}
      {filterData?.length == 1 && renderReservation(filterData[0])}
      {filterData?.length > 1 && renderMultipleReservations()}
    </button>
  )

  if(isLimitDay && !isAdmin) return (
    <button className="SpaceDay--LimitDay" disabled>
      <span className="SpaceDay__Number">{dayNumber}</span>
    </button>
  )

  if(!isAfternoonAvailable && !isMorningAvailable && !isAdmin) return (
    <button className="SpaceDay--Disabled" disabled>
      <span className="SpaceDay__Number">{dayNumber}</span>
      {data?.length == 0 && renderWarnings()}
    </button>
  )

  if(isCurrentDay) return (
    <button className="SpaceDay--Current" onClick={data.length == 0 ? handleOpenModal : handleOpenOverlay}>
      <span className="SpaceDay__Number">{dayNumber}</span>
      {data?.length == 0 && renderWarnings()}
      {filterData?.length == 1 && renderReservation(filterData[0])}
      {filterData?.length > 1 && renderMultipleReservations()}
    </button>
  )

  return (
    <button className="SpaceDay" onClick={data.length == 0 ? handleOpenModal : handleOpenOverlay}>
      <span className="SpaceDay__Number">{dayNumber}</span>
      {data?.length == 0 && renderWarnings()}
      {filterData?.length == 1 && renderReservation(filterData[0])}
      {filterData?.length > 1 && renderMultipleReservations()}
    </button>
  )
}

SpaceDay.propTypes = {
  data: propTypes.array,
  dayWeekName: propTypes.string,
  dayNumber: propTypes.number,
  isCurrentDay: propTypes.bool,
  isFill: propTypes.bool,
  isDisabled: propTypes.bool,
  isHoliday: propTypes.bool,
  isPastDay: propTypes.bool,
  isLimitDay: propTypes.bool,
  isMorningAvailable: propTypes.bool,
  isAfternoonAvailable: propTypes.bool,
  filter: propTypes.oneOfType([propTypes.string, propTypes.number]),
  openModal: propTypes.func,
  openOverlay: propTypes.func,
  closeOverlay: propTypes.func,
}

export default memo(SpaceDay)