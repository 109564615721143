import { deleteData, getData, patchData, postData } from './apiVariables'

export const postEventsForm = async (body) => {
  return await postData({
    path: '/community/events-contact/',
    body
  })
}

export const postIndexForm = async (body) => {
  return await postData({
    path: '/community/index-contact/',
    body
  })
}

// Benefits
export const getBenefits = async () => {
  return await getData({
    path: '/community/benefits/',
    cache: 604800,
    callback: (data) => data.data
  })
}

// News
export const getNews = async () => {
  return await getData({
    path: '/community/news/'
  })
}

export const retriveNews = async (id) => {
  return await getData({
    path: `/community/news/${id}`
  })
}

export const addNews = async (token, body) => {
  return await postData({
    path: '/community/news/',
    token,
    body,
  })
}

export const updateNews = async (token, body) => {
  return await patchData({
    path: '/community/news/',
    token,
    body,
  })
}

export const deleteNews = async (token, id) => {
  return await deleteData({
    path: `/community/news/${id}`,
    token,
  })
}

// Reviews
export const getReviews = async () => {
  return await getData({
    path: '/community/reviews/',
    cache: 604800,
    callback: (data) => data.data
  })
}

// Announcements
export const createAnnouncements = async (token, body) => {
  return await postData({
    path: '/community/create-announcement/',
    token,
    body,
    callback: (data) => data.data
  })
}

export const getAnnouncements = async (token, body) => {
  return await getData({
    path: '/community/info-diffusion-mails/',
    token,
    body,
    callback: (data) => data.data
  })
}

// Others
export const getPartners = async () => {
  return await getData({
    path: '/community/partners/',
    cache: 604800,
  })
}

export const postSendAnnouncements = async (token, body) => {
  return await postData({
    path: '/community/create-announcement/',
    token,
    body
  })
}

export const getCommunityDiscount = async (token, params) => {
  return await getData({
    path: '/community/discount/',
    token,
    params,
    callback: (data) => data.data
  })
}
