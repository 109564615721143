import PropTypes from 'prop-types'
import { useState } from 'react'
import Media from './Media'


const BenefitCard = ({ image, title, description }) => {
  const [showMore, setShowMore] = useState(false)

  const hasOverflow = description.length >= (105 + 230)

  const toggleShowMore = () => setShowMore(p => !p)

  return (
    <div className='BenefitCard'>
      <Media type='image' className='BenefitCard__Image' src={image} alt={title} />
      <div className='BenefitCard__Content'>
        <p className='text-4'>{ title }</p>
        <div className={`BenefitCard__Description text-5 ${hasOverflow ? 'hasOverflow' : ''} ${showMore ? 'showMore' : ''}`} dangerouslySetInnerHTML={{ __html: description }} />
        {hasOverflow ? <button className='BenefitCard__ShowMore_Button text-5 ms-auto d-block' onClick={toggleShowMore}>{!showMore ? 'Mostrar más': 'Mostrar menos'}</button> : <></>}
      </div>
    </div>
  )
}

BenefitCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
}

export default BenefitCard