import { useContext, useEffect, useState } from 'react'
import DataAdminHeader from '../../../containers/DataAdminHeader'
import { Helmet } from 'react-helmet'
import TableSearcher from '../../../components/TableSearcher'
import Table from '../../../containers/Table'
import { AppContext } from '../../../context'
import { useSearchParams } from 'react-router-dom'
import useApi from '../../../hooks/useApi'
import { getCompaniesData } from '../../../api/admin-data'
import AddCompanyModal from '../../../components/modals/AddCompanyModal'
import CompanyInfoModal from '../../../components/modals/CompanyInfoModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const CompaniesDataAdminPanel = () => {
  const { token } = useContext(AppContext)
  const [page, setPage] = useState(1)
  const per_page = 25
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchSpecial, setSearchSpecial] = useState({
    user: {
      id: searchParams.get('user_id') ?? '',
      label: searchParams.get('user_label') ?? '',
    },
    company: {
      id: searchParams.get('company_id') ?? '',
      label: searchParams.get('company_label') ?? '',
    },
  })
  const [search, setSearch] = useState(searchParams.get('search') ?? '')
  const [filtersSelected, setFiltersSelected] = useState([
    {
      label: 'Por estado',
      defaultOption: {
        label: 'Todos los estados',
        value: '',
      },
      option: {
        label: 'Todos los estados',
        value: '',
      },
    },
  ])
  const [order_by, setOrderBy] = useState('')
  const body = {
    page,
    per_page,
    search,
    is_debtor: filtersSelected[0].option.value,
    order_by,
    company_id: searchSpecial.company.id,
  }
  const [companiesData, updateCompaniesData] = useApi(
    () => getCompaniesData(token, body),
    {
      page: 1,
      total_pages: 1,
      total_items: 0,
      data: [],
    },
    [token, page, search, filtersSelected, order_by, searchSpecial]
  )
  const [showAddCompanyModal, setShowAddCompanyModal] = useState(false)

  useEffect(() => {
    setPage(1)
  }, [search])

  const handleSearch = (e) => {
    setSearch(e.target.value)
  }

  const toggleShowAddCompanyModal = () => {
    setShowAddCompanyModal((prev) => !prev)
  }

  const tableHeaders = [
    {
      id: 0,
      label: 'Empresa',
      value: 'name'
    },
    {
      id: 1,
      label: 'Miembros',
      value: 'total_people'
    },
    'Administrador/es',
    {
      id: 3,
      label: 'Fecha de Registro',
      value: 'date_created'
    },
    'Teléfono',
    {
      id: 5,
      label: 'Estado',
      value: 'is_debtor'
    },
    {
      id: 6,
      label: 'Debe',
      value: 'total_debt'
    },
    'Información',
  ]
  const tableHeadersMobile = [
    {
      id: 0,
      label: 'Empresa',
      value: 'name'
    },
    {
      id: 1,
      label: 'Estado',
      value: 'is_debtor'
    },
    {
      id: 2,
      label: 'Debe',
      value: 'total_debt'
    },
  ]

  const filtersForCard = [
    {
      label: 'Por estado',
      options: [
        {
          label: 'Activo',
          value: 'false',
        },
        {
          label: 'Semibloqueado',
          value: 'true',
        },
      ],
    },
  ]


  return (
    <>
      <Helmet>
        <title>Vida Cowork: Empresas</title>
      </Helmet>
      <main className="DataAdminPanel--empresas panel-main panel-main--table">
        <DataAdminHeader currentSection="empresas">
          <>
            <button
              className="button-inverted-panel"
              onClick={toggleShowAddCompanyModal}
            >
              <FontAwesomeIcon icon={faPlus} />
              Agregar empresa
            </button>
            <TableSearcher
              search={search}
              handleSearch={handleSearch}
              filtersSelected={filtersSelected}
              setFiltersSelected={setFiltersSelected}
              filtersForCard={filtersForCard}
              totalItems={companiesData.total_items}
              type={'companies'}
              exportButton
              setSearchParams={setSearchParams}
              searchSpecial={searchSpecial}
              setSearchSpecial={setSearchSpecial}
            />
          </>
        </DataAdminHeader>

        <Table
          type="Companies"
          headers={tableHeaders}
          headersMobile={tableHeadersMobile}
          data={companiesData.data}
          updateData={updateCompaniesData}
          page={companiesData.page}
          setPage={setPage}
          perPage={12}
          totalPages={companiesData.total_pages}
          totalItems={companiesData.total_items}
          setOrderBy={setOrderBy}
          showInfoMobile
        />

        {showAddCompanyModal && (
          <AddCompanyModal
            updateCompaniesData={updateCompaniesData}
            closeModal={toggleShowAddCompanyModal}
          />
        )}
      </main>
    </>
  )
}

export default CompaniesDataAdminPanel
