import { useState, memo, useRef, useEffect } from 'react'
import propTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleDown,
  faCircleExclamation,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'


const InputAnimated = ({
  label,
  name,
  type,
  placeholder,
  value,
  list,
  onChange,
  error,
  tip,
  icon,
  disabled,
  required = false,
  min,
}) => {
  const inputRef = useRef()
  const [showPassword, setShowPassword] = useState(false)
  const [showList, setShowList] = useState(false)
  const [selectedValue, setSelectedValue] = useState(value)

  useEffect(() => {
    setSelectedValue(value)
  }, [value])

  useEffect(() => {
    if (disabled) {
      setShowList(false)
      setSelectedValue('')
    }
  }, [disabled])

  useEffect(() => {
    if (inputRef.current) {
      if (showList) {
        setTimeout(() => {
          inputRef.current.focus()
        }, 200)
      }
    }
  }, [showList])

  const toogleShowPassword = () => {
    setShowPassword((p) => !p)
  }

  const selectValue = (value) => {
    setShowList(false)
    setSelectedValue(value)
    onChange(name, value)
  }

  if (list)
    return (
      <div className="InputAnimated__Input_Container w-100 d-flex flex-column align-items-start position-relative">
        {error && <span className="Input__Error">{error}</span>}

        <div
          className={classNames('Input__List-Container', {
            Disabled: disabled,
          })}
          ref={inputRef}
          onClick={() => !disabled && setShowList(!showList)}
          onBlur={() => {
            setTimeout(() => {
              setShowList(false)
            }, 150)
          }}
          tabIndex="0"
        >
          <div
            className={classNames(
              'InputAnimated__Input Input',
              {
                'Input--With-Icon': icon,
                Selecting: showList,
              },
              'text-4'
            )}
            id={name}
          >
            {selectedValue}
          </div>
          <label
            htmlFor={name}
            className={classNames('InputAnimated__Title text-4 fw-normal', {
              With_Icon: icon,
              Not_Empty: value || showList,
            })}
          >
            {label}
          </label>
          {icon ? (
            <div className="Input__Icon_container">
              <FontAwesomeIcon icon={icon} className="Input__Icon" />
            </div>
          ) : (
            <></>
          )}
          <FontAwesomeIcon
            icon={faAngleDown}
            className={
              showList ? 'Input__List-Icon--Active' : 'Input__List-Icon'
            }
          />
          {showList && (
            <div className="Input__List">
              {list.map((item, index) => (
                <span
                  key={`Input__List-${item}-${index}`}
                  className={
                    item === selectedValue
                      ? 'Input__List-Item--Active'
                      : 'Input__List-Item'
                  }
                  onClick={() => selectValue(item)}
                >
                  {item}
                </span>
              ))}
            </div>
          )}
        </div>
      </div>
    )

  return (
    <div className="InputAnimated__Input_Container w-100 d-flex flex-column align-items-start position-relative">
      <div className="w-100 position-relative">
        <input
          className={classNames(
            'InputAnimated__Input Input',
            {
              'Input--Disabled': disabled,
              'Input--With-Icon': !disabled && icon,
              Active: type === 'date' && value !== '',
            },
            'text-4'
          )}
          type={showPassword ? 'text' : type}
          name={name}
          placeholder={placeholder}
          id={name}
          value={value}
          min={min}
          onChange={onChange}
          required={required}
          disabled={disabled}
        />
        <label
          htmlFor={name}
          className={classNames('InputAnimated__Title text-4 fw-normal', {
            With_Icon: icon,
            Not_Empty: value,
          })}
        >
          {label}
        </label>
        {icon ? (
          <div className="Input__Icon_container">
            <FontAwesomeIcon icon={icon} className="Input__Icon" />
          </div>
        ) : (
          <></>
        )}
        {type === 'password' ? (
          <button className="Input__Password-Icon" type='button' onClick={toogleShowPassword}>
            <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
          </button>
        ) : (
          <></>
        )}
        {tip && !error && (
          <div
            className="Input__Tip"
            dangerouslySetInnerHTML={{ __html: tip }}
          />
        )}
      </div>
      {error && (
        <span className="Input__Error">
          <FontAwesomeIcon
            icon={faCircleExclamation}
            className="Input__Error-Icon"
          />
          {error}
        </span>
      )}
    </div>
  )
}

InputAnimated.propTypes = {
  label: propTypes.string.isRequired,
  name: propTypes.string.isRequired,
  type: propTypes.string,
  placeholder: propTypes.string,
  value: propTypes.any,
  list: propTypes.array,
  search: propTypes.array,
  onChange: propTypes.func,
  error: propTypes.string,
  tip: propTypes.string,
  icon: propTypes.object,
  disabled: propTypes.bool,
  required: propTypes.bool,
  min: propTypes.number,
}

export default memo(InputAnimated)
