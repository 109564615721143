import { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { getCompanyBooks } from '../api/companies'
import { getMyUsersPlans } from '../api/plans'
import InfoCard from '../components/InfoCard'
import HelpModal from '../components/modals/HelpModal'
import Table from './Table'
import { AppContext } from '../context'
import useApi from '../hooks/useApi'
import CancelBookModal from '../components/modals/CancelBookModal'
import ExpirationModal from '../components/modals/ExpirationOverlay'


const CompanyUserPanel = ({ company_id }) => {
  const { token, user } = useContext(AppContext)
  const [plans] = useApi(() => getMyUsersPlans(token, { company: company_id }), [], [token])
  const [totalCreditsHoursAvailable, setTotalCreditsHoursAvailable] = useState({
    total_credits: 0,
    total_hours: 0,
  })
  const [creditsHoursExpiration, setCreditsHoursExpiration] = useState({
    creditsList: [{ id: 0, credits: 0, end_date: '' }],
    hoursList: [{ id: 0, hours: 0, end_date: '' }],
  })
  const [page, setPage] = useState(1)
  const per_page = 12
  const [order_by, setOrderBy] = useState('')
  const body = {
    company_id,
    page,
    per_page,
    order_by,
  }
  if (order_by === '-date') {
    body.order_by = 'date'
    body.reverse = 'true'
  } else if (order_by === '-type') {
    body.order_by = 'type'
    body.reverse = 'true'
  }
  const [companyBooks, updateCompanyBooks] = useApi(
    () => getCompanyBooks(token, body),
    {
      page: 1,
      total_pages: 1,
      total_items: 0,
      data: [],
    },
    [token, user, company_id, page, order_by]
  )
  const [book, setBook] = useState({})
  const [showHelpModal, setShowHelpModal] = useState(false)
  const [showExpirationModal, setShowExpirationModal] = useState(false)
  const [showCancelBookModal, setShowCancelBookModal] = useState(false)

  const toggleShowExpirationModal = () => {
    setShowExpirationModal((prev) => !prev)
  }

  const openShowCancelBookModal = (book) => {
    setBook(book)
    setShowCancelBookModal(true)
  }

  const closeShowCancelBookModal = () => {
    setShowCancelBookModal(false)
  }

  useEffect(() => {
    const plansInCompany = plans.filter((plan) => plan.company === company_id)

    setCreditsHoursExpiration({
      creditsList: [{ id: 0, credits: 0, end_date: '' }],
      hoursList: [{ id: 0, hours: 0, end_date: '' }],
    })
    if (plansInCompany.length !== 0) {
      let creditsHoursExpiration = {
        creditsList: [],
        hoursList: [],
      }

      plansInCompany.forEach((plan) => {
        const planIndex = creditsHoursExpiration.creditsList.findIndex(
          (item) => item.end_date === plan.end_date
        )

        if (planIndex >= 0) {
          creditsHoursExpiration = {
            creditsList: [
              ...creditsHoursExpiration.creditsList.slice(0, planIndex),
              {
                ...creditsHoursExpiration.creditsList[planIndex],
                credits:
                  creditsHoursExpiration.creditsList[planIndex].credits +
                  plan.available_credits,
              },
              ...creditsHoursExpiration.creditsList.slice(planIndex + 1),
            ],
            hoursList: [
              ...creditsHoursExpiration.hoursList.slice(0, planIndex),
              {
                ...creditsHoursExpiration.hoursList[planIndex],
                hours:
                  creditsHoursExpiration.hoursList[planIndex].hours +
                  plan.available_hours,
              },
              ...creditsHoursExpiration.hoursList.slice(planIndex + 1),
            ],
          }
        } else {
          creditsHoursExpiration = {
            creditsList: [
              ...creditsHoursExpiration.creditsList,
              {
                id: plan.id,
                credits: plan.available_credits,
                end_date: plan.end_date,
              },
            ],
            hoursList: [
              ...creditsHoursExpiration.hoursList,
              {
                id: plan.id,
                hours: plan.available_hours,
                end_date: plan.end_date,
              },
            ],
          }
        }
      })

      setCreditsHoursExpiration(creditsHoursExpiration)
    }

    let totalCreditsHours = { total_credits: 0, total_hours: 0 }

    plansInCompany.forEach((plan) => {
      totalCreditsHours = {
        total_credits: totalCreditsHours.total_credits + plan.available_credits,
        total_hours: totalCreditsHours.total_hours + plan.available_hours,
      }
    })

    setTotalCreditsHoursAvailable(totalCreditsHours)
  }, [plans, company_id])

  const dataHeaders = [
    {
      id: 0,
      label: 'Fecha',
      value: 'date',
    },
    {
      id: 1,
      label: 'Tipo de reserva',
      value: 'type',
    },
    'Jornada',
    'Horas / créditos utilizados',
    '',
  ]

  const companyBooksHeadersMobile = [
    {
      id: 0,
      label: 'Fecha',
      value: 'date',
    },
    {
      id: 1,
      label: 'Reserva',
      value: 'type',
    },
    'Horario',
  ]

  const defaultInfo = [
    {
      title: 'Créditos Vida',
      name: 'Créditos',
      description:
        'Disponibles para reservar un puesto en el espacio compartido.',
      value: totalCreditsHoursAvailable.total_credits,
      isExpiration: creditsHoursExpiration.creditsList[0].credits !== 0,
    },
    {
      title: 'Horas en Salas',
      name: 'Horas',
      description:
        'Disponibles para reservar las salas de reuniones.',
      value: totalCreditsHoursAvailable.total_hours,
      isExpiration: creditsHoursExpiration.hoursList[0].hours !== 0,
    },
  ]

  return (
    <main className="CompanyUserPanel panel-main py-4">
      <section className="CompanyUserPanel__Three-Card px-3 px-md-5">
        <div className="CompanyUserPanel__Card-Company-name">
          <h3>
            {
              user?.employee_in.find((company) => company.id === company_id)
                .name
            }
          </h3>
        </div>
        {defaultInfo.map((info, index) => (
          <InfoCard
            key={`Info-Company-Card-${index}`}
            title={info.title}
            name={info.name}
            description={info.description}
            value={info.value}
            maxValue={info.maxValue}
            showHelp={() => setShowHelpModal(true)}
            isExpiration={info.isExpiration}
            showExpiration={toggleShowExpirationModal}
          />
        ))}
      </section>
      <section className="CompanyUserPanel__Reservations">
        <div className="CompanyUserPanel__CompanieUsers-title px-3 px-md-5">
          <h3 className="text-2">
            {companyBooks.data.length !== 0
              ? `Reservas realizadas (${companyBooks.data.length})`
              : 'Reservas'}
          </h3>
        </div>
        <Table
          type={'Reservation-User'}
          headers={dataHeaders}
          headersMobile={companyBooksHeadersMobile}
          data={companyBooks.data}
          page={companyBooks.page}
          setPage={setPage}
          perPage={per_page}
          totalPages={companyBooks.total_pages}
          totalItems={companyBooks.total_items}
          setOrderBy={setOrderBy}
          isEmptyMenssage="No hay reservas realizadas"
          openInfoModal={openShowCancelBookModal}
          unselectable
          showInfoMobile
        />
      </section>

      {showHelpModal && (
        <HelpModal closeModal={() => setShowHelpModal(false)} />
      )}

      {showExpirationModal && (
        <ExpirationModal
          creditsHoursExpiration={creditsHoursExpiration}
          closeModal={toggleShowExpirationModal}
        />
      )}

      {showCancelBookModal && (
        <CancelBookModal
          book={book}
          updateCompanyBooks={updateCompanyBooks}
          closeModal={closeShowCancelBookModal}
        />
      )}
    </main>
  )
}

CompanyUserPanel.propTypes = {
  company_id: PropTypes.number.isRequired,
}

export default CompanyUserPanel
