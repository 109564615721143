import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import { useContext, useState } from 'react'
import { delDeleteAdmin, delDeleteEmployee } from '../../api/companies'
import { AppContext } from '../../context'
import ModalHeader from '../ModalHeader'
import FinishModal from './FinishModal'


const DeleteEmployeeModal = ({ userInfo, company_id, updateCompanyInfo, closeModal }) => {
  const { token } = useContext(AppContext)
  const [step, setStep] = useState(1)
  const [buttonDisable, setButtonDisable] = useState(false)

  const handleDeleteEmployee = async () => {
    if(buttonDisable) return
    setButtonDisable(true)
    const body = { company_id, user_id: userInfo.id }
    if (userInfo.type === 'Company_admin') {
      const response = await delDeleteAdmin(token, body)

      if (response.error) {
        return
      }
      else if (response) {
        updateCompanyInfo()
        setStep((prev) => prev + 1)
      }
    } else {
      const response = await delDeleteEmployee(token, body)

      if (response.error) {
        return
      }
      else if (response) {
        updateCompanyInfo()
        setStep((prev) => prev + 1)
      }
    }
    setButtonDisable(false)
  }

  if (step === 1)
    return (
      <div className="PanelModal px-3" onClick={closeModal}>
        <div
          className="PanelModal__Card--Medium"
          onClick={(e) => e.stopPropagation()}
        >
          <ModalHeader title={'Eliminar integrante'} closeModal={closeModal} />

          <div className="d-flex flex-column align-items-center gap-2 text-center my-4 text-2">
            <FontAwesomeIcon icon={faExclamationCircle} />
            {userInfo.first_name || userInfo.last_name ? `¿Estás seguro que deseas eliminar a ${userInfo.first_name} ${userInfo.last_name}?` : '¿Estás seguro que deseas eliminar integrante?'}
          </div>

          <div className="text-4 text-center mb-4">
						Sus planes y reservas dentro de la empresa serán removidos
          </div>

          <div className="d-flex justify-content-center mb-2">
            <button
              className="button-green-panel"
              onClick={handleDeleteEmployee}
            >
							Sí, eliminar
            </button>
          </div>

          <div className="d-flex justify-content-center">
            <button
              className="button-white-gray-dark px-4 text-decoration-underline"
              onClick={closeModal}
            >
							No eliminar
            </button>
          </div>
        </div>
      </div>
    )

  if (step === 2)
    return (
      <FinishModal
        type="--Medium"
        title={userInfo.first_name || userInfo.last_name ? `${userInfo.first_name} ${userInfo.last_name} a sido eliminado`: 'Integrante eliminado'}
        message={'Su reserva ha sido cancelada'}
        buttonText="¡Entendido!"
        closeModal={closeModal}
      />
    )
}

DeleteEmployeeModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  userInfo: PropTypes.object.isRequired,
  company_id: PropTypes.number.isRequired,
  updateCompanyInfo: PropTypes.func.isRequired,
}

export default DeleteEmployeeModal
