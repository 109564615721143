import { memo, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { useContext } from 'react'
import { AppContext } from '../context'
import Media from './Media'


const OtherPlanCard = ({
  name,
  info,
  showSolicitPlanFreelan,
  disable = false,
  is_selling_allowed = true,
}) => {
  const { user, plansDescriptions } = useContext(AppContext)
  const [infoKeys, setInfoKeys] = useState([])
  const activePlan = user.plans.filter(
    (item) => item.plan.name === name
  ).length


  useEffect(() => {
    const keys = Object.keys(info).map((key) => key)

    setInfoKeys(keys)
  }, [])


  return (
    <div className={`OtherPlanCard${!is_selling_allowed ? '--Disabled' : ''}`}>
      <Media
        type="image"
        className="OtherPlanCard__Logo"
        src={info[infoKeys[0]]?.image}
        alt={name}
      />

      <h3 className="OtherPlanCard__Title text-1">
        Plan {name}
        <br />
        {disable ? (
          <span className="OtherPlanCard__Active-Text text-5">
            Activo (x{activePlan})
          </span>
        ) : (
          <div className="OtherPlanCard__Fill"></div>
        )}
      </h3>
      <p
        className="OtherPlanCard__Text mb-0"
        dangerouslySetInnerHTML={{
          __html: plansDescriptions?.find((info) => info.plan_name === name)
            ?.description + ' (*)',
        }}
      />
      <div className="OtherPlanCard__Price-Container">
        <div className="d-flex flex-column">
          <span className="OtherPlanCard__Price-Title text-5">PART</span>
          <span className="OtherPlanCard__Price text-4">
            <span className="text-5">$</span>
            {infoKeys.map((key) =>
              info[key].type === 'Part plan' ? info[key].price : ''
            )}
          </span>
        </div>
        <div className="d-flex flex-column">
          <span className="OtherPlanCard__Price-Title text-5">FULL</span>
          <span className="OtherPlanCard__Price text-4">
            <span className="text-5">$</span>
            {infoKeys.map((key) =>
              info[key].type === 'Full plan' ? info[key].price : ''
            )}
          </span>
        </div>
      </div>
      <div className="OtherPlanCard__Price-Container">
        <div className="d-flex flex-column">
          <span className="OtherPlanCard__Data text-4 text-start">
            {infoKeys.map((key) =>
              info[key].type === 'Part plan' ? info[key].credits : ''
            )}
            <span className="text-5 ms-2">créditos</span>
          </span>
          <span className="OtherPlanCard__Data text-4 text-start">
            + {' '}
            {infoKeys.map((key) =>
              info[key].type === 'Part plan' ? info[key].hours : ''
            )}
            <span className="text-5 ms-2">horas en salas</span>
          </span>
        </div>
        <div className="d-flex flex-column">
          <span className="OtherPlanCard__Data text-4 text-start">
            {infoKeys.map((key) =>
              info[key].type === 'Full plan' ? info[key].credits : ''
            )}
            <span className="text-5 ms-2">créditos</span>
          </span>
          <span className="OtherPlanCard__Data text-4 text-start">
            + {' '}
            {infoKeys.map((key) =>
              info[key].type === 'Full plan' ? info[key].hours : ''
            )}
            <span className="text-5 ms-2">horas en salas</span>
          </span>
        </div>
      </div>
      <div className="OtherPlanCard__Button-Container">
        <button
          className="OtherPlanCard__Button button-orange"
          onClick={() => showSolicitPlanFreelan(name)}
          disabled={!is_selling_allowed}
        >
          {!is_selling_allowed ? 'Desabilitado' : '¡Lo quiero!'}
        </button>
      </div>
    </div>
  )
}

OtherPlanCard.propTypes = {
  name: propTypes.string.isRequired,
  info: propTypes.object.isRequired,
  showSolicitPlanFreelan: propTypes.func.isRequired,
  disable: propTypes.bool,
  is_selling_allowed: propTypes.bool,
}

export default memo(OtherPlanCard)
