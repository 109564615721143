import { useContext } from 'react'
import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom'
import { AppContext } from '../context'
// Home pages
import Home from '../pages/home/Home'
import Plans from '../pages/home/Plans.jsx'
import Rooms from '../pages/home/Rooms.jsx'
import Offices from '../pages/home/Offices.jsx'
import Events from '../pages/home/Events.jsx'
// Auth pages
import Login from '../pages/auth/Login.jsx'
import Register from '../pages/auth/Register.jsx'
import ForgotPassword from '../pages/auth/ForgotPassword.jsx'
// User panel pages
import GeneralUserPanel from '../pages/panel/client/GeneralUserPanel'
import ReservationsUserPanel from '../pages/panel/client/ReservationsUserPanel'
import PlansUserPanel from '../pages/panel/client/PlansUserPanel'
// import CommunityUserPanel from '../pages/panel/client/CommunityUserPanel'
import CompanyPanel from '../pages/panel/client/CompanyPanel'
// Admin panel pages
import GeneralAdminPanel from '../pages/panel/admin/GeneralAdminPanel'
import CalendarAdminPanel from '../pages/panel/admin/CalendarAdminPanel'
import DisabledDaysAdminPanel from '../pages/panel/admin/DisabledDaysAdminPanel'
import UsersDataAdminPanel from '../pages/panel/admin/UsersDataAdminPanel'
import PlansDataAdminPanel from '../pages/panel/admin/PlansDataAdminPanel'
import HoursAndCreditsDataAdminPanel from '../pages/panel/admin/HoursAndCreditsDataAdminPanel'
import CompaniesDataAdminPanel from '../pages/panel/admin/CompaniesDataAdminPanel'
import OfficesDataAdminPanel from '../pages/panel/admin/OfficesDataAdminPanel'
import StatisticsAdminPanel from '../pages/panel/admin/StatisticsAdminPanel'
import PlacesAdminPanel from '../pages/panel/admin/PlacesAdminPanel'
import HistoricAdminPanel from '../pages/panel/admin/HistoricAdminPanel'
import Layout from '../layout/Layout.jsx'
import NotFound from './NotFound'
import PanelLayout from '../layout/PanelLayout'
import PanelNotFound from './PanelNotFound'
import RegisterToken from '../pages/auth/RegisterToken'


const App = () => {
  const { isAdmin, user } = useContext(AppContext)

  const hasACompany =
    user?.admin_in?.length !== 0 || user?.employee_in?.length !== 0

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/planes" element={<Plans />} />
          <Route path="/oficinas" element={<Offices />} />
          <Route path="/salas" element={<Rooms />} />
          <Route path="/eventos" element={<Events />} />

          <Route path="/iniciar-sesion" element={<Login />} />
          <Route path="/registro" element={<Register />} />
          <Route path="/registro/verificar-mail/:token" element={<RegisterToken />} />
          <Route path="/recuperar-contrasena" element={<ForgotPassword />} />

          <Route path="*" element={<NotFound />} />
        </Route>

        <Route path="/panel/" element={<PanelLayout />}>
          {isAdmin ? (
            <>
              <Route path="" element={<GeneralAdminPanel />} />
              <Route path="calendario" element={<CalendarAdminPanel />} />
              <Route
                path="calendario/deshabilitar"
                element={<DisabledDaysAdminPanel />}
              />
              <Route path="datos/usuarios" element={<UsersDataAdminPanel />} />
              <Route path="datos/planes" element={<PlansDataAdminPanel />} />
              <Route path="datos/horas-y-creditos" element={<HoursAndCreditsDataAdminPanel />} />
              <Route path="datos/empresas" element={<CompaniesDataAdminPanel />} />
              <Route path="datos/empresa/:name" element={<CompanyPanel />} />
              <Route path="datos/oficinas" element={<OfficesDataAdminPanel />} />
              <Route path="estadisticas" element={<StatisticsAdminPanel />} />
              <Route path="espacios" element={<PlacesAdminPanel />} />
              <Route path="historial" element={<HistoricAdminPanel />} />
              {/* <Route path='ajustes' element={<SettingsAdminPanel/>}/> */}
            </>
          ) : (
            <>
              <Route path="" element={<GeneralUserPanel />} />
              {
                hasACompany && (
                  <Route path="empresa/:name" element={<CompanyPanel />} />
                )
              }
              <Route path="reservas" element={<ReservationsUserPanel />} />
              <Route path="planes" element={<PlansUserPanel />} />
              {/* <Route path="comunidad" element={<CommunityUserPanel />} /> */}
            </>
          )}

          <Route path="*" element={<PanelNotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
