import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import { useContext, useState } from 'react'
import { postAssignPlanToEmployee } from '../../../api/companies'
import { AppContext } from '../../../context'
import ModalHeader from '../../ModalHeader'
import Steps from '../../Steps'


const AssignPlanUserModalStep2 = ({
  closeModal,
  setStep,
  employeesSelected,
  discountCommonUse,
  notAssingedPlans,
  company_id,
  updateCompanyInfo,
}) => {
  const { token } = useContext(AppContext)
  const [buttonDisable, setButtonDisable] = useState(false)

  const handleEndStep = async () => {
    if(buttonDisable) return
    setButtonDisable(true)

    const body = {
      users_list: '',
      usersplan_list: '',
      full_assing: !discountCommonUse,
      company_id,
    }

    let plansToAssing = [...notAssingedPlans]

    employeesSelected.forEach((employee) => {
      const planIndex = plansToAssing.findIndex(
        (plan) => plan.plan__id === employee.plan__id
      )

      body.users_list += employee.user.id + ','
      body.usersplan_list += plansToAssing[planIndex].id + ','

      plansToAssing = [
        ...plansToAssing.slice(0, planIndex),
        ...plansToAssing.slice(planIndex + 1),
      ]
    })

    if (body.users_list.endsWith(',')) {
      body.users_list = body.users_list.slice(0, -1)
    }
    if (body.usersplan_list.endsWith(',')) {
      body.usersplan_list = body.usersplan_list.slice(0, -1)
    }

    const response = await postAssignPlanToEmployee(token, body)

    if (response.error) {
      // console.error(response.error)
      return
    }
    else if (response) {
      updateCompanyInfo()
      setStep((step) => step + 1)
    }
    setButtonDisable(false)
  }

  return (
    <div className="PanelModal px-3" onClick={closeModal}>
      <div
        className="PanelModal__Card--Big"
        onClick={(e) => e.stopPropagation()}
      >
        <ModalHeader
          title={'Asignar plan'}
          closeModal={closeModal}
          goBack={() => setStep((prev) => prev - 1)}
        />

        <div className="PanelModal__Confirm PanelModal__User-Info">
          <div className="PanelModal__User-Info-Item">
            <h4 className="text-4">Usuario</h4> <h4 className="text-4">Plan</h4>
          </div>
          {employeesSelected.map((employee) => (
            <div key={employee.id} className="PanelModal__User-Info-Item">
              <span>
                {employee.user.first_name} {employee.user.last_name}
              </span>{' '}
              <span>{employee.plan}</span>
            </div>
          ))}
        </div>

        <div className="PanelModal__Confirm FinishModal__Info">
          <FontAwesomeIcon icon={faTriangleExclamation} />
          <span>
            Los usuarios pueden tener hasta dos planes activos simultáneamente.
            Si se les asigna un tercer plan, el más próximo a vencerse se
            perderá.
          </span>
        </div>

        <div className="d-flex justify-content-center">
          <button className="button-green-panel my-3" onClick={handleEndStep}>
            Asignar
          </button>
        </div>
        <Steps totalCount={2} currentCount={2} />
      </div>
    </div>
  )
}

AssignPlanUserModalStep2.propTypes = {
  closeModal: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  employeesSelected: PropTypes.array.isRequired,
  discountCommonUse: PropTypes.bool.isRequired,
  notAssingedPlans: PropTypes.array.isRequired,
  company_id: PropTypes.number.isRequired,
  updateCompanyInfo: PropTypes.func.isRequired,
}

export default AssignPlanUserModalStep2
