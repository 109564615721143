import { useContext, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { getSystemPlans, postBuyPlanByUser } from '../../api/plans'
import useApi from '../../hooks/useApi'
import Input from '../Input'
import ModalHeader from '../ModalHeader'
import PanelModalSelector from '../PanelModalSelector'
import FinishModal from './FinishModal'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { getCommunityDiscount } from '../../api/community'
import { AppContext } from '../../context'
import { faTags } from '@fortawesome/free-solid-svg-icons'
import Steps from '../Steps'


const SolicitPlanFreelanModal = ({ closeModal, planName }) => {
  const { token, updateUser } = useContext(AppContext)
  const [step, setStep] = useState(1)
  const [error, setError] = useState('')

  const [plans] = useApi(getSystemPlans, {
    data: {},
    is_selling_allowed: true,
  })
  const [planNameSelected, setPlanNameSelected] = useState('')
  const [planOptionSelected, setPlanOptionSelected] = useState({})

  const [paySelected, setPaySelected] = useState('')
  const [discount, setDiscount] = useState({ name: '', porcent: 0 })

  const filterOptions = Object.keys(plans.data)

  const payOptions = ['Coordinar pago con Vida Cowork']

  useEffect(() => {
    setPlanNameSelected(planName ?? filterOptions[0])
  }, [plans.data])

  const handlePlanOption = (value) => {
    if (value.id === planOptionSelected?.id) {
      setPlanOptionSelected({})
    } else {
      setPlanOptionSelected(value)
    }
  }

  const changeFilter = (filterIndex) => {
    setPlanNameSelected(filterOptions[filterIndex])
    setPlanOptionSelected({})
  }

  const planOptions = (planName) => {
    if (filterOptions.length !== 0) {
      const optionsKey = Object.keys(plans.data[planName])
      return optionsKey.map((option) => {
        return { id: option, name: planName, ...plans.data[planName][option] }
      })
    }
  }

  useEffect(() => {
    if (planOptionSelected?.id) {
      return setError('')
    }
  }, [planOptionSelected])

  const handleNextStep = () => {
    if (!planOptionSelected?.id) {
      return setError('No hay plan seleccionado')
    }

    setStep((step) => step + 1)
  }

  const formik = useFormik({
    initialValues: {
      code: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      code: Yup.string()
        .min(1, 'El codigo de descuento esta vacio')
        .max(40, 'Máximo de caracteres: 40'),
    }),
    onSubmit: async ({ code }) => {
      if(code === '') return 
      const params = { code }

      const response = await getCommunityDiscount(token, params)

      if (response.error) {
        formik.setFieldError('code', 'El codigo de descuento no es  valido')
        setDiscount({
          name: '',
          porcent: 0,
        })
      } else if (response) {
        setDiscount({
          name: response.code,
          porcent: response.porcent_discount,
        })
      }
    },
  })

  const handlePayOption = (value) => {
    if (value === paySelected) {
      setPaySelected('')
    } else {
      setPaySelected(value)
    }
  }

  useEffect(() => {
    if (step === 2) {
      const checkPay = paySelected.length === 0
      setError(checkPay ? 'Es necesario un metodo de pago' : '')
    }
  }, [paySelected])

  const handleEndStep = async () => {
    if (paySelected.length === 0) {
      setError('Es necesario un metodo de pago')
      return null
    }

    const body = {
      plan: planOptionSelected.id,
    }
    if (discount.porcent !== 0) {
      body.code = discount.name
    }

    const response = await postBuyPlanByUser(token, body)

    if (response.error === 'Selling is not allowed for the moment'){
      setError('La compra de planes esta deshabilitada por el momento')
    } else if (response.error === 'You have an unpaid plan') 
      setError('El plan no puede ser solicitado si hay planes impagos')
    else {
      updateUser()
      setStep((step) => step + 1)
    }
  }

  if (step === 1)
    return (
      <div className="PanelModal px-3" onClick={closeModal}>
        <div
          className="PanelModal__Card--Big"
          onClick={(e) => e.stopPropagation()}
        >
          <ModalHeader title={'Adquirí un plan'} closeModal={closeModal} />

          <h3 className="text-4 mt-4 text-center">
            Elegí el que se adapte a tus necesidades
          </h3>

          <div className="PanelModal__SolicitPlanModal-Plans-Container">
            <div className="PanelModal__SolicitPlanModal-Plans-Container-item w-100">
              <div className="d-flex justify-content-center py-4">
                <PanelModalSelector
                  options={filterOptions}
                  optionSelected={planNameSelected}
                  onChange={changeFilter}
                />
              </div>

              <div className="PanelModal__Options-Container mb-4">
                {planOptions(planNameSelected) &&
                  planOptions(planNameSelected).map((option) => {
                    const planType = option.type.split(' ')[0]

                    return (
                      <button
                        className={`PanelModal__Option${
                          option.id === planOptionSelected?.id ? '--Active' : ''
                        } text-4`}
                        key={option.id}
                        onClick={() => handlePlanOption(option)}
                      >
                        <div
                          className={`PanelModal__Option-Circle${
                            option.id === planOptionSelected?.id
                              ? '--Active'
                              : ''
                          }`}
                        ></div>
                        <span>{planType}</span>
                        <span>{option.credits} créditos</span>
                        <span>{option.hours} hs en salas</span>
                        <span className="PanelModal__Option-Price">
                          ${option.price}
                        </span>
                      </button>
                    )
                  })}
              </div>
            </div>
          </div>

          {error.length !== 0 && (
            <div className="d-flex justify-content-center">
              <span className="PanelModal__Error text-5 text-center mt-3">
                {error}
              </span>
            </div>
          )}

          <div className="d-flex justify-content-center">
            <button
              className={`button-green-panel ${
                error.length !== 0 ? 'button-disabled' : ''
              } my-3 `}
              onClick={handleNextStep}
            >
              Siguiente
            </button>
          </div>

          <Steps totalCount={2} currentCount={1} />
        </div>
      </div>
    )

  if (step === 2)
    return (
      <div className="PanelModal px-3" onClick={closeModal}>
        <div
          className="PanelModal__Card--Big"
          onClick={(e) => e.stopPropagation()}
        >
          <ModalHeader
            title={'Adquirí un plan'}
            closeModal={closeModal}
            goBack={() => setStep((step) => step - 1)}
          />

          <div className="py-3">
            <h3 className="text-4 text-center">Detalle de compra</h3>
          </div>

          <div className="PanelModal__Plan-Detail text-uppercase mb-4">
            <span className="text-2 fw-bold">{planOptionSelected.name}</span>
            <span className="text-3">
              {planOptionSelected.type.split(' ')[0]}
            </span>
            <span className="text-2 fw-bold">${planOptionSelected.price}</span>
          </div>

          <div className="PanelModal__Discount-Container py-3">
            <div className="w-100 h-100 d-flex flex-column justify-content-between align-items-start">
              <form className="d-flex align-items-end gap-2 w-100">
                <Input
                  label="Código de descuento"
                  name="code"
                  value={formik.values.code}
                  onChange={formik.handleChange}
                  error={formik.errors.code}
                  icon={faTags}
                  tip="Ingresa aquí tu código de descuento"
                />
                <button
                  className="button-green-panel text-4"
                  type="submit"
                  onClick={formik.handleSubmit}
                >
                  Validar
                </button>
              </form>

              <div className="mt-30px">
                <h5 className="text-4 fw-normal">Subtotal</h5>
                <h4 className="text-3 mt-2">${planOptionSelected.price}</h4>
              </div>
            </div>

            <span className="PanelModal__Discount-Divisor"></span>

            <div className="w-100 d-flex flex-column justify-content-start align-items-start">
              <h4 className="text-3">Pago:</h4>
              <div className="PanelModal__Options-Container my-4">
                {payOptions &&
                  payOptions.map((option, index) => {
                    return (
                      <button
                        className={`PanelModal__Option${
                          option === paySelected ? '--Active' : ''
                        } text-4 justify-content-start`}
                        key={`Spaces-Reservation-Modal-Option-${index}`}
                        onClick={() => handlePayOption(option)}
                      >
                        <div
                          className={`PanelModal__Option-Circle${
                            option === paySelected ? '--Active' : ''
                          }`}
                        />
                        <span>{option}</span>
                      </button>
                    )
                  })}
              </div>

              <h5 className="text-3 fw-normal">Total</h5>
              <h4 className="text-2 mt-2">
                $
                {discount.porcent !== 0
                  ? planOptionSelected.price -
                    planOptionSelected.price * (discount.porcent / 100)
                  : planOptionSelected.price}
              </h4>
              {discount.porcent !== 0 && (
                <span className="text-5 mt-2">
                  {discount.porcent}% de descuento
                </span>
              )}
            </div>
          </div>

          {error.length !== 0 && (
            <div className="d-flex justify-content-center">
              <span className="PanelModal__Error text-5 text-center mt-3">
                {error}
              </span>
            </div>
          )}

          <div className="d-flex justify-content-center">
            <button
              className={`button-green-panel ${
                error.length !== 0 ? 'button-disabled' : ''
              } my-3 `}
              onClick={handleEndStep}
            >
              Finalizar compra
            </button>
          </div>

          <Steps totalCount={2} currentCount={2} />
        </div>
      </div>
    )

  if (step === 3)
    return (
      <FinishModal
        type="--Big"
        title="Adquirí un plan"
        message="¡Plan solicitado con éxito!"
        tips={[
          'Recordá que para ingresar al espacio, tiene que estar <b>abonado el plan.</b>',
          'Si acordaste el pago con nosotros, se te habilitarán <b>solamente 2 Créditos Vida</b> para crear reservas hasta que el plan se encuentre abonado.',
        ]}
        buttonText="Entendido"
        closeModal={closeModal}
      />
    )
}

SolicitPlanFreelanModal.propTypes = {
  planName: propTypes.string,
  closeModal: propTypes.func.isRequired,
}

export default SolicitPlanFreelanModal
