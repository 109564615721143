import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'


const Steps = ({ totalCount, currentCount }) => {
  const [totalCountArray, setTotalCountArray] = useState([])

  useEffect(() => {
    const array = []
    for (let i = 0; i < totalCount; i++) {
      array.push(i + 1)
    }
    setTotalCountArray(array)
  }, [totalCount])

  return (
    <div className="Steps">
      {totalCountArray.map((number) => (
        <div
          key={number}
          className={`Steps__Dot${number <= currentCount ? '--Active' : ''}`}
        >
          {number}
        </div>
      ))}
    </div>
  )
}

Steps.propTypes = {
  totalCount: PropTypes.number.isRequired,
  currentCount: PropTypes.number.isRequired,
}

export default Steps
