import PropTypes from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'
import PlanDetailCard from '../components/PlanDetailCard'
import useApi from '../hooks/useApi'
import { getSystemPlans } from '../api/plans'
import { useContext } from 'react'
import { AppContext } from '../context'


const PlansSlider = () => {
  const { plansDescriptions } = useContext(AppContext)
  const [plans] = useApi(getSystemPlans, {
    data: {},
    is_selling_allowed: true,
  })


  return (
    <div className="PlansSlider mt-4">
      <Swiper
        className="PlansSlider__Swiper"
        modules={[Navigation, Pagination]}
        slidesPerView={1}
        spaceBetween={40}
        navigation
        pagination={{ clickable: true }}
        grabCursor={true}
        speed={500}
        tabIndex={0}
      >
        {Object.keys(plans.data).map((plan, index) => (
          <SwiperSlide
            key={`PlanDetailCard-${index}`}
            className="PlansSlider__Item"
          >
            <PlanDetailCard
              name={plan}
              info={plans.data[plan]}
              description={
                plansDescriptions?.find(
                  (info) =>
                    info?.plan === Number(Object.keys(plans?.data[plan])[0])
                )?.description
              }
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

PlansSlider.propTypes = {
  setSwiper: PropTypes.func,
}

export default PlansSlider
