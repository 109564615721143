import { useContext, useEffect, useState } from 'react'
import DataAdminHeader from '../../../containers/DataAdminHeader'
import { Helmet } from 'react-helmet'
import TableSearcher from '../../../components/TableSearcher'
import Table from '../../../containers/Table'
import OfficeCards from '../../../containers/OfficeCards'
import ChargeHoursModal from '../../../components/modals/ChargeHoursModal'
import AddOfficeModal from '../../../components/modals/AddOfficeModal'
import DeleteOfficeModal from '../../../components/modals/DeleteOfficeModal'
import CreateOfficeBookModal from '../../../components/modals/assignOfficeModal/CreateOfficeBookModal'
import CancelOfficeBook from '../../../components/modals/CancelOfficeBook'
import { AppContext } from '../../../context'
import { useSearchParams } from 'react-router-dom'
import useApi from '../../../hooks/useApi'
import { getOfficesData } from '../../../api/admin-data'
import { getOffices } from '../../../api/offices'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'


const OfficesDataAdminPanel = () => {
  const { token } = useContext(AppContext)
  const [page, setPage] = useState(1)
  const per_page = 10
  const [searchParams] = useSearchParams()
  const [search, setSearch] = useState(searchParams.get('search') ?? '')
  const [filtersSelected, setFiltersSelected] = useState([
    {
      label: 'Por historico',
      defaultOption: {
        label: 'Officinas actules',
        value: '',
      },
      option: {
        label: 'Officinas actules',
        value: '',
      },
    },
    {
      label: 'Por oficina',
      defaultOption: {
        label: 'Todas las oficinas',
        value: '',
      },
      option: {
        label: 'Todas las oficinas',
        value: '',
      },
    },
  ])
  const [order_by, setOrderBy] = useState('')
  const body = {
    page,
    per_page,
    search,
    historical: filtersSelected[0].option.value,
    office_id: filtersSelected[1].option.value,
    order_by,
  }
  if (order_by === '-full_name') {
    body.order_by = 'full_name'
    body.reverse = 'true'
  } else if (order_by === '-company_name') {
    body.order_by = 'company_name'
    body.reverse = 'true'
  } else if (order_by === '-type') {
    body.order_by = 'type'
    body.reverse = 'true'
  }
  const [officesData, updateOfficesData] = useApi(
    () => getOfficesData(token, body),
    {
      page: 1,
      total_pages: 1,
      total_items: 0,
      data: [],
    },
    [token, page, search, filtersSelected, order_by]
  )
  const [offices, updateOffices] = useApi(() => getOffices(token), [], [token])
  const [officesFill, setOfficesFill] = useState([])
  const [showChargeHoursModal, setShowChargeHoursModal] = useState(false)
  const [showAddOfficeModal, setShowAddOfficeModal] = useState(false)
  const [officeSelected, setOfficeSelected] = useState({})
  const [showDeleteOfficeModal, setShowDeleteOfficeModal] = useState(false)
  const [showCreateOfficeBookModal, setShowCreateOfficeBookModal] =
    useState(false)
  const [showCancelOfficeBookModal, setshowCancelOfficeBookModal] =
    useState(false)

  useEffect(() => {
    setPage(1)
  }, [search])

  const handleSearch = (e) => {
    setSearch(e.target.value)
  }

  useEffect(() => {
    const list = []
    offices.forEach((office) => {
      if (!office.books) return
      list.push('*^*')
    })

    setOfficesFill(list)
  }, [offices])

  const tableHeaders = [
    {
      id: 0,
      label: 'Nombre y Apellido',
      value: 'full_name'
    },
    {
      id: 1,
      label: 'Empresa',
      value: 'company_name'
    },
    'Oficina',
    {
      id: 3,
      label: 'Tipo de Usuario',
      value: 'type'
    },
  ]
  const tableHeadersMobile = [
    {
      id: 0,
      label: 'Nombre y Apellido',
      value: 'full_name'
    },
    {
      id: 1,
      label: 'Empresa',
      value: 'company_name'
    },
    'Oficina',
  ]
  const filtersForCard = [
    {
      label: 'Por historico',
      options: [
        {
          label: 'Historico',
          value: 'true',
        },
      ],
    },
    {
      label: 'Por oficina',
      options: offices.map((office) => ({
        label: `Oficina ${office.name}`,
        value: office.id,
      })),
    },
  ]

  const toggleShowChargeHoursModal = () => {
    setShowChargeHoursModal((prev) => !prev)
  }

  const toggleShowAddOfficeModal = () => {
    setShowAddOfficeModal((prev) => !prev)
  }

  const openDeleteOfficeModal = (office) => {
    setOfficeSelected(office)
    setShowDeleteOfficeModal(true)
  }

  const closeShowDeleteOfficeModal = () => {
    setShowDeleteOfficeModal(false)
  }

  const openCreateOfficeBooks = (office) => {
    setOfficeSelected(office)
    setShowCreateOfficeBookModal(true)
  }

  const closeShowCreateOfficeBookModal = () => {
    setShowCreateOfficeBookModal(false)
  }

  const openCancelOfficeBooks = (office) => {
    setOfficeSelected(office)
    setshowCancelOfficeBookModal(true)
  }

  const closeShowCancelOfficeBookModal = () => {
    setshowCancelOfficeBookModal(false)
  }

  return (
    <>
      <Helmet>
        <title>Vida Cowork: Oficinas</title>
      </Helmet>
      <main className="DataAdminPanel--oficinas panel-main panel-main--table">
        <DataAdminHeader currentSection="oficinas">
          <>
            {officesFill.length !== 0 && (
              <button
                className="button-inverted-panel"
                onClick={toggleShowChargeHoursModal}
              >
                <FontAwesomeIcon icon={faPlus} />
                Cargar horas
              </button>
            )}
            <TableSearcher
              search={search}
              handleSearch={handleSearch}
              filtersSelected={filtersSelected}
              setFiltersSelected={setFiltersSelected}
              filtersForCard={filtersForCard}
              totalItems={officesData.total_items}
              type={'offices'}
              exportButton
            />
          </>
        </DataAdminHeader>

        <Table
          type="Offices"
          headers={tableHeaders}
          headersMobile={tableHeadersMobile}
          data={officesData.data}
          page={officesData.page}
          setPage={setPage}
          perPage={per_page}
          totalPages={officesData.total_pages}
          totalItems={officesData.total_items}
          setOrderBy={setOrderBy}
          showInfoMobile
          unselectable
        />

        <OfficeCards
          offices={offices}
          openAddOfficeModal={toggleShowAddOfficeModal}
          openDeleteOfficeModal={openDeleteOfficeModal}
          openCreateOfficeBooks={openCreateOfficeBooks}
          openCancelOfficeBooks={openCancelOfficeBooks}
        />

        {showChargeHoursModal && (
          <ChargeHoursModal
            offices={offices}
            updateOffices={updateOffices}
            updateOfficesData={updateOfficesData}
            closeModal={toggleShowChargeHoursModal}
          />
        )}

        {showAddOfficeModal && (
          <AddOfficeModal
            updateOffices={updateOffices}
            closeModal={toggleShowAddOfficeModal}
          />
        )}

        {showDeleteOfficeModal && (
          <DeleteOfficeModal
            officeSelected={officeSelected}
            updateOfficesData={updateOfficesData}
            updateOffices={updateOffices}
            closeModal={closeShowDeleteOfficeModal}
          />
        )}

        {showCreateOfficeBookModal && (
          <CreateOfficeBookModal
            offices={offices}
            officeSelected={officeSelected}
            updateOfficesData={updateOfficesData}
            updateOffices={updateOffices}
            closeModal={closeShowCreateOfficeBookModal}
          />
        )}

        {showCancelOfficeBookModal && (
          <CancelOfficeBook
            officeSelected={officeSelected}
            updateOfficesData={updateOfficesData}
            updateOffices={updateOffices}
            closeModal={closeShowCancelOfficeBookModal}
          />
        )}
      </main>
    </>
  )
}

export default OfficesDataAdminPanel
