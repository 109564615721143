import propTypes from 'prop-types'
import DisabledDay from '../DisabledDay'
import ModalHeader from '../ModalHeader'


const DisabledSelectedDaysModal = ({ closeModal, daysSelected, removeDay, toggleShowConfirmModal, buttonDisable }) => {
  return (
    <div className="DisabledSelectedDaysModal">
      <ModalHeader
        title='Días seleccionados'
        closeModal={closeModal}
      />

      <div className="PanelModal__Confirm">
        {daysSelected.length > 0
          ? <div className="DisabledSelectedDaysModal__Grid">
            {daysSelected.map((date, index) => (
              <DisabledDay
                key={`Disabled-Day-${index}`}
                date={date}
                removeDay={removeDay}
              />
            ))}
          </div>
          : <h4 className="DisabledSelectedDaysModal__Text fw-normal text-4">No has seleccionado ningun día</h4>
        }
      </div>

      <div className="d-flex justify-content-center pt-3">
        <button
          className={`DisabledSelectedDaysModal__Button ${daysSelected.length > 0 ? 'button-green-panel' : 'button-disabled-panel'}`}
          onClick={toggleShowConfirmModal}
          disabled={daysSelected.length === 0}
        >
          {buttonDisable ? 'Cargando' : 'Confirmar y deshabilitar días'}
        </button>
      </div>
    </div>
  )
}

DisabledSelectedDaysModal.propTypes = {
  closeModal: propTypes.func.isRequired,
  daysSelected: propTypes.array.isRequired,
  removeDay: propTypes.func.isRequired,
  toggleShowConfirmModal: propTypes.func.isRequired,
  buttonDisable: propTypes.bool.isRequired,
}

export default DisabledSelectedDaysModal