import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import PropTypes from 'prop-types'


const ExpirationOverlay = ({ closeModal, creditsHoursExpiration }) => {
  return (
    <div className="ExpirationOverlay" onClick={closeModal}>
      <div
        className="ExpirationOverlay__Container"
        onClick={(e) => e.stopPropagation()}
      >
        <header>
          <button
            className="ExpirationOverlay__Button-Close"
            onClick={closeModal}
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
          <h2 className="ExpirationOverlay__Title text-1">
            Detalle de vencimiento
          </h2>
        </header>

        {creditsHoursExpiration.creditsList[0].credits !== 0 && (
          <section>
            <h2 className="text-2 mt-5 mb-3">Créditos</h2>
            {creditsHoursExpiration.creditsList.map((credits) => {
              return (
                <div key={credits.id} className="ExpirationOverlay__Table">
                  <span className="text-2 fw-bold">{credits.credits}</span>
                  <span className="text-4">{`Crédito${
                    credits.credits !== 1 ? 's' : ''
                  }`}</span>
                  <span className="text-5">VTO:</span>
                  <span className="text-4 fw-bold">
                    {credits.end_date
                      ? moment(credits.end_date, 'YYYY-MM-DD').format(
                        'DD/MM/YY'
                      )
                      : '-'}
                  </span>
                </div>
              )
            })}
          </section>
        )}

        {creditsHoursExpiration.hoursList[0].hours !== 0 && (
          <section>
            <h2 className="text-2 my-5 mb-3">Horas</h2>
            {creditsHoursExpiration.hoursList.map((hours) => {
              return (
                <div key={hours.id} className="ExpirationOverlay__Table">
                  <span className="text-2 fw-bold">{hours.hours}</span>
                  <span className="text-4">{`Hora${
                    hours.hours !== 1 ? 's' : ''
                  }`}</span>
                  <span className="text-5">VTO:</span>
                  <span className="text-4 fw-bold">
                    {hours.end_date
                      ? moment(hours.end_date, 'YYYY-MM-DD').format('DD/MM/YY')
                      : '-'}
                  </span>
                </div>
              )
            })}
          </section>
        )}
      </div>
    </div>
  )
}

ExpirationOverlay.propTypes = {
  closeModal: PropTypes.func.isRequired,
  creditsHoursExpiration: PropTypes.object.isRequired,
}

export default ExpirationOverlay
