import { useContext, useState } from 'react'
import propTypes from 'prop-types'
import ModalHeader from '../ModalHeader'
import Table from '../../containers/Table'
import useApi from '../../hooks/useApi'
import { getExtraHoursAndCreditsDetail } from '../../api/plans'
import { AppContext } from '../../context'


const PAGE_SIZE = 8
const TABLE_HEADERS = [
  'Fecha',
  'Tarea',
  'Plan',
  'Horas',
  'Créditos',
  'Vencimiento',
  'Pago',
  'Total',
]
// const TABLE_HEADERS = [
//   {
//     id: 0,
//     label: 'Fecha',
//     value: 'date'
//   }, {
//     id: 1,
//     label: 'Tarea',
//     value: 'task'
//   },
//   'Tarea',
//   {
//     id: 3,
//     label: 'Horas',
//     value: 'hours'
//   }, {
//     id: 4,
//     label: 'Créditos',
//     value: 'credits'
//   }, {
//     id: 5,
//     label: 'Vencimiento',
//     value: 'end_date'
//   }, {
//     id: 6,
//     label: 'Pago',
//     value: 'is_paid'
//   }, {
//     id: 7,
//     label: 'Total',
//     value: 'total'
//   }
// ]
const TABLE_HEADERS_MOBILE = [
  'Fecha',
  'Tarea',
  'Pago',
  'Total',
]


const HoursAndCreditsModal = ({ data, closeModal }) => {
  const { token } = useContext(AppContext)
  const [page, setPage] = useState(1)
  const [order_by, setOrderBy] = useState('')
  const params = {
    page,
    per_page: PAGE_SIZE,
    order_by,
  }


  const [extrasData, updateExtrasData] = useApi(
    () => getExtraHoursAndCreditsDetail(token, data?.id, params),
    {
      page: 1,
      total_pages: 1,
      total_items: 0,
      data: [],
    },
    [token, page, order_by]
  )


  return (
    <div className="PanelModal px-3" onClick={closeModal}>
      <form className="PanelModal__Card--ExtraBig" onClick={(e) => e.stopPropagation()}>
        <ModalHeader title={'Información extra'} closeModal={closeModal}/>

        <div className='d-flex flex-column gap-3 pt-4'>
          <div className="d-flex justify-content-center flex-wrap gap-3">
            <div className="d-flex flex-column">
              <span className='text-5 text-start'>Nombre</span>
              <span className='text-4 fw-medium text-black'>
                { data?.full_name }
              </span>
            </div>
            <div className="d-flex flex-column">
              <span className='text-5 text-start'>Tipo</span>
              <span className='text-4 fw-medium text-black'>
                { data?.company?.name ? 'Empresa' : 'Freelancer' }
              </span>
            </div>
            <div className="d-flex flex-column">
              <span className='text-5 text-start'>Empresa</span>
              <span className='text-4 fw-medium text-black'>
                { data?.company?.name ?? '-' }
              </span>
            </div>
          </div>

          <div className="w-100 d-flex flex-column align-items-start position-relative">
            <Table
              type="Hours-And-Credits-Detail"
              headers={TABLE_HEADERS}
              headersMobile={TABLE_HEADERS_MOBILE}
              data={extrasData.data}
              page={extrasData.page}
              setPage={setPage}
              perPage={PAGE_SIZE}
              totalPages={extrasData.total_pages}
              totalItems={extrasData.total_items}
              setOrderBy={setOrderBy}
              showInfoMobile
              unselectable
            />
          </div>
        </div>
      </form>
    </div>
  )
}

HoursAndCreditsModal.propTypes = {
  data: propTypes.object.isRequired,
  closeModal: propTypes.func.isRequired,
}

export default HoursAndCreditsModal