import { useState } from 'react'
import propTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus, faSortDown, faXmark } from '@fortawesome/free-solid-svg-icons'


const PanelModalSelector = ({ options, optionSelected, onChange, isQuantity, quantity, handlePlanQuantity, planPrice, handleRemove, planIndex }) => {
  const [ showOptions, setShowOptions ] = useState(false)

  const toggleShowOptions = () => {
    setShowOptions(prev => !prev)
  }

  const selectOption = (e, option) => {
    e.stopPropagation()
    if(onChange) {
      const index = options.indexOf(option)
      onChange(index, planIndex)
    }

    setShowOptions(false)
  }

  const handleQuantity = (e) => {
    e.stopPropagation()
  }

  if (isQuantity) return (
    <button
      className={`PanelModalSelector__Select-Quantity${showOptions ? '--Active' : ''} text-3 text-uppercase`}
      onClick={toggleShowOptions}
      onBlur={() => setShowOptions(false)}
    >
      <span className='d-flex align-items-center gap-1 gap-md-3'>
        { optionSelected || options[0] }
        <FontAwesomeIcon icon={faSortDown} className="PanelModalSelector__Icon"/>
      </span>

      <div className="PanelModalSelector__Amount-Buttons" onClick={handleQuantity}>
        <div className='PanelModal__Amount-Button' onClick={() => handlePlanQuantity(quantity - 1, planIndex)}>
          <FontAwesomeIcon icon={faMinus}/>
        </div>

        <input
          className='PanelModal__Amount-Input text-5'
          type="text"
          value={quantity}
          min={0}
          max={50}
          onChange={(e) => handlePlanQuantity(e.target.value, planIndex)}
        />

        <div className='PanelModal__Amount-Button' onClick={() => handlePlanQuantity(quantity + 1, planIndex)}>
          <FontAwesomeIcon icon={faPlus}/>
        </div>
      </div>
      <span>${planPrice * quantity}</span>
      {handleRemove &&
        <div className='PanelModal__Button-Arrow' onClick={handleRemove}>
          <FontAwesomeIcon icon={faXmark}/>
        </div>
      }
      { showOptions && (
        <div className='PanelModalSelector__Items-Container'>
          { options.map((item, index) => (
            <div
              key={`Calendar-Selector-${item}-${index}`}
              className={`PanelModalSelector__Item${item === optionSelected ? '--Active' : ''} text-5`}
              onClick={(e) => selectOption(e, item)}
              disabled={item === optionSelected}
            >
              {item}
            </div>
          ))}
        </div>
      )}
    </button>
  )

  return (
    <button
      className={`PanelModalSelector${handleRemove ? '__Select-Quantity' : ''}${showOptions ? '--Active' : ''} text-3 text-uppercase`}
      onClick={toggleShowOptions}
      onBlur={() => setShowOptions(false)}
    >
      <span className='d-flex align-items-center gap-1 gap-md-3'>
        { optionSelected || options[0] }
        <FontAwesomeIcon icon={faSortDown} className="PanelModalSelector__Icon"/>
      </span>

      {handleRemove &&
        <div className='PanelModal__Button-Arrow' onClick={handleRemove}>
          <FontAwesomeIcon icon={faXmark}/>
        </div>
      }
      { showOptions && (
        <div className='PanelModalSelector__Items-Container'>
          { options.map((item, index) => (
            <div
              key={`Calendar-Selector-${item}-${index}`}
              className={`PanelModalSelector__Item${item === optionSelected ? '--Active' : ''} text-5`}
              onClick={(e) => selectOption(e, item)}
              disabled={item === optionSelected}
            >
              {item}
            </div>
          ))}
        </div>
      )}
    </button>
  )
}

PanelModalSelector.propTypes = {
  options: propTypes.array.isRequired,
  optionSelected: propTypes.string,
  onChange: propTypes.func,
  isQuantity: propTypes.bool,
  quantity: propTypes.number,
  handlePlanQuantity: propTypes.func,
  planPrice: propTypes.number,
  handleRemove: propTypes.func,
  planIndex: propTypes.number,
}

export default PanelModalSelector