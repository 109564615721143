import propTypes from 'prop-types'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import classNames from 'classnames'
import { AppContext } from '../context'


const InfoCard = ({
  title,
  description,
  value,
  maxValue,
  showHelp,
  company,
  assignCredits,
  isExpiration,
  showExpiration,
  buttonText,
}) => {
  const { isAdmin } = useContext(AppContext)
  const [bar, setBar] = useState([])


  useEffect(() => {
    if (maxValue) {
      const list = []

      for (let i = 0; i < maxValue; i++) {
        list.push(i < value)
      }

      setBar(list)
    }
  }, [value])


  const handleAssignCreditsHours = () => {
    if (value !== 0) assignCredits()
  }


  if (company)
    return (
      <div className="InfoCard">
        <div className="d-flex flex-column align-items-center w-100">
          <h3 className="InfoCard__Title text-3" style={{ height: 36 }}>
            {title}
            {title === 'Créditos Vida' && (
              <button
                className="InfoCard__Title-Button rotate-90"
                onClick={showHelp}
              >
                <FontAwesomeIcon icon={faCircleExclamation} />
              </button>
            )}
          </h3>
          <div className="InfoCard__Line" />
        </div>

        <div className="d-flex flex-column gap-3">
          {description && (
            <span className="my-4 text-2 fw-bolder">{description}</span>
          )}

          <span className="title fw-bold">{value}</span>
        </div>

        {!isAdmin ? (
          <div className="InfoCard__Buttons">
            <button
              className={`button-white-green-panel-rounded-border ${
                value === 0 ? 'button-border-disabled' : ''
              } text-5 `}
              onClick={handleAssignCreditsHours}
            >
              {buttonText}
            </button>
            <Link
              to="/panel/reservas"
              className="button-green-panel-rounded text-5"
            >
            + Crear reserva
            </Link>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    )

  return (
    <div className="InfoCard">
      <div className="d-flex flex-column align-items-center w-100">
        {isExpiration ? (
          <div className="InfoCard__Company-Header">
            <h3
              className={`InfoCard__Title${
                title === 'Créditos Vida' ? '--Credits' : ''
              } text-3`}
            >
              {title === 'Créditos Vida' && (
                <button
                  className="InfoCard__Title-Button--left rotate-90"
                  onClick={showHelp}
                >
                  <FontAwesomeIcon icon={faCircleExclamation} />
                </button>
              )}
              {title}
            </h3>
            <p
              className="InfoCard__Expiration text-5 fst-italic"
              onClick={showExpiration}
            >
              Ver vencimientos
            </p>
          </div>
        ) : (
          <h3 className="InfoCard__Title text-3">
            {title}
            {title === 'Créditos Vida' && (
              <button
                className="InfoCard__Title-Button ms-1 rotate-90"
                onClick={showHelp}
              >
                <FontAwesomeIcon icon={faCircleExclamation} />
              </button>
            )}
          </h3>
        )}
        <div className="InfoCard__Line" />
      </div>
      {description && (
        <span className="my-4 text-3 fw-bolder">{description}</span>
      )}
      {maxValue ? (
        <>
          <p className={`text-2 fw-bold ${!description ? 'mt-5' : ''}`}>
            <span className="title">{value}</span>
            <span className="ms-1 text-1 fw-normal">/{maxValue}</span>
          </p>
          <div className="InfoCard__Progress-Container">
            <div className="InfoCard__Progress">
              {bar.map((e, i) => (
                <div
                  key={i}
                  className={classNames('InfoCard__Progress_Item', {
                    Active: e,
                  })}
                />
              ))}
            </div>
          </div>
        </>
      ) : (
        <span className="title fw-bold">{value ?? 0}</span>
      )}
    </div>
  )
}

InfoCard.propTypes = {
  title: propTypes.string.isRequired,
  name: propTypes.string,
  description: propTypes.string,
  value: propTypes.number,
  maxValue: propTypes.number,
  showHelp: propTypes.func,
  company: propTypes.bool,
  assignCredits: propTypes.func,
  isExpiration: propTypes.bool,
  showExpiration: propTypes.func,
  buttonText: propTypes.string,
}

export default InfoCard
