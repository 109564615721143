import propTypes from 'prop-types'
import { faEllipsisVertical, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { useEffect, useState } from 'react'


const OfficeCard = ({
  name,
  hours_by_month,
  books,
  office,
  openDeleteOfficeModal,
  openCreateOfficeBooks,
  openCancelOfficeBooks,
}) => {
  const [showActions, setShowActions] = useState(false)
  const [booksToDelete, setBooksToDelete] = useState([])

  useEffect(() => {
    if (!books) return setBooksToDelete([])

    const today = new Date().getTime()
    const date = new Date(books[0].start_date).getTime()

    if (date > today) {
      setBooksToDelete(books)
    } else if (books.length === 2) {
      setBooksToDelete([books[1]])
    }
  }, [books])

  const toggleShowActions = () => {
    setShowActions((prev) => !prev)
  }

  const closeShowActions = () => {
    setShowActions(false)
  }

  const handleAction = (enabled, callback) => {
    if (!enabled) return
    callback()
  }

  return (
    <div className="OfficeCard">
      <header className="OfficeCard__Header">
        <div className="OfficeCard__Title">
          <h4 className="text-3 fw-bold">Oficina {name}</h4>
          {!books ? (
            <span className="text-4">Sin reservas</span>
          ) : (
            <div className="d-flex justify-content-between align-items-center position-relative w-100 ">
              <span className="text-4">{books[0].company_name}</span>
              {books.length !== booksToDelete.length ? (
                <span className='OfficeCard__Book-Active me-3 text-5'>En curso</span>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
        <button
          className="OfficeCard__Button"
          onClick={toggleShowActions}
          onBlur={closeShowActions}
        >
          <FontAwesomeIcon icon={faEllipsisVertical} />
          {showActions && (
            <div className="OfficeCard__Menu">
              <div
                className={`OfficeCard__Menu-Action${
                  !books || books?.length < 2 ? '' : '--Disabled'
                } text-4`}
                onClick={() =>
                  handleAction(!books || books?.length < 2, () =>
                    openCreateOfficeBooks(office)
                  )
                }
              >
                Crear reserva
              </div>
              <div
                className={`OfficeCard__Menu-Action${
                  booksToDelete.length !== 0 ? '' : '--Disabled'
                } text-4`}
                onClick={() =>
                  handleAction(booksToDelete.length !== 0, () =>
                    openCancelOfficeBooks(office)
                  )
                }
              >
                Cancelar reservas
              </div>
              <div
                className={`OfficeCard__Menu-Action${
                  !books ? '' : '--Disabled'
                } text-4`}
                onClick={() =>
                  handleAction(!books, () => openDeleteOfficeModal(office))
                }
              >
                Eliminar Oficina
              </div>
            </div>
          )}
        </button>
      </header>

      {!books ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <button
            className="button-inverted-panel"
            onClick={() => openCreateOfficeBooks(office)}
          >
            <FontAwesomeIcon icon={faPlus} />
            Asignar oficina
          </button>
        </div>
      ) : (
        <>
          <div className="OfficeCard__Data fw-bold">
            <div className="OfficeCard__Sub-Data fw-bold">
              <span className="text-5">Horas</span>
              <span className="text-4">
                {books[0].plan_hours}/{hours_by_month}
              </span>
            </div>
            <div className="OfficeCard__Sub-Data fw-bold">
              <span className="text-5">Teléfono</span>
              <span className="text-4">{books[0].company_phone}</span>
            </div>
            <div className="OfficeCard__Sub-Data fw-bold">
              <span className="text-5">Inicio</span>
              <span className="text-4">
                {moment(books[0].start_date, 'YYYY-MM-DD').format('DD/MM/YY')}
              </span>
            </div>
            <div className="OfficeCard__Sub-Data fw-bold">
              <span className="text-5">Fin</span>
              <span className="text-4">
                {moment(books[0].end_date, 'YYYY-MM-DD').format('DD/MM/YY')}
              </span>
            </div>
          </div>

          <div className="OfficeCard__Next-Booking">
            <h5 className="text-5">Próxima reserva</h5>
            {books[1] ? (
              <h4 className="text-4">
                {moment(books[1].start_date, 'YYYY-MM-DD').format('DD/MM/YY')} -{' '}
                {books[1].company_name}
              </h4>
            ) : (
              <h4 className="text-4"> - </h4>
            )}
          </div>
        </>
      )}
    </div>
  )
}

OfficeCard.propTypes = {
  name: propTypes.string.isRequired,
  hours_by_month: propTypes.number,
  books: propTypes.array,
  office: propTypes.object.isRequired,
  openDeleteOfficeModal: propTypes.func.isRequired,
  openCreateOfficeBooks: propTypes.func.isRequired,
  openCancelOfficeBooks: propTypes.func.isRequired,
}

export default OfficeCard
