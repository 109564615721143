import { deleteData, getData, postData } from './apiVariables'

// General
export const getMeetingRooms = async () => {
  return await getData({
    path: '/meeting-rooms/',
    cache: 604800,
    callback: (data) => data.data
  })
}

export const postCreateMeetingRoom = async (token, body) => {
  return await postData({
    path: '/meeting-rooms/',
    token,
    body,
  })
}

export const delDeleteMeetingRoom = async (token, roomId) => {
  return await deleteData({
    path: `/meeting-rooms/details/${roomId}/`,
    token,
  })
}

// Books
export const createMeetingRoomBook = async (token, body) => {
  return await postData({
    path: `/meeting-rooms/meeting-room-book/${body.room_id}/`,
    token,
    body
  })
}

export const cancelMeetingRoomBook = async (token, body) => {
  return await postData({
    path: '/meeting-rooms/canceled-meeting-room-book/',
    token,
    body
  })
}

export const getMeetingRoomsBooksCalendar = async (token, params) => {
  return await getData({
    path: '/meeting-rooms/get-meeting-books-calendar/',
    token,
    params,
  })
}

export const getMeetingRoomsBooks = async (token, params) => {
  params = Object.entries(params).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {})
  return await getData({
    path: '/meeting-rooms/get-meeting-books/',
    token,
    params,
  })
}

export const getMeetingRoomsFreeRanges = async (token, params) => {
  return await getData({
    path: '/meeting-rooms/get-free-books-ranges/',
    token,
    params,
    callback: (data) => data.data
  })
}