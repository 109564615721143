import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import { useContext, useState } from 'react'
import { postCancelPlan } from '../../api/plans'
import { AppContext } from '../../context'
import ModalHeader from '../ModalHeader'
import FinishModal from './FinishModal'

const CancelPlanModal = ({
  closeModal,
  cancelPlanInfo: {
    user_plan_id ,
    planName,
  },
}) => {
  const { updateUser, token } = useContext(AppContext)
  const [step, setStep] = useState(1)
  const [buttonDisable, setButtonDisable] = useState(false)

  const handleCancel = async () => {
    if(buttonDisable) return
    setButtonDisable(true)

    const response = await postCancelPlan(token, { user_plan_id })

    if (response.error) {
      return
    }
    else if (response) {
      updateUser()
      setStep(prev => prev + 1)
    }
    setButtonDisable(false)
  }

  if (step === 1)
    return (
      <div className="PanelModal px-3" onClick={closeModal}>
        <div
          className="PanelModal__Card--Medium"
          onClick={(e) => e.stopPropagation()}
        >
          <ModalHeader title={'Cancelar plan'} closeModal={closeModal} />

          <div className="d-flex flex-column align-items-center gap-2 my-4 text-2 text-center">
            <FontAwesomeIcon icon={faExclamationCircle} />
						¿Estás seguro que deseas cancelar el {planName}?
          </div>

          <div className="d-flex justify-content-center mb-2">
            <button className="button-green-panel" onClick={handleCancel}>
              {buttonDisable ? 'Cargando...' : 'Sí, cancelar'}
            </button>
          </div>

          <div className="d-flex justify-content-center">
            <button
              className="button-white-gray-dark px-4 text-decoration-underline"
              onClick={closeModal}
            >
							Cambié de opinión, no quiero cancelar
            </button>
          </div>
        </div>
      </div>
    )

  if (step === 2)
    return (
      <FinishModal
        type="--Medium"
        title="Cancelar plan"
        message="Su plan ha sido cancelado"
        buttonText="¡Entendido!"
        closeModal={closeModal}
      />
    )
}

CancelPlanModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  cancelPlanInfo: PropTypes.object.isRequired,
}

export default CancelPlanModal