import { useContext, useState } from 'react'
import chroma from 'chroma-js'
import propTypes from 'prop-types'
import { faBriefcase, faChampagneGlasses, faUser, faUserSlash, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AppContext } from '../context'


const RoomReservationOverlayItem = ({ item, closeOverlay, openCancelModal, isCurrentDay, isPastDay, serverTime }) => {
  const { isAdmin, rooms } = useContext(AppContext)
  const [showData, setShowData] = useState(false)

  const time = new Date(serverTime).getTime()

  const reservationStartTime = new Date(item.start_date).getTime()
  const reservationEndTime = new Date(item.end_date).getTime()
  const reservationIsStarted = reservationStartTime < time
  const reservationIsEnded = reservationEndTime < time

  const handleOpenCancelReservationModal = async (reservation) => {
    closeOverlay()
    openCancelModal(reservation, isCurrentDay)
  }

  return (
    <>
      <div
        className={'RoomsReservationOverlay__Item'}
        style={{
          backgroundColor: rooms.find(room => room.name == item.room).color,
          borderLeftColor: chroma(rooms.find(room => room.name == item.room).color).darken(1).hex(),
        }}
        onClick={() => setShowData(true)}
      >
        <h4 className='RoomsReservationOverlay__Item-Title fw-bold text-4'>{item.room}</h4>

        {isAdmin
          ? <div className="d-flex flex-column justify-content-between align-items-start gap-1">
            <h5 className='text-4' title={item.user || item.reference || `${item.first_name} ${item.last_name}`}>
              {item.user
                ? <FontAwesomeIcon icon={faUser} size='1x' className='me-2'/>
                : item.reference
                  ? <FontAwesomeIcon icon={faChampagneGlasses} size='1x' className='me-2'/>
                  : <FontAwesomeIcon icon={faUserSlash} size='1x' className='me-2'/>
              }
              <span>{item.user || item.reference || `${item.first_name} ${item.last_name}`}</span>
            </h5>
            <h5 className='text-5 fw-normal'>{item.start_hour} a {item.end_hour} hs</h5>
          </div>
          : <div className="d-flex flex-column justify-content-between align-items-start gap-1">
            <h5 className='text-4'>
              {item.company_owner
                ? <FontAwesomeIcon icon={faBriefcase} size='1x' className='me-2'/>
                : <FontAwesomeIcon icon={faUser} size='1x' className='me-2'/>
              }
              {item.company ? 'Empresa' : 'Personal'}
            </h5>
            <h5 className='text-5 fw-normal'>{item.start_hour} a {item.end_hour} hs</h5>
          </div>
        }

        {!isPastDay && (
          reservationIsStarted && !isAdmin
            ? <button
              className="RoomsReservationOverlay__Cancel-Button--Disabled"
              title='No se puede cancelar una reserva que ya ha comenzado o terminado'
              disabled
            >
              <FontAwesomeIcon icon={faXmark}/>
            </button>
            : reservationIsEnded
              ? <button
                className="RoomsReservationOverlay__Cancel-Button--Disabled"
                title='No se puede cancelar una reserva que ya ha terminado'
                disabled
              >
                <FontAwesomeIcon icon={faXmark}/>
              </button>
              : <button className="RoomsReservationOverlay__Cancel-Button" onMouseDown={() => handleOpenCancelReservationModal(item)}>
                <FontAwesomeIcon icon={faXmark}/>
              </button>
        )}
      </div>

      {showData && (
        <div className="RoomsReservationOverlay__Modal" onClick={() => setShowData(false)}>
          <div className="RoomsReservationOverlay__Modal-Content" onClick={(e) => e.stopPropagation()}>
            <header className="border-bottom">
              <div></div>
              <h4 className='text-3 text-center'>Detalle de reserva</h4>
              <button className="RoomsReservationOverlay__Button-Close" onClick={() => setShowData(false)}>
                <FontAwesomeIcon icon={faXmark}/>
              </button>
            </header>
            <div className="d-flex flex-column gap-2">
              <div className="d-flex align-items-start gap-1">
                <span className='text-4 fw-semibold me-2'>Sala:</span>
                <span className='text-4'>{item.room}</span>
              </div>

              <div className="d-flex align-items-start gap-1">
                <span className='text-4 fw-semibold me-2'>Reserva:</span>
                <span className='text-4'>
                  {item.user
                    ? <FontAwesomeIcon icon={faUser} size='1x' className='me-1'/>
                    : item.reference
                      ? <FontAwesomeIcon icon={faChampagneGlasses} size='1x' className='me-1'/>
                      : <FontAwesomeIcon icon={faUserSlash} size='1x' className='me-1'/>
                  }
                  {item.user || item.reference || `${item.first_name} ${item.last_name}`}
                </span>
              </div>

              <div className="d-flex align-items-start gap-1">
                <span className='text-4 fw-semibold me-2'>Horario:</span>
                <span className='text-4'>{item.start_hour} a {item.end_hour} hs</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

RoomReservationOverlayItem.propTypes = {
  item: propTypes.object,
  closeOverlay: propTypes.func,
  openCancelModal: propTypes.func,
  isCurrentDay: propTypes.bool,
  isPastDay: propTypes.bool,
  serverTime: propTypes.string.isRequired,
}

export default RoomReservationOverlayItem