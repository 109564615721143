const CalendarExplanation = () => {
  const currentDay = new Date().getDate()

  return (
    <section className='CalendarExplanation py-4'>
      <div className="CalendarExplanation__Item--Active">
        <div className="CalendarExplanation__Circle">
          { currentDay }
        </div>
        <span className="CalendarExplanation__Label text-3">Día seleccionado</span>
      </div>

      <div className="CalendarExplanation__Item--Current">
        <div className="CalendarExplanation__Circle">
          { currentDay }
        </div>
        <span className="CalendarExplanation__Label text-3">Día en curso</span>
      </div>

      <div className="CalendarExplanation__Item--Disabled">
        <div className="CalendarExplanation__Circle">
          { currentDay }
        </div>
        <span className="CalendarExplanation__Label text-3">Día sin lugar/ deshabilitado</span>
      </div>

      <div className="CalendarExplanation__Item--Reservation">
        <div className="CalendarExplanation__Circle">
          { currentDay }
          <div className="CalendarExplanation__Detail"></div>
        </div>
        <span className="CalendarExplanation__Label text-3">Día con reserva</span>
      </div>

      <div className="CalendarExplanation__Item--Enable">
        <div className="CalendarExplanation__Circle">
          { currentDay }
        </div>
        <span className="CalendarExplanation__Label text-3">Día disponible para reservar</span>
      </div>
    </section>
  )
}

export default CalendarExplanation