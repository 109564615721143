import { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import FinishModal from './FinishModal'
import ModalHeader from '../ModalHeader'
import Switch from '../Switch'
import Input from '../Input'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { AppContext } from '../../context'
import {
  postAssingHoursAndCredits,
  postRemoveHoursAndCredits,
} from '../../api/users'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { postAssingHoursAndCreditsCompany, postRemoveHoursAndCreditsCompany } from '../../api/companies'


const MODES = ['Horas', 'Créditos']
const OPERATION_TYPE_LIST = ['Cargar', 'Quitar']
const REASONS_LIST = ['Devolución', 'Compra']



const AssignCreditsAdminModal = ({ closeModal, selectedItems, updateData }) => {
  const { token } = useContext(AppContext)
  const [step, setStep] = useState(1)
  const [mode, setMode] = useState('Horas')
  const [operationType, setOperationType] = useState('Cargar')
  const [generalError, setGeneralError] = useState('')


  const changeMode = (newMode) => {
    setMode(newMode)
  }


  const formik = useFormik({
    initialValues: {
      reason: 'Devolución',
      amount: 0,
      pricePerItem: 0,
      priceTotal: 0,
      end_date: undefined,
      paid: false
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      amount: Yup.number('Solo se permite números.')
        .required(`Cantidad de ${mode} es requerido.`)
        .positive('La cantidad no puede ser negativa.')
        .test('test .5', 'Las horas deben terminar en .0 o .5', (num) =>
          mode === 'Horas' ? num % 0.5 === 0 : Number.isInteger(num)
        )
        .min(0.5, 'La cantidad debe ser mayor a 0'),
      pricePerItem: Yup.number('Solo se permite números')
        .required(`Precio por ${mode} es requerido.`)
        .min(0, 'La cantidad no puede ser negativa'),
      priceTotal: Yup.number('Solo se permite números')
        .required(`Precio por ${mode} es requerido.`)
        .min(0, 'La cantidad no puede ser negativa'),
      end_date: Yup.date('Fecha inválida').min(new Date(), 'La fecha debe ser mayor a hoy').required('Fecha requerida')
    }),
    onSubmit: async (data) => {
      const isCompany = selectedItems[0]?.company?.id
      let body

      if(isCompany) {
        body = {
          user_id: `${selectedItems[0].id ?? selectedItems[0].user.id}`,
          company_id: `${selectedItems[0].company.id}`,
          hours: mode === 'Horas' ? data.amount : 0,
          credits: mode === 'Créditos' ? data.amount : 0,
          total_amount: data.priceTotal,
          description: data.reason,
          end_date: data.end_date,
          paid: data.paid ? 'True' : 'False',
        }
      } else {
        body = {
          users_list: `${selectedItems[0].id ?? selectedItems[0].user.id}`,
          hours: mode === 'Horas' ? data.amount : 0,
          credits: mode === 'Créditos' ? data.amount : 0,
          total_amount: data.priceTotal,
          description: data.reason,
          end_date: data.end_date,
          paid: data.paid ? 'True' : 'False',
        }
      }

      let response

      if(isCompany) {
        response = await postAssingHoursAndCreditsCompany(token, body)
      } else {
        response = await postAssingHoursAndCredits(token, body)
      }

      if (response.error)
        setGeneralError('No se pudo realizar la operación.')
      else if (response) {
        updateData()
        setStep((prev) => prev + 1)
      }
    },
  })

  const formik2 = useFormik({
    initialValues: {
      amount: 0,
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      amount: Yup.number('Solo se permite números.')
        .required(`Cantidad de ${mode} es requerido.`)
        .positive('La cantidad no puede ser negativa.')
        .test('test .5', 'Las horas deben terminar en .0 o .5', (num) =>
          mode === 'Horas' ? num % 0.5 === 0 : Number.isInteger(num)
        )
        .min(0.5, 'La cantidad debe ser mayor a 0'),
    }),
    onSubmit: async (data) => {
      const isCompany = selectedItems[0]?.company?.id
      let body

      if(isCompany) {
        body = {
          user_id: `${selectedItems[0].id}`,
          company_id: `${selectedItems[0].company.id}`,
          hours: mode === 'Horas' ? Number(data.amount) : 0,
          credits: mode === 'Créditos' ? Number(data.amount) : 0,
        }
      } else {
        body = {
          user_id: `${selectedItems[0].id}`,
          hours: mode === 'Horas' ? Number(data.amount) : 0,
          credits: mode === 'Créditos' ? Number(data.amount) : 0,
        }
      }

      let response

      if(isCompany) {
        response = await postRemoveHoursAndCreditsCompany(token, body)
      } else {
        response = await postRemoveHoursAndCredits(token, body)
      }

      if (response.error) {
        if (response.error === 'User has not enough hours') {
          return setGeneralError('El usuario no tiene suficientes horas.')
        } else if (response.error === 'User has not enough credits')
          return setGeneralError('El usuario no tiene suficientes créditos.')
        setGeneralError('No se pudo realizar la operación.')
      } else if (response) {
        updateData()
        setStep((prev) => prev + 1)
      }
    },
  })


  useEffect(() => {
    setGeneralError('')
  }, [mode])


  const handleAmount = (e) => {
    if (isNaN(Number(e.target.value))) return

    formik.setFieldValue(
      'priceTotal',
      Math.round(e.target.value * formik.values.pricePerItem * 100) / 100
    )
    return formik.setFieldValue(
      'amount',
      Math.round(Number(e.target.value) * 100) / 100
    )
  }

  const handlePricePerItem = (e) => {
    if (isNaN(Number(e.target.value))) return

    formik.setFieldValue(
      'pricePerItem',
      Math.round(e.target.value * 100) / 100
    )
    formik.setFieldValue(
      'priceTotal',
      Math.round(e.target.value * formik.values.amount * 100) / 100
    )
  }

  const handlePriceTotal = (e) => {
    if (isNaN(Number(e.target.value))) return

    formik.setFieldValue('priceTotal', Math.round(e.target.value * 100) / 100)
    if (formik.values.amount !== 0)
      formik.setFieldValue(
        'pricePerItem',
        Math.round((e.target.value / formik.values.amount) * 100) / 100
      )
  }


  if (step === 1)
    return (
      <div className="PanelModal px-3">
        <div
          className="PanelModal__Card--Medium"
          onClick={(e) => e.stopPropagation()}
        >
          <ModalHeader
            title='Horas y créditos'
            closeModal={closeModal}
          />

          <div className="w-100 d-flex justify-content-center py-4 px-3">
            <Switch list={MODES} current={mode} changeCurrent={changeMode} />
          </div>

          <div className="mb-3">
            <Input
              label="¿Qué tarea desea realizar?"
              name="reason"
              type="select"
              value={operationType}
              list={OPERATION_TYPE_LIST}
              onChange={(_, value) => setOperationType(value)}
            />
          </div>

          {operationType === 'Cargar' ? (
            <>
              <div className="mt-3 mb-3">
                <Input
                  label="Motivo"
                  name="reason"
                  type="select"
                  value={formik.values.reason}
                  list={REASONS_LIST}
                  onChange={formik.setFieldValue}
                  error={formik.errors.reason}
                />
              </div>

              <div className="PanelModal__Inputs-Container mt-3 mb-3">
                <Input
                  label={`Cantidad de ${mode}`}
                  name="amount"
                  type="text"
                  value={formik.values.amount}
                  onChange={handleAmount}
                  error={formik.errors.amount}
                />
                <Input
                  label={`Precio por ${mode}`}
                  name="pricePerItem"
                  type="text"
                  value={formik.values.pricePerItem}
                  onChange={handlePricePerItem}
                  error={formik.errors.pricePerItem}
                />
              </div>

              <div className="mt-3 mb-3">
                <Input
                  label='Total a pagar'
                  name="priceTotal"
                  type="text"
                  value={formik.values.priceTotal}
                  onChange={handlePriceTotal}
                  error={formik.errors.priceTotal}
                />
              </div>

              <Input
                label='Fecha de vencimiento'
                name="end_date"
                type="date"
                value={formik.values.end_date}
                onChange={formik.handleChange}
                // onClick={showPicker}
                error={formik.errors.end_date}
              />

              {generalError && (
                <p className="text-center fw-medium text-error mt-3">
                  {generalError}
                </p>
              )}

              <button
                className="w-100 d-flex justify-content-center align-items-center gap-3 pt-3"
                onClick={() => formik.setFieldValue('paid', !formik.values.paid)}
              >
                <div
                  className={`PanelModal__Checkbox ${
                    formik.values.paid && 'PanelModal__Checkbox--Selected'
                  }`}
                ></div>
                <span className='text-4'>El usuario ya abonó</span>
              </button>

              <div className="d-flex justify-content-center pt-5">
                <button
                  className="button-green-panel"
                  type="submit"
                  onClick={() => formik.handleSubmit()}
                  disabled={formik.isSubmitting}
                >
                  Cargar {mode}
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="mt-3 mb-3">
                <Input
                  label={`Cantidad de ${mode}`}
                  name="amount"
                  type="text"
                  value={formik2.values.amount}
                  onChange={formik2.handleChange}
                  error={formik2.errors.amount}
                />
              </div>

              <div className="d-flex gap-2">
                <FontAwesomeIcon icon={faCircleExclamation}/>
                <span className='text-5'>
                  Se quitarán los créditos más próximos a su vencimiento
                </span>
              </div>

              {generalError && (
                <p className="text-center fw-medium text-error mt-3">
                  {generalError}
                </p>
              )}

              <div className="d-flex justify-content-center pt-3">
                <button
                  className="button-red"
                  type="submit"
                  onClick={formik2.handleSubmit}
                  disabled={formik2.isSubmitting}
                >
                  Quitar {mode}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    )

  if (step === 2)
    return (
      <FinishModal
        title="Horas y créditos"
        message={`¡${
          operationType === 'Cargar' ? formik.values.reason : 'Subtraction'
        } exitosa!`}
        closeModal={closeModal}
      />
    )
}

AssignCreditsAdminModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  selectedItems: PropTypes.array.isRequired,
  updateData: PropTypes.func.isRequired,
}

export default AssignCreditsAdminModal
