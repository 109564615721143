import PropTypes from 'prop-types'
import { useContext, useState } from 'react'
import { AppContext } from '../../context'
import ModalHeader from '../ModalHeader'
import AssignCreditsAdminModal from './AssignCreditsAdminModal'
import AssignPlanAdminModal from './assignPlanAdminModal/AssignPlanAdminModal'
import MarkAsPaidModal from './MarkAsPaidModal'


const TableActionsModal = ({
  closeModal,
  selectedItems,
  selectedActionType,
  type,
  updateData,
}) => {
  const { token } = useContext(AppContext)
  const [buttonDisable, setButtonDisable] = useState(false)

  const handleSubmit = async () => {
    if(buttonDisable) return
    setButtonDisable(true)
    selectedActionType.doQuery(selectedItems, token, updateData, closeModal, setButtonDisable)
  }

  switch (selectedActionType.type) {
  case 'assign plan':
    return (
      <AssignPlanAdminModal
        selectedItems={selectedItems}
        updateData={updateData}
        closeModal={closeModal}
      />
    )

  case 'assign plan company':
    return (
      <AssignPlanAdminModal
        type='company'
        selectedItems={selectedItems}
        updateData={updateData}
        closeModal={closeModal}
      />
    )

  case 'assign hours and credits':
    return (
      <AssignCreditsAdminModal
        selectedItems={selectedItems}
        updateData={updateData}
        closeModal={closeModal}
      />
    )

  case 'mark as paid modal':
    return (
      <MarkAsPaidModal
        data={selectedItems?.[0]}
        // // selectedItems={selectedItems}
        updateData={updateData}
        closeModal={() => {
          closeModal()
        }}
      />
    )
    // case 'assign hours and credits':
    //   return (
    //     <AssignCreditsAdminModal
    //       selectedItems={selectedItems}
    //       updateData={updateData}
    //       closeModal={closeModal}
    //     />
    //   )

  default:
    return (
      <div className="PanelModal px-3" onClick={closeModal}>
        <div
          className="PanelModal__Card"
          onClick={(e) => e.stopPropagation()}
        >
          <ModalHeader
            title={selectedActionType.label}
            closeModal={closeModal}
          />
          <div className="PanelModal__Confirm text-3">
            <span className="text-2 fw-bold me-2">
              {selectedItems.length}
            </span>
            {`Seleccionado${selectedItems.length === 1 ? 's' : ''}`}
          </div>

          <div className="PanelModal__Confirm PanelModal__Show-Name">
            {selectedItems.map((item, index) => (
              <span className="text-3" key={`Selected-Item-${index}`}>
                {type === 'Companies'
                  ? item.name
                  : `${item.first_name ?? item.name_reference} ${
                    item.last_name
                  }`}
              </span>
            ))}
          </div>

          <div className="d-flex justify-content-center pt-3">
            <button className="button-green-panel" onClick={handleSubmit}>
              Confirmar
            </button>
          </div>
        </div>
      </div>
    )
  }
}

TableActionsModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  selectedItems: PropTypes.array.isRequired,
  updateData: PropTypes.func.isRequired,
  selectedActionType: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
}

export default TableActionsModal
