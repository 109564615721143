import propTypes from 'prop-types'
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'


const PlaceCard = ({ type, data, openDeleteModal }) => {
  const [showActions, setShowActions] = useState(false)
  const navigate = useNavigate()

  const toggleShowActions = () => {
    setShowActions(prev => !prev)
  }

  const closeShowActions = () => {
    setShowActions(false)
  }

  const openLink = () => { 
    navigate('/panel/datos/oficinas/')
  }

  const handleAction = (disabled, callback) => {
    if (disabled) return
    callback()
  }

  if (type === 'rooms')
    return (
      <div className="PlaceCard">
        <div className="PlaceCard__Title">
          <h4 className="text-3 fw-bold">Sala {data.name}</h4>
        </div>

        <span className="text-3 fw-bold">Capacidad: {data.capacity}</span>
      </div>
    )

  if (type === 'offices')
    return (
      <div className="PlaceCard">
        <button className="PlaceCard__Button text-3" 
          onClick={toggleShowActions}
          onBlur={closeShowActions}
        >
          <FontAwesomeIcon icon={faEllipsisVertical} />
          {showActions && (
            <div className="PlaceCard__Menu">
              <div
                className={`PlaceCard__Menu-Action${
                  !data.books ? '--Disabled' : ''
                } text-4`}
                onClick={() => handleAction(!data.books, openLink)}
              >
                Ver reservas
              </div>
              <div
                className={`PlaceCard__Menu-Action${
                  data.books ? '--Disabled' : ''
                } text-4`}
                onClick={() =>
                  handleAction(data.book, () =>
                    openDeleteModal(data)
                  )
                }
              >
              Eliminar Oficina
              </div>
            </div> 
          )}
        </button>

        <div className="PlaceCard__Title--Left">
          <h4 className="text-3 fw-bold">Oficina {data.name}</h4>
          <span className="text-4">
            {!data.books ? 'Sin asignar' : 'Asignada'}
          </span>
        </div>

        <div className="PlaceCard__Info">
          <span className="text-4">Horas por mes:</span>{' '}
          <span className="text-3 fw-bold">{data.hours_by_month} hs</span>
          <span />
          <span className="text-4">Capacidad:</span>{' '}
          <span className="text-3 fw-bold">{data.capacity} personas</span>
        </div>
      </div>
    )
}

PlaceCard.propTypes = {
  type: propTypes.string.isRequired,
  data: propTypes.object.isRequired,
  openDeleteModal: propTypes.func,
}

export default PlaceCard
