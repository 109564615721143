import propTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'


const DisabledDay = ({ date, removeDay }) => {
  return (
    <div className="DisabledDay">
      <span className="text-5 fw-semibold">
        {`${date.split('-')[2]} de `}
        <span className="text-capitalize">
          {moment(date, 'YYYY-MM-DD').format('MMMM')}
        </span>
        {/* {` de ${date.split('-')[0]}`} */}
      </span>
      <button className="DisabledDay__Button text-5" onClick={() => removeDay(date)}>
        <FontAwesomeIcon icon={faX}/>
      </button>
    </div>
  )
}

DisabledDay.propTypes = {
  date: propTypes.string.isRequired,
  removeDay: propTypes.func.isRequired
}

export default DisabledDay