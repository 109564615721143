import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../context'
import ExpirationOverlay from './modals/ExpirationOverlay'


const ExtraCreditsAndHours = () => {
  const { user } = useContext(AppContext)
  const [showExpiration, setShowExpiration] = useState(false)
  const [creditsHoursExpiration, setCreditsHoursExpiration] = useState({
    creditsList: [{ id: 0, credits: 0, end_date: '' }],
    hoursList: [{ id: 0, hours: 0, end_date: '' }],
  })

  useEffect(() => {
    const creditsList = []
    const hoursList = []

    user.extra_plans.forEach(extra_plan => {
      if(extra_plan.available_credits !== 0)
        creditsList.push({
          id: extra_plan.id,
          credits: extra_plan.available_credits,
          end_date: extra_plan.end_date,
        })
      if(extra_plan.available_hours !== 0)
        hoursList.push({
          id: extra_plan.id,
          hours: extra_plan.available_hours,
          end_date: extra_plan.end_date,
        })
    })

    if(creditsList.length === 0)
      creditsList.push({ id: 0, credits: 0, end_date: '' })
    if(hoursList.length === 0)
      hoursList.push({ id: 0, hours: 0, end_date: '' })

    setCreditsHoursExpiration({
      creditsList,
      hoursList
    })
  }, [user.extra_plans])

  const toggleShowExpiration = () => {
    setShowExpiration((prev) => !prev)
  }

  if (user.total_extra_credit || user.total_extra_hours)
    return (
      <>
        <div className="ExtraCreditsAndHours text-4">
          {user.total_extra_credit !== 0 && (
            <span>Creditos extra: {user.total_extra_credit}</span>
          )}
          {user.total_extra_hours !== 0 && (
            <span>Horas extra: {user.total_extra_hours}</span>
          )}
          <div
            className="ExtraCreditsAndHours__Expiration"
            onClick={toggleShowExpiration}
          >
            Ver vencimientos
          </div>
        </div>
        {showExpiration && (
          <ExpirationOverlay
            closeModal={toggleShowExpiration}
            creditsHoursExpiration={creditsHoursExpiration}
          />
        )}
      </>
    )
}

ExtraCreditsAndHours.propTypes = {}

export default ExtraCreditsAndHours
