import { useContext } from 'react'
import PropTypes from 'prop-types'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AppContext } from '../context'
import { getDebtorCompany } from '../api/companies'
import useApi from '../hooks/useApi'


const DebtAlert = ({ company_id, companyInfo }) => {
  const { user, token } = useContext(AppContext)
  const [ debtorCompany ] = useApi(
    () => getDebtorCompany(token, {company_id}),
    {
      data: 0
    },
    [token, company_id, companyInfo]
  )

  if(debtorCompany && debtorCompany.data !== 0) return (
    <div className="DebtAlert text-4">
      <span className="me-3 text-2">
        <FontAwesomeIcon icon={faCircleExclamation}/>
      </span>
      {companyInfo?.data?.name} tiene una deuda pendiente de ${debtorCompany?.data}
    </div>
  )

  if (user?.debt && !company_id) return (
    <div className="DebtAlert text-4">
      <span className="me-3 text-2">
        <FontAwesomeIcon icon={faCircleExclamation}/>
      </span>
      Acercate a recepción para abonar tu saldo pendiente de ${user?.debt}
    </div>
  )
}

DebtAlert.propTypes = {
  company_id: PropTypes.number,
  companyInfo: PropTypes.object,
}

export default DebtAlert