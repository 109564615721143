import propTypes from 'prop-types'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../context'
import { delDeleteChair, postCreateChair } from '../api/shared-areas'

const ChairsContainer = ({ chairs, updateChairs }) => {
  const { token } = useContext(AppContext)
  const [buttonDisable, setButtonDisable] = useState(false)
  const [chairsGroups, setChairsGroups] = useState([])

  useEffect(() => {
    const chairsSorted = chairs.sort((a, b) => a.number - b.number)

    let list = []
    const dividend = 12

    for (let index = 0; index < Math.ceil(chairs.length / dividend); index++) {
      list = [
        ...list,
        {
          id: index,
          group: chairsSorted.slice(index * dividend, index * dividend + dividend)
        },
      ]
    }

    setChairsGroups(list)
  }, [chairs])

  const handleAddChair = async () => {
    if (buttonDisable) return
    setButtonDisable(true)

    const response = await postCreateChair(token)

    if (response.error) {
      // console.error(response.error)
      return
    }
    else if (response) {
      updateChairs()
    }
    setButtonDisable(false)
  }

  const handleDeleteChair = async () => {
    if (buttonDisable) return
    setButtonDisable(true)

    const response = await delDeleteChair(token)

    if (response.error) {
      return
    }
    else if (response) {
      updateChairs()
    }
    setButtonDisable(false)
  }

  return (
    <section className="ChairsContainer">
      <header className="ChairsContainer__Header">
        <h2 className="text-2">Puestos de espacios compartidos</h2>
        <button className="button-green-panel" onClick={handleAddChair}>
          <FontAwesomeIcon icon={faPlus} />
          Añadir puesto
        </button>
        <button className="button-green-panel" onClick={handleDeleteChair}>
          Eliminar puesto
        </button>
      </header>

      <div className="ChairsContainer__Cards">
        {chairsGroups.map((chairsGroup) => (
          <div key={chairsGroup.id} className="ChairsContainer__Card-Group">
            {chairsGroup.group.map((chair) => (
              <div key={chair.id} className="ChairsContainer__Card-Item">
                Puesto {chair.number}
              </div>
            ))}
          </div>
        ))}
      </div>
    </section>
  )
}

ChairsContainer.propTypes = {
  chairs: propTypes.array.isRequired,
  updateChairs: propTypes.func.isRequired,
}

export default ChairsContainer
