import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import DebtAlert from '../../../components/DebtAlert'
import ExtraCreditsAndHours from '../../../components/ExtraCreditsAndHours'
import InfoCard from '../../../components/InfoCard'
import CancelPlanModal from '../../../components/modals/CancelPlanModal'
import HelpModal from '../../../components/modals/HelpModal'
import PanelPlanCard from '../../../components/PanelPlanCard'
import { AppContext } from '../../../context'


const GeneralUserPanel = () => {
  const { user } = useContext(AppContext)
  const [showHelp, setShowHelp] = useState(false)
  const [showHelpModal, setShowHelpModal] = useState(false)
  const [plansCanBeCanceled, setPlansCanBeCanceled] = useState([])
  const [showCancelPlanModal, setShowCancelPlanModal] = useState(false)
  const [cancelPlanInfo, setCancelPlanInfo] = useState({
    user_plan_id: 0,
    planName: ''
  })


  const openShowCancelPlanModal = (user_plan_id, planName) => {
    setCancelPlanInfo({ user_plan_id, planName })
    setShowCancelPlanModal(true)
  }
  const closeShowCancelPlanModal = () => {
    setShowCancelPlanModal(false)
  }


  useEffect(() => {
    setShowHelp(user?.profile?.is_first_time)
  }, [user])

  useEffect(() => {
    const list = user?.plans?.map((plan) => {
      let totalCredits = 0
      let totalHours = 0

      if (!user.profile?.is_first_time) {
        totalCredits = plan.plan.credits
        totalHours = plan.plan.hours
      } else {
        if (plan.plan.credits >= 2) {
          totalCredits = 2
          totalHours = 0
        } else {
          totalCredits = plan.plan.credits
          totalHours = 0
        }
      }

      let availableCredits = plan.available_credits
      let availableHours = plan.available_hours

      return totalCredits === availableCredits && totalHours === availableHours && !plan.paid
    })

    setPlansCanBeCanceled(list ?? [])
  }, [user])


  if (user?.plans)
    return (
      <>
        <Helmet>
          <title>Vida Cowork: General</title>
        </Helmet>
        <main className="GeneralUserPanel panel-main py-4 px-3 px-md-5">
          <DebtAlert />
          {showHelp && (
            <div className="GeneralUserPanel__Explanation gap-3 py-3 py-md-5 px-3">
              <h1 className="text-1 text-orange">
              ¡Llevá tu experiencia al próximo nivel!
              </h1>
              <p className="mb-0">
              Adquiri  planes, organiza tus jornadas y gestiona tus reservas con un sólo click. 
              </p>
              <button
                className="GeneralUserPanel__Explanation-Button"
                onClick={() => setShowHelp(false)}
              >
                <FontAwesomeIcon icon={faXmark} />
              </button>
            </div>
          )}

          {user?.plans?.length === 0 && <PanelPlanCard direction="horizontal" />}

          {user?.plans?.length > 0 && (
            <div
              className={`GeneralUserPanel__${
                user?.plans?.length === 1 ? 'One-Plan' : 'Two-Plans'
              }`}
            >
              <div className="GeneralUserPanel__Plan-Item">
                <PanelPlanCard
                  direction={
                    user?.plans?.length === 1 ? 'horizontal' : 'vertical'
                  }
                  plan={user?.plans[0]}
                  marginBotton={plansCanBeCanceled.some((bool) => bool)}
                  canBeCanceled={plansCanBeCanceled[0]}
                  openModal={openShowCancelPlanModal}
                />
                <div className="GeneralUserPanel__Data">
                  <InfoCard
                    title="Créditos Vida"
                    description="Disponibles para reservar un puesto en el espacio compartido."
                    value={user?.plans[0]?.available_credits}
                    maxValue={user?.plans[0]?.plan?.credits}
                    showHelp={() => setShowHelpModal(true)}
                  />
                  <InfoCard
                    title="Horas en Salas"
                    description="Disponibles para reservar las salas de reuniones."
                    value={user?.plans[0]?.available_hours}
                    maxValue={user?.plans[0]?.plan?.hours}
                    showHelp={() => setShowHelpModal(true)}
                  />
                </div>
              </div>
              {user?.plans?.length === 2 && (
                <div className="GeneralUserPanel__Plan-Item">
                  <PanelPlanCard
                    direction="vertical"
                    plan={user?.plans[1]}
                    marginBotton={plansCanBeCanceled.some((bool) => bool)}
                    canBeCanceled={plansCanBeCanceled[1]}
                    openModal={openShowCancelPlanModal}
                  />
                  <div className="GeneralUserPanel__Data">
                    <InfoCard
                      title="Créditos Vida"
                      description="Disponibles para reservar un puesto en el espacio compartido."
                      value={user?.plans[1]?.available_credits}
                      maxValue={user?.plans[1]?.plan?.credits}
                      showHelp={() => setShowHelpModal(true)}
                    />
                    <InfoCard
                      title="Horas en Salas"
                      description="Disponibles para reservar las salas de reuniones."
                      value={user?.plans[1]?.available_hours}
                      maxValue={user?.plans[1]?.plan?.hours}
                      showHelp={() => setShowHelpModal(true)}
                    />
                  </div>
                </div>
              )}
            </div>
          )}

          <ExtraCreditsAndHours />

          {user?.plans?.length === 0 && (
            <div className="d-flex flex-column justify-content-center align-items-center h-100">
              <h2 className="text-1">Sé parte de la #ComunidadVida</h2>
              <p className="text-center text-5 mt-2">
                Con la compra de un plan podés reservar tu puesto de trabajo y horas en salas de reuniones
              </p>
              <Link to="/panel/planes" className="button-orange mt-3">
                Adquirir un plan
              </Link>
            </div>
          )}
        </main>

        {showHelpModal && (
          <HelpModal closeModal={() => setShowHelpModal(false)} />
        )}
        {
          showCancelPlanModal && (
            <CancelPlanModal closeModal={closeShowCancelPlanModal} cancelPlanInfo={cancelPlanInfo} />
          )
        }
      </>
    )
}

export default GeneralUserPanel
