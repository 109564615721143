import { useContext, useEffect, useState } from 'react'
import DataAdminHeader from '../../../containers/DataAdminHeader'
import { Helmet } from 'react-helmet'
import TableSearcher from '../../../components/TableSearcher'
import Table from '../../../containers/Table'
import { AppContext } from '../../../context'
import { useSearchParams } from 'react-router-dom'
import useApi from '../../../hooks/useApi'
import HoursAndCreditsModal from '../../../components/modals/HoursAndCreditsModal'
import { getUsersData } from '../../../api/admin-data'
import { getExtraHoursAndCreditsList } from '../../../api/plans'


const PAGE_SIZE = 25
const TABLE_HEADERS = [
  'Nombre y Apellido',
  'Empresa',
  'Email',
  'Horas extra activas',
  'Créditos extra activos',
  'Deuda',
]
// const TABLE_HEADERS = [
//   {
//     id: 0,
//     label: 'Nombre y Apellido',
//     value: 'full_name'
//   }, {
//     id: 1,
//     label: 'Empresa',
//     value: 'company'
//   }, {
//     id: 2,
//     label: 'Email',
//     value: 'email'
//   }, {
//     id: 3,
//     label: 'Horas extra activas',
//     value: 'hours'
//   }, {
//     id: 4,
//     label: 'Créditos extra activos',
//     value: 'credits'
//   }, {
//     id: 5,
//     label: 'Deuda',
//     value: 'debt'
//   },
// ]
const TABLE_HEADERS_MOBILE = [
  {
    id: 0,
    label: 'Nombre',
    value: 'full_name'
  },   {
    id: 1,
    label: 'Empresa',
    value: 'company'
  }, {
    id: 2,
    label: 'Deuda',
    value: 'debt'
  }
]


const HoursAndCreditsDataAdminPanel = () => {
  const { token } = useContext(AppContext)
  const [ searchParams, setSearchParams ] = useSearchParams()
  const [ page, setPage ] = useState(1)
  const [ searchSpecial, setSearchSpecial ] = useState({
    user: {
      id: searchParams.get('user_id') ?? '',
      label: searchParams.get('user_label') ?? '',
    },
    company: {
      id: searchParams.get('company_id') ?? '',
      label: searchParams.get('company_label') ?? '',
    },
  })
  const [ search, setSearch ] = useState(searchParams.get('search') ?? '')
  const [ filtersSelected, setFiltersSelected ] = useState([
    {
      label: 'Por estado',
      defaultOption: {
        label: 'Todos los estados',
        value: '',
      },
      option: {
        label: 'Todos los estados',
        value: '',
      },
    },
  ])
  const [ order_by, setOrderBy ] = useState('')
  const [ userData, setUserData ] = useState({})
  const [ showInfoModal, setShowInfoModal ] = useState(false)
  const [ selectedItems, setSelectedItems ] = useState([])


  const params = {
    page,
    per_page: PAGE_SIZE,
    search,
    order_by,
  }
  const [usersData, updateUsersData] = useApi(
    () => getExtraHoursAndCreditsList(token, params),
    {
      page: 1,
      total_pages: 1,
      total_items: 0,
      data: [],
    },
    [token, page, search, filtersSelected, order_by, searchSpecial]
  )


  useEffect(() => {
    setPage(1)
  }, [search])


  const handleSearch = (e) => {
    setSearch(e.target.value)
  }
  const openShowInfoModal = (data) => {
    setUserData(data)
    setShowInfoModal(true)
  }
  const closeShowInfoModal = () => {
    setUserData({})
    setShowInfoModal(false)
  }

  const handleSelectItem = (item) => {
    if (selectedItems.find(selectedItem => (selectedItem.id === item.id) && (selectedItem?.company?.id === item?.company?.id))) {
      setSelectedItems((prev) => prev.filter((selectedItem) => {
        if(selectedItem?.company?.id === item?.company?.id) {
          return selectedItem.id !== item.id
        } else {
          return selectedItem
        }
      }))
    } else {
      setSelectedItems(prev => [...prev, item])
    }
  }


  return (
    <>
      <Helmet>
        <title>Vida Cowork: Planes</title>
      </Helmet>

      <main className="DataAdminPanel--planes panel-main panel-main--table">
        <DataAdminHeader currentSection="horas y créditos">
          <TableSearcher
            search={search}
            handleSearch={handleSearch}
            filtersSelected={filtersSelected}
            setFiltersSelected={setFiltersSelected}
            // filtersForCard={filtersForCard}
            totalItems={usersData.total_items}
            type={'hours-and-credits'}
            setSearchParams={setSearchParams}
            searchSpecial={searchSpecial}
            setSearchSpecial={setSearchSpecial}
          />
        </DataAdminHeader>

        <Table
          type="Hours-And-Credits"
          headers={TABLE_HEADERS}
          headersMobile={TABLE_HEADERS_MOBILE}
          data={usersData.data}
          updateData={() => {
            updateUsersData()
            setSelectedItems([])
          }}
          page={usersData.page}
          setPage={setPage}
          perPage={PAGE_SIZE}
          totalPages={usersData.total_pages}
          totalItems={usersData.total_items}
          setOrderBy={setOrderBy}
          showInfoMobile
          openInfoModal={openShowInfoModal}
          selectedParentItems={selectedItems}
          handleParentItems={handleSelectItem}
        />

        {showInfoModal && (
          <HoursAndCreditsModal
            data={userData}
            closeModal={closeShowInfoModal}
          />
        )}
      </main>
    </>
  )
}

export default HoursAndCreditsDataAdminPanel
