import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import Table from '../../../containers/Table'
import Input from '../../Input'
import ModalHeader from '../../ModalHeader'
import PanelModalSelector from '../../PanelModalSelector'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useEffect, useState } from 'react'
import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import moment from 'moment'
import Steps from '../../Steps'


const CreateOfficeBookModalStep1 = ({
  closeModal,
  offices,
  officeSelected,
  companiesData,
  updateCompaniesData,
  search,
  setSearch,
  setPage,
  per_page,
  setBody,
  setStep,
}) => {
  const [firstShow, setFirstShow] = useState(true)

  const [selectedItemsId, setSelectedItemsId] = useState([])
  const [selectedItems, setSelectedItems] = useState([])

  const [currentOfficeSelected, setCurrentOfficeSelected] = useState({})
  const [officeNameSelected, setOfficeNameSelected] = useState(
    officeSelected.name
  )
  const [yesterday, setYesterday] = useState(new Date())
  const [error, setError] = useState('')

  useEffect(() => {
    setSelectedItems(
      selectedItemsId.map((id) =>
        companiesData.data.find((item) => item.id === id)
      )
    )
  }, [selectedItemsId, companiesData.data])

  useEffect(() => {
    if (firstShow) return

    setSelectedItems([])
    setSelectedItemsId([])
  }, [companiesData.data])

  useEffect(() => {
    setCurrentOfficeSelected(
      offices.find((office) => office.name === officeNameSelected)
    )
  }, [officeNameSelected])

  useEffect(() => {
    if (companiesData.data.length === 0) return

    if (firstShow) return setFirstShow(false)

    if (selectedItemsId.length === 0)
      return setError('Es necesario seleccionar una empresa')
    else setError('')
  }, [selectedItemsId, companiesData.data])

  useEffect(() => {
    const today = new Date()
    const past = new Date()
    past.setDate(today.getDate() - 1)

    setYesterday(past)
  }, [])

  const formik1 = useFormik({
    initialValues: {
      start_date: '',
      end_date: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      start_date: Yup.date()
        .required('La fecha de inicio es requerida')
        .min(yesterday, 'La fecha no puede ser menor a la actual')
        .test(
          'test .5',
          'La fecha no puede solaparse con la reserva actual',
          (date) => {
            if (currentOfficeSelected.books) {
              const startDate = moment(
                currentOfficeSelected.books[0].start_date,
                'YYYY-MM-DD'
              ).format('YYYY-MM-DD')
              const endDate = moment(
                currentOfficeSelected.books[0].end_date,
                'YYYY-MM-DD'
              ).format('YYYY-MM-DD')
              const currentDate = moment(date).format('YYYY-MM-DD')

              return !(startDate <= currentDate && currentDate <= endDate)
            }
            return true
          }
        ),
      end_date: Yup.date()
        .required('La fecha de fin es requerida')
        .min(new Date(), 'La fecha no puede ser menor a la actual')
        .test(
          'test .5',
          'La fecha no puede solaparse con la reserva actual',
          (date) => {
            if (currentOfficeSelected.books) {
              const startDate = moment(
                currentOfficeSelected.books[0].start_date,
                'YYYY-MM-DD'
              ).format('YYYY-MM-DD')
              const endDate = moment(
                currentOfficeSelected.books[0].end_date,
                'YYYY-MM-DD'
              ).format('YYYY-MM-DD')
              const currentDate = moment(date).format('YYYY-MM-DD')

              return !(startDate <= currentDate && currentDate <= endDate)
            }
            return true
          }
        ),
    }),
    onSubmit: async ({ start_date, end_date }) => {
      if (error.length !== 0) return
      if (selectedItemsId.length === 0)
        return setError('Es necesario seleccionar una empresa')

      if (new Date(start_date).getTime() >= new Date(end_date).getTime())
        return formik1.setFieldError(
          'end_date',
          'La fecha de fin no puede ser menor a la de inicio'
        )

      setBody({
        office_id: `${currentOfficeSelected.id}`,
        company_id: `${selectedItemsId}`,
        start_date,
        end_date,
      })

      setStep((prev) => prev + 1)
    },
  })

  const officesNames = offices
    .filter((office) => !(office.books?.length === 2))
    .map((office) => office.name)
  const tableHeaders = ['Nombre', 'Miembros']

  const handleSearch = (e) => {
    setSearch(e.target.value)
  }

  const handleSelectItem = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItemsId((prev) => prev.filter((id) => id !== item.id))
    } else {
      setSelectedItemsId([item.id])
    }
  }

  const handleOfficeNameSelected = (officeIndex) => {
    setOfficeNameSelected(officesNames[officeIndex])
  }

  const handleDate = (e) => {
    const target = e.target

    if ('showPicker' in HTMLInputElement.prototype) {
      target.showPicker()
    }
  }

  return (
    <div className="PanelModal px-3" onClick={closeModal}>
      <div
        className="PanelModal__Card--Medium"
        onClick={(e) => e.stopPropagation()}
      >
        <ModalHeader title={'Asignar oficina'} closeModal={closeModal} />

        <div className="d-flex justify-content-center pt-2">
          <PanelModalSelector
            options={officesNames}
            optionSelected={officeNameSelected}
            onChange={handleOfficeNameSelected}
          />
        </div>

        {currentOfficeSelected.books && (
          <div className='p-2 b-r-10 mt-2 text-center text-error bg-red'>
            Período ocupado: {' '}
            {moment(
              currentOfficeSelected.books[0].start_date,
              'YYYY-MM-DD'
            ).format('DD/MM/YY')}{' '}
            -{' '}
            {moment(
              currentOfficeSelected.books[0].end_date,
              'YYYY-MM-DD'
            ).format('DD/MM/YY')}
          </div>
        )}

        <div className="py-3">
          <h3 className="text-3 mb-2">Buscar empresa</h3>

          <div className="d-flex align-items-center">
            <FontAwesomeIcon icon={faSearch} />
            <input
              className="PanelModal__Input text-5 ms-2"
              name="search"
              type="text"
              value={search}
              onChange={handleSearch}
            />
          </div>
        </div>

        <Table
          type={'Assign-Office'}
          headers={tableHeaders}
          data={companiesData.data}
          updateData={updateCompaniesData}
          page={companiesData.page}
          setPage={setPage}
          perPage={per_page}
          totalPages={companiesData.total_pages}
          totalItems={companiesData.total_items}
          selectedParentItems={selectedItems}
          handleParentItems={handleSelectItem}
          rounded
        />

        <div className="PanelModal__Inputs-Container pb-3">
          <Input
            type="date"
            label="Desde"
            name="start_date"
            min={moment().format('YYYY-MM-DD')}
            value={formik1.values.start_date}
            onClick={handleDate}
            onChange={formik1.handleChange}
            error={formik1.errors.start_date}
            icon={faCalendar}
          />
          <Input
            type="date"
            label="Hasta"
            name="end_date"
            min={moment().add(1, 'd').format('YYYY-MM-DD')}
            value={formik1.values.end_date}
            onClick={handleDate}
            onChange={formik1.handleChange}
            error={formik1.errors.end_date}
            icon={faCalendar}
          />
        </div>

        {error.length !== 0 && (
          <div className="d-flex justify-content-center">
            <span className="PanelModal__Error text-5 text-center mt-4 mb-2">
              {error}
            </span>
          </div>
        )}

        <div className="d-flex justify-content-center">
          <button
            className={`button-green-panel ${
              error.length !== 0 ||
              (formik1.errors.start_date &&
                formik1.values.start_date.length === 0) ||
              (formik1.errors.end_date && formik1.values.end_date.length === 0)
                ? 'button-disabled'
                : ''
            } mt-4 mb-3`}
            type="submit"
            onClick={formik1.handleSubmit}
          >
            Siguiente
          </button>
        </div>

        <Steps totalCount={2} currentCount={1} />
      </div>
    </div>
  )
}

CreateOfficeBookModalStep1.propTypes = {
  closeModal: PropTypes.func.isRequired,
  offices: PropTypes.array.isRequired,
  officeSelected: PropTypes.object.isRequired,
  companiesData: PropTypes.object.isRequired,
  updateCompaniesData: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  per_page: PropTypes.number.isRequired,
  setBody: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
}

export default CreateOfficeBookModalStep1
